/**
 * @param {{freeSpaceImage:any,tileCategories:any,hide_registeration_form:any}} data
 */

// import url from "./url";
// import mockSiteData from "./mock/data/site";
import $ from "jquery";
import client from "../../Client";
import "../../config";
import Cookies from "universal-cookie";
// import { useLocation, useHistory } from "react-router-dom";
// import { useEffect, useState } from "react";

const cookies = new Cookies();

// const {
//   endpoints,
//   baseApiUrl: baseUrl,
//   baseApiUrlWithoutWin: baseUrlWithoutWin,
// } = url;

const getLocale = (cookiePriority = false) => {
  let date = new Date(); // Now
  date.setDate(date.getDate() + 30);

  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  let localeName = "en-US";
  let pathArray = window.location.pathname.split("/");

  /* Locale set based on Url param OR cookie OR Hostname */
  if (
    pathArray[1] != undefined &&
    pathArray[1] == "en" &&
    cookiePriority == false
  ) {
    cookies.set("Language", "en", { path: "/", expires: date });
    localeName = "en-US";
    if (
      hostname == "valuevillage.ca" ||
      hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
      hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
      hostname == "www.valuevillage.ca" ||
      hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
      hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
    ) {
      localeName = "en-CA";
    } else if (
      hostname == "villagedesvaleurs.ca" ||
      hostname == "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
      hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
      hostname == "www.villagedesvaleurs.ca" ||
      hostname == "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
      hostname == "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
    ) {
      localeName = "en-CA";
    } else if (
      hostname == "gogreendropca-react-test.metaltoad-sites.com" ||
      hostname == "www.gogreendropca-react-test.metaltoad-sites.com" ||
      hostname == "gogreendropca-react-dev.metaltoad-sites.com" ||
      hostname == "www.gogreendropca-react-dev.metaltoad-sites.com" ||
      hostname == "gogreendrop.ca" ||
      hostname == "www.gogreendrop.ca"
    ) {
      localeName = "en-CA";
    } else if (
      hostname == "savers.com.au" ||
      hostname == "saverscomau-react-test.metaltoad-sites.com" ||
      hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
      hostname == "www.savers.com.au" ||
      hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
      hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
    ) {
      localeName = "en-AU";
    } else if (
      hostname == "valuevillage.com" ||
      hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
      hostname == "www.valuevillage.com" ||
      hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
    ) {
      localeName = "en-VI";
    }
  } else if (
    pathArray[1] != undefined &&
    pathArray[1] == "fr" &&
    cookiePriority == false
  ) {
    cookies.set("Language", "fr", { path: "/", expires: date });
    localeName = "fr-CA";

    if (
      hostname == "valuevillage.com" ||
      hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
      hostname == "www.valuevillage.com" ||
      hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
    ) {
      localeName = "fr-TG";
    }
  } else if (
    pathArray[1] != undefined &&
    pathArray[1] == "es" &&
    cookiePriority == false
  ) {
    cookies.set("Language", "es", { path: "/", expires: date });
    localeName = "es";

    if (
      hostname == "valuevillage.com" ||
      hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
      hostname == "www.valuevillage.com" ||
      hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
    ) {
      localeName = "es-VE";
    }
  } else if (
    cookies.get("Language") != undefined &&
    cookies.get("Language") != ""
  ) {
    let cookieLang = cookies.get("Language");

    if (cookieLang == "en") {
      localeName = "en-US";
      if (
        hostname == "valuevillage.ca" ||
        hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
        hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
        hostname == "www.valuevillage.ca" ||
        hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
        hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
      ) {
        localeName = "en-CA";
      } else if (
        hostname == "villagedesvaleurs.ca" ||
        hostname == "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
        hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
        hostname == "www.villagedesvaleurs.ca" ||
        hostname ==
          "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
        hostname == "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
      ) {
        localeName = "en-CA";
      } else if (
        hostname == "gogreendropca-react-test.metaltoad-sites.com" ||
        hostname == "www.gogreendropca-react-test.metaltoad-sites.com" ||
        hostname == "gogreendropca-react-dev.metaltoad-sites.com" ||
        hostname == "www.gogreendropca-react-dev.metaltoad-sites.com" ||
        hostname == "gogreendrop.ca" ||
        hostname == "www.gogreendrop.ca"
      ) {
        localeName = "en-CA";
      } else if (
        hostname == "savers.com.au" ||
        hostname == "saverscomau-react-test.metaltoad-sites.com" ||
        hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
        hostname == "www.savers.com.au" ||
        hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
        hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
      ) {
        localeName = "en-AU";
      } else if (
        hostname == "valuevillage.com" ||
        hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
        hostname == "www.valuevillage.com" ||
        hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
      ) {
        localeName = "en-VI";
      }
    } else if (cookieLang == "fr") {
      localeName = "fr-CA";
      if (
        hostname == "valuevillage.com" ||
        hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
        hostname == "www.valuevillage.com" ||
        hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
      ) {
        localeName = "fr-TG";
      }
    } else if (cookieLang == "es") {
      localeName = "es";
      if (
        hostname == "valuevillage.com" ||
        hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
        hostname == "www.valuevillage.com" ||
        hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
      ) {
        localeName = "es-VE";
      }
    }
  } else if (
    hostname == "valuevillage.ca" ||
    hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
    hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
    hostname == "www.valuevillage.ca" ||
    hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
    hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
  ) {
    localeName = "en-CA";
    cookies.set("Language", "en", { path: "/", expires: date });
  } else if (
    hostname == "gogreendropca-react-test.metaltoad-sites.com" ||
    hostname == "www.gogreendropca-react-test.metaltoad-sites.com" ||
    hostname == "gogreendropca-react-dev.metaltoad-sites.com" ||
    hostname == "www.gogreendropca-react-dev.metaltoad-sites.com" ||
    hostname == "gogreendrop.ca" ||
    hostname == "www.gogreendrop.ca"
  ) {
    localeName = "en-CA";
    cookies.set("Language", "en", { path: "/", expires: date });
  } else if (
    hostname == "villagedesvaleurs.ca" ||
    hostname == "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
    hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
    hostname == "www.villagedesvaleurs.ca" ||
    hostname == "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
    hostname == "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
  ) {
    localeName = "fr-CA";
    cookies.set("Language", "fr", { path: "/", expires: date });
  } else if (
    hostname == "savers.com.au" ||
    hostname == "saverscomau-react-test.metaltoad-sites.com" ||
    hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
    hostname == "www.savers.com.au" ||
    hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
    hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
  ) {
    localeName = "en-AU";
    cookies.set("Language", "en", { path: "/", expires: date });
  } else if (
    hostname == "valuevillage.com" ||
    hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
    hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
    hostname == "www.valuevillage.com" ||
    hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
    hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
  ) {
    localeName = "en-VI";
    cookies.set("Language", "en", { path: "/", expires: date });
  }
  /* Locale set based on URL/Cookie/Hostname code ends here */

  /* if no cookie is there set default cookie */
  if (cookies.get("Language") == undefined || cookies.get("Language") == "") {
    cookies.set("Language", "en", { path: "/", expires: date });
  }
  /* Default cookie code ends here */

  return localeName;
};

const getDomain = () => {
  return global.config.domain;
};

const getDefaultLanguage = () => {
  return global.config.defaultLanguage;
};

const getGlobalData = () => {
  return global.config;
};

const getLocationApiKey = () => {
  return global.config.locationApiKey;
};

const getBaseUrl = () => {
  let origin = window.location.origin;
  let charlist = "/";
  origin = origin.replace(new RegExp("[" + charlist + "]+$"), "");
  return origin;
};

const getHrefLang = (allAliasesObject) => {
  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  let hrefsArray = [];
  let staticHrefsArray = [];

  if (allAliasesObject != undefined && Object.keys(allAliasesObject).length > 0) {
    Object.keys(allAliasesObject).forEach((item) => {
      if (item == "en-US" || item == "es") {
        if (item == "en-US") {
          if (
            hostname == "savers.com" ||
            hostname == "saverscom-react-test.metaltoad-sites.com" ||
            hostname == "saverscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.savers.com" ||
            hostname == "www.saverscom-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "EN",
              locales: item,
              urlAlias: allAliasesObject[item],
            });

            if (hostname == "savers.com" || hostname == "www.savers.com") {
              staticHrefsArray.push({
                lang: "EN",
                locales: "en-au",
                url: "https://savers.com.au/",
              });
            }
            if (
              hostname == "saverscom-react-test.metaltoad-sites.com" ||
              hostname == "www.saverscom-react-test.metaltoad-sites.com"
            ) {
              staticHrefsArray.push({
                lang: "EN",
                locales: "en-au",
                url: "http://saverscomau-react-test.metaltoad-sites.com",
              });
            }
            if (
              hostname == "saverscom-react-dev.metaltoad-sites.com" ||
              hostname == "www.saverscom-react-dev.metaltoad-sites.com"
            ) {
              staticHrefsArray.push({
                lang: "EN",
                locales: "en-au",
                url: "http://saverscomau-react-dev.metaltoad-sites.com",
              });
            }
          }
        }
        if (item == "es") {
          if (
            hostname == "savers.com" ||
            hostname == "saverscom-react-test.metaltoad-sites.com" ||
            hostname == "saverscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.savers.com" ||
            hostname == "www.saverscom-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "ES",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "savers.com.au" ||
            hostname == "saverscomau-react-test.metaltoad-sites.com" ||
            hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
            hostname == "www.savers.com.au" ||
            hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "ES",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "villagedesvaleurs.ca" ||
            hostname ==
              "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.villagedesvaleurs.ca" ||
            hostname ==
              "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname ==
              "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "ES",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "valuevillage.ca" ||
            hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.ca" ||
            hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "ES",
              locales: item,
              externalUrlLocale: "es",
              urlAlias: allAliasesObject[item],
            });
          }
        }
      }
      if (item == "en-AU") {
        if (
          hostname == "savers.com.au" ||
          hostname == "saverscomau-react-test.metaltoad-sites.com" ||
          hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
          hostname == "www.savers.com.au" ||
          hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
          hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
        ) {
          hrefsArray.push({
            lang: "EN",
            locales: item,
            urlAlias: allAliasesObject[item],
          });
          if (
            hostname == "savers.com.au" ||
            hostname == "www.savers.com.au"
          ) {
            staticHrefsArray.push({
              lang: "EN",
              locales: "en-us",
              url: "https://savers.com/",
            });
          }
          if (
            hostname == "saverscomau-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscomau-react-test.metaltoad-sites.com"
          ) {
            staticHrefsArray.push({
              lang: "EN",
              locales: "en-us",
              url: "http://saverscom-react-test.metaltoad-sites.com",
            });
          }
          if (
            hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
            hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
          ) {
            staticHrefsArray.push({
              lang: "EN",
              locales: "en-us",
              url: "http://saverscom-react-dev.metaltoad-sites.com",
            });
          }
        }
      }
      if (item == "en-CA" || item == "fr-CA") {
        if (item == "en-CA") {
          if (
            hostname == "villagedesvaleurs.ca" ||
            hostname ==
              "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.villagedesvaleurs.ca" ||
            hostname ==
              "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname ==
              "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "EN",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "valuevillage.ca" ||
            hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.ca" ||
            hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
          ) {
            if (
              hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
              hostname == "www.valuevillageca-react-test.metaltoad-sites.com"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: item,
                externalUrlLocale: "en-us",
                urlAlias: allAliasesObject[item],
                externalRedirectUrl:
                  "http://valuevillagecom-react-test.metaltoad-sites.com/",
              });
            }
            if (
              hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
              hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: item,
                externalUrlLocale: "en-us",
                urlAlias: allAliasesObject[item],
                externalRedirectUrl:
                  "http://valuevillagecom-react-dev.metaltoad-sites.com/",
              });
            }
            if (
              hostname == "valuevillage.ca" ||
              hostname == "www.valuevillage.ca"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: item,
                externalUrlLocale: "en-us",
                urlAlias: allAliasesObject[item],
              });
            }
          }
        }
        if (item == "fr-CA") {
          if (
            hostname == "villagedesvaleurs.ca" ||
            hostname ==
              "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname == "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.villagedesvaleurs.ca" ||
            hostname ==
              "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
            hostname ==
              "www.villagedesvaleurscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "FR",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "valuevillage.ca" ||
            hostname == "valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "valuevillageca-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.ca" ||
            hostname == "www.valuevillageca-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillageca-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "FR",
              locales: item,
              externalUrlLocale: "fr-TG",
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "savers.com" ||
            hostname == "saverscom-react-test.metaltoad-sites.com" ||
            hostname == "saverscom-react-dev.metaltoad-sites.com" ||
            hostname == "www.savers.com" ||
            hostname == "www.saverscom-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "FR",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
          if (
            hostname == "savers.com.au" ||
            hostname == "saverscomau-react-test.metaltoad-sites.com" ||
            hostname == "saverscomau-react-dev.metaltoad-sites.com" ||
            hostname == "www.savers.com.au" ||
            hostname == "www.saverscomau-react-test.metaltoad-sites.com" ||
            hostname == "www.saverscomau-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "FR",
              locales: item,
              urlAlias: allAliasesObject[item],
            });
          }
        }
      }
      if (item == "en-VI" || item == "fr-TG" || item == "es-VE") {
        if (item == "en-VI") {
          if (
            hostname == "valuevillage.com" ||
            hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.com" ||
            hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
          ) {
            if (
              hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
              hostname == "www.valuevillagecom-react-test.metaltoad-sites.com"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: "en-us",
                externalUrlLocale: "en-ca",
                urlAlias: allAliasesObject[item],
                externalRedirectUrl:
                  "http://valuevillageca-react-test.metaltoad-sites.com/",
              });
            }
            if (
              hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
              hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: "en-us",
                externalUrlLocale: "en-ca",
                urlAlias: allAliasesObject[item],
                externalRedirectUrl:
                  "http://valuevillageca-react-dev.metaltoad-sites.com/",
              });
            }
            if (
              hostname == "valuevillage.com" ||
              hostname == "www.valuevillage.com"
            ) {
              hrefsArray.push({
                lang: "EN",
                locales: "en-us",
                externalUrlLocale: "en-ca",
                urlAlias: allAliasesObject[item],
              });
            }
          }
        }
        if (item == "fr-TG") {
          if (
            hostname == "valuevillage.com" ||
            hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.com" ||
            hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "FR",
              locales: item,
              externalUrlLocale: "fr-ca",
              urlAlias: allAliasesObject[item],
            });
          }
        }
        if (item == "es-VE") {
          if (
            hostname == "valuevillage.com" ||
            hostname == "valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "valuevillagecom-react-dev.metaltoad-sites.com" ||
            hostname == "www.valuevillage.com" ||
            hostname == "www.valuevillagecom-react-test.metaltoad-sites.com" ||
            hostname == "www.valuevillagecom-react-dev.metaltoad-sites.com"
          ) {
            hrefsArray.push({
              lang: "ES",
              locales: "es",
              externalUrlLocale: "fr-ca",
              urlAlias: allAliasesObject[item],
            });
          }
        }
      }
    });
  }
  return [hrefsArray, staticHrefsArray];
};

const addLangKeyPath = (path) => {
  if (path != undefined && path != "") {
    let pathArray = window.location.pathname.split("/");
    const pattern = /[a-z]/;
    if (
      pathArray[1] != undefined &&
      pathArray[1].match(pattern) &&
      pathArray[1].length < 3
    ) {
      let pat = /^https?:\/\//i;
      if (pat.test(path)) {
        return path;
      } else {
        const charlist = "/";
        path = path.replace(new RegExp("^[" + charlist + "]+"), "");
        path = path.replace(new RegExp("[" + charlist + "]+$"), "");
        return "/" + pathArray[1] + "/" + path;
      }
    } else {
      return path;
    }
  }
  return "";
};

const fetchDynamicPage = async (urlAlias) => {
  let currLocale = getLocale();
  let data = {};
  await client
    .getEntries({
      content_type: "pageLayout",
      "fields.urlAlias": urlAlias,
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      let allEntries = "";

      if (response.items != "" && response.items != undefined) {
        allEntries = response.items;
      } else {
        await client
          .getEntries({
            content_type: "story",
            "fields.urlAlias": urlAlias,
            "fields.domains": global.config.domain,
            locale: currLocale,
          })
          .then(async (response) => {
            if (response.items != "" && response.items != undefined) {
              allEntries = response.items;
            } else {
              await client
                .getEntries({
                  content_type: "designer",
                  "fields.urlAlias": urlAlias,
                  "fields.domains": global.config.domain,
                  locale: currLocale,
                })
                .then(async (response) => {
                  if (response.items != "" && response.items != undefined) {
                    allEntries = response.items;
                  }
                })
                .catch(console.error);
            }
          })
          .catch(console.error);
      }

      if (allEntries != "" && allEntries != undefined) {
        let entries = allEntries;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;

          data["id"] = urlAlias;
          data["page"] = {};
          data["content_type"] = "";
          data["storyId"] = "";

          if (entries[0].sys.id != "" && entries[0].sys.id != undefined) {
            data["storyId"] = entries[0].sys.id;
          }
          if (entries[0].sys != "" && entries[0].sys != undefined) {
            data["content_type"] = entries[0].sys.contentType.sys.id;
          }
          data["page"]["createdAt"] = entries[0].sys.createdAt;

          data["page"]["title"] = "";
          if (allFieldsData.title != "" && allFieldsData.title != undefined) {
            data["page"]["title"] = allFieldsData.title;
          }

          data["page"]["heading"] = "";
          if (
            allFieldsData.heading != "" &&
            allFieldsData.heading != undefined
          ) {
            data["page"]["heading"] = allFieldsData.heading;
          }

          if (
            allFieldsData.seoMetaInformation != undefined &&
            allFieldsData.seoMetaInformation != ""
          ) {
            data["seoInformation"] = allFieldsData.seoMetaInformation.fields;
          }

          data["allAliases"] = {
            "en-US": "",
            "en-AU": "",
            "en-CA": "",
            "en-VI": "",
            "fr-CA": "",
            "fr-TG": "",
            es: "",
            "es-VE": "",
          };

          data["nextStories"] = {};
          data["previousStories"] = {};
          data["parentPage"] = "";

          if (
            allFieldsData.parentPage != undefined &&
            allFieldsData.parentPage != ""
          ) {
            if (
              allFieldsData.parentPage.fields.title != undefined &&
              allFieldsData.parentPage.fields.title != ""
            ) {
              data["parentPage"] = allFieldsData.parentPage.fields.title;
            }
            if (
              allFieldsData.parentPage.fields.previousStories != undefined &&
              allFieldsData.parentPage.fields.previousStories != ""
            ) {
              data["previousStories"] =
                allFieldsData.parentPage.fields.previousStories;
            }
            if (
              allFieldsData.parentPage.fields.nextStories != undefined &&
              allFieldsData.parentPage.fields.nextStories != ""
            ) {
              data["nextStories"] = allFieldsData.parentPage.fields.nextStories;
            }
            if (
              allFieldsData.parentPage.fields.sliderPreviousStoryLabel !=
                undefined &&
              allFieldsData.parentPage.fields.sliderPreviousStoryLabel != ""
            ) {
              data["sliderPreviousStoryLabel"] =
                allFieldsData.parentPage.fields.sliderPreviousStoryLabel;
            }
            if (
              allFieldsData.parentPage.fields.sliderNextStoryLabel !=
                undefined &&
              allFieldsData.parentPage.fields.sliderNextStoryLabel != ""
            ) {
              data["sliderNextStoryLabel"] =
                allFieldsData.parentPage.fields.sliderNextStoryLabel;
            }
          }

          if (
            allFieldsData.urlAlias != undefined &&
            allFieldsData.urlAlias != ""
          ) {
            data["allAliases"]["en-US"] = allFieldsData.urlAlias;
            data["allAliases"]["en-AU"] = allFieldsData.urlAlias;
            data["allAliases"]["en-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["en-VI"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-TG"] = allFieldsData.urlAlias;
            data["allAliases"]["es"] = allFieldsData.urlAlias;
            data["allAliases"]["es-VE"] = allFieldsData.urlAlias;
          }

          if (
            allFieldsData.englishUnitedStatesUrlAlias != undefined &&
            allFieldsData.englishUnitedStatesUrlAlias != ""
          ) {
            data["allAliases"]["en-US"] =
              allFieldsData.englishUnitedStatesUrlAlias;
          }

          if (
            allFieldsData.englishAustraliaUrlAlias != undefined &&
            allFieldsData.englishAustraliaUrlAlias != ""
          ) {
            data["allAliases"]["en-AU"] =
              allFieldsData.englishAustraliaUrlAlias;
          }

          if (
            allFieldsData.englishCanadaUrlAlias != undefined &&
            allFieldsData.englishCanadaUrlAlias != ""
          ) {
            data["allAliases"]["en-CA"] = allFieldsData.englishCanadaUrlAlias;
          }

          if (
            allFieldsData.englishUSVirginIslandsUrlAlias != undefined &&
            allFieldsData.englishUSVirginIslandsUrlAlias != ""
          ) {
            data["allAliases"]["en-VI"] =
              allFieldsData.englishUSVirginIslandsUrlAlias;
          }

          if (
            allFieldsData.frenchCanadaUrlAlias != undefined &&
            allFieldsData.frenchCanadaUrlAlias != ""
          ) {
            data["allAliases"]["fr-CA"] = allFieldsData.frenchCanadaUrlAlias;
          }

          if (
            allFieldsData.frenchTogoUrlAlias != undefined &&
            allFieldsData.frenchTogoUrlAlias != ""
          ) {
            data["allAliases"]["fr-TG"] = allFieldsData.frenchTogoUrlAlias;
          }

          if (
            allFieldsData.spanishEsUrlAlias != undefined &&
            allFieldsData.spanishEsUrlAlias != ""
          ) {
            data["allAliases"]["es"] = allFieldsData.spanishEsUrlAlias;
          }

          if (
            allFieldsData.spanishVenezuelaUrlAlias != undefined &&
            allFieldsData.spanishVenezuelaUrlAlias != ""
          ) {
            data["allAliases"]["es-VE"] =
              allFieldsData.spanishVenezuelaUrlAlias;
          }

          data["components"] = {};

          data["page"]["tags"] = {};
          if (allFieldsData.tags != undefined && allFieldsData.tags != "") {
            data["page"]["tags"]["tagLabel"] = "";
            if (
              allFieldsData.tags.fields.tagLabel != undefined &&
              allFieldsData.tags.fields.tagLabel != ""
            ) {
              data["page"]["tags"]["tagLabel"] =
                allFieldsData.tags.fields.tagLabel;
            }

            data["page"]["tags"]["tagNames"] = {};
            if (
              allFieldsData.tags.fields.tags != undefined &&
              allFieldsData.tags.fields.tags != ""
            ) {
              data["page"]["tags"]["tagNames"] = allFieldsData.tags.fields.tags;
            }
          }

          data["page"]["storyBannerImage"] = "";
          data["page"]["storyBannerImageAltText"] = "";

          data["page"]["shareButtons"] = "";

          if (
            allFieldsData.shareButtons != undefined &&
            allFieldsData.shareButtons != ""
          ) {
            data["page"]["shareButtons"] = allFieldsData.shareButtons;
          }

          if (
            allFieldsData.storyBannerImage != undefined &&
            allFieldsData.storyBannerImage != ""
          ) {
            data["page"]["storyBannerImage"] =
              allFieldsData.storyBannerImage.fields.file.url;
            data["page"]["storyBannerImageAltText"] =
              allFieldsData.storyBannerImage.fields.title;
          }

          data["page"]["mobileStoryBannerImage"] = "";
          data["page"]["mobileStoryBannerImageAltText"] = "";

          if (
            allFieldsData.mobileStoryBannerImage != undefined &&
            allFieldsData.mobileStoryBannerImage != ""
          ) {
            data["page"]["mobileStoryBannerImage"] =
              allFieldsData.mobileStoryBannerImage.fields.file.url;
            data["page"]["mobileStoryBannerImageAltText"] =
              allFieldsData.mobileStoryBannerImage.fields.title;
          }

          data["page"]["description"] = "";
          if (
            allFieldsData.description != undefined &&
            allFieldsData.description != ""
          ) {
            data["page"]["description"] = allFieldsData.description;
          }

          data["page"]["highlightedText"] = "";
          if (
            allFieldsData.highlightedText != undefined &&
            allFieldsData.highlightedText != ""
          ) {
            data["page"]["highlightedText"] = allFieldsData.highlightedText;
          }

          data["relatedStories"] = allFieldsData.relatedStories;

          data["page"]["sliderPreviousStoryLabel"] = "";
          data["page"]["sliderNextStoryLabel"] = "";

          if (
            allFieldsData.sliderPreviousStoryLabel != undefined &&
            allFieldsData.sliderPreviousStoryLabel != ""
          ) {
            data["page"]["sliderPreviousStoryLabel"] =
              allFieldsData.sliderPreviousStoryLabel;
          }

          if (
            allFieldsData.sliderNextStoryLabel != undefined &&
            allFieldsData.sliderNextStoryLabel != ""
          ) {
            data["page"]["sliderNextStoryLabel"] =
              allFieldsData.sliderNextStoryLabel;
          }

          data["page"]["findStoreData"] = {};
          if (
            allFieldsData.findAStoreTitle != undefined &&
            allFieldsData.findAStoreTitle != ""
          ) {
            data["page"]["findStoreData"]["title"] =
              allFieldsData.findAStoreTitle;
          }
          if (
            allFieldsData.findAStoreTitleColor != undefined &&
            allFieldsData.findAStoreTitleColor != ""
          ) {
            data["page"]["findStoreData"]["titleColor"] =
              allFieldsData.findAStoreTitleColor;
          }
          if (
            allFieldsData.findAStoreBackgroundColor != undefined &&
            allFieldsData.findAStoreBackgroundColor != ""
          ) {
            data["page"]["findStoreData"]["backgroundColor"] =
              allFieldsData.findAStoreBackgroundColor;
          }
          if (
            allFieldsData.findAStorePlaceholderText != undefined &&
            allFieldsData.findAStorePlaceholderText != ""
          ) {
            data["page"]["findStoreData"]["placeholderText"] =
              allFieldsData.findAStorePlaceholderText;
          }
          if (
            allFieldsData.findAStoreButtonText != undefined &&
            allFieldsData.findAStoreButtonText != ""
          ) {
            data["page"]["findStoreData"]["buttonText"] =
              allFieldsData.findAStoreButtonText;
          }
          if (
            allFieldsData.findAStoreButtonTextColor != undefined &&
            allFieldsData.findAStoreButtonTextColor != ""
          ) {
            data["page"]["findStoreData"]["buttonTextColor"] =
              allFieldsData.findAStoreButtonTextColor;
          }
          if (
            allFieldsData.findAStoreButtonBackgroundColor != undefined &&
            allFieldsData.findAStoreButtonBackgroundColor != ""
          ) {
            data["page"]["findStoreData"]["buttonBackgroundColor"] =
              allFieldsData.findAStoreButtonBackgroundColor;
          }

          data["components"] = {};
          if (
            allFieldsData.pageComponents != "" &&
            allFieldsData.pageComponents != undefined
          ) {
            let allComponents = [];

            await $.each(
              allFieldsData.pageComponents,
              async function (i, singleLayoutComponent) {
                let domainVerified = false;
                if (singleLayoutComponent.fields.domains != undefined) {
                  if (
                    singleLayoutComponent.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    domainVerified = true;
                  }
                } else {
                  domainVerified = true;
                }

                let localeVerified = false;
                if (singleLayoutComponent.sys.locale != undefined) {
                  if (singleLayoutComponent.sys.locale.includes(currLocale)) {
                    localeVerified = true;
                  }
                } else {
                  localeVerified = true;
                }

                if (domainVerified && localeVerified) {
                  let componentName =
                    singleLayoutComponent.sys.contentType.sys.id;

                  if (componentName == "2columnCards") {
                    componentName = "twoColumnCard";
                  } else if (componentName == "columnList") {
                    componentName = "twoColumnVerticalList";
                    if (
                      singleLayoutComponent.fields.listType != undefined &&
                      singleLayoutComponent.fields.listType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.listType == "Horizontal"
                      ) {
                        componentName = "twoColumnHorizontalList";
                      }
                    }
                  } else if (componentName == "4columnCard") {
                    componentName = "fourColumnCard";
                  } else if (componentName == "fullBleedBanner") {
                    componentName = "fullBleedBanner1";
                    if (
                      singleLayoutComponent.fields.bannerType != undefined &&
                      singleLayoutComponent.fields.bannerType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 2"
                      ) {
                        componentName = "fullBleedBanner2";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 3"
                      ) {
                        componentName = "fullBleedBanner3";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 4"
                      ) {
                        componentName = "fullBleedBanner4";
                      }
                    }
                  } else if (componentName == "heroCard") {
                    componentName = "heroCardSmall";
                    if (
                      singleLayoutComponent.fields.cardSize != undefined &&
                      singleLayoutComponent.fields.cardSize != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.cardSize == "2:3 Image"
                      ) {
                        componentName = "heroCardMedium";
                      } else if (
                        singleLayoutComponent.fields.cardSize == "3:4 Image"
                      ) {
                        componentName = "heroCardLarge";
                      } else if (
                        singleLayoutComponent.fields.cardSize ==
                        "Without Image"
                      ) {
                        componentName = "heroCardWithoutImage";
                      }
                    }
                  }

                  let data = {};

                  if (
                    singleLayoutComponent.fields != "" &&
                    singleLayoutComponent.fields != undefined
                  ) {
                    data = singleLayoutComponent.fields;
                  }
                  data.entryId = singleLayoutComponent.sys.id;

                  let singleComponent = { componentName, data };

                  allComponents.push(singleComponent);
                }
              }
            );
            data["components"] = allComponents;
          }
          if (
            allFieldsData.layoutComponents != "" &&
            allFieldsData.layoutComponents != undefined
          ) {
            let allComponents = [];

            await $.each(
              allFieldsData.layoutComponents,
              async function (i, singleLayoutComponent) {
                let domainVerified = false;
                if (singleLayoutComponent.fields.domains != undefined) {
                  if (
                    singleLayoutComponent.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    domainVerified = true;
                  }
                } else {
                  domainVerified = true;
                }

                let localeVerified = false;
                if (singleLayoutComponent.sys.locale != undefined) {
                  if (singleLayoutComponent.sys.locale.includes(currLocale)) {
                    localeVerified = true;
                  }
                } else {
                  localeVerified = true;
                }

                if (domainVerified && localeVerified) {
                  let componentName =
                    singleLayoutComponent.sys.contentType.sys.id;
                  if (componentName == "heroCard") {
                    componentName = "heroCardSmall";
                    if (
                      singleLayoutComponent.fields.cardSize != undefined &&
                      singleLayoutComponent.fields.cardSize != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.cardSize == "2:3 Image"
                      ) {
                        componentName = "heroCardMedium";
                      } else if (
                        singleLayoutComponent.fields.cardSize == "3:4 Image"
                      ) {
                        componentName = "heroCardLarge";
                      } else if (
                        singleLayoutComponent.fields.cardSize ==
                        "Without Image"
                      ) {
                        componentName = "heroCardWithoutImage";
                      }
                    }
                  } else if (componentName == "2columnCards") {
                    componentName = "twoColumnCard";
                  } else if (componentName == "columnList") {
                    componentName = "twoColumnVerticalList";
                    if (
                      singleLayoutComponent.fields.listType != undefined &&
                      singleLayoutComponent.fields.listType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.listType == "Horizontal"
                      ) {
                        componentName = "twoColumnHorizontalList";
                      }
                    }
                  } else if (componentName == "4columnCard") {
                    componentName = "fourColumnCard";
                  } else if (componentName == "fullBleedBanner") {
                    componentName = "fullBleedBanner1";
                    if (
                      singleLayoutComponent.fields.bannerType != undefined &&
                      singleLayoutComponent.fields.bannerType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 2"
                      ) {
                        componentName = "fullBleedBanner2";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 3"
                      ) {
                        componentName = "fullBleedBanner3";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 4"
                      ) {
                        componentName = "fullBleedBanner4";
                      }
                    }
                  }

                  let data = {};

                  if (
                    singleLayoutComponent.fields != "" &&
                    singleLayoutComponent.fields != undefined
                  ) {
                    data = singleLayoutComponent.fields;
                  }
                  data.entryId = singleLayoutComponent.sys.id;

                  let singleComponent = { componentName, data };

                  allComponents.push(singleComponent);
                }
              }
            );
            data["components"] = allComponents;
          }
        }
      }
    })
    .catch(console.error);

  return data;
};

const fetchDesigner = async (urlAlias) => {
  let currLocale = getLocale();
  let data = {};

  await client
    .getEntries({
      content_type: "designer",
      "fields.urlAlias": urlAlias,
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        let entries = response.items;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;

          data["id"] = urlAlias;
          data["page"] = {};

          data["page"]["title"] = "";
          if (allFieldsData.title != "" && allFieldsData.title != undefined) {
            data["page"]["title"] = allFieldsData.title;
          }

          if (
            allFieldsData.seoMetaInformation != undefined &&
            allFieldsData.seoMetaInformation != ""
          ) {
            data["seoInformation"] = allFieldsData.seoMetaInformation.fields;
          }

          data["allAliases"] = {
            "en-US": "",
            "en-AU": "",
            "en-CA": "",
            "en-VI": "",
            "fr-CA": "",
            "fr-TG": "",
            es: "",
            "es-VE": "",
          };

          if (
            allFieldsData.urlAlias != undefined &&
            allFieldsData.urlAlias != ""
          ) {
            data["allAliases"]["en-US"] = allFieldsData.urlAlias;
            data["allAliases"]["en-AU"] = allFieldsData.urlAlias;
            data["allAliases"]["en-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["en-VI"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-TG"] = allFieldsData.urlAlias;
            data["allAliases"]["es"] = allFieldsData.urlAlias;
            data["allAliases"]["es-VE"] = allFieldsData.urlAlias;
          }

          if (
            allFieldsData.englishUnitedStatesUrlAlias != undefined &&
            allFieldsData.englishUnitedStatesUrlAlias != ""
          ) {
            data["allAliases"]["en-US"] =
              allFieldsData.englishUnitedStatesUrlAlias;
          }

          if (
            allFieldsData.englishAustraliaUrlAlias != undefined &&
            allFieldsData.englishAustraliaUrlAlias != ""
          ) {
            data["allAliases"]["en-AU"] =
              allFieldsData.englishAustraliaUrlAlias;
          }

          if (
            allFieldsData.englishCanadaUrlAlias != undefined &&
            allFieldsData.englishCanadaUrlAlias != ""
          ) {
            data["allAliases"]["en-CA"] = allFieldsData.englishCanadaUrlAlias;
          }

          if (
            allFieldsData.englishUSVirginIslandsUrlAlias != undefined &&
            allFieldsData.englishUSVirginIslandsUrlAlias != ""
          ) {
            data["allAliases"]["en-VI"] =
              allFieldsData.englishUSVirginIslandsUrlAlias;
          }

          if (
            allFieldsData.frenchCanadaUrlAlias != undefined &&
            allFieldsData.frenchCanadaUrlAlias != ""
          ) {
            data["allAliases"]["fr-CA"] = allFieldsData.frenchCanadaUrlAlias;
          }

          if (
            allFieldsData.frenchTogoUrlAlias != undefined &&
            allFieldsData.frenchTogoUrlAlias != ""
          ) {
            data["allAliases"]["fr-TG"] = allFieldsData.frenchTogoUrlAlias;
          }

          if (
            allFieldsData.spanishEsUrlAlias != undefined &&
            allFieldsData.spanishEsUrlAlias != ""
          ) {
            data["allAliases"]["es"] = allFieldsData.spanishEsUrlAlias;
          }

          if (
            allFieldsData.spanishVenezuelaUrlAlias != undefined &&
            allFieldsData.spanishVenezuelaUrlAlias != ""
          ) {
            data["allAliases"]["es-VE"] =
              allFieldsData.spanishVenezuelaUrlAlias;
          }

          data["components"] = {};
          if (
            allFieldsData.layoutComponents != "" &&
            allFieldsData.layoutComponents != undefined
          ) {
            let allComponents = [];

            await $.each(
              allFieldsData.layoutComponents,
              async function (i, singleLayoutComponent) {
                let domainVerified = false;
                if (singleLayoutComponent.fields.domains != undefined) {
                  if (
                    singleLayoutComponent.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    domainVerified = true;
                  }
                } else {
                  domainVerified = true;
                }

                let localeVerified = false;
                if (singleLayoutComponent.sys.locale != undefined) {
                  if (singleLayoutComponent.sys.locale.includes(currLocale)) {
                    localeVerified = true;
                  }
                } else {
                  localeVerified = true;
                }

                if (domainVerified && localeVerified) {
                  let componentName =
                    singleLayoutComponent.sys.contentType.sys.id;
                  if (componentName == "heroCard") {
                    componentName = "heroCardSmall";
                    if (
                      singleLayoutComponent.fields.cardSize != undefined &&
                      singleLayoutComponent.fields.cardSize != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.cardSize == "2:3 Image"
                      ) {
                        componentName = "heroCardMedium";
                      } else if (
                        singleLayoutComponent.fields.cardSize == "3:4 Image"
                      ) {
                        componentName = "heroCardLarge";
                      } else if (
                        singleLayoutComponent.fields.cardSize ==
                        "Without Image"
                      ) {
                        componentName = "heroCardWithoutImage";
                      }
                    }
                  } else if (componentName == "2columnCards") {
                    componentName = "twoColumnCard";
                  } else if (componentName == "columnList") {
                    componentName = "twoColumnVerticalList";
                    if (
                      singleLayoutComponent.fields.listType != undefined &&
                      singleLayoutComponent.fields.listType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.listType == "Horizontal"
                      ) {
                        componentName = "twoColumnHorizontalList";
                      }
                    }
                  } else if (componentName == "4columnCard") {
                    componentName = "fourColumnCard";
                  } else if (componentName == "fullBleedBanner") {
                    componentName = "fullBleedBanner1";
                    if (
                      singleLayoutComponent.fields.bannerType != undefined &&
                      singleLayoutComponent.fields.bannerType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 2"
                      ) {
                        componentName = "fullBleedBanner2";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 3"
                      ) {
                        componentName = "fullBleedBanner3";
                      }
                    }
                  }

                  let data = {};
                  if (
                    singleLayoutComponent.fields != "" &&
                    singleLayoutComponent.fields != undefined
                  ) {
                    data = singleLayoutComponent.fields;
                  }
                  data.entryId = singleLayoutComponent.sys.id;
                  let singleComponent = { componentName, data };

                  allComponents.push(singleComponent);
                }
              }
            );
            data["components"] = allComponents;
          }
        }
      }
    })
    .catch(console.error);

  return data;
};

const fetchCms = async (pageType) => {
  let currLocale = getLocale();
  let data = {};

  await client
    .getEntries({
      content_type: "pageLayout",
      "fields.pageType": pageType,
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        let entries = response.items;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;

          data["id"] = pageType;
          data["page"] = {};

          data["page"]["title"] = "";
          if (allFieldsData.title != "" && allFieldsData.title != undefined) {
            data["page"]["title"] = allFieldsData.title;
          }
          if (
            allFieldsData.seoMetaInformation != undefined &&
            allFieldsData.seoMetaInformation != ""
          ) {
            data["seoInformation"] = allFieldsData.seoMetaInformation.fields;
          }

          data["allAliases"] = {
            "en-US": "",
            "en-AU": "",
            "en-CA": "",
            "en-VI": "",
            "fr-CA": "",
            "fr-TG": "",
            es: "",
            "es-VE": "",
          };

          if (
            allFieldsData.urlAlias != undefined &&
            allFieldsData.urlAlias != ""
          ) {
            data["allAliases"]["en-US"] = allFieldsData.urlAlias;
            data["allAliases"]["en-AU"] = allFieldsData.urlAlias;
            data["allAliases"]["en-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["en-VI"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-TG"] = allFieldsData.urlAlias;
            data["allAliases"]["es"] = allFieldsData.urlAlias;
            data["allAliases"]["es-VE"] = allFieldsData.urlAlias;
          }

          if (
            allFieldsData.englishUnitedStatesUrlAlias != undefined &&
            allFieldsData.englishUnitedStatesUrlAlias != ""
          ) {
            data["allAliases"]["en-US"] =
              allFieldsData.englishUnitedStatesUrlAlias;
          }

          if (
            allFieldsData.englishAustraliaUrlAlias != undefined &&
            allFieldsData.englishAustraliaUrlAlias != ""
          ) {
            data["allAliases"]["en-AU"] =
              allFieldsData.englishAustraliaUrlAlias;
          }

          if (
            allFieldsData.englishCanadaUrlAlias != undefined &&
            allFieldsData.englishCanadaUrlAlias != ""
          ) {
            data["allAliases"]["en-CA"] = allFieldsData.englishCanadaUrlAlias;
          }

          if (
            allFieldsData.englishUSVirginIslandsUrlAlias != undefined &&
            allFieldsData.englishUSVirginIslandsUrlAlias != ""
          ) {
            data["allAliases"]["en-VI"] =
              allFieldsData.englishUSVirginIslandsUrlAlias;
          }

          if (
            allFieldsData.frenchCanadaUrlAlias != undefined &&
            allFieldsData.frenchCanadaUrlAlias != ""
          ) {
            data["allAliases"]["fr-CA"] = allFieldsData.frenchCanadaUrlAlias;
          }

          if (
            allFieldsData.frenchTogoUrlAlias != undefined &&
            allFieldsData.frenchTogoUrlAlias != ""
          ) {
            data["allAliases"]["fr-TG"] = allFieldsData.frenchTogoUrlAlias;
          }

          if (
            allFieldsData.spanishEsUrlAlias != undefined &&
            allFieldsData.spanishEsUrlAlias != ""
          ) {
            data["allAliases"]["es"] = allFieldsData.spanishEsUrlAlias;
          }

          if (
            allFieldsData.spanishVenezuelaUrlAlias != undefined &&
            allFieldsData.spanishVenezuelaUrlAlias != ""
          ) {
            data["allAliases"]["es-VE"] =
              allFieldsData.spanishVenezuelaUrlAlias;
          }

          data["components"] = {};
          if (
            allFieldsData.layoutComponents != "" &&
            allFieldsData.layoutComponents != undefined
          ) {
            let allComponents = [];

            await $.each(
              allFieldsData.layoutComponents,
              async function (i, singleLayoutComponent) {
                // const isBrokenDraft = !singleLayoutComponent.sys || !singleLayoutComponent.fields;
                // if (isBrokenDraft) {
                //   return null;
                // }
                let domainVerified = false;
                if (singleLayoutComponent.fields.domains != undefined) {
                  if (
                    singleLayoutComponent.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    domainVerified = true;
                  }
                } else {
                  domainVerified = true;
                }

                let localeVerified = false;
                if (singleLayoutComponent.sys.locale != undefined) {
                  if (singleLayoutComponent.sys.locale.includes(currLocale)) {
                    localeVerified = true;
                  }
                } else {
                  localeVerified = true;
                }

                if (domainVerified && localeVerified) {
                  let componentName =
                    singleLayoutComponent.sys.contentType.sys.id;
                  if (componentName == "heroCard") {
                    componentName = "heroCardSmall";
                    if (
                      singleLayoutComponent.fields.cardSize != undefined &&
                      singleLayoutComponent.fields.cardSize != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.cardSize == "2:3 Image"
                      ) {
                        componentName = "heroCardMedium";
                      } else if (
                        singleLayoutComponent.fields.cardSize == "3:4 Image"
                      ) {
                        componentName = "heroCardLarge";
                      } else if (
                        singleLayoutComponent.fields.cardSize ==
                        "Without Image"
                      ) {
                        componentName = "heroCardWithoutImage";
                      }
                    }
                  } else if (componentName == "2columnCards") {
                    componentName = "twoColumnCard";
                  } else if (componentName == "columnList") {
                    componentName = "twoColumnVerticalList";
                    if (
                      singleLayoutComponent.fields.listType != undefined &&
                      singleLayoutComponent.fields.listType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.listType == "Horizontal"
                      ) {
                        componentName = "twoColumnHorizontalList";
                      }
                    }
                  } else if (componentName == "4columnCard") {
                    componentName = "fourColumnCard";
                  } else if (componentName == "fullBleedBanner") {
                    componentName = "fullBleedBanner1";
                    if (
                      singleLayoutComponent.fields.bannerType != undefined &&
                      singleLayoutComponent.fields.bannerType != ""
                    ) {
                      if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 2"
                      ) {
                        componentName = "fullBleedBanner2";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 3"
                      ) {
                        componentName = "fullBleedBanner3";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 4"
                      ) {
                        componentName = "fullBleedBanner4";
                      } else if (
                        singleLayoutComponent.fields.bannerType ==
                        "Full Bleed Banner 5"
                      ) {
                        componentName = "fullBleedBanner5";
                      }
                    }
                  }

                  let data = {};
                  if (
                    singleLayoutComponent.fields != "" &&
                    singleLayoutComponent.fields != undefined
                  ) {
                    data = singleLayoutComponent.fields;
                  }
                  data.entryId = singleLayoutComponent.sys.id;

                  let singleComponent = { componentName, data };

                  allComponents.push(singleComponent);
                }
              }
            );
            data["components"] = allComponents;
          }
        }
      }
    })
    .catch(console.error);

  return data;
};

const fetchStory = async (urlAlias) => {
  let currLocale = getLocale();
  let data = {};

  await client
    .getEntries({
      content_type: "story",
      "fields.urlAlias": urlAlias,
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        let entries = response.items;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;
          data["page"] = {};

          data["page"]["createdAt"] = entries[0].sys.createdAt;
          data["page"]["title"] = "";
          if (allFieldsData.title != undefined && allFieldsData.title != "") {
            data["page"]["title"] = allFieldsData.title;
          }

          if (
            allFieldsData.seoMetaInformation != undefined &&
            allFieldsData.seoMetaInformation != ""
          ) {
            data["seoInformation"] = allFieldsData.seoMetaInformation.fields;
          }

          data["allAliases"] = {
            "en-US": "",
            "en-AU": "",
            "en-CA": "",
            "en-VI": "",
            "fr-CA": "",
            "fr-TG": "",
            es: "",
            "es-VE": "",
          };

          if (
            allFieldsData.urlAlias != undefined &&
            allFieldsData.urlAlias != ""
          ) {
            data["allAliases"]["en-US"] = allFieldsData.urlAlias;
            data["allAliases"]["en-AU"] = allFieldsData.urlAlias;
            data["allAliases"]["en-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["en-VI"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-CA"] = allFieldsData.urlAlias;
            data["allAliases"]["fr-TG"] = allFieldsData.urlAlias;
            data["allAliases"]["es"] = allFieldsData.urlAlias;
            data["allAliases"]["es-VE"] = allFieldsData.urlAlias;
          }

          if (
            allFieldsData.englishUnitedStatesUrlAlias != undefined &&
            allFieldsData.englishUnitedStatesUrlAlias != ""
          ) {
            data["allAliases"]["en-US"] =
              allFieldsData.englishUnitedStatesUrlAlias;
          }

          if (
            allFieldsData.englishAustraliaUrlAlias != undefined &&
            allFieldsData.englishAustraliaUrlAlias != ""
          ) {
            data["allAliases"]["en-AU"] =
              allFieldsData.englishAustraliaUrlAlias;
          }

          if (
            allFieldsData.englishCanadaUrlAlias != undefined &&
            allFieldsData.englishCanadaUrlAlias != ""
          ) {
            data["allAliases"]["en-CA"] = allFieldsData.englishCanadaUrlAlias;
          }

          if (
            allFieldsData.englishUSVirginIslandsUrlAlias != undefined &&
            allFieldsData.englishUSVirginIslandsUrlAlias != ""
          ) {
            data["allAliases"]["en-VI"] =
              allFieldsData.englishUSVirginIslandsUrlAlias;
          }

          if (
            allFieldsData.frenchCanadaUrlAlias != undefined &&
            allFieldsData.frenchCanadaUrlAlias != ""
          ) {
            data["allAliases"]["fr-CA"] = allFieldsData.frenchCanadaUrlAlias;
          }

          if (
            allFieldsData.frenchTogoUrlAlias != undefined &&
            allFieldsData.frenchTogoUrlAlias != ""
          ) {
            data["allAliases"]["fr-TG"] = allFieldsData.frenchTogoUrlAlias;
          }

          if (
            allFieldsData.spanishEsUrlAlias != undefined &&
            allFieldsData.spanishEsUrlAlias != ""
          ) {
            data["allAliases"]["es"] = allFieldsData.spanishEsUrlAlias;
          }

          if (
            allFieldsData.spanishVenezuelaUrlAlias != undefined &&
            allFieldsData.spanishVenezuelaUrlAlias != ""
          ) {
            data["allAliases"]["es-VE"] =
              allFieldsData.spanishVenezuelaUrlAlias;
          }

          data["page"]["tags"] = {};
          if (allFieldsData.tags != undefined && allFieldsData.tags != "") {
            data["page"]["tags"]["tagLabel"] = "";
            if (
              allFieldsData.tags.fields.tagLabel != undefined &&
              allFieldsData.tags.fields.tagLabel != ""
            ) {
              data["page"]["tags"]["tagLabel"] =
                allFieldsData.tags.fields.tagLabel;
            }

            data["page"]["tags"]["tagNames"] = {};
            if (
              allFieldsData.tags.fields.tags != undefined &&
              allFieldsData.tags.fields.tags != ""
            ) {
              data["page"]["tags"]["tagNames"] = allFieldsData.tags.fields.tags;
            }
          }

          data["page"]["storyBannerImage"] = "";
          if (
            allFieldsData.storyBannerImage != undefined &&
            allFieldsData.storyBannerImage != ""
          ) {
            data["page"]["storyBannerImage"] =
              allFieldsData.storyBannerImage.fields.file.url;
          }

          data["page"]["description"] = "";
          if (
            allFieldsData.description != undefined &&
            allFieldsData.description != ""
          ) {
            data["page"]["description"] = allFieldsData.description;
          }

          data["page"]["highlightedText"] = "";
          if (
            allFieldsData.highlightedText != undefined &&
            allFieldsData.highlightedText != ""
          ) {
            data["page"]["highlightedText"] = allFieldsData.highlightedText;
          }

          data["relatedStories"] = allFieldsData.relatedStories;
          data["components"] = {};
          if (
            allFieldsData.pageComponents != "" &&
            allFieldsData.pageComponents != undefined
          ) {
            let allComponents = [];

            await $.each(
              allFieldsData.pageComponents,
              async function (i, singleLayoutComponent) {
                let domainVerified = false;
                if (singleLayoutComponent.fields.domains != undefined) {
                  if (
                    singleLayoutComponent.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    domainVerified = true;
                  }
                } else {
                  domainVerified = true;
                }

                let localeVerified = false;
                if (singleLayoutComponent.sys.locale != undefined) {
                  if (singleLayoutComponent.sys.locale.includes(currLocale)) {
                    localeVerified = true;
                  }
                } else {
                  localeVerified = true;
                }

                if (domainVerified && localeVerified) {
                  let componentName =
                    singleLayoutComponent.sys.contentType.sys.id;
                  let data = {};

                  if (
                    singleLayoutComponent.fields != "" &&
                    singleLayoutComponent.fields != undefined
                  ) {
                    data = singleLayoutComponent.fields;
                  }
                  data.entryId = singleLayoutComponent.sys.id;

                  let singleComponent = { componentName, data };

                  allComponents.push(singleComponent);
                }
              }
            );
            data["components"] = allComponents;
          }
        }
      }
    })
    .catch(console.error);

  return data;
};

function createSitemap({ hostname, urls }) {
  let xmlData = [];
  let hostName = [
    "https://www.savers.com",
    "https://www.savers.com.au",
    "https://www.valuevillage.com",
    "https://www.valuevillage.ca",
    "https://www.villagedesvaleurs.ca",
  ];

  let sitemapFiles = [
    "savers-sitemap.xml",
    "saverscomau-sitemap.xml",
    "valuevillage-sitemap.xml",
    "valuevillageca-sitemap.xml",
    "villagedesvaleursca-sitemap.xml",
  ];

  for (var i in hostName) {
    var xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
    for (var j in urls) {
      xml += "<url>";
      xml += "<loc>" + hostName[i] + "/" + urls[j] + "</loc>";
      xml += "<changefreq>daily</changefreq>";
      xml += "</url>\n";
      j++;
    }
    xml += "</urlset>";

    xmlData.push(xml);
    i++;
  }

  if (xmlData.length > 0) {
    for (var i in xmlData) {
      var pom = document.createElement("a");
      var bb = new Blob([xmlData[i]], { type: "text/plain" });
      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", sitemapFiles[i]);
      pom.dataset.downloadurl = pom.href;
      pom.draggable = true;
      pom.classList.add("dragout");
      pom.click();
      i++;
    }
  }
}

async function fetchSitemapData() {
  let currLocale = getLocale();
  let allAliases = [];

  await client
    .getEntries({
      content_type: "pageLayout",
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        if (response.items.length > 0) {
          response.items.forEach((data) => {
            if (data.fields != "" && data.fields != undefined) {
              if (
                data.fields.urlAlias != undefined &&
                data.fields.urlAlias != ""
              ) {
                allAliases.push(data.fields.urlAlias);
              }
            }
          });
        }
      }
    });

  await client
    .getEntries({
      content_type: "designer",
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        if (response.items.length > 0) {
          response.items.forEach((data) => {
            if (data.fields != "" && data.fields != undefined) {
              if (
                data.fields.urlAlias != undefined &&
                data.fields.urlAlias != ""
              ) {
                allAliases.push(data.fields.urlAlias);
              }
            }
          });
        }
      }
    });

  await client
    .getEntries({
      content_type: "story",
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then(async (response) => {
      if (response.items != "" && response.items != undefined) {
        if (response.items.length > 0) {
          response.items.forEach((data) => {
            if (data.fields != "" && data.fields != undefined) {
              if (
                data.fields.urlAlias != undefined &&
                data.fields.urlAlias != ""
              ) {
                allAliases.push(data.fields.urlAlias);
              }
            }
          });
        }
      }
    });

  const sitemap = createSitemap({
    hostname: window.location.origin,
    urls: allAliases,
  });

  return sitemap;
}

async function fetchAllUrl() {
  // let currLocale = getLocale();
  var allUrlAliases = [];
  let locales = [
    "en-US",
    "en-AU",
    "en-CA",
    "en-VI",
    "fr-CA",
    "fr-TG",
    "es",
    "es-VE",
  ];

  // let contentTypes = ["pageLayout", "story", "designer"];

  for (var i = 0; i < locales.length; i++) {
    let translation = "";
    if (
      locales[i] == "en-US" ||
      locales[i] == "en-AU" ||
      locales[i] == "en-CA" ||
      locales[i] == "en-VI"
    ) {
      translation = "en";
    }
    if (locales[i] == "fr-CA" || locales[i] == "fr-TG") {
      translation = "fr";
    }
    if (locales[i] == "es" || locales[i] == "es-VE") {
      translation = "es";
    }
    await client
      .getEntries({
        content_type: "pageLayout",
        "fields.domains": global.config.domain,
        locale: locales[i],
      })
      .then(async (response) => {
        if (response.items != "" && response.items != undefined) {
          if (response.items.length > 0) {
            response.items.map(async (data) => {
              let singleUrlItem = {
                title: "",
                comment: "",
                urlAlias: "",
                locale: "",
              };

              if (data.fields != "" && data.fields != undefined) {
                if (
                  data.fields.urlAlias != undefined &&
                  data.fields.urlAlias != ""
                ) {
                  if (
                    data.fields.title != "" &&
                    data.fields.title != undefined
                  ) {
                    let title = data.fields.title;
                    var filteredTitle = title.replace(/,/g, "");
                    singleUrlItem["title"] = filteredTitle;
                  }

                  if (
                    data.fields.comment != "" &&
                    data.fields.comment != undefined
                  ) {
                    let comment = data.fields.comment;
                    var filteredComment = comment.replace(/,/g, "");
                    singleUrlItem["comment"] = filteredComment;
                  }
                  if (
                    data.fields.urlAlias != "" &&
                    data.fields.urlAlias != undefined
                  ) {
                    singleUrlItem["urlAlias"] =
                      translation + "/" + data.fields.urlAlias;
                  }

                  singleUrlItem["locale"] = locales[i];
                }
              }
              allUrlAliases.push(singleUrlItem);
            });
          }
        }
      });
  }

  for (var i = 0; i < locales.length; i++) {
    let translation = "";
    if (
      locales[i] == "en-US" ||
      locales[i] == "en-AU" ||
      locales[i] == "en-CA" ||
      locales[i] == "en-VI"
    ) {
      translation = "en";
    }
    if (locales[i] == "fr-CA" || locales[i] == "fr-TG") {
      translation = "fr";
    }
    if (locales[i] == "es" || locales[i] == "es-VE") {
      translation = "es";
    }
    await client
      .getEntries({
        content_type: "designer",
        "fields.domains": global.config.domain,
        locale: locales[i],
      })
      .then(async (response) => {
        if (response.items != "" && response.items != undefined) {
          if (response.items.length > 0) {
            response.items.map(async (data) => {
              let singleUrlItem = {
                title: "",
                comment: "",
                urlAlias: "",
                locale: "",
              };

              if (data.fields != "" && data.fields != undefined) {
                if (
                  data.fields.urlAlias != undefined &&
                  data.fields.urlAlias != ""
                ) {
                  if (
                    data.fields.title != "" &&
                    data.fields.title != undefined
                  ) {
                    let title = data.fields.title;
                    var filteredTitle = title.replace(/,/g, "");
                    singleUrlItem["title"] = filteredTitle;
                  }

                  if (
                    data.fields.comment != "" &&
                    data.fields.comment != undefined
                  ) {
                    let comment = data.fields.comment;
                    var filteredComment = comment.replace(/,/g, "");
                    singleUrlItem["comment"] = filteredComment;
                  }
                  if (
                    data.fields.urlAlias != "" &&
                    data.fields.urlAlias != undefined
                  ) {
                    singleUrlItem["urlAlias"] =
                      translation + "/" + data.fields.urlAlias;
                  }

                  singleUrlItem["locale"] = locales[i];
                }
              }
              allUrlAliases.push(singleUrlItem);
            });
          }
        }
      });
  }

  for (var i = 0; i < locales.length; i++) {
    let translation = "";
    if (
      locales[i] == "en-US" ||
      locales[i] == "en-AU" ||
      locales[i] == "en-CA" ||
      locales[i] == "en-VI"
    ) {
      translation = "en";
    }
    if (locales[i] == "fr-CA" || locales[i] == "fr-TG") {
      translation = "fr";
    }
    if (locales[i] == "es" || locales[i] == "es-VE") {
      translation = "es";
    }

    await client
      .getEntries({
        content_type: "story",
        "fields.domains": global.config.domain,
        locale: locales[i],
      })
      .then(async (response) => {
        if (response.items != "" && response.items != undefined) {
          if (response.items.length > 0) {
            response.items.map(async (data) => {
              let singleUrlItem = {
                title: "",
                comment: "",
                urlAlias: "",
                locale: "",
              };

              if (data.fields != "" && data.fields != undefined) {
                if (
                  data.fields.urlAlias != undefined &&
                  data.fields.urlAlias != ""
                ) {
                  if (
                    data.fields.title != "" &&
                    data.fields.title != undefined
                  ) {
                    let title = data.fields.title;
                    var filteredTitle = title.replace(/,/g, "");
                    singleUrlItem["title"] = filteredTitle;
                  }

                  if (
                    data.fields.comment != "" &&
                    data.fields.comment != undefined
                  ) {
                    let comment = data.fields.comment;
                    var filteredComment = comment.replace(/,/g, "");
                    singleUrlItem["comment"] = filteredComment;
                  }
                  if (
                    data.fields.urlAlias != "" &&
                    data.fields.urlAlias != undefined
                  ) {
                    singleUrlItem["urlAlias"] =
                      translation + "/" + data.fields.urlAlias;
                  }

                  singleUrlItem["locale"] = locales[i];
                }
              }
              allUrlAliases.push(singleUrlItem);
            });
          }
        }
      });
  }

  const csvData = jsonToCSV(allUrlAliases);

  return csvData;
}

async function jsonToCSV(urlsData) {
  var JsonArray = urlsData;
  var JsonFields = ["Page Title", "Comment", "Urls", "Locales"];
  let Title, UrlAliases, Locale, Comment;

  let hostName = [
    "https://www.savers.com",
    "https://www.savers.com.au",
    "https://www.valuevillage.com",
    "https://www.valuevillage.ca",
    "https://www.villagedesvaleurs.ca",
  ];

  let fileNames = [
    "savers_urls.csv",
    "savers_au_urls.csv",
    "valuevillage.csv",
    "valuevillage_ca.csv",
    "villagedesvaleurs.csv",
  ];

  if (urlsData.length > 0) {
    await hostName.map(async (domainName, i) => {
      let newDomain = domainName;
      var csvStr = JsonFields.join(",") + "\n";
      await JsonArray.forEach((element) => {
        Title = element.title;
        UrlAliases = element.urlAlias;
        Locale = element.locale;
        Comment = element.comment;
        csvStr +=
          Title +
          "," +
          Comment +
          "," +
          newDomain +
          "/" +
          UrlAliases +
          "," +
          Locale +
          "\n";
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
      hiddenElement.target = "_blank";
      hiddenElement.download = fileNames[i];
      hiddenElement.click();
    });
  }
}

// const fetchThriftProudNav = async () => {
//   try {
//     const data = await mockSiteData();
//     return data;
//   } catch (e) {
//     return mockSiteData();
//     throw e;
//   }
// };

const fetchSiteData = async (endpoint) => {
  try {
    const data = await fetchHeaderFooterData();
    return data;
  } catch (e) {
    return fetchHeaderFooterData();
    // throw e;
  }
};

const fetchHeaderFooterData = async (endpoint) => {
  let currLocale = getLocale();
  let data = { header: {}, footer: {}, social_links: {} };

  await client
    .getEntries({
      content_type: "header",
      "fields.domains": global.config.domain,
      "fields.thriftHeader[ne]": "yes",
      locale: currLocale,
    })
    .then((response) => {
      if (response.items != "" && response.items != undefined) {
        let entries = response.items;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;

          data["header"]["site_logo"] = "";
          data["header"]["logo_altText"] = "";

          if (
            allFieldsData.siteLogos != "" &&
            allFieldsData.siteLogos != undefined
          ) {
            $.each(allFieldsData.siteLogos, function (i, siteLogo) {
              if (siteLogo.fields.domains != undefined) {
                if (siteLogo.fields.domains.includes(global.config.domain)) {
                  let fileUrl = siteLogo.fields.image.fields.file.url;
                  data["header"]["site_logo"] = fileUrl;
                  data["header"]["logo_altText"] =
                    siteLogo.fields.image.fields.title;
                  return false;
                }
              } else {
                let fileUrl = siteLogo.fields.image.fields.file.url;
                data["header"]["site_logo"] = fileUrl;
                data["header"]["logo_altText"] =
                  siteLogo.fields.image.fields.title;
              }
            });
          }

          data["header"]["storeText"] = "";
          if (
            allFieldsData.storeText != "" &&
            allFieldsData.storeText != undefined
          ) {
            data["header"]["storeText"] = allFieldsData.storeText;
          }

          data["header"]["gtmScript"] = [];
          if (
            allFieldsData.gtmScript != "" &&
            allFieldsData.gtmScript != undefined
          ) {
            data["header"]["gtmScript"] = allFieldsData.gtmScript;
          }

          data["header"]["searchText"] = "";
          if (
            allFieldsData.searchText != "" &&
            allFieldsData.searchText != undefined
          ) {
            data["header"]["searchText"] = allFieldsData.searchText;
          }

          data["header"]["searchPlaceholderText"] = "";
          if (
            allFieldsData.searchPlaceholderText != "" &&
            allFieldsData.searchPlaceholderText != undefined
          ) {
            data["header"]["searchPlaceholderText"] =
              allFieldsData.searchPlaceholderText;
          }

          data["header"]["additionalCode"] = "";
          if (
            allFieldsData.additionalCode != "" &&
            allFieldsData.additionalCode != undefined
          ) {
            data["header"]["additionalCode"] = allFieldsData.additionalCode;
          }

          data["header"]["menuItems"] = {};
          if (
            allFieldsData.topMenu != "" &&
            allFieldsData.topMenu != undefined
          ) {
            let menuItems = [];
            $.each(allFieldsData.topMenu, function (i, value) {
              if (value.fields.domains != undefined) {
                if (value.fields.domains.includes(global.config.domain)) {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  menuItems.push(singleLink);
                }
              } else {
                let singleLink = {
                  title: value.fields.title,
                  link: value.fields.alias,
                };
                menuItems.push(singleLink);
              }
            });
            data["header"]["menuItems"] = menuItems;
          }

          data["header"]["HeaderOptions"] = {};
          if (
            allFieldsData.siteMainMenu != "" &&
            allFieldsData.siteMainMenu != undefined
          ) {
            let HeaderOptions = [];
            $.each(allFieldsData.siteMainMenu, function (i, value) {
              if (value.fields.domains != undefined) {
                if (value.fields.domains.includes(global.config.domain)) {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  HeaderOptions.push(singleLink);
                }
              } else {
                let singleLink = {
                  title: value.fields.title,
                  link: value.fields.alias,
                };
                HeaderOptions.push(singleLink);
              }
            });
            data["header"]["HeaderOptions"] = HeaderOptions;
          }

          data["header"]["topHeaderBar"] = "";
          if (
            allFieldsData.topHeaderBars != "" &&
            allFieldsData.topHeaderBars != undefined
          ) {
            $.each(allFieldsData.topHeaderBars, function (i, topHeaderBar) {
              if (topHeaderBar.fields.domains.includes(global.config.domain)) {
                data["header"]["topHeaderBar"] = topHeaderBar.fields.html;
                return false;
              }
            });
          }

          data["header"]["topHeaderBarBackgroundColor"] = "";
          if (
            allFieldsData.topHeaderBarBackgroundColor != "" &&
            allFieldsData.topHeaderBarBackgroundColor != undefined
          ) {
            data["header"]["topHeaderBarBackgroundColor"] =
              allFieldsData.topHeaderBarBackgroundColor;
          }

          data["header"]["nondisclosureMessage"] = "";
          if (
            allFieldsData.nondisclosureMessage != "" &&
            allFieldsData.nondisclosureMessage != undefined
          ) {
            $.each(
              allFieldsData.nondisclosureMessage,
              function (i, nondisclosureMessage) {
                if (nondisclosureMessage.fields.domains != undefined) {
                  if (
                    nondisclosureMessage.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    data["header"]["nondisclosureMessage"] =
                      nondisclosureMessage.sys.id;
                    return false;
                  }
                } else {
                  data["header"]["nondisclosureMessage"] =
                    nondisclosureMessage.sys.id;
                }
              }
            );
          }
        }
      }
    })
    .catch(console.error);

  await client
    .getEntries({
      content_type: "footer",
      "fields.domains": global.config.domain,
      locale: currLocale,
    })
    .then((response) => {
      if (response.items != "" && response.items != undefined) {
        let entries = response.items;
        if (entries[0] != "" && entries[0] != undefined) {
          let allFieldsData = entries[0].fields;

          data["footer"]["termlyButton"] = null;
          if (
            allFieldsData.termlyButton != "" &&
            allFieldsData.termlyButton != undefined
          ) {
            data["footer"]["termlyButton"] = allFieldsData.termlyButton;
          }

          data["footer"]["LinkOptions"] = {};
          if (
            allFieldsData.column1MenuLinks != "" &&
            allFieldsData.column1MenuLinks != undefined
          ) {
            let LinkOptions = [];
            $.each(allFieldsData.column1MenuLinks, function (i, value) {
              if (value.fields.domains != undefined) {
                if (value.fields.domains.includes(global.config.domain)) {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  LinkOptions.push(singleLink);
                }
              } else {
                let singleLink = {
                  title: value.fields.title,
                  link: value.fields.alias,
                };
                LinkOptions.push(singleLink);
              }
            });
            data["footer"]["LinkOptions"] = LinkOptions;
          }

          data["footer"]["menuItems"] = {};
          if (
            allFieldsData.column2MenuLinks != "" &&
            allFieldsData.column2MenuLinks != undefined
          ) {
            let menuItems = [];
            $.each(allFieldsData.column2MenuLinks, function (i, value) {
              if (value.fields.domains != undefined) {
                if (value.fields.domains.includes(global.config.domain)) {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  menuItems.push(singleLink);
                }
              } else {
                let singleLink = {
                  title: value.fields.title,
                  link: value.fields.alias,
                };
                menuItems.push(singleLink);
              }
            });
            data["footer"]["menuItems"] = menuItems;
          }

          data["footer"]["join_ss_text"] = "";
          data["footer"]["join_ss_desc"] = "";
          data["footer"]["join_cta_id"] = "";

          if (
            allFieldsData.column3Content != "" &&
            allFieldsData.column3Content != undefined
          ) {
            $.each(allFieldsData.column3Content, function (i, column3Item) {
              if (column3Item.fields.domains != undefined) {
                if (column3Item.fields.domains.includes(global.config.domain)) {
                  if (
                    column3Item.fields.title != "" &&
                    column3Item.fields.title != undefined
                  ) {
                    data["footer"]["join_ss_text"] = column3Item.fields.title;
                  }
                  if (
                    column3Item.fields.description != "" &&
                    column3Item.fields.description != undefined
                  ) {
                    data["footer"]["join_ss_desc"] =
                      column3Item.fields.description;
                  }
                  if (
                    column3Item.fields.ctaButton != "" &&
                    column3Item.fields.ctaButton != undefined
                  ) {
                    data["footer"]["join_cta_id"] =
                      column3Item.fields.ctaButton.sys.id;
                  }
                  return false;
                }
              } else {
                if (
                  column3Item.fields.title != "" &&
                  column3Item.fields.title != undefined
                ) {
                  data["footer"]["join_ss_text"] = column3Item.fields.title;
                }
                if (
                  column3Item.fields.description != "" &&
                  column3Item.fields.description != undefined
                ) {
                  data["footer"]["join_ss_desc"] =
                    column3Item.fields.description;
                }
                if (
                  column3Item.fields.ctaButton != "" &&
                  column3Item.fields.ctaButton != undefined
                ) {
                  data["footer"]["join_cta_id"] =
                    column3Item.fields.ctaButton.sys.id;
                }
              }
            });
          }

          data["footer"]["footerBackgroundColor"] = "";
          if (
            allFieldsData.footerBackgroundColor != "" &&
            allFieldsData.footerBackgroundColor != undefined
          ) {
            data["footer"]["footerBackgroundColor"] =
              allFieldsData.footerBackgroundColor;
          }

          data["footer"]["bottomFooterColumn1MenuLinks"] = {};
          if (
            allFieldsData.bottomFooterColumn1MenuLinks != "" &&
            allFieldsData.bottomFooterColumn1MenuLinks != undefined
          ) {
            let bottomFooterColumn1MenuLinks = [];
            $.each(
              allFieldsData.bottomFooterColumn1MenuLinks,
              function (i, value) {
                if (value.fields.domains != undefined) {
                  if (value.fields.domains.includes(global.config.domain)) {
                    let singleLink = {
                      title: value.fields.title,
                      link: value.fields.alias,
                    };
                    bottomFooterColumn1MenuLinks.push(singleLink);
                  }
                } else {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  bottomFooterColumn1MenuLinks.push(singleLink);
                }
              }
            );
            data["footer"]["bottomFooterColumn1MenuLinks"] =
              bottomFooterColumn1MenuLinks;
          }

          data["footer"]["bottomFooterColumn2CopyrightContent"] = "";
          if (
            allFieldsData.bottomFooterColumn2Copyright != "" &&
            allFieldsData.bottomFooterColumn2Copyright != undefined
          ) {
            $.each(
              allFieldsData.bottomFooterColumn2Copyright,
              function (i, bottomFooterColumn2Copyright) {
                if (bottomFooterColumn2Copyright.fields.domains != undefined) {
                  if (
                    bottomFooterColumn2Copyright.fields.domains.includes(
                      global.config.domain
                    )
                  ) {
                    data["footer"]["bottomFooterColumn2CopyrightContent"] =
                      bottomFooterColumn2Copyright.fields.html;
                    return false;
                  }
                } else {
                  data["footer"]["bottomFooterColumn2CopyrightContent"] =
                    bottomFooterColumn2Copyright.fields.html;
                }
              }
            );
          }

          data["footer"]["bottomFooterBottomMenuLinks"] = {};
          if (
            allFieldsData.bottomFooterBottomMenuLinks != "" &&
            allFieldsData.bottomFooterBottomMenuLinks != undefined
          ) {
            let bottomFooterBottomMenuLinks = [];
            $.each(
              allFieldsData.bottomFooterBottomMenuLinks,
              function (i, value) {
                if (value.fields.domains != undefined) {
                  if (value.fields.domains.includes(global.config.domain)) {
                    let singleLink = {
                      title: value.fields.title,
                      link: value.fields.alias,
                    };
                    bottomFooterBottomMenuLinks.push(singleLink);
                  }
                } else {
                  let singleLink = {
                    title: value.fields.title,
                    link: value.fields.alias,
                  };
                  bottomFooterBottomMenuLinks.push(singleLink);
                }
              }
            );
            data["footer"]["bottomFooterBottomMenuLinks"] =
              bottomFooterBottomMenuLinks;
          }

          data["social_links"] = {};
          if (
            allFieldsData.column4SocialLinks != "" &&
            allFieldsData.column4SocialLinks != undefined
          ) {
            let column4SocialLinks = [];
            $.each(allFieldsData.column4SocialLinks, function (i, value) {
              if (value.fields.domains != undefined) {
                if (value.fields.domains.includes(global.config.domain)) {
                  let singleSocialLink = {
                    icon_type: "font_awesome",
                    url: value.fields.link,
                    icon_class: value.fields.socialiconClass,
                    title: value.fields.title,
                  };
                  column4SocialLinks.push(singleSocialLink);
                }
              } else {
                let singleSocialLink = {
                  icon_type: "font_awesome",
                  url: value.fields.link,
                  icon_class: value.fields.socialiconClass,
                  title: value.fields.title,
                };
                column4SocialLinks.push(singleSocialLink);
              }
            });
            data["social_links"] = column4SocialLinks;
          }

          data["footer"]["levelAccessImage"] = {};
          if (
            allFieldsData.levelAccessImage != "" &&
            allFieldsData.levelAccessImage != undefined
          ) {
            const levelAccessObject = {
              ctaList: allFieldsData.levelAccessImage.fields.cta,
              imageUrl:
                allFieldsData.levelAccessImage.fields.levelAccessImage.fields
                  .file.url,
              altText:
                allFieldsData.levelAccessImage.fields.levelAccessImage.fields
                  .title,
            };
            data["footer"]["levelAccessImage"] = levelAccessObject;
          }
        }
      }
    })
    .catch(console.error);

  return data;
};

export {
  fetchCms,
  fetchSiteData,
  fetchStory,
  fetchDynamicPage,
  fetchDesigner,
  getLocale,
  getDomain,
  getGlobalData,
  getBaseUrl,
  addLangKeyPath,
  fetchSitemapData,
  fetchAllUrl,
  getHrefLang,
  getDefaultLanguage,
  getLocationApiKey,
};
export default {
  fetchCms,
  fetchSiteData,
  fetchStory,
  fetchDynamicPage,
  fetchDesigner,
  getLocale,
  getDomain,
  getGlobalData,
  getBaseUrl,
  addLangKeyPath,
  fetchSitemapData,
  fetchAllUrl,
  getHrefLang,
  getDefaultLanguage,
  getLocationApiKey,
};
