import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const ImpactTimeline: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const [timelineInformation, setTimelineInformation] = useState('');
    const [timelineItems,setTimelineItems]=useState([]);
    const [timelineItemsLength,setTimelineItemsLength]=useState('');

    useEffect(()=>
    {
      
      function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry!="" && entry!=undefined)
          {
            console.log('timeline data ', entry);
            if(entry.fields!="" && entry.fields!=undefined)
            {
              if(entry.fields.timelineInformation!="" && entry.fields.timelineInformation!=undefined)
              {
                 const options = {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                        return node.data.target.fields.html;
                      },
                      [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                    }
                  }
                  let rawRichText = documentToHtmlString(entry.fields.timelineInformation, options);
                  setTimelineInformation(rawRichText);
              }   
              if(entry.fields.timelineItems!="" && entry.fields.timelineItems!=undefined)
              {
                  let allTimelineItems:any=[];
                 if(entry.fields.timelineItems.length>0)
                 {
                     entry.fields.timelineItems.map(async(itemEntry:any,i:any)=>
                     { 
                         client.getEntry(itemEntry.sys.id, {locale: currLocale}).then(async(item : any) => {
                           let singleTimelineItem={
                              index: i,
                              title:"",
                              image:"",
                              imageAltText:"",
                              description:""
                           } 
                            if(item!="" && item!=undefined)
                            {
                              if(item.fields!="" && item.fields!=undefined)
                              {
                                 if(item.fields.title!="" && item.fields.title!=undefined)
                                 {
                                     singleTimelineItem['title']= item.fields.title;
                                 } 
                                 if(item.fields.image!="" && item.fields.image!=undefined)
                                 {
                                     let fileUrl= item.fields.image.fields.file.url;
                                     singleTimelineItem['image']= fileUrl;
                                     singleTimelineItem['imageAltText']= item.fields.image.fields.title;
                                 }
                                 if(item.fields.description!="" && item.fields.description!=undefined)
                                 {
                                     const options = {
                                      renderNode: {
                                        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                          return node.data.target.fields.html;
                                        },
                                        [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                                      }
                                    }
                                    let rawRichText = documentToHtmlString(item.fields.description, options);
                                    singleTimelineItem['description']=rawRichText;
                                 } 
                                 allTimelineItems.push(singleTimelineItem);
                                 allTimelineItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                                 
                                 await setTimelineItems(allTimelineItems);
                                 await setTimelineItemsLength(allTimelineItems.length);
                              }  
                            }
                         });    
                         
                     })
                 }
              } 
            }
          }
        }); 
      }

    }
    
    fetchComponentData();
    },[]);
    return (
    <div className="medium-container">
        {timelineInformation?<div className="impact-head text-center">
          <p dangerouslySetInnerHTML={{__html:timelineInformation}}></p>
        </div>:null} 
        <div className="impact-timeline">
          
          {timelineItems.length>0?timelineItems.map((items:any)=>
           <div className="impact-timeline-outer">
            {items.image?<div className="timeline-img">
              <img className="wow fadeInLeft" width = '345' height = '247' data-wow-duration="2s" data-wow-delay="0" src={items.image} alt={items.imageAltText?items.imageAltText:""}/>
            </div>:null}
            <div className="timeline-info wow fadeInRight" data-wow-duration="2s" data-wow-delay="0">
              {items.title?<h6>{items.title}</h6>:null}
              {items.description?<p dangerouslySetInnerHTML={{__html:items.description}}></p>:null}             
            </div>
           </div>):null}

        </div>
    </div>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ImpactTimeline.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ImpactTimeline };

export default connect(mapStateToProps, mapDispatchToProps)(ImpactTimeline);
