import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { getLocale } from "../../api/cms/api";

const TwoColumnCardSecond: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [listTitle, setListTitle] = useState([]);
  const [listDescription, setListDescription] = useState([]);
  const [listImage, setListImage] = useState<any>([]);
  const [sectionHeading, setSectionHeading] = useState([]);

  useEffect(() => {

  if (componentData.sectionHeading != "" && componentData.sectionHeading != undefined) {
      setSectionHeading(componentData.sectionHeading);
  }

  if (componentData.listItem != "" && componentData.listItem != undefined) {
      componentData.listItem.map((item: any) => {
        client.getEntry(item.sys.id, {locale: currLocale})
          .then((entry: any) => {
           if (entry != "" && entry != undefined) {
              if (entry.fields.description != "" && entry.fields.description != undefined) 
              {
                setListDescription((listDescription) =>listDescription.concat(entry.fields.description));
              }
              if (entry.fields.title != "" && entry.fields.title != undefined) 
              {
                setListTitle((listTitle) =>listTitle.concat(entry.fields.title));
              }
              if (entry.fields.image.fields.file.url != "" && entry.fields.image.fields.file.url != undefined) 
              {
                let imageDetail={
                  image:"",
                  imageAltText:""
                }
                imageDetail['image']=entry.fields.image.fields.file.url;
                imageDetail['imageAltText']=entry.fields.image.fields.title;
                setListImage(imageDetail);
              }
            
          }
          })
          .catch(console.error);
      });
    }
  },[]);

  return (
   <>
  <div className="two-column-card-layout">
    <div className="container">
      <h4 className="mb-4">{sectionHeading?sectionHeading:""}</h4>
      <div className="row">
        {listImage
          ? listImage.map((_item: any,index:any) => {
              return (
                <div className="col-md-6">
                  <div className="verical-list-wrap">
                    <div className="media-row">
                      {_item.image?<img src={_item.image} alt={_item.imageAltText?_item.imageAltText:""}/>:null}
                    </div>
                    {listTitle?<h5>{listTitle[index]}</h5>:null}
                    {listDescription?<p>{listDescription[index]}</p>:null}
                    <a href="#" className="read-more-link">
                      Watch the video
                    </a>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  </div>  
   </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColumnCardSecond.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColumnCardSecond };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoColumnCardSecond);
