
import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const StepsBlock: React.FC<any> = (componentData) => {
    
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [imageAlignment, setImageAlignment] = useState([]);
  const [image, setImage] = useState<any>([]);

  useEffect(() => {

    if (componentData.title != '' && componentData.title != undefined) {
      setTitle((title)=>title.concat(componentData.title));
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription((description)=>description.concat(componentData.description));
    }

    if (componentData.imageAlignment != '' && componentData.imageAlignment != undefined) {
      setImageAlignment((imageAlignment)=>imageAlignment.concat(componentData.imageAlignment));
    }

    if (componentData.image.fields.file.url != '' && componentData.image.fields.file.url != undefined) {
      let imageDetail={
        image:"",
        imageAltText:""
      }
      imageDetail['image']= componentData.image.fields.file.url;
      imageDetail['imageAltText']= componentData.image.fields.title;

      setImage(imageDetail);
    }


  },[]);

    return (
        <>
        <div className="steps-block">
            <div className="container">
                {image.map((item:any,index:any)=>
                <div className={imageAlignment[index]=="Left"?"steps-common-wrap mb-5 text-right-media-left-block":"steps-common-wrap mb-5 text-left-media-right-block"}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="descp-block">
                                    <h6 className=" mb-3 text-uppercase">{title?title[index]:""}</h6>
                                    <p className="body1">{description?description[index]:""}</p>
                                </div>
                             </div>
                             <div className="col-md-6">
                                 <div className="media-block">
                                    {item.image?<img src={item.image} alt={item.imageAltText?item.imageAltText:""}/>:null}
                                 </div>
                             </div>
                        </div>
                    </div>)
                    }                 
            </div>
        </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

StepsBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { StepsBlock };

export default connect(mapStateToProps, mapDispatchToProps)(StepsBlock);
