import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from '../../Client';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const HeroCardLarge: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [textAlignment, setTextAlignment] = useState('');
  const [title, setTitle] = useState('');
  const [titleHeadingTag, setTitleHeadingTag] = useState('h3');
  const HeroTitle = titleHeadingTag as keyof JSX.IntrinsicElements;
  const [image, setImage] = useState('');
  const [handleName, setHandleName] = useState('');
  const [handleIcon, setHandleIcon] = useState('');
  const [imageAltText, setImageAltText] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [description, setDescription] = useState('');
  const [ctaButtonTitle, setCtaButtonTitle] = useState('');
  const [ctaAriaLabel, setCtaAriaLabel] = useState('');
  const [ctaButtonLink, setCtaButtonLink] = useState('');
  const [ctaButtonClass, setCtaButtonClass] = useState('read-more-link');
  const [textSectionBackgroundColor, setTextSectionBackgroundColor] = useState('');
  const [titleFontColor, setTitleFontColor] = useState('');
  const [subHeadingFontColor, setSubHeadingFontColor] = useState('');
  const [subHeadingFontSize, setSubHeadingFontSize] = useState('');
  const [descriptionFontColor, setDescriptionFontColor] = useState('');
  const [primaryButtonClass, setPrimaryButtonClass] = useState('');
  const [ctaFontAwesomeIconClass, setCtaFontAwesomeIconClass] = useState('');
  const [cardTag, setCardTag] = useState('');
  const [cardTagBackgroundColor, setCardTagBackgroundColor] = useState('');
  const [icon, setIcon] = useState('');
   const [iconAltText, setIconAltText] = useState('');
  const [fullWidth, setFullWidth] = useState(false);
  const [titleAlignment, setTitleAlignment] = useState('');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');
  const [noPadding, setNoPadding] = useState('');
  const [entryId, setEntryId] = useState('');
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [imageTopPadding, setImageTopPadding] = useState('');
  const [imageBottomPadding, setImageBottomPadding] = useState('');
  const [mobileImageTopPadding, setMobileImageTopPadding] = useState('');
  const [mobileImageBottomPadding, setMobileImageBottomPadding] = useState('');

  useEffect(() => {

    if (componentData.textAlignment !== '' && componentData.textAlignment !== undefined) {
      setTextAlignment(componentData.textAlignment);
    }

    if (componentData.title !== '' && componentData.title !== undefined) {
      setTitle(componentData.title);
    }

    if (componentData.titleHeadingTag !== '' && componentData.titleHeadingTag !== undefined) {
      setTitleHeadingTag(componentData.titleHeadingTag.toLowerCase());
    }

    if (componentData.entryId !== "" && componentData.entryId !== undefined) {
     setEntryId("content-"+componentData.entryId);
    }

    if (componentData.titleAlignment !== '' && componentData.titleAlignment !== undefined) {
      if (componentData.titleAlignment === "Left") {
        setTitleAlignment("title-align-left");
      }
      else if (componentData.titleAlignment === "Right") {
        setTitleAlignment("title-align-right");
      }
    }

    if (componentData.image !== '' && componentData.image !== undefined) {
      let fileUrl = componentData.image.fields.file.url;
      let altText = componentData.image.fields.title;
      setImage(fileUrl);
      setImageAltText(altText);
    }

    if (componentData.subHeading !== '' && componentData.subHeading !== undefined) {
      setSubHeading(componentData.subHeading);
    }

    if (componentData.description !== '' && componentData.description !== undefined) {
      setDescription(componentData.description);
    }

    if (componentData.disclaimerText !== '' && componentData.disclaimerText !== undefined) {
      setDisclaimerText(componentData.disclaimerText);
    }

    if (componentData.icon !== '' && componentData.icon !== undefined) {
      setIcon(componentData.icon.fields.file.url);
      setIconAltText(componentData.icon.fields.title);
    }

    if (componentData.ctaButton !== '' && componentData.ctaButton !== undefined) {
      client.getEntry(componentData.ctaButton.sys.id, {locale: currLocale})
      .then((entry : any) => {
        if (entry !== '' && entry !== undefined) {
          if (entry.fields.title !== '' && entry.fields.title !== undefined) {
            setCtaButtonTitle(entry.fields.title);
          }
          if (entry.fields.ariaLabel !== '' && entry.fields.ariaLabel !== undefined) {
            setCtaAriaLabel(entry.fields.ariaLabel);
          }
          if (entry.fields.alias !== '' && entry.fields.alias !== undefined) {
            setCtaButtonLink(entry.fields.alias);
          }

          if (entry.fields.ctaType !== '' && entry.fields.ctaType !== undefined) {

            if (entry.fields.ctaType === 'Primary Button') {
              setCtaButtonClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setCtaButtonClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setCtaButtonClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setCtaButtonClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setCtaButtonClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setCtaButtonClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setCtaButtonClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setCtaButtonClass('read-more-link');
            }
          }
          if (entry.fields.ctaTextColor !== '' && entry.fields.ctaTextColor !== undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor !== '' && entry.fields.ctaZigzaglineColor !== undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor !== '' && entry.fields.ctaUnderlineColor !== undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor !== '' && entry.fields.ctaButtonColor !== undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor !== '' && entry.fields.ctaButtonTextColor !== undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor !== '' && entry.fields.hoverColor !== undefined) {
            setHoverColor(entry.fields.hoverColor);
          }

          if (entry.fields.openLinkInNewTab !== '' && entry.fields.openLinkInNewTab !== undefined && entry.fields.openLinkInNewTab === true) {
            setOpenLinkInNewTab("_blank");
          }
        }
      })
      .catch(console.error)
    }

    if (componentData.textSectionBackgroundColor !== '' && componentData.textSectionBackgroundColor !== undefined) {
      setTextSectionBackgroundColor(componentData.textSectionBackgroundColor);
    }

    if (componentData.titleFontColor) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.subHeadingFontColor) {
      setSubHeadingFontColor(componentData.subHeadingFontColor);
    }

    if (componentData.subHeadingFontSize) {
      setSubHeadingFontSize(componentData.subHeadingFontSize);
    }

    if (componentData.descriptionFontColor) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.primaryButtonClass !== '' && componentData.primaryButtonClass !== undefined) {
      setPrimaryButtonClass(componentData.primaryButtonClass);
    }

    if (componentData.ctaFontAwesomeIconClass !== '' && componentData.ctaFontAwesomeIconClass !== undefined) {
      setCtaFontAwesomeIconClass(componentData.ctaFontAwesomeIconClass);
    }

    if (componentData.cardTag !== '' && componentData.cardTag !== undefined) {
      setCardTag(componentData.cardTag);
    }

    if (componentData.cardTagBackgroundColor !== '' && componentData.cardTagBackgroundColor !== undefined) {
      setCardTagBackgroundColor(componentData.cardTagBackgroundColor);
    }

    if (componentData.fullWidth !== '' && componentData.fullWidth !== undefined) {
      setFullWidth(componentData.fullWidth);
    }

    if (componentData.noPadding !== undefined && componentData.noPadding !== "") {
      if (componentData.noPadding === true) {
        setNoPadding('without-padding');
      }     
    }

    if (componentData.handleName !== undefined && componentData.handleName !== "") {
      setHandleName(componentData.handleName);
    }

    if (componentData.handleIcon!== undefined && componentData.handleIcon !== "") {
      setHandleIcon(componentData.handleIcon);
    }
    
    if (componentData.noPadding !== undefined && componentData.noPadding !== "") {
      if (componentData.noPadding === true) {
        setNoPadding('without-padding');
      }     
    }

    if (
      componentData.imageTopPadding !== "" &&
      componentData.imageTopPadding !== undefined
    ) {
      setImageTopPadding(componentData.imageTopPadding);
    }

    if (
      componentData.imageBottomPadding !== "" &&
      componentData.imageBottomPadding !== undefined
    ) {
      setImageBottomPadding(componentData.imageBottomPadding);
    }

    if (
      componentData.mobileImageTopPadding !== "" &&
      componentData.mobileImageTopPadding !== undefined
    ) {
      setMobileImageTopPadding(componentData.mobileImageTopPadding);
    }

    if (
      componentData.mobileImageBottomPadding !== "" &&
      componentData.mobileImageBottomPadding !== undefined
    ) {
      setMobileImageBottomPadding(componentData.mobileImageBottomPadding);
    }

     
    
  }, [componentData.cardTag, componentData.cardTagBackgroundColor, componentData.ctaButton, componentData.ctaFontAwesomeIconClass, componentData.description, componentData.descriptionFontColor, componentData.disclaimerText, componentData.entryId, componentData.fullWidth, componentData.handleIcon, componentData.handleName, componentData.icon, componentData.image, componentData.imageBottomPadding, componentData.imageTopPadding, componentData.mobileImageBottomPadding, componentData.mobileImageTopPadding, componentData.noPadding, componentData.primaryButtonClass, componentData.subHeading, componentData.subHeadingFontColor, componentData.subHeadingFontSize, componentData.textAlignment, componentData.textSectionBackgroundColor, componentData.title, componentData.titleAlignment, componentData.titleFontColor, componentData.titleHeadingTag, currLocale]);

  let styles = {};
  if (fullWidth) {
    styles = {
      background: textSectionBackgroundColor
    }
  }

  return (
  <>  
        <style dangerouslySetInnerHTML=
          {{__html: `
            .hero-card .hero-media.${entryId} {
              padding-top: ${imageTopPadding};
              padding-bottom: ${imageBottomPadding};
            }
           
            @media(max-width: 767px) {
              .hero-card .hero-media.${entryId} {
                padding-top: ${mobileImageTopPadding};
                padding-bottom: ${mobileImageBottomPadding};
              }
            }

            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color:${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color:${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color:${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color:${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color:${ctaButtonColor};
            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color:${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }

            
            .hero-card.hero-card-large .card-inner.${entryId}
            {
               background: ${textSectionBackgroundColor}
            } 
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            } 
            `,
          }}
        />
    <div className={fullWidth ? "hero-card hero-card-large mb-5 full-width "+titleAlignment + " " + noPadding: "hero-card hero-card-large mb-5 "+titleAlignment + " " + noPadding} style={styles}>
      <div className="container">
        <div className={"card-inner "+entryId}>
          <div className="row">
            {textAlignment!=="Left"?
              <>
                <div className="col-lg-6 hero-media no-space">
                  {image ? (
                      <a href={addLangKeyPath(ctaButtonLink)}>
                        <img src={image} width='320' height='240' alt={imageAltText?imageAltText:""}/>
                      </a>
                  ): ""}
                  {handleName?             
                  <div className="user-name-block">
                    <div className="name">{handleName}</div>
                    { componentData.handleIcon=="Instagram" ? <div className="icon"><i className="fa fa-instagram" aria-hidden="true"></i></div> : null}                                     
                  </div>
                  :null
                  }                                
                </div>
                <div className="col-lg-6 hero-media-info">
                  {icon ? <img src={icon} width='141' height='39' alt={iconAltText?iconAltText:""}/> : ""}
                  {cardTag ? <span className="tag-style tag-bg1" style={{background: cardTagBackgroundColor}}>{cardTag}</span> : ""}
                  {title ? <HeroTitle style={{ color: titleFontColor }} dangerouslySetInnerHTML={{__html:title}}></HeroTitle> : ""}
                  {subHeading ? <p className="subheading" style={{ color: subHeadingFontColor, fontSize: Number(subHeadingFontSize) }} dangerouslySetInnerHTML={{__html:subHeading}}></p> : ""}
                  {description ? <p style={{color:descriptionFontColor}}> <ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></p> : ""}
                  {ctaButtonTitle ?
                    <a className={ctaButtonClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaButtonLink)} aria-label={ctaAriaLabel}>{ctaButtonTitle}
                      <i className="fa fa-caret-right" aria-hidden="true"></i>          
                      <span className="svg-outer">
                        <svg width="387" height="12" viewBox="0 0 387 12">
                          <defs>
                            <clipPath>
                              <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                            </clipPath>
                          </defs>
                          <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                            <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                          </g>
                        </svg>          
                      </span>
                      <span>           
                      </span>
                  </a>
                    : ""
                  }
                </div>
              </>
              :
              <>
                <div className="col-lg-6 hero-media-info">
                  {title ? <HeroTitle style={{ color: titleFontColor }} dangerouslySetInnerHTML={{__html:title}}></HeroTitle> : ""}
                  {subHeading ? <h6 style={{ color: subHeadingFontColor, fontSize: Number(subHeadingFontSize) }} dangerouslySetInnerHTML={{__html:subHeading}}></h6> : ""}
                  {description ? <p style={{color:descriptionFontColor}}> <ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></p> : ""}
                  {ctaButtonTitle ?
                     <a className={ctaButtonClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaButtonLink)} aria-label={ctaAriaLabel}>{ctaButtonTitle}
                      <i className="fa fa-caret-right" aria-hidden="true"></i>          
                      <span className="svg-outer">
                        <svg width="387" height="12" viewBox="0 0 387 12">
                          <defs>
                            <clipPath>
                              <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                            </clipPath>
                          </defs>
                          <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                            <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                          </g>
                        </svg>          
                      </span>
                      <span>           
                      </span>
                  </a>
                    : ""
                  }
                </div>
                
                <div className={"col-lg-6 hero-media no-space " + entryId }>
                  {image ? <img src={image} width = '593' height = '543' alt={imageAltText?imageAltText:""}/> : ""}
                  {handleName?
                  <div className="user-name-block">                  
                    <div className="name">{handleName}</div>
                    { handleIcon=="Instagram" ? <div className="icon"><i className="fa fa-instagram" aria-hidden="true"></i></div> : null }                   
                  </div> 
                  : null }
                </div>
              </>
            }
          </div>
        </div>
        {disclaimerText ? <div className="col-lg-10 offset-lg-1 pb-10"><small> <ReactMarkdown allowDangerousHtml source={disclaimerText} disallowedTypes={['paragraph']} unwrapDisallowed /></small></div> : ""}
      </div>  
    </div>
  </>  
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

HeroCardLarge.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { HeroCardLarge };

export default connect(mapStateToProps, mapDispatchToProps)(HeroCardLarge);
