import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const RelatedStoryBlock: React.FC<any> = (props) => {


    return (
        <>
            <div className="related-story-block">
            <div className="container">
                <h4 className="mb-4">{props.title}</h4>
                <div className="row">
                    {props.blocks ? props.blocks.map((_item:any)=>{
                      return(<div className="col-md-4">
                          <div className="grid-box">
                              <div className="media-row mb-4">
                                  <img src={_item.url} />
                              </div>
                              <div className="media-info">
                                  <h5>{_item.title}</h5>
                                  <p>{_item.desc}</p>
                                  <a className="small-text" href="#">{_item.link_text}</a>
                              </div>
                          </div>
                      </div>)
                    }):null}

                    

                </div>
            </div>
        </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

RelatedStoryBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { RelatedStoryBlock };

export default connect(mapStateToProps, mapDispatchToProps)(RelatedStoryBlock);
