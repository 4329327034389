import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import GoogleMap from 'google-map-react';

var zoom=11;

const StoreLocatorView: React.FC<any> = (props) => {

    const [Search_array,setSearchArray] = useState([{"id":0,Location :"",Distance:"",Address:"",Contact:"",Open:"",Detail:""}]);
    const [searchedItem,setSearchedItem]=useState("");
    const [currentLat,setLat]=useState(20.5937);
    const [currentLng,setLng]=useState(78.9629);

    useEffect(() => {
        getCurrentLocation();
        var propsData=(props.places);
        if(propsData!=null)
        {
            setSearchArray(props.places);
        }

    });


    const getCurrentLocation=()=>
    {
        navigator.permissions.query({name:'geolocation'}).then((result) =>
        {
            if (result.state === 'granted')
            {
                navigator.geolocation.getCurrentPosition((position) => {
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                });
            }
            else if (result.state === 'prompt') {
                navigator.geolocation.getCurrentPosition((position) => {
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                });
            }
            else if (result.state === 'denied') {

            }

        });
    }



    return (
        <> <div className="map-block bg-grey">
            <div className="sidebar-address-block">
                <div className="location-textfield-block">
                    <div className="textfield-wrap-with-btn">
                        <input type="text" placeholder="Enter location" value={searchedItem} onChange={(event)=>{setSearchedItem(event.target.value)}} />

                        <button className="btn btn-primary">{props.search_button_text}</button>
                    </div>
                    <p>{props.paragraph} <a href="#">{props.anchor_text}</a></p>
                </div>

                <div className="address-listing-wrap">
                    {Search_array? Search_array.filter(data => data.Location.includes(searchedItem)).map(LocationData => {
                        return(
                            <div className="address-box">
                                <div className="logo-row">
                                    <img src={props.logo} alt="logo"/>
                                </div>
                                <div className="location-name">
                                    <h6>{LocationData.Location}</h6>
                                    <span className="distance">{LocationData.Distance}</span>
                                </div>
                                <p>{LocationData.Address} <span className="phone-number">{LocationData.Contact}</span>
                                </p>
                                <h6 className="opening-time small-text clr-green">{LocationData.Open}</h6>
                                <p className="small-text">{LocationData.Detail}</p>
                                <div className="directions-links">
                                    <a href="#">{props.direction_link_title}</a>
                                    <a href="/store-locator-details">{props.view_link_title}</a>
                                </div>
                                <p className="donation-text small-text mb-0">{props.donation_text} <a href="#">{props.donation_anchor_text}</a></p>
                            </div>
                        )


                    }) : null}




                </div>
            </div>
            <div className="wide-map-block">
              <GoogleMap
                    bootstrapURLKeys={{ key: 'AIzaSyDu0HrmwHqKthWbvzAkxEzwse9htKL-1yI' }}
                    defaultCenter={{lat:currentLat,lng:currentLng}}
                    defaultZoom={zoom}
                >
              </GoogleMap>
            </div>
        </div>

            <div className="mob-map-block map-block bg-grey">
                <div className="location-textfield">
                    <div className="textfield-wrap-with-btn">
                        <input type="text" placeholder="Enter location" value={searchedItem}  onChange={(e)=>setSearchedItem(e.target.value)}/>
                        <button className="btn btn-primary">{props.button_text}</button>
                    </div>
                </div>
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab"><img src={props.tabs[0].icon} />{props.tabs[0].tab}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab"><img src={props.tabs[1].icon} />{props.tabs[1].tab}</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <div className="sidebar-address-block">
                            <div className="location-textfield-block">
                                <p>{props.paragraph}<a href="#">learn more here</a></p>
                            </div>

                            <div className="address-listing-wrap">
                                {Search_array ? Search_array.filter(data => data.Location.includes(searchedItem)).map(LocationData => {
                                    return(
                                        <div className="address-box">
                                            <div className="logo-row">
                                                <img src={props.logo} alt="logo"/>
                                            </div>
                                            <div className="location-name">
                                                <h6>{LocationData.Location}</h6>
                                                <span className="distance">{LocationData.Distance}</span>
                                            </div>
                                            <p>{LocationData.Address} <span
                                                className="phone-number">{LocationData.Contact}</span></p>
                                            <h6 className="opening-time small-text clr-green">{LocationData.Open}</h6>
                                            <p className="small-text">{LocationData.Detail}</p>
                                            <div className="directions-links">
                                                <a href="#">{props.direction_link_title}</a>
                                                <a href="#">{props.view_link_title}</a>
                                            </div>
                                            <p className="donation-text small-text mb-0">{props.donation_text} <a href="#">{props.donation_anchor_text}</a></p>
                                        </div>

                                    )}):null}
                                {Search_array.length>=1 ?<div className="text-center mt-4 mb-5">
                                    <a href="javascript:void(0);" className="btn btn-primary">{props.load_button_text}</a>
                                </div>:null}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane bgclr" id="tabs-2" role="tabpanel" >
                        <div className="wide-map-block" style={{height:"100vh"}}>
                            <GoogleMap
                                bootstrapURLKeys={{ key: 'AIzaSyDu0HrmwHqKthWbvzAkxEzwse9htKL-1yI' }}
                                defaultCenter={{lat:currentLat,lng:currentLng}}
                                defaultZoom={zoom}
                            >
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </div>

           
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

StoreLocatorView.defaultProps = {
    fetchSiteData: () => {},
    header: {},

};

export { StoreLocatorView };

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocatorView);
