import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const ImpactLeftImageWithText: React.FC<any> = (componentData) => {
    const currLocale = getLocale();
    const [leftSideTitle, setLeftSideTitle] = useState('');
    const [rightSideTitle, setRightSideTitle] = useState('');
    const [rightSideImage, setRightSideImage] = useState('');
    const [rightSideImageAltText, setRightSideImageAltText] = useState('');
    const [leftSideContent, setLeftSideContent] = useState('');
    const [rightSideContent, setRightSideContent] = useState('');
    const [rightSideBackgroundColor, setRightSideBackgroundColor] = useState('');
    const [disclaimerText,setdDisclaimerText]=useState('');
    useEffect(() => {
    function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry!="" && entry!=undefined)
          {
            if(entry.fields!="" && entry.fields!=undefined)
            {
              if(entry.fields.rightSideContent!="" && entry.fields.rightSideContent!=undefined)
              {
                 const options = {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                        return node.data.target.fields.html;
                      },
                      [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                    }
                  }
                  let rawRichRightSide = documentToHtmlString(entry.fields.rightSideContent, options);
                     
                  setRightSideContent(rawRichRightSide);
              }   
              if(entry.fields.disclaimerText!="" && entry.fields.disclaimerText!=undefined)
              {
                 const options = {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                        return node.data.target.fields.html;
                      },
                      [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                    }
                  }
                  let rawRichText = documentToHtmlString(entry.fields.disclaimerText, options);
                  setdDisclaimerText(rawRichText);
              } 
            }
          }
        }); 
      }

    }
    
    fetchComponentData();
  
        

        if(componentData.rightSideImage!="" && componentData.rightSideImage!=undefined)
        {
          let fileUrl=componentData.rightSideImage.fields.file.url;
          setRightSideImage(fileUrl);
          setRightSideImageAltText(componentData.rightSideImage.fields.title)
        }

        if(componentData.rightSideTitle!="" && componentData.rightSideTitle!=undefined)
        {      
          setRightSideTitle(componentData.rightSideTitle);
        }
 

        if(componentData.rigthSideBackgroundColor!="" && componentData.rigthSideBackgroundColor!=undefined)
        {
           setRightSideBackgroundColor(rightSideBackgroundColor);
        } 

    },[]);

    return (           
      <div className="left-img-with-text" style={{backgroundColor:rightSideBackgroundColor}}>
        <div className="bg-layer" style={{backgroundColor:rightSideBackgroundColor}}></div>
        <div className="box-layer-inner" >  
          {rightSideImage?<div className="left-img">
            <img className="" src={rightSideImage} width = '290' height   = '490' alt={rightSideImageAltText?rightSideImageAltText:""}/>                    
          </div>:null}
          <div className="right-info">
            {rightSideTitle?<h6>{rightSideTitle}</h6>: ""}
            <div className="overflow-hidden">
              <div className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0s" dangerouslySetInnerHTML={{ __html:rightSideContent}}>  
              </div>  
            </div>
          </div>
        </div>
        {disclaimerText?<p className="bottom-right-align" dangerouslySetInnerHTML={{__html:disclaimerText}}></p>:null}
      </div>  

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ImpactLeftImageWithText.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ImpactLeftImageWithText };

export default connect(mapStateToProps, mapDispatchToProps)(ImpactLeftImageWithText);
