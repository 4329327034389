import {
  SITE_TYPE,
  NAV_TYPE,
  FOOTER_BUTTONS_TYPE,
  FOOTER_LINKS_TYPE,
} from '../actions/actionTypes';

const initialState = {
  isLoadingSite: false,
  isLoadingNav: false,
  isLoadingFooterBtns: false,
  isLoadingFooterLinks: false,

  siteError: false,
  navError: false,
  footerBtnsError: false,
  footerLinksError: false,

  footer: {
    buttons: [],
    links: [],
    social: {},
  },
  header: {
    site_logo:"",
    storeText:"",
    searchText:"",
    logo_altText:"",
    menuItems:[],
    HeaderOptions:[]
  },
  allAliases:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SITE_TYPE.SET_ERROR:
      return { ...state, siteError: action.payload };
    case SITE_TYPE.IS_LOADING:
      return { ...state, isLoadingSite: action.payload };
    case SITE_TYPE.SET_DATA:
      const { footer, social_links, header } = action.payload;
      return {
        ...state,
        header: { ...state.header, ...header, social: social_links },
        footer: { ...state.footer, ...footer, social: social_links },
      };

    case NAV_TYPE.SET_ERROR:
      return { ...state, navError: action.payload };
    case NAV_TYPE.IS_LOADING:
      return { ...state, isLoadingNav: action.payload };
    case NAV_TYPE.SET_DATA:
      return {
        ...state,
        header: { ...state.header, navigation: action.payload },
      };

    case FOOTER_BUTTONS_TYPE.SET_ERROR:
      return { ...state, footerBtnsError: action.payload };
    case FOOTER_BUTTONS_TYPE.IS_LOADING:
      return { ...state, isLoadingFooterBtns: action.payload };
    case FOOTER_BUTTONS_TYPE.SET_DATA:
      return {
        ...state,
        footer: { ...state.footer, buttons: action.payload },
      };

    case FOOTER_LINKS_TYPE.SET_ERROR:
      return { ...state, footerLinksError: action.payload };
    case FOOTER_LINKS_TYPE.IS_LOADING:
      return { ...state, isLoadingFooterLinks: action.payload };
    case FOOTER_LINKS_TYPE.SET_DATA:
      return {
        ...state,
        footer: { ...state.footer, links: action.payload },
      };
     case "aliases":
     return {
        ...state,allAliases:action.payload
      };

    default:
      return state;
  }
};

export default reducer;
