import React, { useEffect } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";

const TwoColumnVerticalListSec: React.FC<any> = (props) => {
  return (
    <div className="two-column-vertical-list vertical-text-list">
      <div className="container">
        <div className="row">
          {props.images
            ? props.images.map((_item: any) => {
                return (
                  <div className="col-md-6">
                    <div className="vertical-list-wrap">
                      <div className="row">
                        <div className="col-md-6 col-6  space-right">
                          <div className="media-block">
                            <img src={_item.url} />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6  space-left">
                          <div className="media-info">
                            <h6>{_item.title}</h6>
                            <p>{_item.desc}</p>
                            <a href="#" className="read-more-link">
                              {_item.link_text}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mob-media-info">
                        <p>{_item.desc}</p>
                        <a href="#" className="read-more-link">
                          {_item.link_text}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColumnVerticalListSec.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColumnVerticalListSec };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoColumnVerticalListSec);
