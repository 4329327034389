import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const TextWithCta: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [titleFontSizeDesktop, setTitleFontSizeDesktop] = useState('');
  const [titleFontSizeMobile, setTitleFontSizeMobile] = useState('');
  const [titleWidth, setTitleWidth] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [badgeIcon, setBadgeIcon] = useState('');
  const [badgeIconAltText, setBadgeIconAltText] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
  const [description, setDescription] = useState('');
  const [ctaButtonTitle, setCtaButtonTitle] = useState('');
  const [ctaAriaLabel, setCtaAriaLabel] = useState('');
  const [ctaButtonLink, setCtaButtonLink] = useState('');
  const [ctaButtonClass, setCtaButtonClass] = useState('read-more-link');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [topPadding, setTopPadding] = useState('');
  const [bottomPadding, setBottomPadding] = useState('');
  const [mobileTopPadding, setMobileTopPadding] = useState('');
  const [mobileBottomPadding, setMobileBottomPadding] = useState('');
  const [entryId, setEntryId] = useState('');
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');


  useEffect(() => {
    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }
    
    if (componentData.titleFontSizeDesktop != '' && componentData.titleFontSizeDesktop != undefined) {
      setTitleFontSizeDesktop(componentData.titleFontSizeDesktop);
    }

    if (componentData.titleFontSizeMobile != '' && componentData.titleFontSizeMobile != undefined) {
      setTitleFontSizeMobile(componentData.titleFontSizeMobile);
    }

    if (componentData.titleWidth != '' && componentData.titleWidth != undefined) {
      setTitleWidth(componentData.titleWidth);
    }

    if (
      componentData.entryId != "" &&
      componentData.entryId != undefined
    ) {
      setEntryId("content-"+componentData.entryId);
    }
    if (componentData.subTitle != '' && componentData.subTitle != undefined) {
      setSubTitle(componentData.subTitle);
    }

    if (componentData.backgroundImage != '' && componentData.backgroundImage != undefined) {
      let fileUrl = componentData.backgroundImage.fields.file.url;
      setBackgroundImage(fileUrl);
    }

    if (componentData.mobileBackgroundImage != '' && componentData.mobileBackgroundImage != undefined) {
      let fileUrl = componentData.mobileBackgroundImage.fields.file.url;
      setMobileBackgroundImage(fileUrl);
    }

    if (
      componentData.topPadding != "" &&
      componentData.topPadding != undefined
    ) {
      setTopPadding(componentData.topPadding);
    }

    if (
      componentData.mobileTopPadding != "" &&
      componentData.mobileTopPadding != undefined
    ) {
      setMobileTopPadding(componentData.mobileTopPadding);
    }

    if (
      componentData.bottomPadding != "" &&
      componentData.bottomPadding != undefined
    ) {
      setBottomPadding(componentData.bottomPadding);
    }

     if (
      componentData.mobileBottomPadding != "" &&
      componentData.mobileBottomPadding != undefined
    ) {
      setMobileBottomPadding(componentData.mobileBottomPadding);
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription(componentData.description);
    }

    if (componentData.badgeIcon != '' && componentData.badgeIcon != undefined) {
      let fileUrl = componentData.badgeIcon.fields.file.url;
      let altText = componentData.badgeIcon.fields.title;
      setBadgeIcon(fileUrl);
      setBadgeIconAltText(altText);
    }

    if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }


    if (componentData.ctaButton != '' && componentData.ctaButton != undefined) {
      client.getEntry(componentData.ctaButton.sys.id, {locale: currLocale})
      .then((entry : any) => {
        if (entry != '' && entry != undefined) {
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setCtaButtonTitle(entry.fields.title);
          }
          if (entry.fields.ariaLabel != '' && entry.fields.ariaLabel != undefined) {
            setCtaAriaLabel(entry.fields.ariaLabel);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setCtaButtonLink(entry.fields.alias);
          }
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {

            if (entry.fields.ctaType === 'Primary Button') {
              setCtaButtonClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setCtaButtonClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setCtaButtonClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setCtaButtonClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setCtaButtonClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setCtaButtonClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setCtaButtonClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setCtaButtonClass('read-more-link');
            }
          }
          if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setHoverColor(entry.fields.hoverColor);
          }

          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setOpenLinkInNewTab("_blank");
          }
        }
      })
      .catch(console.error)
    }
  }, []);

  return (
    <>
      <div className="full-width-media-layer" style={{backgroundColor: backgroundColor}}>
        
      {backgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div> : ""}
      {mobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+mobileBackgroundImage+")"}}></div> : ""}
      <div className={"common-heading-block text-center " + entryId} style={{backgroundColor: backgroundColor}}>
        <style dangerouslySetInnerHTML=
        {{__html: `   
          .common-heading-block.${entryId} {
            padding-top: ${topPadding};
            padding-bottom: ${bottomPadding};            
          }

          .common-heading-block.${entryId} .heading-inner {
            max-width: ${titleWidth};
          }

          .common-heading-block.${entryId} .heading-inner h2 {
            font-size: ${titleFontSizeDesktop};
          }

          @media(max-width: 767px) {
            .common-heading-block.${entryId} .heading-inner h2 {
              font-size: ${titleFontSizeMobile};
            }
            .common-heading-block.${entryId} {
              padding-top: ${mobileTopPadding};
              padding-bottom: ${mobileBottomPadding};
            }
          }
          .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};

            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor} !important;
                 border-color: ${hoverColor} !important;
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};

            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }       

          `,
        }}
        />
        <div className="container">
          <div className="heading-inner">
            {
              badgeIcon?<img src={badgeIcon} width = '30' height = '45' alt={badgeIconAltText?badgeIconAltText:""} />:null
            }
            {
              title ? (<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>)
              : ""
            }
            {
              subTitle ? (<h6>{subTitle}</h6>)
              : ""
            }
            {
              description ? (<> <ReactMarkdown allowDangerousHtml source={description}></ReactMarkdown> <div className="clearfix"></div></>)
              : ""
            }
            {
              ctaButtonTitle ?
              <a className={ctaButtonClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaButtonLink)} aria-label={ctaAriaLabel} role="button">{ctaButtonTitle}
                <i className="fa fa-caret-right" aria-hidden="true"></i>          
                <span className="svg-outer">
                  <svg width="387" height="12" viewBox="0 0 387 12">
                    <defs>
                      <clipPath>
                        <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                      </clipPath>
                    </defs>
                    <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                      <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                    </g>
                  </svg>          
                </span>
                <span>           
                </span>
              </a>
              : ""
            }
          </div>  
        </div>
      </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TextWithCta.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TextWithCta };

export default connect(mapStateToProps, mapDispatchToProps)(TextWithCta);
