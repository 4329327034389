import mockSiteData from "./data/site";
import mockHomeData from "./data/homepage";
import mockThriftProud from "./data/ThriftProud";
import mockStoreLocator from "./data/storeLocator";
import mockRethinkReuse from "./data/rethink";
import mockStoreLocatorView from "./data/storeLocatorView";
import mockStoreLocatorDetail from "./data/storeLocatorDetails";
import mockStoryPage from "./data/story";
import mockHolidayPage from "./data/holiday";
import mockThriftorial from "./data/Thriftorial";
import mockDonation from "./data/donation";
import mockContactData from "./data/Contact";
import mockAboutData from "./data/about";
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const mockFetch = async (mockData) => {
  try {
    await timeout(300);

    return mockData();
  } catch (e) {
    // console.log(e);
    return {};
  }
};

export const mockPost = async (_mockInData, mockData) => {
  await timeout(300);

  return mockData();
};
export const fetchHomePageData = () => mockFetch(mockHomeData);
export const fetchRethinkReuse = () => mockFetch(mockRethinkReuse);
export const fetchSiteData = () => mockFetch(mockSiteData);
export const fetchThriftProud = () => mockFetch(mockThriftProud);
export const fetchStoreLocator = () => mockFetch(mockStoreLocator);
export const fetchStoreLocatorView = () => mockFetch(mockStoreLocatorView);
export const fetchStoreLocatorDetail = () => mockFetch(mockStoreLocatorDetail);
export const fetchStoryPage = () => mockFetch(mockStoryPage);
export const fetchThriftorialPage = () => mockFetch(mockThriftorial);
export const fetchHolidayPageData = () => mockFetch(mockHolidayPage);
export const fetchDonationPage = () => mockFetch(mockDonation);
export const fetchContactPageData = () => mockFetch(mockContactData);
export const fetchAboutPageData = () => mockFetch(mockAboutData);

export const postBonusPuzzleResult = async (data) => {
  try {
    return await mockPost(data, () => data);
  } catch (e) {
    return {};
  }
};

export default {
  fetchRethinkReuse,
  fetchSiteData,
  fetchThriftProud,
  fetchStoreLocator,
  fetchHomePageData,
  fetchStoreLocatorView,
  fetchStoreLocatorDetail,
  fetchStoryPage,
  fetchThriftorialPage,
  fetchHolidayPageData,
  fetchDonationPage,
  fetchContactPageData,
};
