import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getLocale } from "../../api/cms/api";
import client from "../../Client";
import ReactMarkdown from 'react-markdown';

const Testimonials: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const[title,setTitle]=useState("");
    const[testimonialItems,setTestimonialItems]=useState([]);
    const[testimonialItemsLength,setTestimonialItemsLength]=useState(0);
    const[testimonialsInformation,setTestimonialsInformation]=useState("");
    const[dynamicClass,setDynamicClass]=useState("");

    useEffect(()=>
    {
       if(componentData.title!=undefined && componentData.title!=undefined)
       {
           setTitle(componentData.title);
       }
       if(componentData.testimonialsInformation!=undefined && componentData.testimonialsInformation!=undefined)
       {
           setTestimonialsInformation(componentData.testimonialsInformation);
       }
       if(componentData.testimonialStyles!=undefined && componentData.testimonialStyles!=undefined)
       {
           setDynamicClass(componentData.testimonialStyles);
       }
        if(componentData.testimonialItems!=undefined && componentData.testimonialItems!=undefined)
       {
           let allTestimonialItems:any=[];
           componentData.testimonialItems.map((item:any,i:any)=>
             {
                client.getEntry(item.sys.id, {locale: currLocale}).then(async(entry : any) => {
                    let singleTestimonialItems={
                        index: i,
                        bubbleType:"",
                        handlerTitle:"",
                        handlerIcon:"",
                        description:""
                    }
                    if(entry!=undefined && entry!=""){
                        if(entry.fields.bubbleType!="" && entry.fields.bubbleType!=undefined)
                        {
                           singleTestimonialItems['bubbleType']=entry.fields.bubbleType;
                        }
                        if(entry.fields.handlerTitle!="" && entry.fields.handlerTitle!=undefined)
                        {
                           singleTestimonialItems['handlerTitle']=entry.fields.handlerTitle;
                        }
                         if(entry.fields.handlerIcon!="" && entry.fields.handlerIcon!=undefined)
                        {
                           singleTestimonialItems['handlerIcon']=entry.fields.handlerIcon;
                        }
                         if(entry.fields.description!="" && entry.fields.description!=undefined)
                        {
                           singleTestimonialItems['description']=entry.fields.description;
                        }

                        allTestimonialItems.push(singleTestimonialItems);
                        allTestimonialItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                        
                        await setTestimonialItems(allTestimonialItems);
                        await setTestimonialItemsLength(allTestimonialItems.length);
                    }
                })
             }
            )
       }

    },[]);
    
    const slickSettings1 = {     
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ]
  };

    return (
        <>
     
        <div className={"impact-head text-center -testimonial" +dynamicClass}>                          
          {title?<p dangerouslySetInnerHTML={{ __html:title}}></p>
          :null}   
          {testimonialsInformation?<h2 className="box-heading"><ReactMarkdown allowDangerousHtml source={testimonialsInformation} disallowedTypes={['paragraph']} unwrapDisallowed /></h2>:null}
        </div>
        <div className={"right-impact-testimonials "+dynamicClass}>
          

          <div className="testimonilas-outer">
            <img className="layer4" src={require("../../images/svg/layer4.svg")} alt="layer4"/>
            <img className="layer5" src={require("../../images/layer7.svg")} alt="layer5"/>
            <img className="layer6" src={require("../../images/svg/layer8.svg")} alt="layer6"/>
            <img className="layer7" src={require("../../images/svg/layer4.svg")} alt="layer7"/>
            <img className="layer8" src={require("../../images/svg/layer5.svg")} alt="layer8"/>
            <img className="layer9" src={require("../../images/svg/layer6.svg")} alt="layer9"/>
            <img className="desktop-bg" src={require("../../images/testimonial-bg.jpg")} alt="testimonial-bg"/> 
              {testimonialItems.length>0?
                testimonialItems.map((item:any)=> 
                {
                  if(item.bubbleType!="bg7")
                  {
                    return(<div className={"item "+item.bubbleType+" wow zoomIn"} data-wow-duration="2s" data-wow-delay="0.5s">
                      <div className="user-name">
                        <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                          {item.handlerIcon?<div className="icon top"><i className={item.handlerIcon} aria-hidden="true"></i></div>:null}
                          {item.handlerTitle?<div className="name">{item.handlerTitle}</div>:null}
                        </div>
                      </div>
                      {item.description?<p><span className="quote-left">“</span><ReactMarkdown allowDangerousHtml source={item.description} disallowedTypes={['paragraph']} unwrapDisallowed /><span className="quote-right">”</span></p>:null}
                    </div>)
                  }
                } 
                ):null
              }   
          </div> 

          <div className="testimonilas-outer below-slides">
            {testimonialItems.length>0?
                testimonialItems.map((item:any)=> 
                {
                  if(item.bubbleType=="bg7")
                  {
                    return(<div className={"item "+item.bubbleType+" wow zoomIn"} data-wow-duration="2s" data-wow-delay="0.5s">
                      <div className="user-name">
                        <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                          {item.handlerIcon?<div className="icon top"><i className={item.handlerIcon} aria-hidden="true"></i></div>:null}
                          {item.handlerTitle?<div className="name">{item.handlerTitle}</div>:null}
                        </div>
                      </div>
                      {item.description?<p><span className="quote-left">“</span><ReactMarkdown allowDangerousHtml source={item.description} disallowedTypes={['paragraph']} unwrapDisallowed /><span className="quote-right">”</span></p>:null}
                    </div>)
                  }
                } 
                ):null
              }

          </div>                          
        </div>        
        <div className={"right-impact-testimonials with-slider " +dynamicClass}>
    
            <div className="testimonilas-outer">
              <img className="layer4" src={require("../../images/svg/layer4.svg")} alt="layer4"/>
              <img className="layer5" src={require("../../images/layer7.svg")} alt="layer5"/>
              <img className="layer6" src={require("../../images/svg/layer8.svg")} alt="layer6"/>
              <img className="layer7" src={require("../../images/svg/layer4.svg")} alt="layer7"/>
              <img className="layer8" src={require("../../images/svg/layer5.svg")} alt="layer8"/>
              <img className="layer9" src={require("../../images/svg/layer6.svg")} alt="layer9"/>
              <img className="desktop-bg" src={require("../../images/testimonial-bg.jpg")} alt="testimonial-bg"/> 
           {testimonialItems.length>0?
             <Slider {...slickSettings1}>
              { testimonialItems.length>0?
                testimonialItems.map((item:any)=>  
                  {
                    if(item.bubbleType!="bg7")
                   {
                    return (<div className={"item "+item.bubbleType+" wow zoomIn"} data-wow-duration="2s" data-wow-delay="0.5s">
                    <div className="user-name">
                      <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                        {item.handlerIcon?<div className="icon top"><i className={item.handlerIcon} aria-hidden="true"></i></div>:null}
                        {item.handlerTitle?<div className="name">{item.handlerTitle}</div>:null}
                      </div>
                    </div>
                    {item.description?<p><span className="quote-left">“</span><ReactMarkdown allowDangerousHtml source={item.description} disallowedTypes={['paragraph']} unwrapDisallowed /><span className="quote-right">”</span></p>:null}
                  </div>)
                }
               }):null
              }  
              </Slider>
              :null}
            </div>
            <div className="testimonilas-outer below-slides">
            {testimonialItems.length>0?
                testimonialItems.map((item:any)=> 
                {
                  if(item.bubbleType=="bg7")
                  {
                    return(<div className={"item "+item.bubbleType+" wow zoomIn"} data-wow-duration="2s" data-wow-delay="0.5s">
                      <div className="user-name">
                        <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                          {item.handlerIcon?<div className="icon top"><i className={item.handlerIcon} aria-hidden="true"></i></div>:null}
                          {item.handlerTitle?<div className="name">{item.handlerTitle}</div>:null}
                        </div>
                      </div>
                      {item.description?<p><span className="quote-left">“</span><ReactMarkdown allowDangerousHtml source={item.description} disallowedTypes={['paragraph']} unwrapDisallowed /><span className="quote-right">”</span></p>:null}
                    </div>)
                  }
                } 
                ):null
              }

          </div> 
          </div>
       
     
      </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

Testimonials.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { Testimonials };

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
