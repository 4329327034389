import React, { useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ComponentComposer from "../../components/ComponentComposer";
import ReactMarkdown from 'react-markdown';

const ImpactStats: React.FC<any> = (componentData) => {
    const currLocale = getLocale();
    const[title,setTitle]=useState('');
    const[rightToLeftMovingObject,setRightToLeftMovingObject]=useState('');
    const[rightToLeftMovingObjectAltText,setRightToLeftMovingObjectAltText]=useState('');
    const[fashionIndustryTitle,setFashionIndustryTitle]=useState('');
    const[fashionIndustryValue,setFashionIndustryValue]=useState('');
    const[fashionIndustryUnit,setFashionIndustryUnit]=useState(''); 
    const[fashionIndustryWaterConsumptionImage,setFashionIndustryWaterConsumptionImage]=useState('');
    const[fashionIndustryWaterConsumptionImageAltText,setFashionIndustryWaterConsumptionImageAltText]=useState('');
    const[consumptionNeedsTitle,setConsumptionNeedsTitle]=useState('');
    const[consumptionNeedsValue,setConsumptionNeedsValue]=useState('');
    const[consumptionNeedsUnit,setConsumptionNeedsUnit]=useState(''); 
    const[annualEnviormentalImage,setAnnualEnviormentalImage]=useState('');
     const[annualEnviormentalImageAltText,setAnnualEnviormentalImageAltText]=useState('');
    const[annualEnviormentalDescription,setAnnualEnviormentalDescription]=useState(''); 
    const[threeColLeftTitle,setThreeColLeftTitle]=useState('');
    const[threeColLeftImage,setThreeColLeftImage]=useState('');
    const[threeColLeftImageAltText,setThreeColLeftImageAltText]=useState('');
    const[threeColRightTitle,setThreeColRightTitle]=useState('');
    const[threeColRightImage,setThreeColRightImage]=useState('');
    const[threeColRightImageAltText,setThreeColRightImageAltText]=useState('');
    const[threeColRightSubTitle,setThreeColRightSubTitle]=useState('');
    const[threeColTitle,setThreeColTitle]=useState(''); 
    const[mobileReusableTextileTitle,setMobileReusableTextileTitle]=useState('');
    const[mobileReusableTextileUnit,setMobileReusableTextileUnit]=useState(''); 
    const[mobileReusableTextileSubTitle,setMobileReusableTextileSubTitle]=useState('');
    const[leftSideHiddenText,setLeftSideHiddenText]=useState('');
    const[movingObjectTopSubTitle,setMovingObjectTopSubTitle]=useState('');
    const[movingObjectTitle,setMovingObjectTitle]=useState('');
    const[movingObjectBottomSubTitle,setMovingObjectBottomSubTitle]=useState('');
    const[fashionIndustryWaterFillLayer,setFashionIndustryWaterFillLayer]=useState('');
    const[fashionIndustryWaterFillLayerAltText,setFashionIndustryWaterFillLayerAltText]=useState('');
    const[annualEnviormentalImageFillLayer,setAnnualEnviormentalImageFillLayer]=useState('');
    const[annualEnviormentalImageFillLayerAltText,setAnnualEnviormentalImageFillLayerAltText]=useState('');
    const[annualEnviormentalImageTitle,setAnnualEnviormentalImageTitle]=useState('');

    useEffect(()=>
    {

     function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry.fields.fashionIndustryUnit!="" && entry.fields.fashionIndustryUnit!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.fashionIndustryUnit, options);             
              setFashionIndustryUnit(rawRichText);
          }   
          if(entry.fields.consumptionNeedsUnit!="" && entry.fields.consumptionNeedsUnit!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.consumptionNeedsUnit, options);            
              setConsumptionNeedsUnit(rawRichText);
          }
          if(entry.fields.annualEnviormentalDescription!="" && entry.fields.annualEnviormentalDescription!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.annualEnviormentalDescription, options);
              setAnnualEnviormentalDescription(rawRichText);
          }
           if(entry.fields.threeColTitle!="" && entry.fields.threeColTitle!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.threeColTitle, options);
              setThreeColTitle(rawRichText);
          }
           if(entry.fields.mobileReusableTextileUnit!="" && entry.fields.mobileReusableTextileUnit!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.mobileReusableTextileUnit, options);
              setMobileReusableTextileUnit(rawRichText);
          }


        }); 
      }

    }
    
    fetchComponentData();

      if(componentData.title!="" && componentData.title!=undefined)
      {
          setTitle(componentData.title);
      }
      if(componentData.rightToLeftMovingObject!="" && componentData.rightToLeftMovingObject!=undefined)
      {
          let fileUrl=componentData.rightToLeftMovingObject.fields.file.url;
          setRightToLeftMovingObject(fileUrl);
          setRightToLeftMovingObjectAltText(componentData.rightToLeftMovingObject.fields.title);
      }
      if(componentData.fashionIndustryTitle!="" && componentData.fashionIndustryTitle!=undefined)
      {
          setFashionIndustryTitle(componentData.fashionIndustryTitle);
      }
      if(componentData.fashionIndustryValue!="" && componentData.fashionIndustryValue!=undefined)
      {
          setFashionIndustryValue(componentData.fashionIndustryValue);
      }
      if(componentData.fashionIndustryWaterConsumptionImage!="" && componentData.fashionIndustryWaterConsumptionImage!=undefined)
      {
          let fileUrl=componentData.fashionIndustryWaterConsumptionImage.fields.file.url;
          setFashionIndustryWaterConsumptionImage(fileUrl);
          setFashionIndustryWaterConsumptionImageAltText(componentData.fashionIndustryWaterConsumptionImage.fields.title);
      }
      if(componentData.fashionIndustryWaterFillLayer!="" && componentData.fashionIndustryWaterFillLayer!=undefined)
      {
          let fileUrl=componentData.fashionIndustryWaterFillLayer.fields.file.url;
          setFashionIndustryWaterFillLayerAltText(componentData.fashionIndustryWaterFillLayer.fields.title);
          setFashionIndustryWaterFillLayer(fileUrl);
      }
      if(componentData.consumptionNeedsTitle!="" && componentData.consumptionNeedsTitle!=undefined)
      {
          setConsumptionNeedsTitle(componentData.consumptionNeedsTitle);
      }
      if(componentData.consumptionNeedsValue!="" && componentData.consumptionNeedsValue!=undefined)
      {
          setConsumptionNeedsValue(componentData.consumptionNeedsValue);
      }
      if(componentData.annualEnviormentalImage!="" && componentData.annualEnviormentalImage!=undefined)
      {
          let fileUrl=componentData.annualEnviormentalImage.fields.file.url;
          setAnnualEnviormentalImage(fileUrl);
          setAnnualEnviormentalImageAltText(componentData.annualEnviormentalImage.fields.title);
      }
      if(componentData.annualEnviormentalImageFillLayer!="" && componentData.annualEnviormentalImageFillLayer!=undefined)
      {
          let fileUrl=componentData.annualEnviormentalImageFillLayer.fields.file.url;
          setAnnualEnviormentalImageFillLayer(fileUrl);
          setAnnualEnviormentalImageFillLayerAltText(componentData.annualEnviormentalImageFillLayer.fields.title);
      }
      if(componentData.annualEnviormentalImageTitle!="" && componentData.annualEnviormentalImageTitle!=undefined)
      {
          setAnnualEnviormentalImageTitle(componentData.annualEnviormentalImageTitle);
      }
      if(componentData.threeColLeftTitle!="" && componentData.threeColLeftTitle!=undefined)
      {
          setThreeColLeftTitle(componentData.threeColLeftTitle);
      }
       if(componentData.threeColLeftImage!="" && componentData.threeColLeftImage!=undefined)
      {
          let fileUrl=componentData.threeColLeftImage.fields.file.url;
          setThreeColLeftImage(fileUrl);
          setThreeColLeftImageAltText(componentData.threeColLeftImage.fields.title);
      }
      if(componentData.threeColRightTitle!="" && componentData.threeColRightTitle!=undefined)
      {
          setThreeColRightTitle(componentData.threeColRightTitle);
      }
       if(componentData.threeColRightImage!="" && componentData.threeColRightImage!=undefined)
      {
          let fileUrl=componentData.threeColRightImage.fields.file.url;
          setThreeColRightImage(fileUrl);
          setThreeColRightImageAltText(componentData.threeColRightImage.fields.title);
      }
      if(componentData.threeColRightSubTitle!="" && componentData.threeColRightSubTitle!=undefined)
      {
          setThreeColRightSubTitle(componentData.threeColRightSubTitle);
      }
      if(componentData.mobileReusableTextileTitle!="" && componentData.mobileReusableTextileTitle!=undefined)
      {
          setMobileReusableTextileTitle(componentData.mobileReusableTextileTitle);
      }
      if(componentData.mobileReusableTextileSubTitle!="" && componentData.mobileReusableTextileSubTitle!=undefined)
      {
          setMobileReusableTextileSubTitle(componentData.mobileReusableTextileSubTitle);
      }
      if(componentData.leftSideHiddenText!="" && componentData.leftSideHiddenText!=undefined)
      {
          setLeftSideHiddenText(componentData.leftSideHiddenText);
      }

      if(componentData.movingObjectTitle!="" && componentData.movingObjectTitle!=undefined)
      {
          setMovingObjectTitle(componentData.movingObjectTitle);
      }

      if(componentData.movingObjectTopSubTitle!="" && componentData.movingObjectTopSubTitle!=undefined)
      {
        setMovingObjectTopSubTitle(componentData.movingObjectTopSubTitle);
      }

      if(componentData.movingObjectBottomSubTitle!="" && componentData.movingObjectBottomSubTitle!=undefined)
      {
          setMovingObjectBottomSubTitle(componentData.movingObjectBottomSubTitle);
      }



    },[]);

    return (
    <div className="-impact-right-stats">
      <div className="medium-container">
        {rightToLeftMovingObject? <div className="right fixed-img">
          <div className="impact-header">
            <p className="hidden-para wow fadeInOut" data-wow-duration="2s" data-wow-delay="2s" dangerouslySetInnerHTML={{__html:leftSideHiddenText}}></p>
            <img className="wow slideInRight2" data-wow-duration="6s" data-wow-delay="0.5s" width = '513' height  = '210' src={rightToLeftMovingObject} alt={rightToLeftMovingObjectAltText}/>
            <div className="inner wow fadeIn" data-wow-duration="2s" data-wow-delay="4s">
             
              <p>{movingObjectTopSubTitle}</p>
              <div className="clearfix"></div>
              <h1 className="solid-heading"><span dangerouslySetInnerHTML={{__html:movingObjectTitle}}></span></h1>
              <div className="clearfix"></div>
              <p dangerouslySetInnerHTML={{__html:movingObjectBottomSubTitle}}></p>
            </div>
          </div>
        </div>:null}       
        </div> 

        <div className="horizontal-rule one">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <hr></hr>
                <style dangerouslySetInnerHTML=
                {{__html: `
                  .horizontal-rule.one hr {
                    margin-top: 70px;
                    border-top: 0;                 
                  }
                  @media(max-width: 767px) {
                    .horizontal-rule.one hr {
                      margin-top: 50px;                    
                    }
                  }
                  `,
                }}
              />
              </div>
            </div>
          </div>
        </div>        

              
        <div className="medium-container">
          {title?<div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
            <h2 className="box-heading">{title}</h2>
          </div>:null}
          <div className="impact-right-stats">
            <div className="two-col-with-img">
              {fashionIndustryWaterConsumptionImage?<div className="img-col">
                <div className="inner circel">
                  <img className="animatted-img wow slideInUp50" width = '1084' height = '624' data-wow-duration="2s" data-wow-delay=".5s" src={fashionIndustryWaterFillLayer} alt={fashionIndustryWaterFillLayer?fashionIndustryWaterFillLayer:""}/>
                  <img src={fashionIndustryWaterConsumptionImage} width = '425' height = '425' alt={fashionIndustryWaterConsumptionImage?fashionIndustryWaterConsumptionImage:""} />
                </div>
              </div>:null}

              <div className="info-col">
                <div className="info-col-inner">
                  {fashionIndustryTitle?<p><ReactMarkdown allowDangerousHtml source={fashionIndustryTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                  <div className="inline">
                    {fashionIndustryValue?<span className="large" dangerouslySetInnerHTML={{__html:fashionIndustryValue}}></span>:null}
                    {fashionIndustryUnit?<span dangerouslySetInnerHTML={{__html:fashionIndustryUnit}}></span>:null}
                    <span><img width = '46' height = '66' src={require("../../images/drop.png")} alt="drop"/></span>
                  </div>
                </div>
                <div className="info-col-inner">
                  {consumptionNeedsTitle?<p><ReactMarkdown allowDangerousHtml source={consumptionNeedsTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                  <div className="inline">
                    {consumptionNeedsValue?<span className="large" dangerouslySetInnerHTML={{__html:consumptionNeedsValue}}></span>:null}
                    {consumptionNeedsUnit?<span dangerouslySetInnerHTML={{__html:consumptionNeedsUnit}}></span>:null}                  
                  </div>
                </div>
              </div>
            </div>            
            <div className="two-col-with-img">
              <div className="info-col">
                {annualEnviormentalDescription?<div className="info-col-inner">
                  <p dangerouslySetInnerHTML={{__html:annualEnviormentalDescription}}></p>
                </div>:null}
              </div>
             {annualEnviormentalImage?<div className="img-col">< div className="inner">
                <h2>{annualEnviormentalImageTitle}</h2>
                <img className="animatted-img wow slideInUp100" data-wow-duration="2s" data-wow-delay=".5s" width = '600' height   = '232' src={annualEnviormentalImageFillLayer} alt={annualEnviormentalImageFillLayerAltText?annualEnviormentalImageFillLayerAltText:""}/>
                <img src={annualEnviormentalImage} width = '407' height = '191' alt={annualEnviormentalImage?annualEnviormentalImage:""}/>
                </div>
              </div>:null}
            </div>          
          </div>
        </div>

        <div className="small-container">
          <div className="three-col-with-img">
            <div className="info-col-inner">
              {threeColLeftTitle?<p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s"><ReactMarkdown allowDangerousHtml source={threeColLeftTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
              {threeColLeftImage?<img className="wow fadeInDown" width = '153' height = '145' data-wow-duration="2s" data-wow-delay=".5s" src={threeColLeftImage} alt={threeColLeftImageAltText?threeColLeftImageAltText:""}/>:null}
            </div>
            {threeColTitle?<div className="info-col-inner wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
              <h2 dangerouslySetInnerHTML={{__html:threeColTitle}}></h2>
            </div>:null}
            <div className="info-col-inner">
              {threeColRightTitle?<p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s"><ReactMarkdown allowDangerousHtml source={threeColRightTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
              {threeColRightImage?<img  className="wow fadeInDown" width = '63' height = '102' data-wow-duration="2s" data-wow-delay="1.5s" src={threeColRightImage} alt={threeColRightImageAltText?threeColRightImageAltText:""}/>:null}
              {threeColRightSubTitle?<h3 className="wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.5s"><ReactMarkdown allowDangerousHtml source={threeColRightSubTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></h3>:null}
            </div>
          </div>
        </div>

    </div>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ImpactStats.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ImpactStats };

export default connect(mapStateToProps, mapDispatchToProps)(ImpactStats);
