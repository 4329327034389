import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const ThreeColsMedia: React.FC<any> = (props) => {


    return (
        <>
            <div className="three-cols-media">
                <div className="container">
                    <div className="row">
                        {props.images ? props.images.map((item:any)=>{
                           return( <div className="col-md-4">
                               <div className="media-wrap">
                                   <img src={item} />
                               </div>
                           </div>)
                        }):null}


                    </div>
                </div>
            </div></>

    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ThreeColsMedia.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ThreeColsMedia };

export default connect(mapStateToProps, mapDispatchToProps)(ThreeColsMedia);
