
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import GoogleMap from 'google-map-react';
import CommonBoxesWrap from "./../CommonBoxesWrap";
import StoreNearBy from './../StoreNearBy';
import HeroCardLarge from './../HeroCardLarge';
import Header from "./../Header";
var zoom=11;

const StoreLocationBlock: React.FC<any> = (props) => {
    const [currentLat,setLat]=useState(20.5937);
    const [currentLng,setLng]=useState(78.9629);

    useEffect(() => {
        fetchdata();
        getCurrentLocation();
        async  function fetchdata() {
        }


    });

    const getCurrentLocation=()=>
    {
        navigator.permissions.query({name:'geolocation'}).then((result) =>
        {
            if (result.state === 'granted')
            {
                navigator.geolocation.getCurrentPosition((position) => {
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                });
            }
            else if (result.state === 'prompt') {
                navigator.geolocation.getCurrentPosition((position) => {
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                });
            }
            else if (result.state === 'denied') {

            }

        });
    }

    const {

        site_logo,
        storeText,
        storeTextMobile,
        searchText,
        HeaderOptions,
        menuItems
    } = props.header;

    return (
        <>
            <div className="breadcrumbs-row mt-5">
                <div className="container">
                    <ul>
                        <li>{props.breadcrumbs_data[0].list}</li>
                        <li>{props.breadcrumbs_data[1].list}</li>
                        <li><a href="#">{props.breadcrumbs_data[2].list}</a></li>
                    </ul>
                </div>
            </div>
            <div className="store-location-block mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="location-info">
                                <div className="location-inner-details">
                                    <div className="logo-row mb-4">
                                        <img src={props.logo} alt="logo"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="location-name">
                                                <h6>{props.location_data.title}</h6>
                                                <p>{props.location_data.address} <span
                                                    className="phone-number">{props.location_data.contact}</span></p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="timing-listing text-right">
                                                <ul>
                                                    <li>{props.location_data.timing_list[0].time}</li>
                                                    <li>{props.location_data.timing_list[1].time}</li>
                                                    <li>{props.location_data.timing_list[2].time}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="direction-links mt-2">
                                    <a href="javascript:void(0);" className="btn btn-primary">{props.direction_link_title}</a>
                                    <a href="javascript:void(0);" className="btn btn-secondary">{props.call_store_link_title}</a>
                                </div>

                                <div className="location-media location-media-mob" style={{width:"100vh",height:"100vh"}}>
                                    <GoogleMap
                                        bootstrapURLKeys={{ key: 'AIzaSyDu0HrmwHqKthWbvzAkxEzwse9htKL-1yI' }}
                                        defaultCenter={{lat:currentLat,lng:currentLng}}
                                        defaultZoom={zoom}
                                    >
                                    </GoogleMap>
                                </div>

                                <div className="parking-row mt-4 mb-4">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <ul className="parking-list">
                                                <li>{props.parking_data[0].list}</li>
                                                <li>{props.parking_data[1].list}</li>
                                                <li>{props.parking_data[2].list}</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="payment-row mt-3">
                                                <p>{props.payment_data.title}</p>
                                                <div className="payment-method">
                                                    <span><img src={props.payment_data.card_images[0].image}  /></span>
                                                    <span><img src={props.payment_data.card_images[1].image}  /></span>
                                                    <span><img src={props.payment_data.card_images[2].image}  /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="media-slider">
                                    <div className="owl-slider">
                                        <div id="media-carousel" className="owl-carousel">
                                            <div className="item">
                                                <span><img src={props.slider_data[0].image}  /></span>
                                            </div>
                                            <div className="item">
                                                <span><img src={props.slider_data[0].image} /></span>
                                            </div>
                                            <div className="item">
                                                <span><img src={props.slider_data[0].image} /></span>
                                            </div>
                                            <div className="item">
                                                <span><img src={props.slider_data[0].image} /></span>
                                            </div>
                                            <div className="item">
                                                <span><img src={props.slider_data[0].image} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="location-media" style={{width:"100vh",height:"100vh"}}>
                                <GoogleMap
                                    bootstrapURLKeys={{ key: 'AIzaSyDu0HrmwHqKthWbvzAkxEzwse9htKL-1yI' }}
                                    defaultCenter={{lat:currentLat,lng:currentLng}}
                                    defaultZoom={zoom}
                                >
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




           

        </>

    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

StoreLocationBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { StoreLocationBlock };

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocationBlock);






