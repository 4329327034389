import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const TwoColumnList: React.FC<any> = (componentData) => {
   const currLocale = getLocale();
   const [leftSideTitle, setLeftSideTitle] = useState('');
   const [leftSideContent, setLeftSideContent] = useState('');
   const [rightSideContent, setRightSideContent] = useState('');

   useEffect(() => {
       function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
           if(entry.fields.leftSideContent!="" && entry.fields.leftSideContent!=undefined)
        {
            const options = {
              renderNode: {
                [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                  return node.data.target.fields.html;
                },
                [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
              }
            }
            let rawRichLeftSide = documentToHtmlString(entry.fields.leftSideContent, options);
            setLeftSideContent(rawRichLeftSide);
        } 
           if(entry.fields.rightSideContent!="" && entry.fields.rightSideContent!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width = '238' height   = '38' alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichRightSide = documentToHtmlString(entry.fields.rightSideContent, options);
                 
              setRightSideContent(rawRichRightSide);
          }  
        }); 
      }
    }
    
    fetchComponentData();
  
     if (componentData.leftSideTitle != '' && componentData.leftSideTitle != undefined) {
        setLeftSideTitle(componentData.leftSideTitle);
      }

   },[])

  return (
    <section className="two-col-list-wrapper -impact-2-col-para impact-main-wrapper">
      <div className="impact-container">
        <div className="row">
          <div className="col-12 side-space-85">          
            {leftSideTitle?<div id="sticky-title" className="sticky-title"><h1 className="solid-heading left wow slideInLeft" data-wow-duration="2s" data-wow-delay="0s" dangerouslySetInnerHTML={{__html:leftSideTitle}}> 
              
              </h1></div>:null}            
            <div className="col-2-para">
              <div className="leftSideContent wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s" dangerouslySetInnerHTML={{ __html:leftSideContent}}></div>
              <div className="rightSideContent wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s" dangerouslySetInnerHTML={{ __html:rightSideContent}}></div>
            </div>
          </div>
        </div>
      </div>
    </section> 
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColumnList.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColumnList };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnList);
