import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const ThreeColumnVideo: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [videoBannerImage, setVideoBannerImage] = useState([]);
  const [videoBannerImageLength, setVideoBannerImageLength] = useState(0);
  const [videoPlayIcon,setVideoPlayIcon] = useState("");
  const [videoPlayIconAltText,setVideoPlayIconAltText] = useState("");


  useEffect(() => {
    if (
      componentData.title != "" &&
      componentData.title != undefined
    ) {
      setTitle(componentData.title);
    }

    if (
      componentData.description != "" &&
      componentData.description != undefined
    ) {
      setDescription(componentData.description);
    }

    if (
      componentData.backgroundColor != "" &&
      componentData.backgroundColor != undefined
    ) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.videoPlayIcon != '' && componentData.videoPlayIcon != undefined) {
      let playIcon= componentData.videoPlayIcon.fields!==undefined? componentData.videoPlayIcon.fields.file.url:""
      let playAltText= componentData.videoPlayIcon.fields!==undefined? componentData.videoPlayIcon.fields.title:""
      setVideoPlayIcon(playIcon);
      setVideoPlayIconAltText(playAltText)
    }

    if (
      componentData.videoSelection != "" &&
      componentData.videoSelection != undefined
    ) {
        let allVideoBannerImages:any=[];
       componentData.videoSelection.map((item: any,i:any) => {
        client
          .getEntry(item.sys.id, {locale: currLocale})
          .then(async(entry: any) => {
              let singleBannerImage={
                index: i,
                image:"",
                imageAltText:"",
                videoLink:"",
                videoTitle:""
              }

            if (entry.fields.videoLink != "" && entry.fields.videoLink  != undefined) {
              singleBannerImage['videoLink']= entry.fields.videoLink;
            }
             if (entry.fields.videoTitle != "" && entry.fields.videoTitle  != undefined) {
              singleBannerImage['videoTitle']= entry.fields.videoTitle;
            }

            if (entry.fields.videoBannerImage.fields.file.url != "" && entry.fields.videoBannerImage.fields.file.url  != undefined) {
              singleBannerImage['image']=entry.fields.videoBannerImage.fields.file.url;
              singleBannerImage['imageAltText']=entry.fields.videoBannerImage.fields.title;
            }

              allVideoBannerImages.push(singleBannerImage);
              await allVideoBannerImages.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);            
              await setVideoBannerImage(allVideoBannerImages);
              await setVideoBannerImageLength(allVideoBannerImages.length);

          })
          .catch(console.error);
      });

    }

  }, []);

 

  return (
    <>
     <div className="video-modal-block columns-3" style={{backgroundColor: backgroundColor}}>
      <div className="container">
        <div className="row">
              
            {videoBannerImage
              ? videoBannerImage.map((item: any,index) =>{
              var link= item.videoLink;
              var initilaIndex = link.indexOf("embed");
              var videoIdIndex=initilaIndex+6;
              var filteredTitle = link.slice(videoIdIndex,link.length);
              return(<>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="modal-card-box mb-4">
                {item?<img
                  className="large-media"
                  src={item.image} alt={item.imageAltText?item.imageAltText:""}
                />:null}
                <div className="caption-block">
                  <a
                    className="play-icon"
                    data-toggle="modal"
                    data-target={"#myModal"+filteredTitle}
                    tabIndex={0}
                    href="#video-modal"
                  >
                    <img src={videoPlayIcon!=="" ? videoPlayIcon:require("../../images/play-icon.png")} width = '64' height = '64' alt={videoPlayIconAltText} />
                  </a>
                  {
                    item.videoTitle?
                    <h5 className="mt-4">
                    <span dangerouslySetInnerHTML={{__html:item.videoTitle}}></span>
                  </h5>:
                  null
                  }
                
                </div>
              </div>
            </div>
           
         
              
            <div className="modal fade video-modal" id={"myModal"+filteredTitle}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <a href="#close-video-modal" type="button" className="close" data-dismiss="modal">
                      <img className="close-image" src={require("../../images/close-white.png")} alt="close"/>
                    </a>
                  </div>

                  <div className="modal-body">
                    <iframe
                      width="560"
                      height="470"
                      src={item.videoLink}
                      src-url = {item.videoLink}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
              </>)}
              ):null}

          </div>
        </div> 
      </div> 
      

      
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

ThreeColumnVideo.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { ThreeColumnVideo };

export default connect(mapStateToProps, mapDispatchToProps)(ThreeColumnVideo);
