import React, { useEffect } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";

const HolidayBanner: React.FC<any> = ({ fetchSiteData, header }) => {
  useEffect(() => {
    fetchdata();
    async function fetchdata() {
      const data = await fetchSiteData();
      console.log("datasss", data);
    }
  }, [fetchSiteData]);

  const {
    site_logo,
    storeText,
    storeTextMobile,
    searchText,
    HeaderOptions,
    menuItems,
  } = header;

  return (
    <div className="holiday-banner bg4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="banner-info">
              <h1 className="mb-3 clr-nblue">
                Here’s to a tradition of holiday thrifting.
              </h1>
              <h6 className="clr-nblue">
                Tis the season to find a little something for everyone on your
                gift-giving list.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

HolidayBanner.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { HolidayBanner };

export default connect(mapStateToProps, mapDispatchToProps)(HolidayBanner);
