import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";


const TwoColWithTopImage: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const [title, setTitle] = useState('');
    const [containerType, setContainerType] = useState('');
    const[items,setItems]=useState([]);
    const[itemsLength,setItemsLength]=useState(0);
    const[value,setValue]=useState('');

    useEffect(()=>
    {
      
        if(componentData.title!="" && componentData.title!=undefined)
        {
            setTitle(componentData.title);
        }
        if(componentData.containerType!="" && componentData.containerType!=undefined)
        {
            setContainerType(componentData.containerType);
        }

        if(componentData.items!="" && componentData.items!=undefined)
        {
           let allItems:any=[];       

               if(componentData.items.length>0)
                {

                  componentData.items.map(async(itemEntry:any,i:any)=>
                 {
                   client.getEntry(itemEntry.sys.id, {locale: currLocale}).then(async(item : any) => {
                     let singleItem={
                      index: i,
                      description:"",
                      image:"",
                      imageAltText:"",
                      title:"",
                      animatedIcons:[],
                      animatedIconsDimensions:[{width:'68',height:'173'},{width:'103',height:'173'},{width:'56',height:'173'},{width:'67',height:'173'}]
                     }
                   
                  if(item!="" && item!=undefined)
                  {  
                    if(item.fields!="" && item.fields!=undefined)
                    { 
                       if(item.fields.description!="" && item.fields.description!=undefined)
                       {
                           const options = {
                             renderNode: {
                               [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                   return node.data.target.fields.html;
                               },
                               [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                             }
                           }
                           let rawRichText = documentToHtmlString(item.fields.description, options);
                           singleItem['description']=rawRichText;
                        } 
                        if(item.fields.image!="" && item.fields.image)
                        {
                            singleItem['image']= item.fields.image.fields.file.url;
                            singleItem['imageAltText']= item.fields.image.fields.title;
                        }
                        if(item.fields.title!="" && item.fields.image)
                        {
                            singleItem['title']= item.fields.title;
                        }
                        if(item.fields.animatedIcons!="" && item.fields.animatedIcons!=undefined)
                         {
                            let allIcons:any=[];
                            if(item.fields.animatedIcons.length>0)
                            {  
                                item.fields.animatedIcons.map(async(animatedItem:any,index:any)=>
                                {
                                  if(animatedItem.fields!=undefined && animatedItem.fields!="")
                                  {
                                    let singleAnimatedIcon={
                                      index: index,
                                      icons:"",
                                      iconsAltText:"",
                                      animationDelay:"",
                                      animationDuration:"",
                                      dataDelay:""
                                    }
                                    if(animatedItem.fields.images!=undefined && animatedItem.fields.images!="")
                                    {
                                       singleAnimatedIcon['icons']= animatedItem.fields.images.fields.file.url;
                                       if(animatedItem.fields.images.fields.title!=undefined && animatedItem.fields.images.fields.title!="")
                                       {
                                         singleAnimatedIcon['iconsAltText']= animatedItem.fields.images.fields.title;
                                       }
                                    }

                                    if(animatedItem.fields.animationDelay!=undefined && animatedItem.fields.animationDelay!="")
                                    {
                                       singleAnimatedIcon['animationDelay']= animatedItem.fields.animationDelay;
                                    }

                                    if(animatedItem.fields.animationDuration!=undefined && animatedItem.fields.animationDuration!="")
                                    {
                                       singleAnimatedIcon['animationDuration']= animatedItem.fields.animationDuration;
                                    }

                                    if(animatedItem.fields.dataDelay!=undefined && animatedItem.fields.dataDelay!="")
                                    {
                                       singleAnimatedIcon['dataDelay']= animatedItem.fields.dataDelay;
                                    }
                                   
                                    allIcons.push(singleAnimatedIcon);
                                    allIcons.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                                    singleItem['animatedIcons']=allIcons; 
                                  }
                                })
                                
                            }
                         } 

                        allItems.push(singleItem);
                        allItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                        
                        await setItems(allItems);
                        await setItemsLength(allItems.length);
                   }
                  }
                   });

                 }
                )
               }
                
        }
        

    },[]);
  

    return (
    <>
         <div className="medium-container text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
           {title?<div className="impact-head">
             <h2 className="box-heading" dangerouslySetInnerHTML={{ __html:title}}></h2>
           </div>:null}
         </div>         
         <div className="small-container">
           <div className="impact-2-col-with-top-img ">
            {items.map((data:any)=>
              {
               if (data.animatedIcons.length === 0) {
                 return(<div className="inner">
                 <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                   <img className="" src={data.image} width = '131' height  = '171' alt={data.imageAltText?data.imageAltText:""}/>
                 </div>
                 <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1s">
                    {data.title?<h6 dangerouslySetInnerHTML={{__html:data.title}}></h6>:null}
                    <p dangerouslySetInnerHTML={{__html:data.description}}></p>   
                 </div>
               </div>)
               }
              }
             )}

            {items.map((data:any)=>
              {
               if (data.animatedIcons.length>0) { 
                 return(
                   <div className="inner">            
                     <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                       <div className="multiple">
                         {
                          data.animatedIcons.map((iconsData:any,i:any)=>
                          {
                            let iconWidth = data.animatedIconsDimensions[i].width
                            let iconHeight = data.animatedIconsDimensions[i].height
                            return(
                              <img className="wow fadeInLeft" width={iconWidth} height={iconHeight} data-wow-duration={iconsData.animationDuration} data-wow-delay={iconsData.dataDelay} src={iconsData.icons} alt={iconsData.iconsAltText}/>
                            )
                          })
                        }
                       </div>
                     </div>               
                     <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1s">
                       <p dangerouslySetInnerHTML={{__html:data.description}}></p>  
                     </div>
                   </div>
            )
               }
              }
             )}
           </div>
         </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColWithTopImage.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColWithTopImage };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColWithTopImage);
