import React, { useEffect,useState} from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TeamBlock: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [sectionTitle, setSectionTitle] = useState("");
  const [designersHeading, setDesignersHeading] = useState([]);
  const [designersImage, setDesignersImage] = useState([]);
  const [itemLink, setItemLink] = useState([]);
  const [itemAlias, setItemAlias] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [designerGalleryType, setDesignerGalleryType] = useState("grid");
  const [designersItem, setDesignersItem] = useState([]);
  const [designersItemLength, setDesignersItemLength] = useState();

  useEffect(() => {
   if (
      componentData.sectionTitle != "" &&
      componentData.sectionTitle != undefined
    ) {
      setSectionTitle(componentData.sectionTitle);
    }

    if (
      componentData.designer_gallery_type != "" &&
      componentData.designer_gallery_type != undefined
    ) {
      setDesignerGalleryType(componentData.designer_gallery_type);
    }

    if (
      componentData.backgroundColor != "" &&
      componentData.backgroundColor != undefined
    ) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (
      componentData.designersItems != "" &&
      componentData.designersItems != undefined
    ) {
      let allDesignerItems:any=[];
      componentData.designersItems.map((item: any,i:any) => {
        client
          .getEntry(item.sys.id, {locale: currLocale})
          .then((entry: any) => {
             if(entry!==undefined && entry!=="")
             {
              let singleDesignerItem={
                index: i,
                heading:"",
                image:"",
                imageAltText:"",
                title:"",
                alias:"",
                ariaLabel:""
              }

              if (entry.fields.heading != "" && entry.fields.heading  != undefined) {
                singleDesignerItem["heading"]=entry.fields.heading;
              }
              if (entry.fields.image.fields.file.url != "" && entry.fields.image.fields.file.url  != undefined) {
                singleDesignerItem["image"]=entry.fields.image.fields.file.url;
                singleDesignerItem["imageAltText"]= entry.fields.image.fields.title;
              } 

              if (entry.fields.itemLink.fields.title  != "" && entry.fields.itemLink.fields.title != undefined) {
                singleDesignerItem["title"]=entry.fields.itemLink.fields.title;
              } 

              if (entry.fields.itemLink.fields.ariaLabel  != "" && entry.fields.itemLink.fields.ariaLabel != undefined) {
                singleDesignerItem["ariaLabel"]=entry.fields.itemLink.fields.ariaLabel;
              } 

              if (entry.fields.itemLink.fields.alias  != "" && entry.fields.itemLink.fields.alias != undefined) {
                singleDesignerItem["alias"]=entry.fields.itemLink.fields.alias;
              }
              allDesignerItems.push(singleDesignerItem);
              allDesignerItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              
              setDesignersItem(allDesignerItems);
              setDesignersItemLength(allDesignerItems.length);
             }
          })
          .catch(console.error);
      });
    }

   },[]);
  const slickSettings = {     
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            dots: true,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 575,
          
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        
      ]

    };  
   

  return (
    <div style={{backgroundColor: backgroundColor}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="carousel-heading-row">
              {sectionTitle?<h4>{sectionTitle}</h4>:null}
            </div>
          </div>
        </div>
        
        {designerGalleryType==="grid"||designerGalleryType===undefined||designerGalleryType===""?
        <ul className="team-block-inner">
          {designersItem
            ? designersItem.map((item:any,index) => {
                return (
                  <li>
                    <figure className="team-media">
                    <a href={addLangKeyPath(item.alias)} aria-label="designer-image">{item.image?<LazyLoadImage src={item.image} alt={item.imageAltText?item.imageAltText:""}/>:null }
                    </a></figure>
                    {item.heading?<h6 className="name">{item.heading}</h6>:null}
                    <a href={addLangKeyPath(item.alias)} aria-label={item.ariaLabel}>{item.title?item.title:""}</a>
                  </li> 
                );
              })
            : null}
          </ul>
          :
          <>
          {
           designersItem.length>0?
            <Slider {...slickSettings}>
               {designersItem
               ? designersItem.map((item:any,index) => {
                   return (
                     <li>
                       <figure className="team-media">
                        {item.image?<LazyLoadImage src={item.image} alt={item.imageAltText?item.imageAltText:""}/>:null }
                       </figure>
                       {item.heading?<h6 className="name">{item.heading}</h6>:null}
                       <a href={addLangKeyPath(item.alias)}>{item.title?item.title:""}</a>
                     </li> 
                   );
                 })
               : null}
             </Slider>
           :null 
          }
            
          </>
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TeamBlock.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TeamBlock };

export default connect(mapStateToProps, mapDispatchToProps)(TeamBlock);
