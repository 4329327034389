import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const FullBleedBannerTwo: React.FC<any> = (componentData) => {
  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [titleFontColor, setTitleFontColor] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionFontColor, setDescriptionFontColor] = useState('');
  const [descriptionBackground, setDescriptionBackground] = useState('');
  const [kraftTexture, setKraftTexture] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#007473');
  const [contentAlignment, setContentAlignment] = useState('');
  const [moreCta,setMoreCta] = useState([]);
  const [moreCtaItemsLength,setMoreCtaItemsLength] = useState(0);
  const [ctaTitle, setCtaTitle] = useState('');
  const [ctaLink, setCtaLink] = useState('');
  const [ctaClass, setCtaClass] = useState('read-more-link');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');

  const [entryId, setEntryId] = useState('');
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [backgroundPosition, setBackgroundPosition] = useState('');


   useEffect(() => {
    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    if (componentData.titleFontColor != '' && componentData.titleFontColor != undefined) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.entryId != "" && componentData.entryId != undefined) {
      setEntryId("content-"+componentData.entryId);
    }

    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription(componentData.description);
    }

    if (componentData.descriptionBackground != '' && componentData.descriptionBackground != undefined) {
      setDescriptionBackground(componentData.descriptionBackground);
    }

    if (componentData.descriptionFontColor != '' && componentData.descriptionFontColor != undefined) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.kraftTexture != '' && componentData.kraftTexture != undefined) {
      setKraftTexture(componentData.kraftTexture);
    }

    if (componentData.backgroundImage != '' && componentData.backgroundImage != undefined) {
      let fileUrl = componentData.backgroundImage.fields.file.url;
      setBackgroundImage(fileUrl);
    }

    if (componentData.mobileBackgroundImage != '' && componentData.mobileBackgroundImage != undefined) {
      let fileUrl = componentData.mobileBackgroundImage.fields.file.url;
      setMobileBackgroundImage(fileUrl);
    }

    if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.contentAlignment != '' && componentData.contentAlignment != undefined) {
      setContentAlignment(componentData.contentAlignment);
    }

    if (componentData.disclaimerText != '' && componentData.disclaimerText != undefined) {
      setDisclaimerText(componentData.disclaimerText);
    }

  

     if (componentData.cta != '' && componentData.cta != undefined) {
      client.getEntry(componentData.cta.sys.id, {locale: currLocale})
      .then((entry : any) => {
        if (entry != '' && entry != undefined) {
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
            let ctaClassName = 'read-more-link';

            if (entry.fields.ctaType === 'Primary Button') {
              setCtaClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setCtaClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setCtaClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setCtaClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setCtaClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setCtaClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setCtaClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setCtaClass('read-more-link');
            }
          }
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setCtaTitle(entry.fields.title);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setCtaLink(entry.fields.alias);
          }
          if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setHoverColor(entry.fields.hoverColor);
          }
          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setOpenLinkInNewTab("_blank");
          }
          if (componentData?.backgroundPosition) {
            setBackgroundPosition(componentData.backgroundPosition);
          }
        }
      })
      .catch(console.error)
    }

     if (componentData.moreCtas != '' && componentData.moreCtas != undefined) {
      componentData.moreCtas.map(async(item:any,i:any)=>
      {
      let allCtaItems: any = [];
      
      await client.getEntry(item.sys.id, {locale: currLocale})
      .then(async(entry : any) => {
       if (entry != '' && entry != undefined) {
        let singleCtaItem = {
            index: i,
            MoreCtaClass: "read-more-link",
            MoreCtaTitle: "",
            MoreCtaLink:"",
            ctaButtonColor:"",
            ctaButtonTextColor:"",
            ctaTextColor:"",
            ctaUnderlineColor:"",
            ctaZigzaglineColor:"",
            hoverColor:"",
            openLinkInNewTab: "",
          };
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {

            if (entry.fields.ctaType === 'Primary Button') {
              singleCtaItem["MoreCtaClass"]='btn btn-primary';
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              singleCtaItem["MoreCtaClass"]='btn btn-secondary';
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              singleCtaItem["MoreCtaClass"]='small-text-cta cta-black';
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              singleCtaItem["MoreCtaClass"]='small-text-cta cta-white';
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              singleCtaItem["MoreCtaClass"]='large-text-cta cta-black';
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              singleCtaItem["MoreCtaClass"]='large-text-cta cta-white';
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              singleCtaItem["MoreCtaClass"]='icon-text-cta';
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              singleCtaItem["MoreCtaClass"]='read-more-link';
            }
          }
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            singleCtaItem["MoreCtaTitle"]=entry.fields.title;
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            singleCtaItem["MoreCtaLink"]=entry.fields.alias;
          }
          if (entry.fields.ctaTextColor !== undefined && entry.fields.ctaTextColor !== "") {
             singleCtaItem["ctaTextColor"] = entry.fields.ctaTextColor;
          }
          if (entry.fields.ctaZigzaglineColor !== undefined && entry.ctaZigzaglineColor !== "") {
           singleCtaItem["ctaZigzaglineColor"] = entry.fields.ctaZigzaglineColor;
          }
          if (entry.fields.ctaUnderlineColor !== undefined && entry.fields.ctaUnderlineColor !== "") {
           singleCtaItem["ctaUnderlineColor"] = entry.fields.ctaUnderlineColor;
          }
          if (entry.fields.ctaButtonColor !== undefined && entry.fields.ctaButtonColor !== "") {
           singleCtaItem["ctaButtonColor"] = entry.fields.ctaButtonColor;
         }
          if (entry.fields.ctaButtonTextColor !== undefined && entry.fields.ctaButtonTextColor !== "") {
           singleCtaItem["ctaButtonTextColor"] = entry.fields.ctaButtonTextColor;
         }
         if (entry.fields.hoverColor !== undefined && entry.fields.hoverColor !== "") {
          singleCtaItem["hoverColor"] = entry.fields.hoverColor;
          }
          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            singleCtaItem["openLinkInNewTab"] = "_blank";
          }

          allCtaItems.push(singleCtaItem);
          allCtaItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
          
          await setMoreCta(moreCta=>moreCta.concat(allCtaItems));
          await setMoreCtaItemsLength(allCtaItems.length);
        }
        
      })
      .catch(console.error)
      })
    }

  },[]);

  let contentAlign = "";
  if (contentAlignment == "Left") {
    contentAlign = "content-align-left";
  }
  else if (contentAlignment == "Center") {
    contentAlign = "content-align-center";
  }

  return (
    <>
      <div className={contentAlign ? "full-bleed-banner bg1 "+ contentAlign : "full-bleed-banner bg1"} style={{backgroundColor: backgroundColor}}>
        {backgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div>: ""}
        {mobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+mobileBackgroundImage+")"}}></div>: ""}
          <style dangerouslySetInnerHTML=
          {{__html: `
             .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }       

            .full-bleed-banner.bg1 .desktop-bg,
            .full-bleed-banner.bg1 .mobile-bg {
              background-position: ${backgroundPosition};
            }

            `,
          }}
        />
        <div className="container">
        {title ? (kraftTexture==true ? <h1 style={{color: titleFontColor}}> <span dangerouslySetInnerHTML={{__html:title}}></span></h1>:<h1>{title}</h1>): ""}
          
          
          {description ? <div className="banner-descp" style={{background: descriptionBackground, color: descriptionFontColor}}>
          <div className="inner-wrapper">
            <ReactMarkdown allowDangerousHtml source={description}></ReactMarkdown>
          {moreCtaItemsLength > 0 ?
            <div className="cta-wrap mt-4">
              {
                 moreCta?moreCta.map((item:any)=>
                   <>
                    <style dangerouslySetInnerHTML=
                      {{__html: `
                        .large-text-cta.new.cta-white i,
                        .large-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};            
                        }            

                       .large-text-cta.new.cta-white:hover  i,
                       .large-text-cta.new.cta-white.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                        .large-text-cta.new.cta-white span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-white:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                         .large-text-cta.new.cta-black i,
                        .large-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .large-text-cta.new.cta-black:hover i,
                       .large-text-cta.new.cta-black.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                         .large-text-cta.new.cta-black span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-black:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-black i,
                        .small-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .small-text-cta.new.cta-black:hover i,
                        .small-text-cta.new.cta-black.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-black span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-black:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-white i,
                        .small-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};
                        }
                        .small-text-cta.new.cta-white:hover i,
                        .small-text-cta.new.cta-white.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-white span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-white:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .btn.btn-primary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-primary.${entryId}:focus,
                        .btn.btn-primary.${entryId}:focus:active,
                        .btn.btn-primary.${entryId}:visited{
                            background:${hoverColor};
                           color:${ctaButtonTextColor};
                           border-color: ${hoverColor};   
                        }            

                        .btn.btn-primary.${entryId} {
                            background:${ctaButtonColor};
                           color:${ctaButtonTextColor};
                           border-color: ${ctaButtonColor};
                        }            

                        .btn.btn-primary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color: ${item.hoverColor};
                        }            

                        .btn.btn-secondary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-secondary.${entryId}:focus,
                        .btn.btn-secondary.${entryId}:focus:active,
                        .btn.btn-secondary.${entryId}:visited{
                            background:${hoverColor};
                           color:${ctaButtonTextColor};
                           border-color: ${hoverColor};   
                        }            

                        .btn.btn-secondary.${entryId} {
                            background:${ctaButtonColor};
                           color:${ctaButtonTextColor};
                           border-color: ${ctaButtonColor};
                        }

                         .btn.btn-secondary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color: ${item.hoverColor};
                        }            

                        .read-more-link.${entryId} {
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        .read-more-link.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }            

                        .icon-text-cta.${entryId},a{
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        
                        .icon-text-cta.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }       
                        `,
                      }}
                    /> 
                     <a className={item.MoreCtaClass+" new"+" "+entryId} target={item.openLinkInNewTab} href={addLangKeyPath(item.MoreCtaLink)}>{item.MoreCtaTitle}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                        <span className="svg-outer">
                          <svg width="387" height="12" viewBox="0 0 387 12">
                            <defs>
                              <clipPath>
                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                              </clipPath>
                            </defs>
                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                            </g>
                          </svg>          
                        </span>
                        <span>           
                        </span>
                      </a>
                   </> ):""
              }
              {ctaTitle ? (
                <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)}>{ctaTitle}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                  <span className="svg-outer">
                    <svg width="387" height="12" viewBox="0 0 387 12">
                      <defs>
                        <clipPath>
                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                        </clipPath>
                      </defs>
                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                      </g>
                    </svg>          
                  </span>
                  <span>           
                  </span>
                </a>
                )
                : ""
              }
            </div>
          : ""
          }
          </div></div>: ""}
         
        </div>
      </div>
      {disclaimerText ? <div className="container"><div className="col-lg-10 offset-lg-1 pb-10"><small><ReactMarkdown allowDangerousHtml source={disclaimerText} disallowedTypes={['paragraph']} unwrapDisallowed /></small></div></div> : ""}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FullBleedBannerTwo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FullBleedBannerTwo };

export default connect(mapStateToProps, mapDispatchToProps)(FullBleedBannerTwo);
