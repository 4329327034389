import React, { useEffect, useState } from "react";
import ComponentComposer from "../../components/ComponentComposer";
import {fetchSitemapData} from "../../api/cms/api";
import Helmet from "react-helmet";
import client from "../../Client";
import { RootState } from "../../reducers";
import { connect } from "react-redux"
import {setAllAliases} from "../../actions/cms";

const SitemapPage: React.FC<any> = (props) => {

    useEffect(() => {
      async function getData()
      {
        let data:any= await fetchSitemapData();
      }
        getData();
    }, []);

    return (
        <div className="savers-page" id="maincontent">
        </div>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
  allAliases:state.site.allAliases,
});

SitemapPage.defaultProps = {
  fetchSiteData: () => {},
  header: {},
  allAliases:[]
};

export { SitemapPage };

export default connect(mapStateToProps, mapDispatchToProps)(SitemapPage);
