import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const SingleColSquareTestimonial: React.FC<any> = (componentData) => {

    const[title,setTitle]=useState(''); //testimonialData
    const[testimonialData,setTestimonialData]=useState([]);
    const[testimonialDataLength,setTestimonialDataLength]=useState(0);
     const[squareType,setSquareType]=useState('square1');

    useEffect(()=>
    {    

        if(componentData.title!="" && componentData.title!=undefined)
        {
            setTitle(componentData.title);
        }
        if(componentData.squareType!="" && componentData.squareType!=undefined)
        {
            setSquareType(componentData.squareType);
        }
        if(componentData.testimonialData!="" && componentData.testimonialData!=undefined)
        {
            let allTestimonialData:any=[];
            componentData.testimonialData.map(async(item:any,i:any)=>
            {
                if(item!="" && item!=undefined)
                {
                    let singleTestimonialData={
                        index: i,
                        author:"",
                        authorDesignation:"",
                        authorImage:"",
                        authorImageAltText:"",
                        description:""
                    }
                    if(item.fields!="" && item.fields!=undefined)
                    {
                        if(item.fields.author!="" && item.fields.author!=undefined)
                        {
                            singleTestimonialData['author']= item.fields.author;
                        }

                        if(item.fields.authorDesignation!="" && item.fields.authorDesignation!=undefined)
                        {
                             singleTestimonialData['authorDesignation']= item.fields.authorDesignation;
                        }

                        if(item.fields.authorImage!="" && item.fields.authorImage!=undefined)
                        {
                             singleTestimonialData['authorImage']= item.fields.authorImage.fields.file.url;
                             singleTestimonialData['authorImageAltText']= item.fields.authorImage.fields.title;
                        }
                         if(item.fields.description!="" && item.fields.description!=undefined)
                        {
                             singleTestimonialData['description']= item.fields.description;
                        }

                        allTestimonialData.push(singleTestimonialData);
                        allTestimonialData.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

                        await setTestimonialData(allTestimonialData);
                        await setTestimonialDataLength(allTestimonialData.length);

                    }
                }
            })
        }

    },[]);

    return (
    <>
        <div className="small-container text-center">
            {title?<div className="impact-head wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
              <h2 className="box-heading" dangerouslySetInnerHTML={{__html:title}}></h2>
            </div>:null}     

            <div className="right-impact-testimonials square mobile-negtive-space">
              <img className="orange-layer3 mobile-hide" src={require("../../images/orange-layer3.png")} alt="orange-layer3"></img>
              
            {testimonialData.map((item:any)=>
              <div className={"testimonilas-outer "+squareType}>
                {item.description?<p><span className="quote-left">“</span>{item.description}<span className="quote-right">”</span></p>:null}
                <p className="name">{item.author} <span dangerouslySetInnerHTML={{__html:item.authorDesignation}}></span></p>
                {item.authorImage?<img className="" src={item.authorImage} alt={item.authorImageAltText?item.authorImageAltText:""}/>:null}
              </div>)}

            </div>
        </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

SingleColSquareTestimonial.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { SingleColSquareTestimonial };

export default connect(mapStateToProps, mapDispatchToProps)(SingleColSquareTestimonial);
