import { combineReducers } from 'redux';

import site from './site';


const rootReducer = combineReducers({
  site
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
