import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const TwoColSquareTestimonial: React.FC<any> = (componentData) => {

    const[testimonialItems,setTestimonialItems]=useState([]);
    const[testimonialItemsLength,setTestimonialItemsLength]=useState([]);

    useEffect(()=>
    {
        if(componentData.colOneTestimonials!="" && componentData.colOneTestimonials!=undefined)
        {
            let allTestimonialItems:any=[];
          if(componentData.colOneTestimonials.length>0)
          {
              componentData.colOneTestimonials.map(async(item:any,i:any)=>
              {    
                  if(item!="" && item!=undefined)
                  {
                      let singleTestimonial={
                        index: i,
                        description:"",
                        author:"",
                        authorDesignation:""
                      }
                     if(item.fields!="" && item.fields!=undefined)
                     {
                        if(item.fields.description!="" && item.fields.description!=undefined)
                        {
                            singleTestimonial['description']= item.fields.description;
                        }  
                        if(item.fields.author!="" && item.fields.author!=undefined)
                        {
                             singleTestimonial['author']= item.fields.author;
                        }   
                        if(item.fields.authorDesignation!="" && item.fields.authorDesignation!=undefined)
                        {    
                             singleTestimonial['authorDesignation']= item.fields.authorDesignation;
                        }  
                        allTestimonialItems.push(singleTestimonial);
                        allTestimonialItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                        
                        await setTestimonialItems(allTestimonialItems);
                        await setTestimonialItemsLength(allTestimonialItems.length);
                     } 
                  }
              })
          }
        }
    },[])

    return (
    <>
        <div className="right-impact-testimonials square">
            <img className="orange-layer2" src={require("../../images/orange-layer2.png")} alt="layer2"></img>
            <div className="static-container">
            {
                testimonialItems.map((item:any,i:any)=>
                {
                    if(i==0)
                    {
                      return(
                        <div className="left-block">
                          <div className="right-impact-testimonials square wow bounceIn" data-wow-duration="1s" data-wow-delay="1s">
                            <div className="testimonilas-outer square3 ">
                              {item.description?<p><span className="quote-left">“</span>{item.description}<span className="quote-right">”</span></p>:null}
                              <p className="name">{item.author} <span dangerouslySetInnerHTML={{__html:item.authorDesignation}}></span></p>
                            </div>
                          </div>
                        </div>
                      )  
                    }
                    else{
                        return(
                           <div className="right-block">
                            <div className="right-impact-testimonials square  wow bounceIn" data-wow-duration="1s" data-wow-delay="1.5s">
                              <div className="testimonilas-outer square3">
                                {item.description?<p><span className="quote-left">“</span>{item.description}<span className="quote-right">”</span></p>:null}
                                <p className="name">{item.author} <span dangerouslySetInnerHTML={{__html:item.authorDesignation}}></span></p>
                              </div>
                            </div>
                          </div>
                        )
                    }
                })
            }
             
            </div>
        </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColSquareTestimonial.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColSquareTestimonial };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColSquareTestimonial);
