import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const ContactInfo: React.FC<any> = (props) => {
    return (
    <>
        <section className="contact-info-block">
           <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3>Contact Us</h3>
                    </div>
                    <div className="col-12">
                        <ul>
                            <li><h5>Customer Care</h5><a href="">e-mail@savers.com</a></li>
                            <li><h5>Press & Media Inquires</h5><a href="">e-mail@savers.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ContactInfo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ContactInfo };

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);