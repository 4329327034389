let domainName = "Savers.com";
let localeDomain = domainName;
let hostname = window.location.hostname;
const charlist = "/";
hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");
let siteEnv = "master";
let accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
let storeUrl = "https://stores.savers.com/";
let storeSiteName = "Savers";
let countryName = "US";
let langToggleOpts={en: "EN", es: "ES"};
let locationApi="https://api.savers.com/api/locations?addr=";
let defaultLanguage="EN";
let locationApiKey= "pY5ZcjhOBBcMZygwCJJJEdUZg4o0GzrcYOx4i8neAFnuL68Xnu";
let favicon = "favicon.ico";

/** production urls **/
if(hostname === "savers.com" || hostname === "www.savers.com") {
  storeUrl = "https://stores.savers.com/";
  siteEnv = "master";
  accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
}

else if (hostname === "savers.com.au" || hostname === "www.savers.com.au") {
  domainName = "Savers.com.au";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com.au/";
  storeSiteName = "SaversAU";
  countryName = "AU";
  langToggleOpts = {en: "EN"};
  locationApi= "https://api.savers.com.au/api/locations?addr=";
  siteEnv = "master";
  accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
  favicon="favicon-au.ico";
}

else if (hostname === "valuevillage.com"|| hostname === "www.valuevillage.com") {
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "US";
  siteEnv = "master";
  accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
  localeDomain = "ValueVillage.com";
}

else if (hostname === "valuevillage.ca"|| hostname === "www.valuevillage.ca") {
  domainName = "ValueVillage.ca";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
  siteEnv = "master";
  accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
}

else if (hostname === "villagedesvaleurs.ca" || hostname === "www.villagedesvaleurs.ca") {
  domainName = "VillagedesValeurs.ca";
  localeDomain = domainName;
  storeUrl = "https://magasinage.villagedesvaleurs.com/";
  storeSiteName = "VillagedesValeurs";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
  locationApi= "https://api.villagedesvaleurs.com/api/locations?addr=";
  siteEnv = "master";
  accessToken = "cg5Gzpg-zE5tphqGHwjKK6C5Gliwt2udl6EaB0DDEAQ";
  defaultLanguage="FR";
}

else if (hostname === "2ndavestores.com" || hostname === "www.2ndavestores.com") {
  domainName = "2ndavestores.com";
  localeDomain = domainName;
  storeSiteName = "2a";
  favicon = "favicon-2a.ico";
}

else if (hostname === "gogreendrop.com" || hostname === "www.gogreendrop.com") {
  domainName = "gogreendrop.com";
  localeDomain = domainName;
  storeSiteName = "GreenDrop";
  storeUrl = "https://locations.gogreendrop.com/";
  favicon = "favicon-gd.ico";
  langToggleOpts = {en: "EN", es: "ES"};
}

else if (hostname === "gogreendrop.ca" || hostname === "www.gogreendrop.ca") {
  domainName = "gogreendrop.ca";
  localeDomain = domainName;
  storeSiteName = "GreenDropCA";
  storeUrl = "https://locations.gogreendrop.com/";
  favicon = "favicon-gd.ico";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
}

/** metaltoad test urls **/
else if (hostname === "saverscomau-react-test.metaltoad-sites.com"|| hostname === "www.saverscomau-react-test.metaltoad-sites.com") {
  domainName = "saverscomau-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com.au/";
  storeSiteName = "SaversAU";
  countryName = "AU";
  langToggleOpts = {en: "EN"};
  locationApi= "https://api.savers.com.au/api/locations?addr=";
  favicon="favicon-au.ico";
}

else if (hostname === "valuevillageca-react-test.metaltoad-sites.com" || hostname === "www.valuevillageca-react-test.metaltoad-sites.com") {
  domainName = "valuevillageca-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
}

else if (hostname === "saverscom-react-test.metaltoad-sites.com" || hostname === "www.saverscom-react-test.metaltoad-sites.com") {
  domainName = "saverscom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
}

else if (hostname === "2ndavestorescom-react-test.metaltoad-sites.com" || hostname === "www.2ndavestorescom-react-test.metaltoad-sites.com") {
  domainName = "2ndavestorescom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeSiteName = "2a";
  favicon = "favicon-2a.ico";
}

else if (hostname === "valuevillagecom-react-test.metaltoad-sites.com" || hostname === "www.valuevillagecom-react-test.metaltoad-sites.com") {
  domainName = "saverscom-react-test.metaltoad-sites.com";
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "US";
  localeDomain = "valuevillagecom-react-test.metaltoad-sites.com";
}

else if (hostname === "villagedesvaleurscom-react-test.metaltoad-sites.com" || hostname === "www.villagedesvaleurscom-react-test.metaltoad-sites.com") {
  domainName = "villagedesvaleurscom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://magasinage.villagedesvaleurs.com/";
  storeSiteName = "VillagedesValeurs";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
  locationApi= "https://api.villagedesvaleurs.com/api/locations?addr=";
  defaultLanguage="FR";
}

else if (hostname === "gogreendropcom-react-test.metaltoad-sites.com" || hostname === "www.gogreendropcom-react-test.metaltoad-sites.com") {
  domainName = "gogreendropcom-react-test.metaltoad-sites.com";
  storeUrl = "https://locations.gogreendrop.com/";
  storeSiteName = "GreenDrop";
  countryName = "US";
  localeDomain = "gogreendropcom-react-test.metaltoad-sites.com";
  favicon = "favicon-gd.ico";
  langToggleOpts = {en: "EN", es: "ES"};
}

else if (hostname === "gogreendropca-react-test.metaltoad-sites.com" || hostname === "www.gogreendropca-react-test.metaltoad-sites.com") {
  domainName = "gogreendropca-react-test.metaltoad-sites.com";
  storeUrl = "https://locations.gogreendrop.com/";
  storeSiteName = "GreenDropCA";
  countryName = "CA";
  localeDomain = "gogreendropca-react-test.metaltoad-sites.com";
  favicon = "favicon-gd.ico";
  langToggleOpts = {en: "EN", fr: "FR"};
}

/** metaltoad dev urls **/
else if (hostname === "saverscomau-react-dev.metaltoad-sites.com"|| hostname === "www.saverscomau-react-dev.metaltoad-sites.com") {
  domainName = "saverscomau-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com.au/";
  storeSiteName = "SaversAU";
  countryName = "AU";
  langToggleOpts = {en: "EN"};
  locationApi= "https://api.savers.com.au/api/locations?addr=";
  favicon="favicon-au.ico";
}

else if (hostname === "valuevillageca-react-dev.metaltoad-sites.com" ||hostname === "www.valuevillageca-react-dev.metaltoad-sites.com") {
  domainName = "valuevillageca-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
}
                                                      /** added localhost here to call dev api, remove to call prod endpoint locally - DAVID DOLAN **/
else if (hostname === "saverscom-react-dev.metaltoad-sites.com" || hostname === "www.saverscom-react-dev.metaltoad-sites.com" || hostname === "localhost") {
  domainName = "saverscom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
}

else if (hostname === "2ndavestorescom-react-dev.metaltoad-sites.com" || hostname === "www.2ndavestorescom-react-dev.metaltoad-sites.com") {
  domainName = "2ndavestorescom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeSiteName = "2a";
  favicon = "favicon-2a.ico";
}

else if (hostname === "valuevillagecom-react-dev.metaltoad-sites.com"|| hostname === "www.valuevillagecom-react-dev.metaltoad-sites.com") {
  domainName = "saverscom-react-test.metaltoad-sites.com";
  storeUrl = "https://stores.savers.com/";
  storeSiteName = "ValueVillage";
  countryName = "US";
  localeDomain = "valuevillagecom-react-test.metaltoad-sites.com";
}

else if (hostname === "villagedesvaleurscom-react-dev.metaltoad-sites.com" || hostname === "www.villagedesvaleurscom-react-dev.metaltoad-sites.com") {
  domainName = "villagedesvaleurscom-react-test.metaltoad-sites.com";
  localeDomain = domainName;
  storeUrl = "https://magasinage.villagedesvaleurs.com/";
  storeSiteName = "VillagedesValeurs";
  countryName = "CA";
  langToggleOpts = {en: "EN", fr: "FR"};
  locationApi= "https://api.villagedesvaleurs.com/api/locations?addr=";
  defaultLanguage="FR";
}

else if (hostname === "gogreendropcom-react-dev.metaltoad-sites.com" || hostname === "www.gogreendropcom-react-dev.metaltoad-sites.com") {
  domainName = "gogreendropcom-react-test.metaltoad-sites.com";
  storeUrl = "https://locations.gogreendrop.com/";
  storeSiteName = "GreenDrop";
  countryName = "US";
  localeDomain = "gogreendropcom-react-dev.metaltoad-sites.com";
  favicon = "favicon-gd.ico";
  langToggleOpts = {en: "EN", es: "ES"};
}

else if (hostname === "gogreendropca-react-dev.metaltoad-sites.com" || hostname === "www.gogreendropca-react-dev.metaltoad-sites.com") {
  domainName = "gogreendropca-react-test.metaltoad-sites.com";
  storeUrl = "https://locations.gogreendrop.com/";
  storeSiteName = "GreenDropCA";
  countryName = "CA";
  localeDomain = "gogreendropca-react-dev.metaltoad-sites.com";
  favicon = "favicon-gd.ico";
  langToggleOpts = {en: "EN", fr: "FR"};
}



module.exports = global.config = {
  domain: domainName,
  localeDomain: localeDomain,
  storeUrl: storeUrl,
  storeSite: storeSiteName,
  countryCode: countryName,
  langToggleOpts: langToggleOpts,
  locationApi:locationApi,
  siteEnv: siteEnv,
  accessToken: accessToken,
  defaultLanguage:defaultLanguage,
  locationApiKey:locationApiKey,
  favicon:favicon
};