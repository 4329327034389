import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const AboutStory: React.FC<any> = (props) => {
  const options = {
    autoplay: true,
    lazyLoad: true,
    rewind: true,
    margin: 20,
    loop: true,
    responsiveClass: true,
    autoplayTimeout: 7000,
    smartSpeed: 800,
    nav: true,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      }, 767: {
        items: 1,
        margin: 20,
      },
    }
  };
    return (
    <>
     <section className="about-story">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Our Story</h2>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                  <div className="next-pre-slider">
                      <div className="item">
                          <img src="http://45.40.138.118/savers/dev/images/slide1.jpg" />
                      </div>
                      <div className="item">
                          <img src="http://45.40.138.118/savers/dev/images/slide2.jpg" />
                      </div>
                      <div className="item">
                          <img src="http://45.40.138.118/savers/dev/images/slide3.jpg" />
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div className="container">    

            <div className="row text-left text-block ptb-80 light">
                <div className="col-md-6">
                    <div className="text-info-left">
                        <h4>Back in 1954, our first thrift shop called an old movie theater in San Francisco’s Mission District home. From day one, we gave shoppers what they were looking for: stylish finds at unbelievable savings.</h4>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="text-info-right">
                        <p>Since then, we’ve opened stores across Canada, the U.S., and Australia and have consistently partnered with local nonprofit organizations across these communities to help support their important programs. In fact, our very first store supported the Woodrow Wilson Chapter #15 of the Military Order of the Purple Heart.  We’ve chosen to keep our business model this way — to buy our supply from local nonprofits. But we didn’t stop there. We’ve also created a leading recycling and reuse program, where we keep, on average, 700 million pounds of reusable items out of area landfills a year.</p>
                        <a href="" className="large-text-cta cta-white">Read our Community Impact Report <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

AboutStory.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { AboutStory };

export default connect(mapStateToProps, mapDispatchToProps)(AboutStory);