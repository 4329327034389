import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const ContactBanner: React.FC<any> = (props) => {
    return (
        <>
<section className="responsive-tabs">
    <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <h6>{props.title}</h6>
            </div>          
            <div className="col-12">            
                <ul id="tabs" className="nav nav-tabs main-tabs" role="tablist">                     
                    <li className="nav-item">
                        <a id="tab-1" href="#pane-1" className="nav-link" data-toggle="tab" role="tab">
                            <img src={props.tabList[0].image} /> <span>{props.tabList[0].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-2" href="#pane-2" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[1].image} />  <span>{props.tabList[1].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-3" href="#pane-3" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[2].image} />  <span>{props.tabList[2].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-4" href="#pane-4" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[3].image} />  <span>{props.tabList[3].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-5" href="#pane-5" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[4].image} />  <span>{props.tabList[4].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-6" href="#pane-6" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[5].image} /> <span> {props.tabList[5].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-7" href="#pane-7" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[6].image} />  <span>{props.tabList[6].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-8" href="#pane-8" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[7].image} /> <span> {props.tabList[7].name}</span></a>
                    </li>
                    <li className="nav-item">
                        <a id="tab-9" href="#pane-9" className="nav-link" data-toggle="tab" role="tab"><img src={props.tabList[8].image} /> <span>{props.tabList[8].name}</span></a>
                    </li>
                </ul>


                <div id="content" className="tab-content" role="tablist">
                    <div id="pane-1" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-1">
                        <div className="card-header" role="tab" id="heading-1">
                            <h5 className="mb-0">                                
                                <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                <img src={props.tabList[0].image} /> {props.tabList[0].name}
                                </a>
                            </h5>
                        </div>

                     
                        <div id="collapse-1" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-1">
                            <div className="card-body"> 
                                <div className="vertical-tabs">
                                    <ul id="tabs1" className="nav nav-tabs sub-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a id="tab-11" href="#pane-11" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[0].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-12" href="#pane-12" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[1].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-13" href="#pane-13" className="nav-link" data-toggle="tab" role="tab"> {props.tabList[0].sub_list[2].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-14" href="#pane-14" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[3].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-15" href="#pane-15" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[4].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-16" href="#pane-16" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[5].item}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="tab-17" href="#pane-17" className="nav-link" data-toggle="tab" role="tab">{props.tabList[0].sub_list[6].item}</a>
                                        </li>
                                         
                                        
                                    </ul>


                                    <div id="content1" className="tab-content sub-content-block" role="tablist">
                                        <div id="pane-11" className="card tab-pane fade active show" role="tabpanel" aria-labelledby="tab-11">
                                            <div className="card-header" role="tab" id="heading-11">
                                                <h5 className="mb-0">
                                                    <a data-toggle="collapse" href="#collapse-11" aria-expanded="true" aria-controls="collapse-11">
                                                    {props.tabList[0].sub_list[0].item}
                                                    </a>
                                                </h5>
                                            </div>

                                            <div id="collapse-11" className="collapse active show" data-parent="#content" role="tabpanel" aria-labelledby="heading-11">
                                                <div className="card-body">
                                                    <h4>{props.tabList[0].name}</h4>
                                                    <h2>{props.tabList[0].sub_list[0].item_detail[0].title}</h2>
                                                    <ul>
                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[0].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[1].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[2].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[3].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[4].item}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-12" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-12">
                                            <div className="card-header" role="tab" id="heading-12">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                                                    {props.tabList[0].sub_list[1].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-12" className="collapse" data-parent="#content1" role="tabpanel" aria-labelledby="heading-12">
                                                <div className="card-body">
                                                <h3>{props.tabList[0].name}</h3>
                                                    <h2>{props.tabList[0].sub_list[0].item_detail[0].title}</h2>
                                                    <ul>
                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[0].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[1].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[2].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[3].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[4].item}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-13" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-13">
                                            <div className="card-header" role="tab" id="heading-13">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-13" aria-expanded="false" aria-controls="collapse-13">
                                                        {props.tabList[0].sub_list[2].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-13" className="collapse" role="tabpanel" data-parent="#content1" aria-labelledby="heading-13">
                                                <div className="card-body">
                                                <h3>{props.tabList[0].name}</h3>
                                                    <h2>{props.tabList[0].sub_list[0].item_detail[0].title}</h2>
                                                    <ul>
                                                       <li>{props.tabList[0].sub_list[0].item_detail[0].list[0].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[1].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[2].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[3].item}</li>

                                                        <li>{props.tabList[0].sub_list[0].item_detail[0].list[4].item}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-14" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-14">
                                            <div className="card-header" role="tab" id="heading-14">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-14" aria-expanded="false" aria-controls="collapse-14">
                                                    {props.tabList[0].sub_list[3].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-14" className="collapse" role="tabpanel" data-parent="#content1" aria-labelledby="heading-14">
                                                <div className="card-body">
                                                    <p>{props.tabList[0].sub_list[0].item_detail[0].list[5].item}</p>
                                                    <p>{props.tabList[0].sub_list[0].item_detail[0].list[5].item}</p>
                                                    <p>{props.tabList[0].sub_list[0].item_detail[0].list[5].item}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-15" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-15">
                                            <div className="card-header" role="tab" id="heading-15">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-15" aria-expanded="false" aria-controls="collapse-15">
                                                    {props.tabList[0].sub_list[4].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-15" className="collapse" role="tabpanel" data-parent="#content1" aria-labelledby="heading-15">
                                                <div className="card-body">
                                                    {props.tabList[0].sub_list[0].item_detail[0].list[6].item}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-16" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-16">
                                            <div className="card-header" role="tab" id="heading-16">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-16" aria-expanded="false" aria-controls="collapse-16">
                                                    {props.tabList[0].sub_list[5].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-16" className="collapse" role="tabpanel" data-parent="#content1" aria-labelledby="heading-16">
                                                <div className="card-body">
                                                    {props.tabList[0].sub_list[0].item_detail[0].list[7].item}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="pane-17" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-17">
                                            <div className="card-header" role="tab" id="heading-17">
                                                <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href="#collapse-17" aria-expanded="false" aria-controls="collapse-17">
                                                    {props.tabList[0].sub_list[6].item}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-17" className="collapse" role="tabpanel" data-parent="#content1" aria-labelledby="heading-17">
                                                <div className="card-body">
                                                    {props.tabList[0].sub_list[0].item_detail[0].list[8].item}
                                                </div>
                                            </div>
                                        </div>

                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="pane-2" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-2">
                        <div className="card-header" role="tab" id="heading-2">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                <img src={props.tabList[1].image} /> {props.tabList[1].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-2" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-2">
                            <div className="card-body">
                                 {props.tabList[1].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-3" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-3">
                        <div className="card-header" role="tab" id="heading-3">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                <img src={props.tabList[2].image} /> {props.tabList[2].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-3" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-3">
                            <div className="card-body">
                                {props.tabList[2].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-4" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-4">
                        <div className="card-header" role="tab" id="heading-4">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                <img src={props.tabList[3].image} /> {props.tabList[3].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-4" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-4">
                            <div className="card-body">
                                {props.tabList[3].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-5" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-5">
                        <div className="card-header" role="tab" id="heading-5">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                <img src={props.tabList[4].image} /> {props.tabList[4].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-5" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-5">
                            <div className="card-body">
                                {props.tabList[4].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-6" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-6">
                        <div className="card-header" role="tab" id="heading-6">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                <img src={props.tabList[5].image} />{props.tabList[5].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-6" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-6">
                            <div className="card-body">
                                {props.tabList[5].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-7" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-7">
                        <div className="card-header" role="tab" id="heading-7">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                <img src={props.tabList[6].image} /> {props.tabList[6].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-7" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-7">
                            <div className="card-body">
                                {props.tabList[6].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-8" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-8">
                        <div className="card-header" role="tab" id="heading-8">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                                <img src={props.tabList[7].image} /> {props.tabList[7].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-8" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-8">
                            <div className="card-body">
                                {props.tabList[7].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                    <div id="pane-9" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-9">
                        <div className="card-header" role="tab" id="heading-9">
                            <h5 className="mb-0">
                                <a className="collapsed" data-toggle="collapse" href="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                                <img src={props.tabList[8].image} />{props.tabList[0].name}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-9" className="collapse" role="tabpanel" data-parent="#content" aria-labelledby="heading-9">
                            <div className="card-body">
                               {props.tabList[8].sub_list[0].item_detail[0].list[0].item}
                            </div>
                        </div>
                    </div>

                 
                
                </div>
            </div>

            
        </div>
    </div>
</section>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ContactBanner.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ContactBanner };

export default connect(mapStateToProps, mapDispatchToProps)(ContactBanner);
