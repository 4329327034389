import React, { useEffect,useState, useCallback} from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from "../../Client";
import { getLocale } from "../../api/cms/api";
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getRichTextEntityLinks } from '@contentful/rich-text-links';
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const TeamGrid: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [allLeaders, setAllLeaders] = useState([]);
  const [allLeadersLength, setAllLeadersLength] = useState(0);
  const [currentImageIndex,setCurrentImageIndex]=useState(0);
  const [backgroundColor,setBackgroundColor]=useState('');
  // const [facbookLink,setFacbookLink] = useState('');
  // const [twitterLink,setTwitterLink] = useState('');
  // const [instagramLink,setInstagramLink] = useState('');
  // const [linkedInLink,setLinkedInLink] = useState('');
  // const [pinterestLink,setPinterestLink] = useState('');


  useEffect(() => {
      if (componentData.title != "" && componentData.title != undefined) {
        setTitle(componentData.title);
      }

      if (componentData.backgroundColor != "" && componentData.backgroundColor != undefined) {
        setBackgroundColor(componentData.backgroundColor);
      }

      if (componentData.teamGridItem != "" && componentData.teamGridItem != undefined) {
          let allTeamLeaders: any = [];
          componentData.teamGridItem.map(async(item:any,i:any)=>
          {
             let singleTeamLeader = {
              index: i,
              label: "",
              subLabel: "",
              description: "",
              image:"",
              coloredImage:"",
              imageAltText:"",
              coloredImageAltText:"",
              facbookLink: "",
              twitterLink: "",
              instagramLink: "",
              linkedInLink: "",
              pinterestLink: "",
            };

             await client
            .getEntry(item.sys.id, {locale: currLocale})
            .then(async(entry: any) => {
              if (entry != "" && entry != undefined) {
                
                  if (entry.fields.label != "" && entry.fields.label != undefined) {
                    singleTeamLeader["label"] = entry.fields.label;
                  }

                  if (entry.fields.subLabel != "" && entry.fields.subLabel != undefined) {
                    singleTeamLeader["subLabel"] = entry.fields.subLabel;
                  }

                  if (entry.fields.itemDescription != "" && entry.fields.itemDescription != undefined) {
                    const options = {
                      renderNode: {
                        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                          return node.data.target.fields.html;
                        },
                        [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                        [INLINES.ASSET_HYPERLINK]:(node: any) => `<a href="${node.data.target.fields.file.url}" target="_blank">${node.data.target.fields.title}</a> `
                      }
                    }
                    let rawRichTextDescription = documentToHtmlString(entry.fields.itemDescription, options);
                    singleTeamLeader["description"] = rawRichTextDescription;
                  }

                  if (entry.fields.image != "" && entry.fields.image != undefined) {
                      singleTeamLeader["image"] = entry.fields.image.fields.file.url;
                      singleTeamLeader["imageAltText"]=entry.fields.image.fields.title;
                  }
                  if (entry.fields.coloredImage != "" && entry.fields.coloredImage != undefined) {
                      singleTeamLeader["coloredImage"] = entry.fields.coloredImage.fields.file.url;
                      singleTeamLeader["coloredImageAltText"]= entry.fields.coloredImage.fields.title;
                  }

                  if (entry.fields.facbookLink != "" && entry.fields.facbookLink != undefined) {
                    singleTeamLeader["facbookLink"] = entry.fields.facbookLink;
                  }

                  if (entry.fields.twitterLink != "" && entry.fields.twitterLink != undefined) {
                    singleTeamLeader["twitterLink"] = entry.fields.twitterLink;
                  }

                  if (entry.fields.instagramLink != "" && entry.fields.instagramLink != undefined) {
                    singleTeamLeader["instagramLink"] = entry.fields.instagramLink;
                  }

                  if (entry.fields.linkedInLink != "" && entry.fields.linkedInLink != undefined) {
                    singleTeamLeader["linkedInLink"] = entry.fields.linkedInLink;
                  }

                  if (entry.fields.pinterestLink != "" && entry.fields.pinterestLink != undefined) {
                    singleTeamLeader["pinterestLink"] = entry.fields.pinterestLink;
                  }

                  await allTeamLeaders.push(singleTeamLeader);
                  allTeamLeaders.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                  
                  await setAllLeaders(allTeamLeaders);
                  await setAllLeadersLength(allTeamLeaders.length);
                  

              }

             });

          })
      }

  },[]);

  const onMouseEnterHandler=async(event:any,color_image:any)=>
  {
    event.target.src=color_image;
  }

  const onMouseLeaveHandler=async(event:any,image:any)=>
  {
    event.target.src=image;
  }

  jQuery(".team-modal").on("hidden.bs.modal", function() {
    jQuery("*").removeAttr("inert");
  })

  const openModal = (index:any) => {
    /** "inert" attribute works for every browser except legacy */
    jQuery("*").attr("inert", "")
    
    jQuery("html").removeAttr("inert");
    jQuery("body").removeAttr("inert");
    jQuery("header").removeAttr("inert");
    jQuery("script").removeAttr("inert");
    jQuery("style").removeAttr("inert");
    jQuery("div").removeAttr("inert");
    jQuery("#root").removeAttr("inert");
    jQuery("#maincontent").removeAttr("inert");
    jQuery(".teamModalId").find("*").removeAttr("inert"); 
    setCurrentImageIndex(index);
  }

  const closeModal = () => {
    jQuery("*").removeAttr("inert");
  }


  return (
  <>
  <section className="all-leaders team-block mt-5 mb-4" style={{backgroundColor: backgroundColor}}>
      <div className="container">
          <div className="row">
              <div className="col-12">
                <div className="carousel-heading-row">
                  {title?<h4 className="mb-4 text-center">{title}</h4>:null}
                  </div>
                  <ul className="team-listing">
                  {allLeaders?allLeaders.map((data:any,index)=>
                      <a className="team-member" data-toggle="modal" data-target=".teamModalId" onClick={()=>openModal(index)} href="#teamModal" key={index}>
                          {data.image?<figure className="team-media"><img src={data.image} width='281.25' height='281.25' alt={data.imageAltText?data.imageAltText:""} onMouseEnter={(e)=>onMouseEnterHandler(e,data.coloredImage)} onMouseLeave={(e)=>onMouseLeaveHandler(e,data.image)}/></figure>:null}
                          {data.label?<h6 className="name" dangerouslySetInnerHTML={{__html: data.label}} />:null}
                          {data.subLabel?<p dangerouslySetInnerHTML={{__html: data.subLabel}} />:null}
                      </a>):null
                  }
                  </ul>
              </div>
          </div>
      </div>
  </section>

      <div className="modal fade team-modal teamModalId" role="dialog" aria-label="team Modal">
            <div className="modal-dialog teamModalId" role="document" >
              <div className="modal-content teamModalId">               
                <div className="modal-body teamModalId">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close Team Modal" onClick={()=>closeModal()} ></button>
                    {allLeaders ? allLeaders.map(
                      (item : any, key) => (
                        <>
                          {key === currentImageIndex ?
                            <>
                              <div className="model-left teamModalId" key={key}>      
                                  <div className="img-outer teamModalId">
                                     {item.coloredImage?<img src={item.coloredImage} width='272' height='272' alt={item.coloredImageAltText?item.coloredImageAltText:""}/>:null}
                                  </div>
                                  {item.label?<h5>{item.label}</h5>:null}
                                  {item.subLabel?<p>{item.subLabel}</p>:null}
                              </div>
                              <div className="model-right teamModalId">
                                  {item.description?<ReactMarkdown allowDangerousHtml source={item.description}></ReactMarkdown> :null}
                                  <div className="profile-links teamModalId">
                                    {item.facbookLink ? <a href={addLangKeyPath(item.facbookLink)} aria-label="facebook-link" className="teamModalId"><i className="fa fa-facebook" aria-hidden="true"></i></a> : ""}
                                    {item.twitterLink ? <a href={addLangKeyPath(item.twitterLink)} aria-label="twitter-link" className="teamModalId"><i className="fa fa-twitter" aria-hidden="true"></i></a> : ""}
                                    {item.instagramLink ? <a href={addLangKeyPath(item.instagramLink)} aria-label="instagram-link" className="teamModalId"><i className="fa fa-instagram" aria-hidden="true"></i></a> : ""}
                                    {item.linkedInLink ? <a href={addLangKeyPath(item.linkedInLink)} aria-label="linkedin-link" className="teamModalId"><i className="fa fa-linkedin" aria-hidden="true"></i></a> : ""}
                                    {item.pinterestLink ? <a href={addLangKeyPath(item.pinterestLink)} aria-label="pinterest-link" className="teamModalId"><i className="fa fa-pinterest" aria-hidden="true"></i></a> : ""}
                              </div>
                            </div>
                            </>
                            : ""
                          }
                        </>
                      )
                    ) : ""}

                      
                 
                </div>
                
              </div>
            </div>
          </div>
  </>

  );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TeamGrid.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TeamGrid };

export default connect(mapStateToProps, mapDispatchToProps)(TeamGrid);
