export default () => ({
  id: "thrift_proud",
  page: {
    title: "Thrift Proud",
    redirect_path: "",
    metatag_image:
      "https://dev-wheel-of-fortune.pantheonsite.io/sites/default/files/2020-09/wof-share-image.jpeg",
    metatag_description: "Meta content goes here",
    status: "ongoing",
  },
  components: [
    {
      componentName: "header",
    },
    {
      componentName: "storeLocationBlock",
      data: {
        logo: "http://45.40.138.118/savers/dev/images/logo.svg",
        direction_link_title: "Get Directions",
        call_store_link_title: "Call Store",
        breadcrumbs_data: [
          { list: "Store Locator" },
          { list: "Region" },
          { list: "Store Name" },
        ],
        location_data: {
          title: "Location name",
          address: "123 Address line, City State, zipcode",
          contact: "(123)-456-7890",
          timing_list: [
            { time: "M-F 11am - 7pm" },
            { time: "S 11am - 7pm" },
            { time: "Su 11am - 7pm" },
          ],
        },
        parking_data: [
          { list: "Parking" },
          { list: "Service pets" },
          { list: "Awarded best thrift" },
        ],
        payment_data: {
          title: "Forms of payment accepted",
          card_images: [
            { image: "http://45.40.138.118/savers/dev/images/discover.png" },
            { image: "http://45.40.138.118/savers/dev/images/master.png" },
            { image: "http://45.40.138.118/savers/dev/images/visa.png" },
          ],
        },
        slider_data: [
          { image: "http://45.40.138.118/savers/dev/images/slider-media" },
        ],
      },
    },
    {
      componentName: "facilitiesBlock",
      data: {
        title: "Nonprofit we support",
        logo: "http://45.40.138.118/savers/dev/images/north.png",
        heading:
          "Donations here of used clothing and household item at this location benefit NPP",
        paragraph:
          "Savers/VV is a for profit commercial fundraiser, accepting donations of secondhand clothing and household goods on behalf of Northwest Center of Seattle, WA. The required registration is on file with the Secretary of State’s charity program. Additional financial disclosure information is available by calling the Secretary of State at 1-800-332-4483.",
        facility_title: "What we accept",
        facility: [
          {
            image: "http://45.40.138.118/savers/dev/images/offer-icon1.png",
            title: "Small appliances",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/back.png",
            title: "Backpacks & purses",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/bed.png",
            title: "Bed & bath",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/books.png",
            title: "Books & Media",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/cloth.png",
            title: "Clothing & shoes",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/camera.png",
            title: "Electronics",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/exercise.png",
            title: "Exercise & sporting goods",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/games.png",
            title: "Games & toys",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/house-wares.png",
            title: "Housewares",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/small.png",
            title: "Small furniture",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/large-f.png",
            title: "Large furniture",
          },
          {
            title: "Learn",
          },
        ],
      },
    },
    {
      componentName: "commonBoxesWrap",
      data: {
        title: "In-store offers",
        heading: "What we accept",
        cards_data: [
          {
            title: "Start a donation drive in your community: FUNDrive®",
            paragraph:
              "It’s a fast, easy, and eco-friendly way to raise money for your nonprofit.",
            anchor_text: "Get Started",
            image: "http://45.40.138.118/savers/dev/images/fun-logo.png",
          },
          {
            image: "http://45.40.138.118/savers/dev/images/join-club.png",
          },
          {
            title: "Learn more about Rethink Reuse®",
            paragraph:
              "Experience The Ultimate Treasure Hunt!® You’ve never seen a thrift store like ours. Take a look at what you can expect to find when you Rethink Reuse®!",
            anchor_text: "Learn more",
            image: "http://45.40.138.118/savers/dev/images/reuse.png",
          },
        ],
      },
    },
    {
      componentName: "storeNearBy",
      data: {
        logo: "http://45.40.138.118/savers/dev/images/logo.svg",
        donation_text: "Donations at this location benefit",
        donation_anchor_text: "[NPP name with link]",
        direction_link_title: "Get Directions",
        view_link_title: "View Details",
        places: [
          {
            id: 1,
            Location: "Savers newZealand",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
          {
            id: 2,
            Location: "Savers England",
            Distance: "13.2mi",
            Address: "125 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
        ],
      },
    },
    {
      componentName: "twoColumnCard",
      data: {
        page: "storeLocatorDetail",
        two_column_card: [
          {
            title: "Need a tax",
            sub_title: "recipt?",
            anchor_text: "Get Started",
            image: "http://45.40.138.118/savers/dev/images/top-right-plus.png",
          },

          {
            title: "Start a donation drive: FUNDrive",
            sub_title:
              "This is your go-to place for style inspiration, what’s going on in our world, and in our communities.",
            anchor_text: "Get Started",
            image: "http://45.40.138.118/savers/dev/images/fun-logo.png",
          },
        ],
      },
    },
    {
      componentName: "heroCardLarge",
      data: {
        page: "locatorView",
        title: "Join the",
        sub_title: "Super Savers Club",
        heading: "Rewards and savings to your inbox.",
        paragraph:
          "Earn points and rewards every time you thrift, enjoy exclusive email offers, and other special perks as a member",
        anchor_text: "join now",
        images: [
          {
            card_media:
              "http://45.40.138.118/savers/dev/images/card-media2.png",
          },
        ],
      },
    },
    {
      componentName: "heroCardMediumTwo",
      data: {
        page: "locatorView",
        title: "Work that matters.",
        sub_title: "Join our team!",
        paragraph: "Learn, grow and be rewarded.",
        anchor_text: "explore opportunities now",
        media_image: "http://45.40.138.118/savers/dev/images/media10.png",
      },
    },
  ],
});
