import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import ReactMarkdown from 'react-markdown';

const FullBleedBannerFour: React.FC<any> = (componentData) => {

    const [title, setTitle] = useState('');
    const [titleFontColor, setTitleFontColor] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundImageAltText, setBackgroundImageAltText] = useState('');
    const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
    const [mobileBackgroundImageAltText, setMobileBackgroundImageAltText] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionFontColor, setDescriptionFontColor] = useState('');
    const [titleIcon, setTitleIcon] = useState('');
    const [iconAltText, setIconAltText] = useState('');
    const [kraftTexture, setKraftTexture] = useState(true);

    useEffect(() => {

    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    if (componentData.titleFontColor != '' && componentData.titleFontColor != undefined) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.backgroundImage != '' && componentData.backgroundImage != undefined) {
      let fileUrl = componentData.backgroundImage.fields.file.url;
      let altText= componentData.backgroundImage.fields.title!==undefined?componentData.backgroundImage.fields.title:"";
      setBackgroundImage(fileUrl);
      setBackgroundImageAltText(altText);
    }

    if (componentData.mobileBackgroundImage != '' && componentData.mobileBackgroundImage != undefined) {
      let fileUrl = componentData.mobileBackgroundImage.fields.file.url;
      let altText= componentData.mobileBackgroundImage.fields.title!==undefined?componentData.mobileBackgroundImage.fields.title:"";
      setMobileBackgroundImage(fileUrl);
      setBackgroundImageAltText(altText);
    }

    if (componentData.titleIcon != '' && componentData.titleIcon != undefined) {
      let fileUrl = componentData.titleIcon.fields.file.url;
      setIconAltText(componentData.titleIcon.fields.title)
      setTitleIcon(fileUrl);
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription(componentData.description);
    }

    if (componentData.descriptionFontColor != '' && componentData.descriptionFontColor != undefined) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.kraftTexture != '' && componentData.kraftTexture != undefined) {
      setKraftTexture(componentData.kraftTexture);
    }

    },[]);

    return (
          <div className="impact-main-wrapper">
            <div className="full-bleed-banner4 text-center banner-solid-bg">
              {backgroundImage?<img className="desktop-bg" src={backgroundImage} alt={backgroundImageAltText} />:null}
              {mobileBackgroundImage?<img className="mobile-bg" src={mobileBackgroundImage} alt={mobileBackgroundImageAltText}/>:null}
              <div className="container">
                <div className="banner-info">                               
                 {titleIcon? <div className="small-banner-logo">
                    <img className="" width = '100' height   = '82' src={titleIcon} alt={iconAltText?iconAltText:""}/>      
                  </div> :null} 
                  {title?<h1 className="big clr-white" style={{color:titleFontColor}}><samp dangerouslySetInnerHTML={{__html:title}}></samp></h1>:null}    
                  {description?<h1 style={{color:descriptionFontColor}}><span><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></span></h1>:null}
                </div>
              </div>
            </div>
          </div>
);
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FullBleedBannerFour.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FullBleedBannerFour };

export default connect(mapStateToProps, mapDispatchToProps)(FullBleedBannerFour);