
import {
  SITE_TYPE,
  AppThunk,
} from './actionTypes';
import cmsApi from '../api/cms';
import { SiteActionType } from '../types';

export default function loadSiteItem(
    type: SiteActionType,
    fetchFn: () => Promise<any>,
): AppThunk {
  return async function(dispatch) {
    dispatch({ type: type.IS_LOADING, payload: true });
    dispatch({ type: type.SET_ERROR, payload: false });
    try {
      const data = await fetchFn();
      dispatch({
        type: type.SET_DATA,
        payload: data,
      });
    } catch (e) {
      dispatch({ type: type.SET_ERROR, payload: true });
    } finally {
      dispatch({ type: type.IS_LOADING, payload: false });
    }
  };
}


export function loadBingoTiles(): AppThunk {
  return async function(dispatch) {
  };
}

export function loadPageData(): AppThunk {

  return async function(dispatch) {

    dispatch(loadSiteItem(SITE_TYPE, cmsApi.fetchSiteData));
  };
}


export function setAllAliases(value:any)
{
  return {
    type:'aliases',
    payload:value
  }
}