import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchStoreLocatorView } from "../../api/cms/mock";
import i18next from "i18next";
import Cookies from "universal-cookie";
import ComponentComposer from "../../components/ComponentComposer";

const cookies = new Cookies();
let date = new Date(); // Now

const StoreLocator: React.FC<any> = () => {
  const [data, setData] = useState<any[]>([]);
  const [storeLocatorData, setStoreLocatorData] = useState(null);
  const { lang } = useParams();
  useEffect(() => {
    if (lang) {
      cookies.set("Language", lang, { path: "/", expires: date });
    } else if (i18next.language && !cookies.get("Language")) {
      cookies.set("Language", i18next.language, { path: "/", expires: date });
    }
    console.log("test");
    fetchdata();
    async function fetchdata() {
      const data = await fetchStoreLocatorView();
      setData(data.components ? data.components : null);
    }
  }, []);

  return (
    <div className="savers-page" id="maincontent">
      {data && data.map((d, i) => <ComponentComposer key={i} {...d} />)}
    </div>
  );
};

export default StoreLocator;
