import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

import {Modal,Button} from 'react-bootstrap';

const ContentGrid: React.FC<any> = (props) => {

const [showModal,setShowModal]=useState(false);


    return (
        <div className="grid-media-blocks">
            <div className="container">

                <div className="row custom-row">
                    {props.gallery_imgs && props.gallery_imgs.length > 0 ? props.gallery_imgs.map((_item:any)=>{
                        return(
                            <div className="col-lg-3 col-sm-6 col-md-4 pad-lr-10 col-6" onClick={()=>setShowModal(true)}>
                                <div className="media-box">
                                    <img className="globe-icon" src={_item} />
                                </div>
                            </div>
                        )
                    }) : null }


                </div>
            </div>


            <div className="thrift-nav mob-bottom">
                <div className="container">
                    <div className="thrift-inner">
                        <ul className="listing-row">
                            <li><a href="#"><img className="globe-icon" src={require("../../images/shirt-female.png")} /> Style</a></li>
                            <li><a href="thriftorials.php"><img className="globe-icon"
                                                                src={require("../../images/clothes-design-thread.png")} /> thriftorials</a>
                            </li>
                            <li><a href="#"><img className="globe-icon" src={require("../../images/recycling-refresh.png")} /> Rethink
                                Reuse</a></li>
                            <li><a href="#"><img className="globe-icon"  src={require("../../images/ecology-globe-leaf.png")} /> impact</a>
                            </li>
                            <li><a href="#"><img className="globe-icon" src={require("../../images/multiple-users-1.png")} /> Community</a>
                            </li>
                            <li><a href="#"><img className="globe-icon" src={require("../../images/newspaper-fold.png")} /> News & Updates</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Modal
                size="lg"
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={()=>setShowModal(false)}
                style={{backgroundColor:'rgba(0, 0, 0 , 0.8)'}}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div id="demo" className="carousel slide" data-ride="carousel">


                        <ul className="carousel-indicators">
                            <li data-target="#demo" data-slide-to="0" className="active"></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                            <li data-target="#demo" data-slide-to="2"></li>
                        </ul>


                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={require("../../images/winter-2.jpg")} alt="Los Angeles" width="1100" height="500"/>
                            </div>
                            <div className="carousel-item">
                                <img src={require("../../images/winter-2.jpg")} alt="Chicago" width="1100" height="500"/>
                            </div>
                            <div className="carousel-item">
                                <img src={require("../../images/winter-2.jpg")} alt="New York" width="1100" height="500"/>
                            </div>
                        </div>


                        <a className="carousel-control-prev" href="#demo" data-slide="prev">
                            <span className="carousel-control-prev-icon"></span>
                        </a>
                        <a className="carousel-control-next" href="#demo" data-slide="next">
                            <span className="carousel-control-next-icon"></span>
                        </a>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ContentGrid.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ContentGrid };

export default connect(mapStateToProps, mapDispatchToProps)(ContentGrid);
