import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactMarkdown from 'react-markdown';
import { getLocale } from "../../api/cms/api";

const FourColumnCard: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [bottomSubTitle, setBottomSubTitle] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [columnCardItems, setColumnCardItems] = useState([]);
  const [columnCardItemsLength, setColumnCardItemsLength] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [bottomLayer, setBottomLayer] = useState(false);
  const [topLayer, setTopLayer] = useState(false);
  const [description, setDescription] = useState("");
  const [topPadding, setTopPadding] = useState('');
  const [bottomPadding, setBottomPadding] = useState('');
  const [mobileTopPadding, setMobileTopPadding] = useState('');
  const [mobileBottomPadding, setMobileBottomPadding] = useState('');
  const [entryId, setEntryId] = useState('');

  useEffect(() => {

      function fetchComponentData()
    {
      client.getEntry(componentData.entryId, {locale: currLocale}).then(async(cardEntry : any) => {
        let allcolumnCardItems: any = [];
       cardEntry.fields.columnCardItems.map((item:any,i:any)=>{
           let singleCardItem = {
            index: i,
            title: "",
            description: "",
            cardItemIcon: "",
            cardItemIconAltText: "",
            backgroundColor: "",
            };
            if(item.sys.id!=undefined && item.sys.id!=""){

               client.getEntry(item.sys.id, {locale: currLocale}).then(async(entry : any) => {

                 if(entry!=undefined && entry!="")
                 {
                    if (
                      entry.fields.title != "" &&
                      entry.fields.title != undefined
                    ) {
                      singleCardItem["title"] = entry.fields.title;
                    }    

                    if (
                      entry.fields.description != "" &&
                      entry.fields.description != undefined
                    ) {
                      const options = {
                      renderNode: {
                        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                          return node.data.target.fields.html;
                        }
                      }
                      }
                        let rawRichTextDescription = documentToHtmlString(entry.fields.description, options);
                        singleCardItem["description"] = rawRichTextDescription;
                    }    

                    if (
                      entry.fields.cardItemIcon !== "" &&
                      entry.fields.cardItemIcon !== undefined
                    ) {
                      let fileUrl = entry.fields.cardItemIcon.fields.file.url;
                      let altText = entry.fields.cardItemIcon.fields.title;
                      singleCardItem["cardItemIcon"] = fileUrl;
                      singleCardItem["cardItemIconAltText"]=altText;
                    }    

                    if (
                      entry.fields.backgroundColor !== "" &&
                      entry.fields.backgroundColor !== undefined
                    ) {
                      singleCardItem["backgroundColor"] =
                        entry.fields.backgroundColor;
                    }
                    
                    allcolumnCardItems.push(singleCardItem);
                    allcolumnCardItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);   

                    await setColumnCardItems(allcolumnCardItems);
                    await setColumnCardItemsLength(allcolumnCardItems.length);
                 }
               })
            }    
       })
      })
    }

    fetchComponentData();


    if (componentData.title != undefined && componentData.title != "") {
      setTitle(componentData.title);
    }

    if (componentData.subTitle != undefined && componentData.subTitle != "") {
      setSubTitle(componentData.subTitle);
    }

     if (componentData.bottomLayer != undefined && componentData.bottomLayer != "") {
      setBottomLayer(componentData.bottomLayer);
    }

     if (componentData.topLayer != undefined && componentData.topLayer != "") {
      setTopLayer(componentData.topLayer);
    }

    if (componentData.fontColor != undefined && componentData.fontColor != "") {
      setFontColor(componentData.fontColor);
    }

    if (
      componentData.bottomSubTitle != undefined &&
      componentData.bottomSubTitle != ""
    ) {
      setBottomSubTitle(componentData.bottomSubTitle);
    }

    if (
      componentData.backgroundColor != undefined &&
      componentData.backgroundColor != ""
    ) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (
      componentData.bottomText != undefined &&
      componentData.bottomText != ""
    ) {
      setBottomText(componentData.bottomText);
    }

    if (
      componentData.entryId != "" &&
      componentData.entryId != undefined
    ) {
      setEntryId("content-"+componentData.entryId);
    }

    if (
      componentData.topPadding != "" &&
      componentData.topPadding != undefined
    ) {
      setTopPadding(componentData.topPadding);
    }

     if (
      componentData.mobileTopPadding != "" &&
      componentData.mobileTopPadding != undefined
    ) {
      setMobileTopPadding(componentData.mobileTopPadding);
    }


    if (
      componentData.bottomPadding != "" &&
      componentData.bottomPadding != undefined
    ) {
      setBottomPadding(componentData.bottomPadding);
    }

     if (
      componentData.mobileBottomPadding != "" &&
      componentData.mobileBottomPadding != undefined
    ) {
      setMobileBottomPadding(componentData.mobileBottomPadding);
    }

    // if (
    //   componentData.columnCardItems != undefined &&
    //   componentData.columnCardItems != ""
    // ) {

    //   let allcolumnCardItems: any = [];
    //   componentData.columnCardItems.map(async (item: any, i: any) => {
    //     if (item.sys.id !== undefined && item.sys.id !== "") {
    //       let singleCardItem = {
    //         index: i,
    //         title: "",
    //         description: "",
    //         cardItemIcon: "",
    //         backgroundColor: "",
    //       };

    //       await client
    //         .getEntry(item.sys.id, {locale: currLocale})
    //         .then(async (entry: any) => {
    //            console.log("comp>>>>",entry)
    //           if (entry != "" && entry != undefined) {
              
    //             if (
    //               entry.fields.title != "" &&
    //               entry.fields.title != undefined
    //             ) {
    //               singleCardItem["title"] = entry.fields.title;
    //             }

    //             if (
    //               entry.fields.description != "" &&
    //               entry.fields.description != undefined
    //             ) {
    //               let rawRichTextField = documentToHtmlString(
    //                 entry.fields.description
    //               );
    //               singleCardItem["description"] = rawRichTextField;
    //             }

    //             if (
    //               entry.fields.cardItemIcon !== "" &&
    //               entry.fields.cardItemIcon !== undefined
    //             ) {
    //               let fileUrl = entry.fields.cardItemIcon.fields.file.url;
    //               singleCardItem["cardItemIcon"] = fileUrl;
    //             }

    //             if (
    //               entry.fields.backgroundColor !== "" &&
    //               entry.fields.backgroundColor !== undefined
    //             ) {
    //               singleCardItem["backgroundColor"] =
    //                 entry.fields.backgroundColor;
    //             }
                
    //             allcolumnCardItems.push(singleCardItem);
    //             allcolumnCardItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

    //             await setColumnCardItems(allcolumnCardItems);
    //             await setColumnCardItemsLength(allcolumnCardItems.length);
    //           }
    //         })
    //         .catch(console.error);
    //     }
    //   });
    // }
  }, []);

  return (
    <>
    <style dangerouslySetInnerHTML=
          {{__html: `
           .four-column-card.${entryId} {
              padding-top: ${topPadding};
              padding-bottom: ${bottomPadding};
            }
            @media(max-width: 767px) {
              .four-column-card.${entryId} {
                padding-top: ${topPadding};
                padding-bottom: ${bottomPadding};
              }
            }
          `,
          }}
        />
     <div className={`${topLayer ? "four-column-card v2" : "four-column-card v2 no-top-layer"} ${bottomLayer ? "four-column-card v2": "four-column-card v2 no-bottom-layer"}` + entryId}  style={{backgroundColor: backgroundColor}}>
      
        <div className="container">
          <div className="common-heading-block text-center">
            <div className="heading-inner">
              {title ? <p className="title-four-column-card" style={{color:fontColor}} dangerouslySetInnerHTML={{ __html:title}}></p> : ""}
              {subTitle ? <p className="subtitle-four-column-card" style={{color:fontColor}} dangerouslySetInnerHTML={{ __html:subTitle}}></p> : ""}
            </div>
          </div>
          <div className="row">
            {columnCardItems.map(
              ({ title, description, cardItemIcon, backgroundColor,cardItemIconAltText }, i) => (
                <div className="col-lg-3 col-md-6" >
                  <div className={"card-wrap card-bg" + (i + 1)} style={{backgroundColor: backgroundColor}}>
                    <div className="icon-row">
                      {cardItemIcon ? <img width='35' height='36' src={cardItemIcon} alt={cardItemIconAltText?cardItemIconAltText:""} /> : ""}
                    </div>
                    {description ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="bottom-row text-center">
            {bottomSubTitle ?<p className="bottom-subtitle" style={{color:fontColor}} dangerouslySetInnerHTML={{ __html:bottomSubTitle}}></p>  : ""}
            {bottomText ? <small style={{color:fontColor}}><ReactMarkdown allowDangerousHtml source={bottomText} disallowedTypes={['paragraph']} unwrapDisallowed /></small> : ""}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

FourColumnCard.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { FourColumnCard };

export default connect(mapStateToProps, mapDispatchToProps)(FourColumnCard);
