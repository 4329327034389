import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const PintrestBoard: React.FC<any> = (props) => {


    return (
        <>
            <div className="pinterest-board mt-5 mb-5">
                <div className="container">
                    <div className="heading-row mb-4">
                        <h4>{props.title}</h4>
                        <span><b>@saversvvillage</b> <i className="fa fa-pinterest" aria-hidden="true"></i></span>
                    </div>

                    <div className="row">
                        {props.gallery ? props.gallery.map((_item:any)=>{
                           return( <div className="col-md-3 col-6">
                               <div className="pinterest-card">
                                   <div className="media-wrap">
                                       <img src={_item.url}/>
                                   </div>
                                   <b>{_item.title}</b>
                               </div>
                           </div>)
                        }):null}



                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

PintrestBoard.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { PintrestBoard };

export default connect(mapStateToProps, mapDispatchToProps)(PintrestBoard);
