import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from '../../Client';
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactPaginate from 'react-paginate';
import ReactMarkdown from 'react-markdown';
import { getLocale,getDomain } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";

const TwoColumnVerticalList: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();

  let currDomain= getDomain();

  const [listItems, setListItems] = useState([]);
  const [listItemsLength, setListItemsLength] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [paginateItems, setPaginateItems] = useState(4);
  const [currPageOffset, setCurrPageOffset] = useState(0);
  const [initialIndex,setInitialIndex] = useState(0);
  const [silceIndex,setSliceIndex] = useState(4);
  const [noOfPages,setNoOfPages] = useState(0);
  const [entryId, setEntryId] = useState('');

  useEffect(() => {

    if (componentData.backgroundColor != undefined && componentData.backgroundColor != '') {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.entryId != "" && componentData.entryId != undefined) {
      setEntryId("content-"+componentData.entryId);
    }

    if (componentData.paginateItems != undefined && componentData.paginateItems != '') {
      setPaginateItems(componentData.paginateItems);
      setSliceIndex(componentData.paginateItems);
    }

    if (componentData.listItems != undefined && componentData.listItems != '') {
      let allListItems: any = [];

      componentData.listItems.map(async (item: any, i: any) => {

        if (item.sys.id !== undefined && item.sys.id !== '') {
          let singleListItem = {
            index: i,
            title: '',
            image: '',
            imageAltText: '',
            description: '',
            ctaButtonTitle: '',
            ctaButtonAriaLabel: '',
            ctaButtonLink: '',
            ctaButtonClass: '',
            openLinkInNewTab: '',
            tag: '',
            ctaButtonColor:"",
            ctaButtonTextColor:"",
            ctaTextColor:"",
            ctaUnderlineColor:"",
            ctaZigzaglineColor:"",
            hoverColor:"",
            tagBackgroundColor: '',
          };

          await client.getEntry(item.sys.id, {locale: currLocale})
          .then( async (entry : any) => {
            if (entry != '' && entry != undefined) {


              if (entry.fields.title != '' && entry.fields.title != undefined) {
                singleListItem['title'] = entry.fields.title;
              }

              if (entry.fields.image !== '' && entry.fields.image !== undefined) {
                let fileUrl = entry.fields.image.fields.file.url;
                let altText = entry.fields.image.fields.title;
                singleListItem['image'] = fileUrl;
                singleListItem['imageAltText']=altText;
              }

              if (entry.fields.description != '' && entry.fields.description != undefined) {
                singleListItem['description'] = entry.fields.description;
              }

              if (entry.fields.tag != '' && entry.fields.tag != undefined) {
                singleListItem['tag'] = entry.fields.tag;
              }

              if (entry.fields.tagBackgroundColor != '' && entry.fields.tagBackgroundColor != undefined) {
                singleListItem['tagBackgroundColor'] = entry.fields.tagBackgroundColor;
              }

              if (entry.fields.cta != '' && entry.fields.cta != undefined) {
                if (entry.fields.cta.fields.title !== undefined && entry.fields.cta.fields.title !== "") {
                  singleListItem['ctaButtonTitle'] = entry.fields.cta.fields.title;
                }

                if (entry.fields.cta.fields.ariaLabel !== undefined && entry.fields.cta.fields.ariaLabel !== "") {
                  singleListItem['ctaButtonAriaLabel'] = entry.fields.cta.fields.ariaLabel;
                }

                if (entry.fields.cta.fields.alias !== undefined && entry.fields.cta.fields.alias !== "") {
                  singleListItem['ctaButtonLink'] = entry.fields.cta.fields.alias;
                }

                singleListItem['ctaButtonClass'] = 'read-more-link';
                if (entry.fields.cta.fields.ctaType != '' && entry.fields.cta.fields.ctaType != undefined) {

                  if (entry.fields.cta.fields.ctaType === 'Primary Button') {
                    singleListItem['ctaButtonClass'] = 'btn btn-primary';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Secondary Button') {
                    singleListItem['ctaButtonClass'] = 'btn btn-secondary';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Small Text CTA Black') {
                    singleListItem['ctaButtonClass'] = 'small-text-cta cta-black';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Small Text CTA White') {
                    singleListItem['ctaButtonClass'] = 'small-text-cta cta-white';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Large Text CTA Black') {
                    singleListItem['ctaButtonClass'] = 'large-text-cta cta-black';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Large Text CTA White') {
                    singleListItem['ctaButtonClass'] = 'large-text-cta cta-white';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Icon Text CTA') {
                    singleListItem['ctaButtonClass'] = 'icon-text-cta';
                  }
                  else if (entry.fields.cta.fields.ctaType === 'Read More CTA') {
                    singleListItem['ctaButtonClass'] = 'read-more-link';
                  }
                }

                if (entry.fields.cta.fields.ctaTextColor !== undefined && entry.fields.cta.fields.ctaTextColor !== "") {
                    singleListItem["ctaTextColor"] = entry.fields.cta.fields.ctaTextColor;
                 }
                 if (entry.fields.cta.fields.ctaZigzaglineColor !== undefined && entry.fields.cta.fields.ctaZigzaglineColor !== "") {
                  singleListItem["ctaZigzaglineColor"] = entry.fields.cta.fields.ctaZigzaglineColor;
                 }
                 if (entry.fields.ctaUnderlineColor !== undefined && entry.fields.cta.fields.ctaUnderlineColor !== "") {
                  singleListItem["ctaUnderlineColor"] = entry.fields.cta.fields.ctaUnderlineColor;
                 }
                 if (entry.fields.cta.fields.ctaButtonColor !== undefined && entry.fields.cta.fields.ctaButtonColor !== "") {
                  singleListItem["ctaButtonColor"] = entry.fields.cta.fields.ctaButtonColor;
                }
                 if (entry.fields.cta.fields.ctaButtonTextColor !== undefined && entry.fields.cta.fields.ctaButtonTextColor !== ""){
                  singleListItem["ctaButtonTextColor"] = entry.fields.cta.fields.ctaButtonTextColor;
                }
                if (entry.fields.cta.fields.hoverColor !== undefined && entry.fields.cta.fields.hoverColor !== "") {
                 singleListItem["hoverColor"] = entry.fields.cta.fields.hoverColor;
                 }

                if (entry.fields.cta.fields.openLinkInNewTab !== undefined && entry.fields.cta.fields.openLinkInNewTab !== "" && entry.fields.cta.fields.openLinkInNewTab === true) {
                  singleListItem['openLinkInNewTab'] = "_blank";
                }
              }
              

              if(entry.fields.domains==undefined)
              {
                allListItems.push(singleListItem);
              }

              else if(entry.fields.domains.length>0)
              {
                 if(entry.fields.domains.includes(currDomain)==true)
                 {
                    allListItems.push(singleListItem);
                 }
              }
              
              allListItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              await setListItems(allListItems);
              await setListItemsLength(allListItems.length);

              let _pages=componentData.paginateItems?(allListItems.length)/componentData.paginateItems:(allListItems.length)/paginateItems;
              if(Number.isInteger(_pages)==true)
              {
                 await setNoOfPages(_pages);
              }
              else{
                 var _page=Math.trunc(_pages)
                 await setNoOfPages(_page+1);
              }
            }
          })
          .catch(console.error)
        }
      })
    }
  }, []);

  const handlePageClick = (data: any) => {
    let selected = data.selected + 1;
    let lastOffset = Math.ceil(selected * paginateItems);
    let startOffset = lastOffset - paginateItems;
    setInitialIndex(startOffset);
    setSliceIndex(lastOffset);
  };

  return (
    <>
      <div className="two-column-vertical-list" style={{backgroundColor: backgroundColor}}>
        <div className="container">
          <div className="row">
            {
              listItems.slice(initialIndex,silceIndex).map((item:any,i) => 
                <div className="col-md-6">
                  <style dangerouslySetInnerHTML=
                      {{__html: `
                        .large-text-cta.new.cta-white.${entryId} i,
                        .large-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};            
                        }            

                       .large-text-cta.new.cta-white.${entryId}:hover  i,
                       .large-text-cta.new.cta-white.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                        .large-text-cta.new.cta-white.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-white.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                         .large-text-cta.new.cta-black.${entryId} i,
                        .large-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .large-text-cta.new.cta-black.${entryId}:hover i,
                       .large-text-cta.new.cta-black.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                         .large-text-cta.new.cta-black.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-black.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId} i,
                        .small-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId}:hover i,
                        .small-text-cta.new.cta-black.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-black.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-white.${entryId} i,
                        .small-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};
                        }
                        .small-text-cta.new.cta-white.${entryId}:hover i,
                        .small-text-cta.new.cta-white.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-white.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-white.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .btn.btn-primary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-primary.${entryId}:focus,
                        .btn.btn-primary.${entryId}:focus:active,
                        .btn.btn-primary.${entryId}:visited{
                            background:${item.hoverColor};
                           color:${item.ctaButtonTextColor};
                           border-color: ${item.hoverColor};   
                        }            

                        .btn.btn-primary.${entryId} {
                            background:${item.ctaButtonColor};
                           color:${item.ctaButtonTextColor};
                           border-color: ${item.ctaButtonColor};
                        }           

                        .btn.btn-primary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color: ${item.hoverColor};
                        }            

                        .btn.btn-secondary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-secondary.${entryId}:focus,
                        .btn.btn-secondary.${entryId}:focus:active,
                        .btn.btn-secondary.${entryId}:visited{
                            background:${item.hoverColor};
                           color:${item.ctaButtonTextColor};
                           border-color: ${item.hoverColor};   
                        }            

                        .btn.btn-secondary.${entryId} {
                            background:${item.ctaButtonColor};
                           color:${item.ctaButtonTextColor};
                           border-color: ${item.ctaButtonColor};
                        }
                        
                         .btn.btn-secondary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color: ${item.hoverColor};
                        }            

                        .read-more-link.${entryId} {
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        .read-more-link.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }            

                        .icon-text-cta.${entryId},a{
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        
                        .icon-text-cta.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }       
                        `,
                      }}
                    /> 
                  <div className="vertical-list-wrap">
                    <div className="row">
                      <div className="col-md-6 col-6 space-right">
                        <div className="media-block">
                          {item.image ? (
                            <a href={addLangKeyPath(item.ctaButtonLink)} aria-label={item.ctaButtonAriaLabel}>
                              <img src={item.image} width = '272' height = '274' alt={item.imageAltText?item.imageAltText:""}/>
                            </a>
                          ): ""}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6 space-left">
                        <div className="media-info">
                          {item.tag ? (<span className="tag-style tag-bg1" style={{background: item.tagBackgroundColor}}>{item.tag}</span>): ""}
                          {item.title ? (<p className="column-card-title" dangerouslySetInnerHTML={{ __html: item.title }}></p>): ""}
                          {item.description ? (<ReactMarkdown allowDangerousHtml source={item.description}/>): ""} 
                          {item.ctaButtonTitle ? 
                            <a className={item.ctaButtonClass+" new"+" "+entryId} target={item.openLinkInNewTab} href={addLangKeyPath(item.ctaButtonLink)} aria-label={item.ctaButtonAriaLabel}>{item.ctaButtonTitle}
                              <i className="fa fa-caret-right" aria-hidden="true"></i>          
                              <span className="svg-outer">
                                <svg width="387" height="12" viewBox="0 0 387 12">
                                  <defs>
                                    <clipPath>
                                      <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                    </clipPath>
                                  </defs>
                                  <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                    <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                  </g>
                                </svg>          
                              </span>
                              <span>           
                              </span>
                            </a>
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="mob-media-info">
                      {item.description ? (<ReactMarkdown allowDangerousHtml source={item.description}/>): ""}
                      {item.ctaButtonTitle ?
                        <a className={item.ctaButtonClass+" new"+" "+entryId} target={item.openLinkInNewTab} href={addLangKeyPath(item.ctaButtonLink)} aria-label={item.ctaButtonAriaLabel}>{item.ctaButtonTitle}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                        <span className="svg-outer">
                          <svg width="387" height="12" viewBox="0 0 387 12">
                            <defs>
                              <clipPath>
                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                              </clipPath>
                            </defs>
                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                            </g>
                          </svg>          
                        </span>
                        <span>           
                        </span>
                      </a>
                      : ""}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        {noOfPages>1?<div className="pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={noOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={noOfPages}
            onPageChange={handlePageClick}
            containerClassName={'pagination-listing'}
            activeClassName={'active'}
          />
          </div>:null
        }
        </div>  
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColumnVerticalList.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColumnVerticalList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoColumnVerticalList);
