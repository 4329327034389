import React, { useEffect } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";

const CommonBoxesWrap: React.FC<any> = (props) => {
  useEffect(() => {
    fetchdata();
    async function fetchdata() {
    }
  });

  const {
    site_logo,
    storeText,
    storeTextMobile,
    searchText,
    HeaderOptions,
    menuItems,
  } = props.header;

  return (
    <div className={props.page!="about"?"common-boxes-wrap mb-5 store-offer with-slides":"blue-bg common-boxes-wrap width-round-borders without-slider"}>
      <div className="container hr-style pt-4 mt-4">
        <h4 className="mb-4">{props.title}</h4>
        <h4 className="mb-4 mob-title">{props.heading}</h4>
        <div className="row mgn-rev-lr-22 store-inner">
          
          <div className="col-md-4 pad-lr-22">
            <div className="common-card bg-dgreen">
              {props.page === "about"?
              <h4 className="clr-white mb-3">
                {props.cards_data[0].title}{" "}
              </h4> :

              <h5 className="clr-white mb-3">
                {props.cards_data[0].title}{" "}
              </h5>
              }
              <p className="clr-white">
                {props.cards_data[0].paragraph}
              </p>
              <div className="bottom-links-row">
                <a href="#" className="large-text-cta cta-white">
                  {props.cards_data[0].anchor_text}{" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
                <img src={props.cards_data[0].image} />
              </div>
            </div>
          </div>

          {props.page!="about"?<div className="col-md-4 pad-lr-22">
            <div className="common-card primary-bg1 media-box">
              <img src={props.cards_data[1].image} />
            </div>
          </div>
          :
          <div className="col-md-4 pad-lr-22">
            <div className={props.page=="about"?"common-card primary-bg":"common-card "}>
              <h5 className="clr-white mb-3">
                {props.cards_data[1].title}
              </h5>
              <p className="clr-white">{props.cards_data[1].paragraph}</p>
              <div className="bottom-links-row">
                <a href="#" className="large-text-cta cta-white">
                  {props.cards_data[1].anchor_text} {" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
                <img src={props.cards_data[1].image} />
              </div>
              {props.page=="about"?<img className="br-fixed-layer" src="http://45.40.138.118/savers/dev/images/lblue-plus.png"/>:""}
            </div>
          </div>
          }

          <div className="col-md-4 pad-lr-22">
            <div className={props.page=="about"?"common-card primary-bg":"common-card bg-skin card-with-black-color"}>
              {props.page=="about"?
              <h4 className="clr-white mb-3">
                {props.cards_data[2].title}
              </h4>
              :
              <h5 className="clr-white mb-3">
                {props.cards_data[2].title}
              </h5>
              }
              <p className="clr-white">{props.cards_data[2].paragraph}</p>
              <div className="bottom-links-row">
                <a href="#" className="large-text-cta cta-white">
                  {props.cards_data[2].anchor_text} {" "}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
                <img src={props.cards_data[2].image} />
              </div>
              {props.page=="about"?<img className="br-fixed-layer" src="http://45.40.138.118/savers/dev/images/lblue-plus2.png"/>:""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

CommonBoxesWrap.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { CommonBoxesWrap };

export default connect(mapStateToProps, mapDispatchToProps)(CommonBoxesWrap);