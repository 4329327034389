import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const PrioritizeContent: React.FC<any> = (componentData) => {
    
    const currLocale = getLocale();
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [imageAltText, setImageAltText] = useState('');
    const [description, setDescription] = useState('');
    const [items, setItems] = useState([]);
    const [itemsLength, setItemsLength] = useState(0);

    useEffect(()=>
    {    
     function fetchComponentData()
     {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry!="" && entry!=undefined)
          {
            if(entry.fields!="" && entry.fields!=undefined)
            {
              if(entry.fields.description!="" && entry.fields.description!=undefined)
              {
                 const options = {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                        return node.data.target.fields.html;
                      },
                      [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                    }
                  }
                  let rawRichText = documentToHtmlString(entry.fields.description, options);
                     
                  setDescription(rawRichText);
              }  

            }
          }
        }); 
      }

    }
    
    fetchComponentData();
       if(componentData.title!="" && componentData.title!=undefined)
       {
           setTitle(componentData.title);
       }
       if(componentData.image!="" && componentData.image!=undefined)
       {
           let fileUrl=componentData.image.fields.file.url;
           setImage(fileUrl);
           setImageAltText(componentData.image.fields.title);
       }
       if(componentData.items!="" && componentData.items)
       {
          let allItems:any=[];
          if(componentData.items.length>0)
          {
              componentData.items.map(async(itemEntry:any,i:any)=>
              {    
                 client.getEntry(itemEntry.sys.id, {locale: currLocale}).then(async(item : any) => {
                     let singleItem={
                      index: i,
                      image:"",
                      imageAltText:"",
                      title:"",
                      description:""
                    }
                  
                   if(item!="" && item!=undefined)
                    {
                        if(item.fields!="" && item.fields!=undefined)
                        {
                           if(item.fields.title!="" && item.fields.title!=undefined)
                            {
                                singleItem['title']= item.fields.title;
                            }
                            if(item.fields.image!="" && item.fields.image!=undefined)
                            {
                                singleItem['image']= item.fields.image.fields.file.url;
                                singleItem['imageAltText']= item.fields.image.fields.title;
                            }    
                            if(item.fields.description!="" && item.fields.description!=undefined)
                            {
                               const options = {
                               renderNode: {
                                 [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                   return node.data.target.fields.html;
                                 },
                                 [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                               }
                             }
                             let rawRichText = documentToHtmlString(item.fields.description, options);
                                
                             singleItem['description']=rawRichText;
                            }
                            allItems.push(singleItem);
                            allItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                            
                            await setItems(allItems);
                            await setItemsLength(allItems.length);    
                        }
                    }
                 });
                
   
                      
              })
          }
            
       }
       
    },[]);

    return (
    <div className="medium-container">
        {title?<div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0">
          <h2 className="box-heading" dangerouslySetInnerHTML={{__html:title}}></h2>
        </div>:null}   
        <div className="row">
          <div className="col-12 xs-no-space">
            <div className="impact-prioritize">
              {image?<img className="wow fadeIn" width = '842' height = '410' data-wow-duration="2s" data-wow-delay=".4s" src={image} alt={imageAltText?imageAltText:""}/>:null}
              <p dangerouslySetInnerHTML={{__html:description}}></p>
            </div>
          </div>
          {
              items.length>0?items.map((item:any)=>
                 <div className="col-12 col-lg-6 xs-no-space">
                    <div className="impact-prioritize">
                      <img className="wow fadeIn" width = '402' height = '185' data-wow-duration="2s" data-wow-delay=".8s" src={item.image} alt={item.imageAltText?item.imageAltText:""}/>
                      <h6>{item.title}</h6>
                      <p dangerouslySetInnerHTML={{__html:item.description}}></p>
                      
                    </div>
                 </div>   
              ):null
          }
     
         
        </div>
    </div>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

PrioritizeContent.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { PrioritizeContent };

export default connect(mapStateToProps, mapDispatchToProps)(PrioritizeContent);
