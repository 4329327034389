import React, { useEffect } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";

const TwoColsCardWithoutLayer: React.FC<any> = (props) => {
  useEffect(() => {}, []);

  const {
    site_logo,
    storeText,
    storeTextMobile,
    searchText,
    HeaderOptions,
    menuItems,
  } = props.header;

  return (
    <>
      <div className="two-column-card mt-0 two-column-card-without-layer">
        <div className="container">
          <div className="card-inner">
            <div className="row">
              <div className="col-md-6">
                <div className="card-wrap">
                  <h2>
                    {props.Two_Colum_Card[0].title}{" "}
                    <span>{props.Two_Colum_Card[0].sub_title}</span>
                  </h2>
                  <p>{props.Two_Colum_Card[0].paragraph}</p>
                  <a href="#" className="large-text-cta cta-white">
                    {props.Two_Colum_Card[0].anchor_text}{" "}
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </a>
                  <img className="hover-thumb" src={props.common_image} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="card-wrap">
                  <h2>{props.Two_Colum_Card[1].title}</h2>
                  <p>{props.Two_Colum_Card[1].paragraph}</p>
                  <a href="#" className="large-text-cta cta-white">
                    {props.Two_Colum_Card[1].anchor_text}{" "}
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </a>
                  <img className="hover-thumb" src={props.common_image} />
                </div>
              </div>
            </div>
          </div>

          <div className="common-boxes-wrap with-slides">
            <div className="owl-slider">
                <div className="item">
                  <div className="common-card bg-dgreen">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[0].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[0].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[0].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                      <img src={props.Common_Boxes[0].image} />
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="common-card primary-bg">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[1].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[1].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[1].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="common-card bg-lgreen">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[2].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[2].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[2].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                      <img src={props.Common_Boxes[2].image} />
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="common-card bg-dgreen">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[0].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[0].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[0].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                      <img src={props.Common_Boxes[0].image} />
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="common-card primary-bg">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[1].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[1].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[1].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="common-card bg-lgreen">
                    <h5 className="clr-white mb-3">
                      {props.Common_Boxes[2].title}
                    </h5>
                    <p className="clr-white">
                      {props.Common_Boxes[2].paragraph}
                    </p>
                    <div className="bottom-links-row">
                      <a href="#" className="large-text-cta cta-white">
                        {props.Common_Boxes[2].anchor_text}{" "}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </a>
                      <img src={props.Common_Boxes[2].image} />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColsCardWithoutLayer.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColsCardWithoutLayer };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoColsCardWithoutLayer);
