import { SiteActionType } from '../types';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

export const BINGO_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_BINGO_TILES',
    SET_ERROR: 'SET_BINGO_TILES_ERROR',
    SET_DATA: 'SET_BINGO_TILES',
};

export const SITE_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_SITE_DATA',
    SET_ERROR: 'SET_SITE_DATA_ERROR',
    SET_DATA: 'SET_SITE_DATA',
};

export const NAV_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_NAV_DATA',
    SET_ERROR: 'SET_NAV_DATA_ERROR',
    SET_DATA: 'SET_NAV_DATA',
};

export const FOOTER_LINKS_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_FOOTER_LINKS_DATA',
    SET_ERROR: 'SET_FOOTER_LINKS_DATA_ERROR',
    SET_DATA: 'SET_FOOTER_LINKS_DATA',
};

export const FOOTER_BUTTONS_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_FOOTER_BUTTONS_DATA',
    SET_ERROR: 'SET_FOOTER_BUTTONS_DATA_ERROR',
    SET_DATA: 'SET_FOOTER_BUTTONS_DATA',
};

export const LOG_IN_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_LOG_IN',
    SET_ERROR: 'SET_LOG_IN_ERROR',
    SET_DATA: 'SET_LOG_IN',
};

export const JOIN_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_JOIN',
    SET_ERROR: 'SET_JOIN_ERROR',
    SET_DATA: 'SET_JOIN',
};

export const LOG_OUT_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_LOG_OUT',
    SET_ERROR: 'SET_LOG_OUT_ERROR',
    SET_DATA: 'SET_LOG_OUT',
};

export const PROFILE_TYPE: SiteActionType = {
    IS_LOADING: 'IS_LOADING_PROFILE_',
    SET_ERROR: 'SET_PROFILE_ERROR',
    SET_DATA: 'SET_PROFILE',
};

export const SET_GIGYA = 'SET_GIGYA';

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
