import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const IconsBlock: React.FC<any> = (componentData) => {
    
    const currLocale = getLocale();
    const [title, setTitle] = useState('');
    const [iconBlockItems, setIconBlockItems] = useState([]);
    const [iconBlockLength, setIconBlockLength] = useState(0);

    useEffect(()=>
    {
       if(componentData.title!="" && componentData.title!=undefined)
        {
            setTitle(componentData.title);
        }
        if(componentData.iconBlockItems!="" && componentData.iconBlockItems!=undefined)
        {
            let allIconsItem:any=[];
            if(componentData.iconBlockItems.length>0)
            {
                componentData.iconBlockItems.map((item:any,i:any)=>
                {
                    client.getEntry(item.sys.id, {locale: currLocale}).then(async(entry : any) => 
                    {
                        let singleIconItem={
                            index: i,
                            itemIcon:"",
                            itemIconAltText:"",
                            itemTitle:"",
                            itemValue:""
                        }
                        if(entry!="" && entry!=undefined)
                        {
                           if(entry.fields!="" && entry.fields!==undefined)
                           {
                               if(entry.fields.itemIcon!="" && entry.fields.itemIcon!==undefined)
                               {
                                   singleIconItem['itemIcon']= entry.fields.itemIcon.fields.file.url;
                                   singleIconItem['itemIconAltText']= entry.fields.itemIcon.fields.title;
                               } 

                               if(entry.fields.itemTitle!="" && entry.fields.itemTitle!==undefined)
                               {
                                   singleIconItem['itemTitle']= entry.fields.itemTitle;
                               }

                               if(entry.fields.itemValue!="" && entry.fields.itemValue!==undefined)
                               {
                                   singleIconItem['itemValue']= entry.fields.itemValue;
                               }
                               allIconsItem.push(singleIconItem);
                               allIconsItem.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                               
                               await setIconBlockItems(allIconsItem);
                               await setIconBlockLength(allIconsItem.length);
                           }
                        }
                    })
                })
            }
        }
        

    },[]);

    return (
    <div className="small-container">
      {title?<div className="impact-head text-center">
        <h2 className="box-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s"><ReactMarkdown allowDangerousHtml source={title} disallowedTypes={['paragraph']} unwrapDisallowed /></h2>
      </div>:null}
      <div className="imact-right-solutions">
        <ul className="small-icon-blocks">
         {iconBlockItems.length>0?iconBlockItems.map((item:any)=>
          <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
            <div className="img-wrap"><img src={item.itemIcon} width ='76' height ='68' alt={item.itemIconAltText?item.itemIconAltText:""}/></div>
            <h2>{item.itemValue}</h2>
            <p>{item.itemTitle}</p>
          </li>):null
          }
        </ul>
      </div>
    </div>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

IconsBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { IconsBlock };

export default connect(mapStateToProps, mapDispatchToProps)(IconsBlock);
