import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const AboutProcess: React.FC<any> = (props) => {
    return (
    <>
     <section className="about-process">
        <div className="container">
        <div className="row text-block ptb-80">
                <div className="col-md-12 text-center">
                    <h2>Our purpose</h2>
                </div>
                <div className="col-md-6">
                    <div className="text-info-left">
                        <h4>As champions of reuse, we’re committed to keeping one-of-a-kind finds out of landfills. </h4>
                        <p>While thrifting should always be fun, we also take secondhand seriously. Because we believe that your pre-loved stuff has the power to change the world. </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="text-info-right">
                        <p>We know that with every thrift purchase or decluttering drop-off, you’re helping protect the planet and support your community. That’s why we run one of the most robust recycling and upcycling programs in the world and stand firmly in support of the circular economy. Just how old trends come back into style, we want to keep your stuff (and our planet!) around for years to come.</p>
                        <a href="" className="large-text-cta cta-black">Read our purpose and values <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

AboutProcess.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { AboutProcess };

export default connect(mapStateToProps, mapDispatchToProps)(AboutProcess);
