import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const TwoColsMedia: React.FC<any> = (props) => {


    return (
        <>
            <div className="small-container ptb-80">
                <div className="longform-text">
                    <h4>{props.headerText}</h4>
                </div>
            </div>

            <div className="two-cols-media">
                <div className="container">
                    <div className="row">
                        {props.images ? props.images.map((item:any)=>{
                          return(  <div className="col-md-6">
                              <div className="media-wrap">
                                  <img src={item} />
                              </div>
                          </div>)
                        }):null}


                    </div>
                </div>
            </div>

            <div className="small-container ptb-80">
                <div className="longform-text">
                    <h5 className="mb-5">{props.desc_text}</h5>
                    <p className="mb-0">{props.desc_text_small}</p>
                </div>
            </div></>

    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColsMedia.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColsMedia };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColsMedia);
