import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const ImpactCycle: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const[title,setTitle]=useState('');
    const[description,setDescription]=useState('');
    const[roundBlock1,setRoundBlock1]=useState('');
    const[roundBlock2,setRoundBlock2]=useState('');
    const[roundBlock3,setRoundBlock3]=useState('');
    const[roundBlock4,setRoundBlock4]=useState('');
    const[roundBlockCenter,setRoundBlockCenter]=useState('');
    const[disclaimerText,setDisclaimerText]=useState('');

    useEffect(()=>
    {
      function fetchComponentData()
      {
       if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {     
  
          if(entry.fields.roundBlock1!="" && entry.fields.roundBlock1!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.roundBlock1, options);     
              setRoundBlock1(rawRichText);
          }   
          if(entry.fields.roundBlock2!="" && entry.fields.roundBlock2!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.roundBlock2, options);     
              setRoundBlock2(rawRichText);
          } 
          if(entry.fields.roundBlock3!="" && entry.fields.roundBlock3!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.roundBlock3, options);     
              setRoundBlock3(rawRichText);
          }   
          if(entry.fields.roundBlock4!="" && entry.fields.roundBlock4!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.roundBlock4, options);     
              setRoundBlock4(rawRichText);
          } 
          if(entry.fields.roundBlockCenter!="" && entry.fields.roundBlockCenter!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.roundBlockCenter, options);     
              setRoundBlockCenter(rawRichText);
          }   
          if(entry.fields.disclaimerText!="" && entry.fields.disclaimerText!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.disclaimerText, options);     
              setDisclaimerText(rawRichText);
          }

          if(entry.fields.description!="" && entry.fields.description!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.description, options);     
              setDescription(rawRichText);
          }

        }); 
      }
        if (componentData.title != '' && componentData.title != undefined) {
            setTitle(componentData.title);
        }
      }
      fetchComponentData();
    },[]);
    const slickSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ]
  };

    return (
      <div className="medium-container">

        {title?<div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
             <h2 className="box-heading">{title} </h2>
           </div>:null}           
           {description?<p className="text-center body1 mb-40" dangerouslySetInnerHTML={{__html:description}}></p>:null}

        <div className="impact-right-cycle">
          <div className="inner">            
          {roundBlockCenter?<div className="round-blocks main wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
              <h1 dangerouslySetInnerHTML={{__html:roundBlockCenter}}></h1>
            </div>:null}            
            {roundBlock1?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="1.5s">
              <img src={require("../../images/cycle-icon2.svg")} alt="cycle-icon2"/>
              <p dangerouslySetInnerHTML={{__html:roundBlock1}}></p>
            </div>:null}            
            {roundBlock2?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2s">
              <img src={require("../../images/cycle-icon3.svg")} alt="cycle-icon3"/>
              <p dangerouslySetInnerHTML={{__html:roundBlock2}}></p>
            </div>:null}            
            {roundBlock3?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2.5s">
              <img src={require("../../images/cycle-icon4.svg")} alt="cycle-icon4"/>
              <p dangerouslySetInnerHTML={{__html:roundBlock3}}></p>
            </div>:null}            
            {roundBlock4?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="3s">
              <img src={require("../../images/cycle-icon5.svg")} alt="cycle-icon5"/>
              <p dangerouslySetInnerHTML={{__html:roundBlock4}}></p>
            </div>:null}
            <span className="circel"><i></i><i></i><i></i><i></i></span>
          </div>
        </div>

        <div className="impact-right-cycle with-slider desktop-hide">
          <div className="inner">
            <div className="heading">
              <h1 dangerouslySetInnerHTML={{__html:roundBlockCenter}}></h1>
            </div>
            {
              roundBlock1!=""||roundBlock2!=""||roundBlock3!=""||roundBlock4!=""?
              <Slider {...slickSettings1}>
              {roundBlock1?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="1.5s">
                <img src={require("../../images/cycle-icon2.svg")} alt="cycle-icon2"/>
                <div dangerouslySetInnerHTML={{__html:roundBlock1}}></div>
              </div>:null}
              
              {roundBlock2?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2s">
                <img src={require("../../images/cycle-icon3.svg")} alt="cycle-icon3"/>
                <div dangerouslySetInnerHTML={{__html:roundBlock2}}></div>
              </div>:null}              {roundBlock3?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2.5s">
                <img src={require("../../images/cycle-icon4.svg")} alt="cycle-icon4"/>
                <div dangerouslySetInnerHTML={{__html:roundBlock3}}></div>
              </div>:null}              {roundBlock4?<div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2.5s">
                <img src={require("../../images/cycle-icon5.svg")} alt="cycle-icon5"/>
                <div dangerouslySetInnerHTML={{__html:roundBlock4}}></div>
              </div>:null}
            </Slider>
            :
            null
            }
                  
          </div>
        </div>

        {disclaimerText?<p className="text-center body2 italic" dangerouslySetInnerHTML={{__html:disclaimerText}}></p>:null}

      </div>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ImpactCycle.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ImpactCycle };

export default connect(mapStateToProps, mapDispatchToProps)(ImpactCycle);
