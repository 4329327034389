import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import ReactMarkdown from 'react-markdown';

const FullBleedBannerThree: React.FC<any> = (componentData) => {

  const [title, setTitle] = useState('');
  const [titleFontColor, setTitleFontColor] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionFontColor, setDescriptionFontColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
  const [contentAlignment, setContentAlignment] = useState('');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');
  const [bannerWithFindStoreBlock, setBannerWithFindStoreBlock] = useState('');
  const [kraftTexture, setKraftTexture] = useState(true);
  const [bannerDesktopHeight, setBannerDesktopHeight] = useState("");
  const [bannerTabHeight, setBannerTabHeight] = useState("");
  const [bannerMobileHeight, setBannerMobileHeight] = useState("");
  const [backgroundPosition, setBackgroundPosition] = useState("");
  const [itemClass,setItemClass]=useState("");
  const [entryid, setEntryId] = useState("");

   useEffect(() => {
    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    

    if (
      componentData.backgroundPosition !== "" &&
      componentData.backgroundPosition !== undefined
    ) {
      setBackgroundPosition(componentData.backgroundPosition);
        if(componentData.backgroundPosition=== 'left')
        {
          setItemClass("bg-position-left")
        }
        else if(componentData.backgroundPosition==='center')
        {
          setItemClass("bg-position-center")
        }
        else if(componentData.backgroundPosition==='right')
        {
          setItemClass("bg-position-right")
        }
         
    }

    if (componentData.titleFontColor != '' && componentData.titleFontColor != undefined) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription(componentData.description);
    }

    if (componentData.descriptionFontColor != '' && componentData.descriptionFontColor != undefined) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }
    else {
      setBackgroundColor('#007473');
    }

    if (componentData.backgroundImage != '' && componentData.backgroundImage != undefined) {
      let fileUrl = componentData.backgroundImage.fields.file.url;
      setBackgroundImage(fileUrl);
    }

    if (componentData.mobileBackgroundImage != '' && componentData.mobileBackgroundImage != undefined) {
      let fileUrl = componentData.mobileBackgroundImage.fields.file.url;
      setMobileBackgroundImage(fileUrl);
    }

    if (componentData.contentAlignment != '' && componentData.contentAlignment != undefined) {
      setContentAlignment(componentData.contentAlignment);
    }

    if (componentData.disclaimerText != '' && componentData.disclaimerText != undefined) {
      setDisclaimerText(componentData.disclaimerText);
    }

    if (componentData.kraftTexture != '' && componentData.kraftTexture != undefined) {
      setKraftTexture(componentData.kraftTexture);
    }

    if (componentData.bannerWithFindStoreBlock !== undefined && componentData.bannerWithFindStoreBlock !== "") {
      if (componentData.bannerWithFindStoreBlock === true) {
        setBannerWithFindStoreBlock('bottom-padding');
      }     
    }

    if (componentData.fullBleedBanner3DesktopHeight != '' && componentData.fullBleedBanner3DesktopHeight != undefined) {
      setBannerDesktopHeight(componentData.fullBleedBanner3DesktopHeight);
    }

    if (componentData.fullBleedBanner3MobileHeight != '' && componentData.fullBleedBanner3MobileHeight != undefined) {
      setBannerMobileHeight(componentData.fullBleedBanner3MobileHeight);
    }

    if (componentData.fullBleedBanner3TabHeight != '' && componentData.fullBleedBanner3TabHeight != undefined) {
      setBannerTabHeight(componentData.fullBleedBanner3TabHeight);
    }
    if (componentData.entryId != '' && componentData.entryId != undefined) {
      setEntryId(componentData.entryId);
    }

  },[]);

  let contentAlign = "";
  if (contentAlignment == "Left") {
    contentAlign = "content-align-left";
  }
  else if (contentAlignment == "Center") {
    contentAlign = "content-align-center";
  }

  
  return (
    <>
      <style dangerouslySetInnerHTML=
        {{__html: `
          .banner-with-solid-bg.${entryid}{
            height: ${bannerDesktopHeight}px;
          }
          
          .banner-with-solid-bg.bg-position-left .mobile-bg ,
          .banner-with-solid-bg.bg-position-left .desktop-bg {
            background-position: center left;
          }
          .banner-with-solid-bg.bg-position-center .mobile-bg ,
          .banner-with-solid-bg.bg-position-center .desktop-bg {
            background-position: center center;
          }

          .banner-with-solid-bg.bg-position-right .mobile-bg ,
          .banner-with-solid-bg.bg-position-right .desktop-bg {
            background-position: center right;
          }

          @media(max-width: 992px) {
            .banner-with-solid-bg.${entryid}{
              height: ${bannerTabHeight}px;
            }
          }

          @media(max-width: 767px) {
            .banner-with-solid-bg.${entryid}{
              height: ${bannerMobileHeight}px;
            }
          }
          `       
        }}
      />
      <div className={contentAlign ? "banner-with-solid-bg "+ entryid + " " + itemClass + " banner-solid-bg " + contentAlign + " " + bannerWithFindStoreBlock : "banner-with-solid-bg "+entryid+ " " + itemClass + " banner-solid-bg " + bannerWithFindStoreBlock} style={{backgroundColor: backgroundColor}}>
        {backgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div>: ""}
        {mobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+mobileBackgroundImage+")"}}></div>: ""}
        <div className="container">
          <div className="banner-info">            
            {title ?  (kraftTexture==true ? <h1 style={{color: titleFontColor}}> <span dangerouslySetInnerHTML={{__html:title}}></span></h1>:<h1>{title}</h1>): ""}
            {description ? <h6 className="clr-dgreen1" style={{color: descriptionFontColor}}><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></h6>: ""}
          </div>
        </div>
      </div>
      {disclaimerText ? <div className="container"><div className="col-lg-10 offset-lg-1 pb-10"><small><ReactMarkdown allowDangerousHtml source={disclaimerText} disallowedTypes={['paragraph']} unwrapDisallowed /></small></div></div> : ""}
    </>

  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FullBleedBannerThree.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FullBleedBannerThree };

export default connect(mapStateToProps, mapDispatchToProps)(FullBleedBannerThree);
