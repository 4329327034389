import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';


const HorizontalRule: React.FC<any> = (componentData) => {

  const [style, setStyle] = useState('solid');
  const [height, setHeight] = useState('2');
  const [color, setColor] = useState('#DBDBDB');
  const [topMargin, setTopMargin] = useState(0);
  const [bottomMargin, setBottomMargin] = useState(0);
  const [mobileTopMargin, setMobileTopMargin] = useState(0);
  const [mobileBottomMargin, setMobileBottomMargin] = useState(0);
  const [entryId,setEntryId] = useState('');

  useEffect(() => {
    if (componentData.style !== undefined && componentData.style !== '') {
      setStyle(componentData.style.toLowerCase());
    }

    if (componentData.height !== undefined && componentData.height !== '') {
      setHeight(componentData.height);
    }

    if (componentData.color !== undefined && componentData.color !== '') {
      setColor(componentData.color);
    }

    if (componentData.entryId !== undefined && componentData.entryId !== '') {
      setEntryId("dynamic-"+componentData.entryId);
    }


    if (componentData.topMargin !== undefined && componentData.topMargin !== '') {
      setTopMargin(componentData.topMargin);
    }

    if (componentData.bottomMargin !== undefined && componentData.bottomMargin !== '') {
      setBottomMargin(componentData.bottomMargin);
    }

    if (componentData.mobileTopMargin !== undefined && componentData.mobileTopMargin !== '') {
      setMobileTopMargin(componentData.mobileTopMargin);
    }

    if (componentData.mobileBottomMargin !== undefined && componentData.mobileBottomMargin !== '') {
      setMobileBottomMargin(componentData.mobileBottomMargin);
    }
  }, []);

  return (
    <>
    <style dangerouslySetInnerHTML=
      {{__html: `
        .horizontal-rule.${entryId} hr{
          border-top:${height} ${style} ${color};
          margin-top: ${topMargin};
          margin-bottom: ${bottomMargin};
        }
              
        @media(max-width: 767px) {
          .horizontal-rule.${entryId} hr{
            margin-top: ${mobileTopMargin};
            margin-bottom: ${mobileBottomMargin};
          }
        }          

        `,
      }}
    />
      <section className={"horizontal-rule "+entryId}>
        <div className="container">
          <div className="row">
              <div className="col-12">
                  <hr></hr>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

HorizontalRule.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { HorizontalRule };

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalRule);