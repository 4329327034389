import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { Modal } from 'react-bootstrap';
import { getLocale, addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const MosaicBlock: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const[socialTitle, setSocialTitle] = useState("");
  const[socialItemsTitleFontColor, setSocialItemsTitleFontColor] = useState("");
  const[socialItems, setSocialItems] = useState([]);
  const[mosaicItem, setMosaicItem] = useState([]);
  const[mosaicItemLength, setMosaicItemLength] = useState(0);
  const[contentType, setContentType] = useState("");
  const[centerDescription, setCenterDescription] = useState("");
  const[logo, setLogo] = useState("");
  const[logoAltText, setLogoAltText] = useState("");
  const[modal,setModal]=useState(false);
  const[currentImageIndex,setCurrentImageIndex]=useState(0);
  const[backgroundColor,setBackgroundColor]=useState("");
  const[instagramFeed,setInstagramFeed]=useState("");

  const title : any = [];

  useEffect(() => {
    
    if (
      componentData.socialItemsTitle != "" &&
      componentData.socialItemsTitle != undefined
    ) {
      setSocialTitle(componentData.socialItemsTitle);
    }

    if (
      componentData.socialItemsTitleFontColor != "" &&
      componentData.socialItemsTitleFontColor != undefined
    ) {
      setSocialItemsTitleFontColor(componentData.socialItemsTitleFontColor);
    }

    if (componentData.contentType) {
      setContentType(componentData.contentType);
    }

    if (
      componentData.centerDescription != "" &&
      componentData.centerDescription != undefined
    ) {
      setCenterDescription(componentData.centerDescription);
    }

    if (
      componentData.logo != "" &&
      componentData.logo != undefined
    ) {
      setLogo(componentData.logo.fields.file.url);
      setLogoAltText(componentData.logo.fields.title);
    }

    if (
      componentData.backgroundColor != "" &&
      componentData.backgroundColor != undefined
    ) {
      setBackgroundColor(componentData.backgroundColor);
    }


    if (
      componentData.socialItems != "" &&
      componentData.socialItems != undefined
    ) {
      let allSocialItems: any = [];
      componentData.socialItems.map((item: any, i: any) => {
        client
          .getEntry(item.sys.id, {locale: currLocale})
          .then((entry: any) => {
            if (entry != "" && entry != undefined) {
              let singleSocialItem = {index: i, socialiconClass: "", link: ""}
              if (entry.fields.socialiconClass != undefined && entry.fields.socialiconClass != "") {
                singleSocialItem["socialiconClass"] = entry.fields.socialiconClass;
              }
              if (entry.fields.link != undefined && entry.fields.link != "") {
                singleSocialItem["link"] = entry.fields.link;
              }
              allSocialItems.push(singleSocialItem);
              allSocialItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

              setSocialItems(allSocialItems);
            }
          })
          .catch(console.error);
      });
    }
    if (componentData.instagramFeed != "" && componentData.instagramFeed != undefined) 
    {
        client
          .getEntry(componentData.instagramFeed.sys.id, {locale: currLocale})
          .then((entry: any) => {
            if (entry !== undefined && entry !== "") {
              setInstagramFeed(entry.fields.feedHtml);

              if(entry.fields.juicerScript!==undefined && entry.fields.juicerScript!=="" && $("script[src*=juicer]").length === 0)
              {
                 const script = document.createElement("script");
                 script.src = entry.fields.juicerScript;
                 script.async = true;          
                 document.body.appendChild(script);
              }

              if(entry.fields.juicerLink!==undefined && entry.fields.juicerLink!=="" && $("link[src*=juicer]").length === 0)
              {
                 const css = document.createElement("link");
                 css.href = entry.fields.juicerLink; 
                 css.media= "all";
                 css.rel="stylesheet";  
                 css.type="text/css";
                 document.body.appendChild(css);
              }
              
              if(entry.fields.additionalScript!==undefined && entry.fields.additionalScript!=="" && $("script:contains('https://app.termly.io/embed.min.js')").length === 0)
              {
                 const additionalScript = document.createElement("script"); 
                 additionalScript.type="text/javascript";
                 additionalScript.innerHTML= entry.fields.additionalScript;
                 document.body.appendChild(additionalScript); 
              }  
               
            }
          })
    }

    if (
      componentData.mosaicItem != "" &&
      componentData.mosaicItem != undefined
    ) {
      let allmosaicItem: any = [];
      componentData.mosaicItem.map(async (item: any, i: any) => {
        client
          .getEntry(item.sys.id, {locale: currLocale})
          .then((entry: any) => {
            if (entry !== undefined && entry !== "") {
              let singleMosaicItem = {
                index: i,
                image: "", 
                imageAltText: "",
                userHandle: "", 
                description: "", 
                hashTags: []
              };

              if (entry.fields.image != undefined && entry.fields.image != "") {
                singleMosaicItem['image'] = entry.fields.image.fields.file.url;
                singleMosaicItem['imageAltText']=entry.fields.image.fields.title;
              }

              if (entry.fields.userHandle != undefined && entry.fields.userHandle != "") {
                singleMosaicItem['userHandle'] = entry.fields.userHandle;
              }

              if (entry.fields.description != undefined && entry.fields.description != "") {
                singleMosaicItem['description'] = entry.fields.description;
              }

              if (entry.fields.hashTags != undefined && entry.fields.hashTags != "") {
                singleMosaicItem['hashTags'] = entry.fields.hashTags;
              }

              allmosaicItem.push(singleMosaicItem);
              allmosaicItem.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              
              setMosaicItem(allmosaicItem);
              setMosaicItemLength(allmosaicItem.length);
            }
          })
          .catch(console.error);
      });
    }
  }, []);

  const renderModal = () => {
    return (
      <>
      <Modal
        size="lg"
        show={modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{backgroundColor:'transparent'}}
      >
        <Modal.Body className="gallery-modal">
          <div className="gallery-modal-inner">
            <div className="close-icon">
              <a href="#" onClick={(event)=>{event.preventDefault();setModal(false)}}><img src={require("../../images/closeModal.png")} alt="close"/></a>
            </div>
              {mosaicItem ? mosaicItem.map(
                (_item : any, key) => (
                  <>
                    {key === currentImageIndex ?
                      <>
                      <div className="left-img">
                        {_item.image ? <img src={_item.image} alt={_item.imageAltText?_item.imageAltText:""}/> : ""}
                      </div>
                      <div className="right-info">
                        <div className="gallery-head">
                          {_item.userHandle ? <h4>@{_item.userHandle}</h4> : ""}
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <div className="text-info">
                          {_item.description ? <p dangerouslySetInnerHTML={{ __html: _item.description }}></p> : ""}
                          {_item.hashTags ? _item.hashTags.map((_hashTagitem : any, tagKey :any) => (
                              <p key={tagKey}><strong>#{_hashTagitem}</strong></p>
                            )) : ""
                          }
                        </div>
                      </div>
                      </>
                      : ""
                    }
                  </>
                )
              ) : ""}
            <a href="#" className="prev-btn" onClick={(event)=>{event.preventDefault();setCurrentImageIndex(currentImageIndex>0?currentImageIndex-1:mosaicItemLength-1)}}>
              <img src={require("../../images/previous.png")} alt="previous-icon"/>
            </a>
            <a href="#" className="next-btn" onClick={(event)=>{event.preventDefault();setCurrentImageIndex(currentImageIndex>0?currentImageIndex-1:mosaicItemLength-1)}}>
              <img src={require("../../images/next.png")} alt="next-icon"/>
            </a>
          </div>
        </Modal.Body>
      </Modal>
      </>
    )
  }

  if (contentType === "") {
    return null;
  }

  if (contentType === "Content grid") {
    return(
      <>
    {
    socialTitle!==""?<div className="follow-our-chanel-block half-top-reverse">
          <div className="container">
            <div className="follow-inner" style={{backgroundColor: backgroundColor}}>
              <p className="social-items-title" style={{color: socialItemsTitleFontColor}}>{socialTitle ? socialTitle : ""}</p>
              <div className="social-block">
                <ul>
                  {socialItems
                    ? socialItems.map((_item: any, key) => {
                        return (
                          <li key={_item.index}>
                            <a href={_item.link ? addLangKeyPath(_item.link) : "#"} aria-label={_item.socialiconClass+" link"}>
                              <i className={_item.socialiconClass} aria-hidden="true"></i>
                            </a>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
        :null
   }
    <div className="grid-media-blocks">
      <div className="container">
        <div className="row custom-row">
           {mosaicItem
            ? mosaicItem.map((_item: any, index) => {
                return (
                  <div key={_item.index} className="col-lg-3 col-sm-6 col-md-4 pad-lr-10 col-6" onClick={async(event)=>{event.preventDefault();setModal(true);setCurrentImageIndex(index);}}>
                    <div className="media-box">
                      {_item.image?<img className="globe-icon" src={_item.image} alt={_item.imageAltText?_item.imageAltText:""}/>:null}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
          {instagramFeed?<div dangerouslySetInnerHTML={{ __html:instagramFeed}}></div>:null}
       
      </div>
    </div>
    {renderModal()}
    </>)
  }

  if (contentType === "Holiday Inspiration") {
    return (
      <>
      <div className="pinterest-board mt-5 mb-5" style={{backgroundColor: backgroundColor}}>
        <div className="container">
          <div className="heading-row">
              <h4>{contentType?contentType:""}</h4>
              <span><b>@saversvvillage</b> <i className="fa fa-pinterest" aria-hidden="true"></i></span>
          </div>
          <div className="row">
              {mosaicItem ? mosaicItem.map((_item:any,index)=>{
                 return( <div className="col-md-3 col-6" key={_item.index}>
                     <div className="pinterest-card">
                         <div className="media-wrap">
                             <img src={_item.image} alt={_item.imageAltText?_item.imageAltText:""}/>
                         </div>
                         <b>{title?title[index]:""}</b>
                     </div>
                 </div>)
              }):null}
          </div>
              {instagramFeed?<div dangerouslySetInnerHTML={{ __html:instagramFeed}}></div>:null}
         </div>
      </div>
      {renderModal()}
      </>
    )
  }

  /** contentType is Mosaic */
  return (
    <>
        <div className="mosaic-block" style={{backgroundColor: backgroundColor}}>
          <div className="container">
                   <div className="top-block">
                       <div className="row">
                           <div className="col-md-4">
                               <div className="logo-media">
                                 {logo?<img src={logo} width='240' height='115' alt={logoAltText?logoAltText:""}/>:null}
                               </div>
                           </div>
                           <div className="col-md-5">
                               <div className="info-block">
                                {centerDescription ? <p><ReactMarkdown allowDangerousHtml source={centerDescription} disallowedTypes={['paragraph']} unwrapDisallowed /></p> : ""}
                               </div>
                           </div>
                           <div className="col-md-3">
                               <div className="social-row">
                                   <h6 style={{color: socialItemsTitleFontColor}}>{socialTitle ? socialTitle : ""}</h6>
                                   <div className="social-block">
                                       <ul>
                                        {socialItems
                                          ? socialItems.map((_item: any, key) => {
                                              return (
                                                <li key={_item.index}>
                                                  <a href={_item.link ? addLangKeyPath(_item.link) : "#"} aria-label={_item.socialiconClass+" link"} style={{color: socialItemsTitleFontColor}}>
                                                    <i className={_item.socialiconClass} aria-hidden="true"></i>
                                                  </a>
                                                </li>
                                              );
                                            })
                                          : null}
                                       </ul>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
          <div className="cols masonry-block">
            {mosaicItem.length>0?<ul className="masonry-listing">
              {mosaicItem.length>0
                ? mosaicItem.map((_item: any, key) => {
                    if (key % 2) {
                      return (
                        <li className="masonry-item" onClick={async(event)=>{event.preventDefault();setModal(true);setCurrentImageIndex(key);}} key={_item.index}>
                          <img className="small-media" src={_item.image} alt={_item.imageAltText?_item.imageAltText:""} />
                        </li>
                      );
                    } else {
                      return (
                        <li className="masonry-item" onClick={async(event)=>{event.preventDefault();setModal(true);setCurrentImageIndex(key);}}  key={_item.index}>
                          <img className="large-media" src={_item.image} alt={_item.imageAltText?_item.imageAltText:""}/>
                        </li>
                      );
                    }
                  })
                : null}
            </ul>:null}
              {instagramFeed?<div dangerouslySetInnerHTML={{ __html:instagramFeed}}></div>:null}
          </div>
        </div>
        {renderModal()}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

MosaicBlock.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { MosaicBlock };

export default connect(mapStateToProps, mapDispatchToProps)(MosaicBlock);
