import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const TrendsGraphicalInformation: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const[reusePercentageTitle,setReusePercentageTitle]=useState('');
    const[wastePercentageTitle,setWastePercentageTitle]=useState('');
    const[rewornPercentageTitle,setRewornPercentageTitle]=useState('');
    const[rewornPercentageDescription,setRewornPercentageDescription]=useState('');//
    const[upcycledPercentageTitle,setUpcycledPercentageTitle]=useState('');
    const[upcycledPercentageDescription,setUpcycledPercentageDescription]=useState('');//
    const[reuseInformation,setReuseInformation]=useState('');//

    useEffect(()=>
    {
      

         function fetchComponentData()
         {
           if(componentData.entryId!="" && componentData.entryId!=undefined)
           {
             client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
                   console.log("TrendsGraphicalInformation",entry);
               if(entry!="" && entry!=undefined)
               {
                   if(entry.fields!="" && entry.fields!=undefined)
                   {
                       if(entry.fields.reuseInformation!="" && entry.fields.reuseInformation!=undefined)
                       {
                          const options = {
                             renderNode: {
                               [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                 return node.data.target.fields.html;
                               },
                               [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                             }
                           }
                           let rawRichText = documentToHtmlString(entry.fields.reuseInformation, options);
                              
                           setReuseInformation(rawRichText);
                       }

                       if(entry.fields.rewornPercentageDescription!="" && entry.fields.rewornPercentageDescription!=undefined)
                       {
                          const options = {
                             renderNode: {
                               [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                 return node.data.target.fields.html;
                               },
                               [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                             }
                           }
                           let rawRichText = documentToHtmlString(entry.fields.rewornPercentageDescription, options);
                              
                           setRewornPercentageDescription(rawRichText);
                       }

                       if(entry.fields.upcycledPercentageDescription!="" && entry.fields.upcycledPercentageDescription!=undefined)
                       {
                          const options = {
                             renderNode: {
                               [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                                 return node.data.target.fields.html;
                               },
                               [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                             }
                           }
                           let rawRichText = documentToHtmlString(entry.fields.upcycledPercentageDescription, options);
                              
                           setUpcycledPercentageDescription(rawRichText);
                       }
                   }
               }   
             }); 
           }     

         }
         
    fetchComponentData();

        if(componentData.reusePercentageTitle!="" && componentData.reusePercentageTitle!=undefined)
        {
            setReusePercentageTitle(componentData.reusePercentageTitle);
        }

        if(componentData.wastePercentageTitle!="" && componentData.wastePercentageTitle!=undefined)
        {
            setWastePercentageTitle(componentData.wastePercentageTitle);
        }

        if(componentData.rewornPercentageTitle!="" && componentData.rewornPercentageTitle!=undefined)
        {
            setRewornPercentageTitle(componentData.rewornPercentageTitle);
        }

        if(componentData.upcycledPercentageTitle!="" && componentData.upcycledPercentageTitle!=undefined)
        {
            setUpcycledPercentageTitle(componentData.upcycledPercentageTitle);
        }

    },[])
    return (
    <>
        <div className="small-container">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-4 xs-no-space">
              <div className="portrait-graph">
                <div className="head">
                  {reusePercentageTitle?<div className="top-left">
                    <span className="wow slideInUp" data-wow-duration="2s" data-wow-delay=".5s"></span>
                   <div className="inner">
                      <p>{reusePercentageTitle}</p>
                    </div>
                  </div>:null}
                 
                 {wastePercentageTitle?<div className="top-right">
                    <span className="wow slideInUp" data-wow-duration="2s" data-wow-delay=".5s"></span>
                    <div className="inner wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
                      <p dangerouslySetInnerHTML={{__html:wastePercentageTitle}}></p>
                    </div>                     </div>:null}                   </div>                   {reuseInformation?<div className="bottom">
                  <span className="line"></span>
                  <p dangerouslySetInnerHTML={{__html:reuseInformation}}></p>
                </div>:null}                 </div>
            </div>
            <div className="col-12 col-md-7 col-lg-8 xs-no-space">
              <div className="imapct-right-trends portrait">
                <div className="graph small blue" style={{ marginTop: "0px" }}>
                  {rewornPercentageTitle?<div className="graph-icon">
                    <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                    </div>
                    <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">
                      <h3>{rewornPercentageTitle}</h3>
                    </div>
                  </div>:null}                     {rewornPercentageDescription?<div className="graph-info">
                    <span className="line"></span>
                    <p dangerouslySetInnerHTML={{__html:rewornPercentageDescription}}></p>
                  </div>:null}                   </div>
                <div className="graph small orange">
                  {upcycledPercentageTitle?<div className="graph-icon">
                    <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="2s">
                    </div>
                    <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
                      <h3>{upcycledPercentageTitle}</h3>
                    </div>
                  </div>:null}                     {upcycledPercentageDescription?<div className="graph-info">
                    <span className="line"></span>
                    <p dangerouslySetInnerHTML={{__html:upcycledPercentageDescription}}></p>
                  </div>:null}                   
                </div>                 
              </div>               
             </div>
          </div>
        </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TrendsGraphicalInformation.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TrendsGraphicalInformation };

export default connect(mapStateToProps, mapDispatchToProps)(TrendsGraphicalInformation);
