import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const AboutInfo: React.FC<any> = (props) => {
    return (
    <>
        <section className="about-info">
           <div className="container">
                <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2">
                            <p>Since our humble thrift shop beginnings, we’ve come a long way in the secondhand space. We’ve grown from a single shop to a global reuse
                champion—and it’s all because of you.</p>               

                <p>So, what does it mean to be Thrift Proud?  </p>
                <h4>It’s the thrill of finding “this is exactly what I need,” 
                the “why not” and “where’d you get that?!” 
                It’s one-of-a-kind finds for one-of-a-kind peopale. 
                It’s reusing, repurposing and upcycling goods for the greater good, helping save the planet while saving a few bucks. This is thrift.</h4>
                <p>But enough about us. Thrift is what you make it. You’ve turned secondhand goods into first-rate fashion and made pre-loved looks feel pretty unbelievable. While we’re honored to fill our racks with one-of-a-kind finds, they don’t really come to life until you make them yours. So, thank you for shopping, styling, and redefining what it means to shop secondhand. </p>              

                <p>We couldn’t be more Thrift Proud—and hope you are, too.  </p>                

                <img src="http://45.40.138.118/savers/dev/images/thrift-logo.png" />
                            </div>
                        </div>
            </div>
        </section> 
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

AboutInfo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { AboutInfo };

export default connect(mapStateToProps, mapDispatchToProps)(AboutInfo);
