import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";


const MenuLinksComponent: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [menuLink, setMenuLink] = useState([]);
  const [menuLinkLength, setMenuLinkLength] = useState(0);
  const [hideInDesktop, setHideInDesktop] = useState(false);

  useEffect(() => {

    if (componentData.hideInDesktop !== undefined && componentData.hideInDesktop !== '') {
      setHideInDesktop(componentData.hideInDesktop);
    }

    if (componentData.menuLink !== undefined && componentData.menuLink !== '') {
      let allMenuLinks: any = [];

      componentData.menuLink.map(async (item: any, i: any) => {
        if (item.sys.id !== undefined && item.sys.id !== "") {
          let singleMenuLink = {
            index: i,
            title: "",
            alias: "",
            menuItemIcon: "",
            menuItemAltText: "",
          };

          await client
            .getEntry(item.sys.id, {locale: currLocale})
            .then(async (entry: any) => {
              if (entry != undefined && entry != "") {

                if (entry.fields.title != undefined && entry.fields.title != "") {
                  singleMenuLink["title"] = entry.fields.title;
                }

                if (entry.fields.alias != undefined && entry.fields.alias != "") {
                  singleMenuLink["alias"] = entry.fields.alias;
                }

                if (entry.fields.menuItemIcon != undefined && entry.fields.menuItemIcon != "") {
                  let fileUrl = entry.fields.menuItemIcon.fields.file.url;
                  singleMenuLink["menuItemAltText"]= entry.fields.menuItemIcon.fields.title;
                  singleMenuLink["menuItemIcon"] = fileUrl;
                }

                allMenuLinks.push(singleMenuLink);
                allMenuLinks.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                
                setMenuLink(allMenuLinks);
                setMenuLinkLength(allMenuLinks.length);
              }
            })
            .catch(console.error);
        }
      });
    }
  }, []);

  return (
    <>
      <div className={hideInDesktop ? "menu-links-component desktop-hide" : "menu-links-component"}>
        <ul className="listing-row">
          {menuLinkLength > 0 ?
            menuLink.map((item: any, i) => (
              <>{item.title ? <li>{item.menuItemIcon ? <img src={item.menuItemIcon} width = '18' height = '18' alt={item.menuItemAltText?item.menuItemAltText:""}/> : ""}<a href={addLangKeyPath(item.alias)}>{item.title}</a></li> : ""}</>
            ))
            : ""
          }
        </ul>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

MenuLinksComponent.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { MenuLinksComponent };

export default connect(mapStateToProps, mapDispatchToProps)(MenuLinksComponent);