import React, { useEffect } from "react";
import { connect } from "react-redux";
import { gigyaLogin, gigyaJoin, gigyaLogOut } from "../../actions/auth";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";

const HeroCardLargeTwo: React.FC<any> = ({ fetchSiteData, header }) => {
  useEffect(() => {
    fetchdata();
    async function fetchdata() {
      const data = await fetchSiteData();
      console.log("datasss", data);
    }
  }, [fetchSiteData]);

  const {
    site_logo,
    storeText,
    storeTextMobile,
    searchText,
    HeaderOptions,
    menuItems,
  } = header;

  return (
    <div className="hero-card hero-card-large">
      <div className="container">
        <div className="card-inner bg-grey">
          <div className="row">
            <div className="col-md-6 hero-media-info">
              <h3>The 81lb challenge</h3>
              <p>
                We challenged designers to take 81 pounds of items that never
                sold from our stores and create an entire collection. All in one
                month.
              </p>
              <a href="#" className="small-text-cta cta-black  bold">
                View the entire show{" "}
                <i className="fa fa-caret-right" aria-hidden="true"></i>
              </a>
            </div>
            <div className="col-md-6 hero-media">
              <img src={require("../../images/hero-media4.jpg")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
  onLogIn: () => dispatch(gigyaLogin()),
  onLogOut: () => dispatch(gigyaLogOut()),
  onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

HeroCardLargeTwo.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { HeroCardLargeTwo };

export default connect(mapStateToProps, mapDispatchToProps)(HeroCardLargeTwo);
