import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ComponentComposer from "../../components/ComponentComposer";

const TwoColumnStructure: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState('');
    const [leftSideTitle, setLeftSideTitle] = useState('');
    const [leftSideDescription, setLeftSideDescription] = useState('');
    const [rightSideImage, setRightSideImage] = useState('');
    const [rightSideContent, setRightSideContent] = useState('');
    const [rightSideBackgroundColor, setRightSideBackgroundColor] = useState('');
    const[testimonialsTitle,setTestimonialsTitle]=useState("");
    const[testimonialItems,setTestimonialItems]=useState([]);
    const[testimonialItemsLength,setTestimonialItemsLength]=useState(0);
    const[mainHeading,setMainHeading]=useState('');
    const[sourceLogo,setSourceLogo]=useState('');
    const[sourceLogoAltText,setSourceLogoAltText]=useState('');
    const[specialClass,setSpecialClass]=useState('');
    const[timelineBackground,setTimelineBackground]=useState(false);
    const[leftSideTitleLines,setLeftSideTitleLines]=useState("");
    const[titleClass,setTitleClass]=useState(""); 
    const [singleComponent, setSingleComponent] = useState({});

    const slickSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ]
  };
    useEffect(() => {

      if (componentData.leftSideTitleLines != undefined && componentData.leftSideTitleLines != "") {
        setLeftSideTitleLines(componentData.leftSideTitleLines);
        if(componentData.leftSideTitleLines == "Title In One Line")
        {
          setTitleClass("style1");       
        }
        else if(componentData.leftSideTitleLines == "Title In Two Line")
        {
          setTitleClass("style2");          
        }
        else if(componentData.leftSideTitleLines == "Title In Three Line")
        {
          setTitleClass("style3");          
        }
        else if(componentData.leftSideTitleLines == "Title In Four Line")
        {
          setTitleClass("style4");         
        }       
        
      }

     
    function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {          
          if(entry.fields.leftSideTitle!="" && entry.fields.leftSideTitle!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width = '240' height  = '115' alt="${node.data.target.fields.title}"/>`,
                   [INLINES.ASSET_HYPERLINK]:(node: any) => `<a href="${node.data.target.fields.file.url}" target="_blank">${node.data.target.fields.title}</a> `
                }
              }
              let rawRichLeftSideTitle = documentToHtmlString(entry.fields.leftSideTitle, options);
                 
              setLeftSideTitle(rawRichLeftSideTitle);
          }   
          if(entry.fields.leftSideDescription!="" && entry.fields.leftSideDescription!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width = '240' height  = '115' alt="${node.data.target.fields.title}"/>`,
                  [INLINES.ASSET_HYPERLINK]:(node: any) => `<a href="${node.data.target.fields.file.url}" target="_blank">${node.data.target.fields.title}</a> `
                }
              }
              let rawRichLeftSideDescription = documentToHtmlString(entry.fields.leftSideDescription, options);
                 
              setLeftSideDescription(rawRichLeftSideDescription);
          }

        }); 
      }
      

      if(componentData.rightSideComponents!="" && componentData.rightSideComponents!=undefined)
      {
         let allComponents:any=[];
         componentData.rightSideComponents.map(async(item:any,i:any)=>
         { 
             if(item.sys.id!="" && item.sys.id!=undefined)
             {
              await client.getEntry(item.sys.id, {locale: currLocale}).then(async(entry : any) => {

                let index = i;
                let componentName = entry.sys.contentType.sys.id;
                let entryId = entry.sys.id;
                  
                let data={entryId:""};
                if(entry.fields!="" && entry.fields!=undefined)
                {
                  data= entry.fields;
                }
                data.entryId = entryId;
                let singleComponent = {index, componentName, data};
                await allComponents.push(singleComponent);
                allComponents.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                await setData(allComponents);
                await setDataLength(allComponents.length);
              });
             }
         });
      }

       if(componentData.mainHeading!="" && componentData.mainHeading!=undefined)
      {
        setMainHeading(componentData.mainHeading);
      }
       
      if(componentData.sourceLogo!="" && componentData.sourceLogo!=undefined)
      {
        let fileUrl= componentData.sourceLogo.fields.file.url;
        setSourceLogo(fileUrl);
        setSourceLogoAltText(componentData.sourceLogo.fields.title);
      }    
      if(componentData.specialClass!="" && componentData.specialClass!=undefined)
      {
        setSpecialClass(componentData.specialClass);
      } 
      if(componentData.timelineBackground!="" && componentData.timelineBackground!=undefined)
      {
        setTimelineBackground(componentData.timelineBackground);
      }   

    }
    
    fetchComponentData();
  
       
    },[]);  
    return (
    <div className="impact-main-wrapper">
    <section className={specialClass!=""?specialClass:timelineBackground?"impact-timeline-bg overflow-hidden":" overflow-hidden"}> 
        <div className="impact-container">
          {mainHeading!=""||sourceLogo!=""?
              <div className="row">
                {mainHeading?<div className="impact-main-full">
                  <h1 id="big-heading" className="solid-heading left big-heading" dangerouslySetInnerHTML={{__html:mainHeading}}>
                    </h1>
                </div>:null}

                {sourceLogo?<div className="impact-main-full source-logo">
                  <img src={sourceLogo} width = '100' height = '82' alt={sourceLogoAltText?sourceLogoAltText:""}/>
                </div>:null}

              </div>:null}
          <div className="row">
            <div className="impact-main-left" >
              <div className={"impact-left-inner " + titleClass}>
                {leftSideTitle?<h1 className="solid-heading left" dangerouslySetInnerHTML={{ __html:leftSideTitle}}></h1>:null}
                <div className="clearfix"></div>
                {leftSideDescription?<div dangerouslySetInnerHTML={{ __html:leftSideDescription}}></div>:null}
              </div>
            </div> 

            <div className="impact-main-right">
              { data && data.length>0 && data.map((d, i) =>{
              return(<ComponentComposer  key={i} {...d} />)
            })} 
            </div>

          </div>
        </div>
      </section>
    </div>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColumnStructure.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColumnStructure };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnStructure);