import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import Cookies from "universal-cookie";
import { getLocale } from "../../api/cms/api";
import { getDomain } from "../../api/cms/api";
import { useLocation, useHistory } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { addLangKeyPath } from "../../api/cms/api";
import {
  getGlobalData,
  getHrefLang,
  getDefaultLanguage,
  getLocationApiKey,
} from "../../api/cms/api";
import Helmet from "react-helmet";
import { Modal } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import SearchInput from "../SearchInput";
import FindStore from "../SearchInput/FindStore";
import { css } from "@emotion/react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { get } from "lodash";

const cookies = new Cookies();
const HeaderThrift: React.FC<any> = (props) => {
  const locationApiKey = getLocationApiKey();
  const defaultLanguage = getDefaultLanguage();
  const location = useLocation();
  const search = location.search;
  let history = useHistory();
  const currDomain = getDomain();

  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  const langCookieKey = cookies.get("Language").toUpperCase();

  const globalData = getGlobalData();
  const storeUrl = globalData.storeUrl;
  const storeSite = globalData.storeSite;
  const countryCode = globalData.countryCode;

  const is2ndAve = storeSite === "2a";

  const nondisclosureMsgCookie = cookies.get("nondisclosure_message");
  let expandOpts: any = {};
  let minimizeOpts: any = {};

  if (nondisclosureMsgCookie === "minimize") {
    expandOpts["display"] = "none";
  } else {
    minimizeOpts["display"] = "none";
  }
  const [mobileDropdownLabel, _setMobileDropdownLabel] = useState("");
  const setMobileDropdownLabel = (label: string) => {
    let returnLabel = label;
    if (returnLabel.includes("&reg;")) {
      returnLabel = returnLabel.slice(0, -5);
      if (returnLabel.length > 15) {
        returnLabel = returnLabel.slice(0, 15) + "...";
      }
      returnLabel = returnLabel + "®";
    } else if (returnLabel.includes("<sup>TM</sup>")) {
      returnLabel = returnLabel.slice(0, -13);
      if (returnLabel.length > 15) {
        returnLabel = returnLabel.slice(0, 15) + "...";
      }
      returnLabel = returnLabel + "™";
    }

    _setMobileDropdownLabel(returnLabel);
  };
  const [topHeaderBar, setTopHeaderBar] = useState("");
  const [siteLogo, setSiteLogo] = useState("");
  const [siteLogoAltText, setSiteLogoAltText] = useState("");
  const [siteMainMenu, setSiteMainMenu] = useState([]);
  const [siteMainMenuLength, setSiteMainMenuLength] = useState(0);
  const [topMenu, setTopMenu] = useState([]);
  const [topMenuLength, setTopMenuLength] = useState(0);
  const [thriftProudNavigation, setThriftProudNavigation] = useState([]);
  const [thriftProudNavigationLength, setThriftProudNavigationLength] =
    useState(0);
  const [searchText, setSearchText] = useState("");
  const [storeText, setStoreText] = useState("");
  const [mobileStoreText, setMobileStoreText] = useState("");
  const [currLang, setCurrLang] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [path, setPath] = useState("");
  const [storeLocation, setStoreLocation] = useState([]);
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState("Search");
  const [searchPlaceholderText, setSearchPlaceholderText] = useState("");
  const [dynamicClassName, setDynamicClassName] = useState("");
  const [expandedText, setExpandedText] = useState("");
  const [minimizedText, setMinimizedText] = useState("");
  const [thriftTextLogo, setThriftTextLogo] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [gtagId, setGtagId] = useState("");
  const [gtagAdditionalCode, setGtagAdditionalCode] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [activeThriftorialClass, setActiveThriftorialClass] = useState(false);
  const [activeCommunityClass, setActiveCommunityClass] = useState(false);
  const [temporaryModalDescription, setTemporaryModalDescription] =
    useState("");
  const [temporaryModalBg, setTemporaryModalBg] = useState("");
  const [temporaryModalIcon, setTemporaryModalIcon] = useState("");
  const [showCountryPopup, setShowCountryPopup] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [countryLangToggle, setCountryLangToggle] = useState(false);
  const [
    automaticallyRedirectCaUsersFromVvusToVvca,
    setAutomaticallyRedirectCaUsersFromVvusToVvca,
  ] = useState(false);
  const [allLinksArray, setAllLinksArray] = useState([]);
  const [staticLinksArray, setStaticLinksArray] = useState([]);
  const [categoryIsOpen, setCategoryIsOpen] = useState(false);
  const [mobileCategoryIsOpen, setMobileCategoryIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);
  const [searchMagnifyingGlassColor, setSearchMagnifyingGlassColor] =
    useState("#E1251B");
  const [searchInlineTextColor, setSearchInlineTextColor] = useState("#000000");
  const [topPromoBarColor, setTopPromoBarColor] = useState("#004E7D");
  const [thriftNavLinkColor, setThriftNavLinkColor] = useState("#000");
  const [thriftNavUnderlineColor, setThriftNavUnderlineColor] =
    useState("#E1251B");
  const [siteMainMenuColor, setSiteMainMenuColor] = useState("#E1251B");
  const [hideSearchPills, setHideSearchPills] = useState(false);
  const [embededHtml, setEmbededHtml] = useState("");
  const [embededHtmlExpanded, setEmbededHtmlExpanded] = useState("");

  let date = new Date(); // Now
  date.setDate(date.getDate() + 30);

  const getLanguage = () => {
    let pathArray = window.location.pathname.split("/");

    if (pathArray[1] !== undefined && pathArray[1] === "en") {
      return "en";
    } else if (pathArray[1] !== undefined && pathArray[1] === "fr") {
      return "fr";
    } else if (pathArray[1] !== undefined && pathArray[1] === "es") {
      return "es";
    } else if (
      cookies.get("Language") !== undefined &&
      cookies.get("Language") !== ""
    ) {
      return cookies.get("Language");
    }

    return "en";
  };

  useEffect(() => {
    const currLocale = getLocale();

    // Get gray top bar disclosure embeded html
    let headerEntryId = props.headerEntryId;
    let disclosure_id = "";

    if (headerEntryId !== "" && headerEntryId !== undefined) {
      client
        .getEntry(headerEntryId, { locale: currLocale })
        .then((entry: any) => {
          let allFieldsData = entry.fields;
          if (
            allFieldsData.topHeaderBarBackgroundColor !== undefined &&
            allFieldsData.topHeaderBarBackgroundColor !== ""
          ) {
            setTopPromoBarColor(allFieldsData.topHeaderBarBackgroundColor);
          }
          if (
            allFieldsData.siteMainMenuBackgroundColor !== undefined &&
            allFieldsData.siteMainMenuBackgroundColor !== ""
          ) {
            setSiteMainMenuColor(allFieldsData.siteMainMenuBackgroundColor);
          }
          if (
            allFieldsData.nondisclosureMessage !== undefined &&
            allFieldsData.nondisclosureMessage !== "" &&
            "target" in
              allFieldsData.nondisclosureMessage[0].fields.minimizedText
                .content[1].data
          ) {
            disclosure_id =
              allFieldsData.nondisclosureMessage[0].fields.minimizedText
                .content[1].data.target.sys.id;
          }
          return disclosure_id;
        })
        .then((entry: any) => {
          if (disclosure_id !== "") {
            client
              .getEntry(disclosure_id, { locale: currLocale })
              .then((entry: any) => {
                //console.log("TEST:", entry)
                return entry.fields.html;
              })
              .then((html: any) => {
                setEmbededHtml(html);
              });
          }
        });
    }

    // Get EXPANDED gray top bar disclosure embeded html
    let disclosure_id_exp = "";

    if (headerEntryId !== "" && headerEntryId !== undefined) {
      client
        .getEntry(headerEntryId, { locale: currLocale })
        .then((entry: any) => {
          let allFieldsData = entry.fields;
          if (
            allFieldsData.nondisclosureMessage !== undefined &&
            allFieldsData.nondisclosureMessage !== "" &&
            "target" in
              allFieldsData.nondisclosureMessage[0].fields.expandedText
                .content[1].data
          ) {
            disclosure_id_exp =
              allFieldsData.nondisclosureMessage[0].fields.expandedText
                .content[1].data.target.sys.id;
            //console.log("nondisclosureMessageExp", disclosure_id_exp);
          }
          return disclosure_id_exp;
        })
        .then((entry: any) => {
          if (disclosure_id_exp !== "") {
            client
              .getEntry(disclosure_id_exp, { locale: currLocale })
              .then((entry: any) => {
                return entry.fields.html;
              })
              .then((html: any) => {
                setEmbededHtmlExpanded(html);
              });
          }
        });
    }
    $(".storeAutoCompleteId div input").attr("aria-label", "Find a store");
    // $(".storeAutoCompleteId div input").attr("aria-required","true");

    const values: any = getHrefLang(props.allAliases);

    if (!document.referrer) {
      if (
        cookies.get("Language") !== undefined ||
        cookies.get("Language") !== ""
      ) {
        let cookieLang = cookies.get("Language");

        if (cookieLang !== defaultLanguage.toLowerCase()) {
          let _pathArray = window.location.pathname.split("/");

          if (_pathArray[1] === "") {
            let path = "/" + cookieLang;
            history.push(path);
          } else if (_pathArray[1] === cookieLang) {
            let path = window.location.pathname + search;
            history.push(path);
          } else if (_pathArray[1] !== cookieLang) {
            let path = "/" + cookieLang + window.location.pathname + search;
            history.push(path);
          }
        }
      }
    }

    if (values !== undefined && values.length > 0) {
      if (values[0] !== undefined && values[0].length > 0) {
        setAllLinksArray(values[0]);
      }
      if (values[1] !== undefined && values[1].length > 0) {
        setStaticLinksArray(values[1]);
      }
    }

    function getGeoInfo() {
      if (countryName === "") {
        axios
          .get("https://ipapi.co/json?key=" + locationApiKey)
          .then((response) => {
            let data = response.data;
            if (data.country_name !== undefined && data.country_name !== "") {
              setCountryName(data.country_name.toLowerCase());
            }
          })
          .catch((error) => {
            console.log("location api error" + error);
          });
      }
    }

    if (
      hostname === "valuevillage.com" ||
      hostname === "www.valuevillage.com" ||
      hostname === "valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname === "www.valuevillagecom-react-test.metaltoad-sites.com" ||
      hostname === "valuevillagecom-react-dev.metaltoad-sites.com" ||
      hostname === "www.valuevillagecom-react-dev.metaltoad-sites.com"
    ) {
      getGeoInfo();
    }

    if (
      countryName === "canada" &&
      (hostname === "valuevillage.com" ||
        hostname === "www.valuevillage.com" ||
        hostname === "www.valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname === "valuevillagecom-react-test.metaltoad-sites.com" ||
        hostname === "valuevillagecom-react-dev.metaltoad-sites.com" ||
        hostname === "www.valuevillagecom-react-dev.metaltoad-sites.com")
    ) {
      if (!document.referrer) {
        if (
          cookies.get("redirect_vvca") === undefined ||
          cookies.get("redirect_vvca") === ""
        ) {
          cookies.set("redirect_vvca", "true", { path: "/", expires: date });
        } else {
          cookies.set("redirect_vvca", "true", { path: "/", expires: date });
        }
      } else {
        let prevHostname = new URL(document.referrer).hostname;
        const charlist = "/";
        prevHostname = prevHostname.replace(
          new RegExp("[" + charlist + "]+$"),
          ""
        );

        if (
          prevHostname === "valuevillage.ca" ||
          prevHostname === "www.valuevillage.ca" ||
          prevHostname === "valuevillageca-react-dev.metaltoad-sites.com" ||
          prevHostname === "www.valuevillageca-react-dev.metaltoad-sites.com" ||
          prevHostname === "valuevillageca-react-test.metaltoad-sites.com" ||
          prevHostname === "www.valuevillageca-react-test.metaltoad-sites.com"
        ) {
          cookies.set("redirect_vvca", "false", { path: "/", expires: date });
        }
      }
    }

    const location = window.location.pathname;
    let pathArray = location.split("/");
    const pattern = /[a-z]/;
    let localized_switch = false;
    let currPathAlias = "";
    if (
      pathArray[1] !== undefined &&
      pathArray[1].match(pattern) &&
      pathArray[1].length < 3
    ) {
      localized_switch = true;

      pathArray = pathArray.filter(function (el) {
        return el !== "";
      });
      pathArray.shift();
      currPathAlias = "/" + pathArray.join("/");
    } else {
      pathArray = pathArray.filter(function (el) {
        return el !== "";
      });
      currPathAlias = "/" + pathArray.join("/");
    }

    if (currPathAlias.includes("thrift-proud/community")) {
      setActiveCommunityClass(true);
    } else if (currPathAlias.includes("thrift-proud/thriftorials")) {
      setActiveThriftorialClass(true);
    }

    if (options.length < 1) {
      client
        .getEntries({
          content_type: "languageToggle",
          "fields.domains": globalData.localeDomain,
          locale: currLocale,
        })
        .then(async (response: any) => {
          if (response.items.length > 0) {
            let optionsArray: any = [];
            response.items.map(async (data: any) => {
              if (data.fields !== undefined || data.fields !== "") {
                if (
                  data.fields.alias !== undefined &&
                  data.fields.alias !== ""
                ) {
                  if (data.fields.flagImage !== undefined) {
                    let toggleLangKey = data.fields.mappingLanguage
                      ? data.fields.mappingLanguage
                      : "";
                    let toggleLangLabel = data.fields.alias
                      ? data.fields.alias
                      : "";
                    let flagIcon = data.fields.flagImage
                      ? data.fields.flagImage.fields.file.url
                      : "";
                    let redirectLink = data.fields.redirectUrl
                      ? data.fields.redirectUrl
                      : "";
                    let itemOrder = data.fields.toggleItemOrder
                      ? data.fields.toggleItemOrder
                      : 0;
                    optionsArray.push({
                      langKey: toggleLangKey,
                      langLabel: toggleLangLabel,
                      icon: flagIcon,
                      link: redirectLink,
                      index: itemOrder,
                    });
                    optionsArray.sort((a: any, b: any) =>
                      a.index > b.index ? 1 : -1
                    );
                    await setOptions(optionsArray);
                    setCountryLangToggle(true);
                  } else {
                    optionsArray.push({
                      [data.fields.alias]: data.fields.alias,
                    });
                    optionsArray.sort((a: any, b: any) =>
                      a.index > b.index ? 1 : -1
                    );
                    await setOptions(optionsArray);
                  }
                }
              }
            });
          }
        });
    }

    function setDynamicClass() {
      let val = cookies.get("Language");
      if (val === "en") {
        setDynamicClassName("lang-en");
      } else {
        setDynamicClassName("lang-fr");
      }
    }
    setDynamicClass();

    setPath(window.location.pathname);

    headerEntryId = props.headerEntryId;

    client
      .getEntry(headerEntryId, { locale: currLocale })
      .then((entry: any) => {
        if (entry !== undefined && entry !== "") {
          let allFieldsData = entry.fields;

          if (
            allFieldsData.topHeaderBarBackgroundColor !== undefined &&
            allFieldsData.topHeaderBarBackgroundColor !== ""
          ) {
            setTopPromoBarColor(allFieldsData.topHeaderBarBackgroundColor);
          }

          if (
            allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca !==
              undefined &&
            allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca !== ""
          ) {
            setAutomaticallyRedirectCaUsersFromVvusToVvca(
              allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca
            );
            if (
              countryName === "canada" &&
              (hostname === "valuevillage.com" ||
                hostname === "www.valuevillage.com" ||
                hostname ===
                  "www.valuevillagecom-react-test.metaltoad-sites.com" ||
                hostname === "valuevillagecom-react-test.metaltoad-sites.com" ||
                hostname === "valuevillagecom-react-dev.metaltoad-sites.com" ||
                hostname ===
                  "www.valuevillagecom-react-dev.metaltoad-sites.com")
            ) {
              if (
                allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ===
                false
              ) {
                if (
                  cookies.get("country_popup") === undefined ||
                  cookies.get("country_popup") === ""
                ) {
                  setShowCountryPopup(true);
                }
              } else if (
                allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ===
                  true &&
                (cookies.get("redirect_vvca") === "true" ||
                  cookies.get("redirect_vvca") === undefined ||
                  cookies.get("redirect_vvca") === "")
              ) {
                if (hostname === "valuevillage.com") {
                  window.location.href = "https://www.valuevillage.ca/";
                } else if (
                  hostname ===
                    "valuevillagecom-react-test.metaltoad-sites.com" ||
                  hostname ===
                    "www.valuevillagecom-react-test.metaltoad-sites.com"
                ) {
                  window.location.href =
                    "http://valuevillageca-react-test.metaltoad-sites.com";
                } else if (
                  hostname ===
                    "valuevillagecom-react-dev.metaltoad-sites.com" ||
                  hostname ===
                    "www.valuevillagecom-react-dev.metaltoad-sites.com"
                ) {
                  window.location.href =
                    "http://valuevillageca-react-dev.metaltoad-sites.com";
                }
              }
            }
          }

          if (
            allFieldsData.automaticallyRedirectCaUsersFromVvusToVvca ===
            undefined
          ) {
            if (
              countryName === "canada" &&
              (hostname === "valuevillage.com" ||
                hostname === "www.valuevillage.com")
            ) {
              if (
                cookies.get("country_popup") === undefined ||
                cookies.get("country_popup") === ""
              ) {
                setShowCountryPopup(true);
              }
            }
          }

          // if(allFieldsData.mobileDropdownLabel!==undefined && allFieldsData.mobileDropdownLabel!=="")
          // {
          //   setMobileDropdownLabel(allFieldsData.mobileDropdownLabel);
          //   console.log("allFieldsData.mobileDropdownLabel", allFieldsData.mobileDropdownLabel)
          // }

          if (
            allFieldsData.gtagId !== undefined &&
            allFieldsData.gtagId !== ""
          ) {
            setGtagId(allFieldsData.gtagId);
          }
          if (
            allFieldsData.gtagAdditionalCode !== undefined &&
            allFieldsData.gtagAdditionalCode !== ""
          ) {
            setGtagAdditionalCode(allFieldsData.gtagAdditionalCode);
          }

          if (
            allFieldsData.topHeaderBars !== undefined &&
            allFieldsData.topHeaderBars !== ""
          ) {
            $.each(allFieldsData.topHeaderBars, function (i, topHeaderBarData) {
              if (
                topHeaderBarData.fields.domains !== undefined &&
                topHeaderBarData.fields.domains !== ""
              ) {
                if (topHeaderBarData.fields.domains.includes(currDomain)) {
                  setTopHeaderBar(topHeaderBarData.fields.html);
                  return false;
                }
              }
            });
          }
          if (
            allFieldsData.thriftTextLogo !== undefined &&
            allFieldsData.thriftTextLogo !== ""
          ) {
            setThriftTextLogo(allFieldsData.thriftTextLogo);
          }

          if (
            allFieldsData.siteLogos !== undefined &&
            allFieldsData.siteLogos !== ""
          ) {
            let fileUrl = "";
            let altText = "";
            $.each(allFieldsData.siteLogos, function (i, siteLogoData) {
              if (siteLogoData.fields.domains !== undefined) {
                if (siteLogoData.fields.domains.includes(currDomain)) {
                  fileUrl = siteLogoData.fields.image.fields.file.url;
                  altText = siteLogoData.fields.image.fields.title;
                  return false;
                }
              } else {
                fileUrl = siteLogoData.fields.image.fields.file.url;
                altText = siteLogoData.fields.image.fields.title;
              }
            });
            document.getElementsByTagName("meta")[4].content = fileUrl;
            setSiteLogo(fileUrl);
            setSiteLogoAltText(altText);
          }

          if (
            allFieldsData.siteMainMenu !== undefined &&
            allFieldsData.siteMainMenu !== ""
          ) {
            let allSiteMainMenu: any = [];
            $.each(allFieldsData.siteMainMenu, function (i, value) {
              if (value.fields.domains !== undefined) {
                if (value.fields.domains.includes(currDomain)) {
                  let singleLink = { title: "", alias: "" };

                  if (
                    value.fields.title !== undefined &&
                    value.fields.title !== ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias !== undefined &&
                    value.fields.alias !== ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  allSiteMainMenu.splice(i, 0, singleLink);
                  setSiteMainMenu(allSiteMainMenu);
                  setSiteMainMenuLength(allSiteMainMenu.length);
                }
              } else {
                let singleLink = { title: "", alias: "" };

                if (
                  value.fields.title !== undefined &&
                  value.fields.title !== ""
                ) {
                  singleLink["title"] = value.fields.title;
                }

                if (
                  value.fields.alias !== undefined &&
                  value.fields.alias !== ""
                ) {
                  singleLink["alias"] = value.fields.alias;
                }

                allSiteMainMenu.splice(i, 0, singleLink);
                setSiteMainMenu(allSiteMainMenu);
                setSiteMainMenuLength(allSiteMainMenu.length);
              }
            });
          }

          if (
            allFieldsData.topMenu !== undefined &&
            allFieldsData.topMenu !== ""
          ) {
            let allTopMenu: any = [];
            $.each(allFieldsData.topMenu, function (i, value) {
              if (value.fields.domains !== undefined) {
                if (value.fields.domains.includes(currDomain)) {
                  let singleLink = { title: "", alias: "", menuItemIcon: "" };

                  if (
                    value.fields.title !== undefined &&
                    value.fields.title !== ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias !== undefined &&
                    value.fields.alias !== ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  if (
                    value.fields.menuItemIcon !== undefined &&
                    value.fields.menuItemIcon !== ""
                  ) {
                    let fileUrl = value.fields.menuItemIcon.fields.file.url;
                    singleLink["menuItemIcon"] = fileUrl;
                  }

                  allTopMenu.splice(i, 0, singleLink);
                  setTopMenu(allTopMenu);
                  setTopMenuLength(allTopMenu.length);
                }
              } else {
                let singleLink = { title: "", alias: "", menuItemIcon: "" };

                if (
                  value.fields.title !== undefined &&
                  value.fields.title !== ""
                ) {
                  singleLink["title"] = value.fields.title;
                }

                if (
                  value.fields.alias !== undefined &&
                  value.fields.alias !== ""
                ) {
                  singleLink["alias"] = value.fields.alias;
                }

                if (
                  value.fields.menuItemIcon !== undefined &&
                  value.fields.menuItemIcon !== ""
                ) {
                  let fileUrl = value.fields.menuItemIcon.fields.file.url;
                  singleLink["menuItemIcon"] = fileUrl;
                }

                allTopMenu.splice(i, 0, singleLink);
                setTopMenu(allTopMenu);
                setTopMenuLength(allTopMenu.length);
              }
            });
          }

          if (
            allFieldsData.thriftProudNavigation !== undefined &&
            allFieldsData.thriftProudNavigation !== ""
          ) {
            let allThriftProudNavigation: any = [];
            $.each(allFieldsData.thriftProudNavigation, function (i, value) {
              if (value.fields.domains !== undefined) {
                if (value.fields.domains.includes(currDomain)) {
                  let singleLink = { title: "", alias: "", menuItemIcon: "" };

                  if (
                    value.fields.title !== undefined &&
                    value.fields.title !== ""
                  ) {
                    singleLink["title"] = value.fields.title;
                  }

                  if (
                    value.fields.alias !== undefined &&
                    value.fields.alias !== ""
                  ) {
                    singleLink["alias"] = value.fields.alias;
                  }

                  if (
                    value.fields.menuItemIcon !== undefined &&
                    value.fields.menuItemIcon !== ""
                  ) {
                    let fileUrl = value.fields.menuItemIcon.fields.file.url;
                    singleLink["menuItemIcon"] = fileUrl;
                  }

                  allThriftProudNavigation.splice(i, 0, singleLink);
                }
              } else {
                let singleLink = { title: "", alias: "", menuItemIcon: "" };

                if (
                  value.fields.title !== undefined &&
                  value.fields.title !== ""
                ) {
                  singleLink["title"] = value.fields.title;
                }

                if (
                  value.fields.alias !== undefined &&
                  value.fields.alias !== ""
                ) {
                  singleLink["alias"] = value.fields.alias;
                }

                if (
                  value.fields.menuItemIcon !== undefined &&
                  value.fields.menuItemIcon !== ""
                ) {
                  let fileUrl = value.fields.menuItemIcon.fields.file.url;
                  singleLink["menuItemIcon"] = fileUrl;
                }

                allThriftProudNavigation.splice(i, 0, singleLink);
              }
            });

            let location = window.location.pathname;
            if (location.split("/").length === 4) {
              location =
                "/" + location.split("/")[2] + "/" + location.split("/")[3];
            }
            const findItem = (item: any) => item["alias"] === location;
            const activePage = allThriftProudNavigation.find((item: any) =>
              findItem(item)
            );
            const label = get(activePage, "title", "");
            setMobileDropdownLabel(label);
            setThriftProudNavigation(allThriftProudNavigation);
            setThriftProudNavigationLength(allThriftProudNavigation.length);
          }
          if (
            allFieldsData.searchText !== undefined &&
            allFieldsData.searchText !== ""
          ) {
            setSearchText(allFieldsData.searchText);
            setPlaceholder(allFieldsData.searchText);
          }
          if (
            allFieldsData.searchPlaceholderText !== undefined &&
            allFieldsData.searchPlaceholderText !== ""
          ) {
            setSearchPlaceholderText(allFieldsData.searchPlaceholderText);
          }

          if (
            allFieldsData.storeText !== undefined &&
            allFieldsData.storeText !== ""
          ) {
            setStoreText(allFieldsData.storeText);
          }

          if (
            allFieldsData.mobileStoreText !== undefined &&
            allFieldsData.mobileStoreText !== ""
          ) {
            setMobileStoreText(allFieldsData.mobileStoreText);
          }

          if (
            allFieldsData.additionalCode !== undefined &&
            allFieldsData.additionalCode !== ""
          ) {
            if (
              hostname === "savers.com" ||
              hostname === "saverscom-react-test.metaltoad-sites.com" ||
              hostname === "saverscom-react-dev.metaltoad-sites.com" ||
              hostname === "www.savers.com" ||
              hostname === "www.saverscom-react-test.metaltoad-sites.com" ||
              hostname === "www.saverscom-react-dev.metaltoad-sites.com" ||
              hostname === "villagedesvaleurs.ca" ||
              hostname ===
                "villagedesvaleurscom-react-test.metaltoad-sites.com" ||
              hostname ===
                "villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
              hostname === "www.villagedesvaleurs.ca" ||
              hostname ===
                "www.villagedesvaleurscom-react-test.metaltoad-sites.com" ||
              hostname ===
                "www.villagedesvaleurscom-react-dev.metaltoad-sites.com" ||
              hostname === "valuevillage.ca" ||
              hostname === "valuevillageca-react-test.metaltoad-sites.com" ||
              hostname === "valuevillageca-react-dev.metaltoad-sites.com" ||
              hostname === "www.valuevillage.ca" ||
              hostname ===
                "www.valuevillageca-react-test.metaltoad-sites.com" ||
              hostname === "www.valuevillageca-react-dev.metaltoad-sites.com"
            ) {
              const script = document.createElement("script");
              script.type = "type='application/ld+json";
              script.innerHTML = allFieldsData.additionalCode;
              document.head.appendChild(script);
            }
          }

          const gtmQuery = $(
            'script[src*="https://www.googletagmanager.com/gtm"]'
          );
          if (
            allFieldsData.gtmScript !== undefined &&
            allFieldsData.gtmScript !== "" &&
            gtmQuery.length === 0
          ) {
            if (allFieldsData.gtmScript.length > 0) {
              allFieldsData.gtmScript.map((item: any, i: any) => {
                if (item !== undefined && item !== "") {
                  if (item.fields !== undefined && item.fields !== "") {
                    if (
                      item.fields.headScript !== undefined &&
                      item.fields.headScript
                    ) {
                      const script = document.createElement("script");
                      script.innerHTML = item.fields.headScript;
                      document.head.appendChild(script);
                    }
                    if (
                      item.fields.bodyScript !== undefined &&
                      item.fields.bodyScript
                    ) {
                      const noscript = document.createElement("noscript");
                      noscript.innerHTML = item.fields.bodyScript;
                      document.body.appendChild(noscript);
                    }
                  }
                }
              });
            }
          }

          if (
            allFieldsData.nondisclosureMessage !== undefined &&
            allFieldsData.nondisclosureMessage !== ""
          ) {
            let minimizedText: any = "";
            let expandedText: any = "";
            $.each(
              allFieldsData.nondisclosureMessage,
              function (i, nondisclosureMessage) {
                if (nondisclosureMessage.fields.domains !== undefined) {
                  if (
                    nondisclosureMessage.fields.domains.includes(currDomain)
                  ) {
                    if (
                      nondisclosureMessage.fields.minimizedText !== undefined &&
                      nondisclosureMessage.fields.minimizedText !== ""
                    ) {
                      const options = {
                        renderNode: {
                          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                            if (embededHtml === undefined) {
                              return node.data.target.fields.html;
                            }
                          },
                          [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                            `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                        },
                      };
                      minimizedText = documentToHtmlString(
                        nondisclosureMessage.fields.minimizedText,
                        options
                      );
                    }
                    if (
                      nondisclosureMessage.fields.expandedText !== undefined &&
                      nondisclosureMessage.fields.expandedText !== ""
                    ) {
                      const options = {
                        renderNode: {
                          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                            if (embededHtmlExpanded === undefined) {
                              return node.data.target.fields.html;
                            }
                          },
                          [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                            `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                        },
                      };
                      expandedText = documentToHtmlString(
                        nondisclosureMessage.fields.expandedText,
                        options
                      );
                    }
                    return false;
                  }
                } else {
                  if (
                    nondisclosureMessage.fields.minimizedText !== undefined &&
                    nondisclosureMessage.fields.minimizedText !== ""
                  ) {
                    const options = {
                      renderNode: {
                        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                          if (embededHtml === undefined) {
                            return node.data.target.fields.html;
                          }
                        },
                        [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                          `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                      },
                    };
                    minimizedText = documentToHtmlString(
                      nondisclosureMessage.fields.minimizedText,
                      options
                    );
                  }
                  if (
                    nondisclosureMessage.fields.expandedText !== undefined &&
                    nondisclosureMessage.fields.expandedText !== ""
                  ) {
                    const options = {
                      renderNode: {
                        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                          if (embededHtmlExpanded === undefined) {
                            return node.data.target.fields.html;
                          }
                        },
                        [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
                          `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                      },
                    };
                    expandedText = documentToHtmlString(
                      nondisclosureMessage.fields.expandedText,
                      options
                    );
                  }
                }
              }
            );
            setMinimizedText(minimizedText);
            setExpandedText(expandedText);
          }

          if (
            allFieldsData.vvusCanadianUsersTemporaryPopup !== undefined &&
            allFieldsData.vvusCanadianUsersTemporaryPopup !== ""
          ) {
            if (
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                .description !== undefined &&
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                .description !== ""
            ) {
              setTemporaryModalDescription(
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields.description
              );
            }

            if (
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon !==
                undefined &&
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon !== ""
            ) {
              let fileUrl =
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields.icon.fields
                  .file.url;
              setTemporaryModalIcon(fileUrl);
            }

            if (
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                .backgroundColor !== undefined &&
              allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                .backgroundColor !== ""
            ) {
              setTemporaryModalBg(
                allFieldsData.vvusCanadianUsersTemporaryPopup.fields
                  .backgroundColor
              );
            }
          }

          if (allFieldsData?.searchMagnifyingGlassColor) {
            setSearchMagnifyingGlassColor(
              allFieldsData.searchMagnifyingGlassColor
            );
          }

          if (allFieldsData?.searchInlineTextColor) {
            setSearchInlineTextColor(allFieldsData.searchInlineTextColor);
          }

          if (allFieldsData?.menuNavigationTextColor) {
            setThriftNavLinkColor(allFieldsData.menuNavigationTextColor);
          }

          if (allFieldsData?.menuNavigationUnderlineColor) {
            setThriftNavUnderlineColor(
              allFieldsData.menuNavigationUnderlineColor
            );
          }

          if (allFieldsData?.siteMainMenuBackgroundColor) {
            setSiteMainMenuColor(allFieldsData.siteMainMenuBackgroundColor);
          }

          if (allFieldsData?.hideSearchPills) {
            setHideSearchPills(allFieldsData.hideSearchPills);
          }
        }
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, countryName]);

  const closeCategoryTimer = () =>
    window.setTimeout(() => {
      setCategoryIsOpen(false);
    }, 3000);

  useEffect(() => {
    if (categoryIsOpen) {
      setTimeoutId(closeCategoryTimer());
    } else {
      clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIsOpen]);

  const closeMobileCategoryTimer = () =>
    window.setTimeout(() => {
      setMobileCategoryIsOpen(false);
    }, 3000);

  useEffect(() => {
    if (mobileCategoryIsOpen) {
      setTimeoutId(closeMobileCategoryTimer());
    } else {
      clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileCategoryIsOpen]);

  const handleChange = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (jQuery(event.target).siblings(".secondary").is(":hidden")) {
      jQuery(event.target).siblings(".secondary").show();
      jQuery(event.target).siblings(".primary").hide();
      jQuery(event.target).addClass("open");
      cookies.set("nondisclosure_message", "expand", {
        path: "/",
        expires: date,
      });
    } else {
      jQuery(event.target).siblings(".secondary").hide();
      jQuery(event.target).siblings(".primary").show();
      jQuery(event.target).removeClass("open");
      cookies.set("nondisclosure_message", "minimize", {
        path: "/",
        expires: date,
      });
    }
  };

  const changeLanguage = (value: any) => {
    setCurrLang(value);
    cookies.set("Language", value, { path: "/", expires: date });

    let cookiePriority = true;
    let selectedLocale = getLocale(cookiePriority);

    let pathArray = location.pathname.split("/");
    const pattern = /[a-z]/;

    if (Object.keys(props.allAliases).length > 0) {
      Object.keys(props.allAliases).forEach((item: any) => {
        if (item === selectedLocale) {
          if (props.allAliases[item] !== "") {
            if (defaultLanguage.toLowerCase() === value) {
              let fullAlias = "/" + props.allAliases[item];
              history.push(fullAlias);
              window.location.reload();
            } else {
              let fullAlias = "/" + value + "/" + props.allAliases[item];
              history.push(fullAlias);
              window.location.reload();
            }
          } else {
            if (
              pathArray[1] !== undefined &&
              pathArray[1].match(pattern) &&
              pathArray[1].length < 3
            ) {
              pathArray = pathArray.filter(function (el) {
                return el !== "";
              });
              pathArray.shift();
              if (defaultLanguage.toLowerCase() === value) {
                let fullAlias = "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload();
              } else {
                let fullAlias = "/" + value + "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload();
              }
            } else {
              pathArray = pathArray.filter(function (el) {
                return el !== "";
              });

              if (defaultLanguage.toLowerCase() === value) {
                let fullAlias = "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload();
              } else {
                let fullAlias = "/" + value + "/" + pathArray.join("/");
                history.push(fullAlias);
                window.location.reload();
              }
            }
          }
        }
      });
    } else {
      if (
        pathArray[1] !== undefined &&
        pathArray[1].match(pattern) &&
        pathArray[1].length < 3
      ) {
        pathArray = pathArray.filter(function (el) {
          return el !== "";
        });
        pathArray.shift();
        if (defaultLanguage.toLowerCase() === value) {
          let fullAlias = "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload();
        } else {
          let fullAlias = "/" + value + "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload();
        }
      } else {
        pathArray = pathArray.filter(function (el) {
          return el !== "";
        });

        if (defaultLanguage.toLowerCase() === value) {
          let fullAlias = "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload();
        } else {
          let fullAlias = "/" + value + "/" + pathArray.join("/");
          history.push(fullAlias);
          window.location.reload();
        }
      }
    }
  };

  const handleFocus = async (e: any) => {
    if (e.target.value.length > 0) {
      if (storeLocation.length <= 0) {
        await fetch(
          storeUrl + "api/getAutocompleteData?date=" + Date().toLocaleString()
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setStoreLocation((storeLocation) =>
                storeLocation.concat(result.data)
              );
              setDropdownVisible(true);
            },
            (error) => {}
          );
      }
    } else {
      setStoreLocation([]);
      setDropdownVisible(false);
    }
  };

  const selectRedirect = (updatedValue: any) => {
    window.location.href =
      storeUrl +
      "?q=" +
      updatedValue +
      "&site=" +
      storeSite +
      "&lang=" +
      langCookieKey +
      "&country=" +
      countryCode;
  };

  const countryModalClose = (event: any) => {
    const countryPopupDate = new Date();
    countryPopupDate.setFullYear(countryPopupDate.getFullYear() + 10);
    cookies.set("country_popup", "displayed", {
      path: "/",
      expires: countryPopupDate,
    });
    let redirectLink = jQuery(".info a").attr("href");
    if (redirectLink !== "" && redirectLink !== undefined) {
      window.location.href = redirectLink;
    }
    setShowCountryPopup(false);
  };

  if (
    temporaryModalDescription !== "" &&
    temporaryModalDescription !== undefined
  ) {
    jQuery(".info a").click(function () {
      const countryPopupDate = new Date();
      countryPopupDate.setFullYear(countryPopupDate.getFullYear() + 10);
      let redirectLink = jQuery(".info a").attr("href");
      if (redirectLink !== "" && redirectLink !== undefined) {
        cookies.set("country_popup", "displayed", {
          path: "/",
          expires: countryPopupDate,
        });
        window.location.href = redirectLink;
      }
    });
  }

  const changeSite = (value: any) => {
    window.location.href = value;
  };

  const handleTabBlur = () => {
    $("#options-view-button").prop("checked", false);
  };

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: currLang ? currDomain : getLanguage() }}>
        {allLinksArray.length > 0
          ? allLinksArray.map((data: any) =>
              options.map((toggleOptionsData: any) =>
                countryLangToggle === false ? (
                  Object.keys(toggleOptionsData).map(
                    (toggleItems: any, index) => {
                      if (
                        toggleItems.toLowerCase() === data.lang.toLowerCase() &&
                        countryLangToggle === false
                      ) {
                        if (
                          data.lang.toLowerCase() ===
                          defaultLanguage.toLowerCase()
                        ) {
                          return (
                            <link
                              rel="alternate"
                              hrefLang={data.locales.toLowerCase()}
                              href={
                                window.location.origin + "/" + data.urlAlias
                              }
                            />
                          );
                        } else {
                          return (
                            <link
                              rel="alternate"
                              hrefLang={data.locales.toLowerCase()}
                              href={
                                window.location.origin +
                                "/" +
                                data.lang.toLowerCase() +
                                "/" +
                                data.urlAlias
                              }
                            />
                          );
                        }
                      }
                      return <Fragment></Fragment>;
                    }
                  )
                ) : toggleOptionsData.langKey.toLowerCase() ===
                    data.lang.toLowerCase() &&
                  window.location.origin.includes(toggleOptionsData.link) ? (
                  data.lang.toLowerCase() === defaultLanguage.toLowerCase() ? (
                    <link
                      rel="alternate"
                      hrefLang={data.locales.toLowerCase()}
                      href={window.location.origin + "/" + data.urlAlias}
                    />
                  ) : (
                    <link
                      rel="alternate"
                      hrefLang={data.locales.toLowerCase()}
                      href={
                        window.location.origin +
                        "/" +
                        data.lang.toLowerCase() +
                        "/" +
                        data.urlAlias
                      }
                    />
                  )
                ) : toggleOptionsData.langKey.toLowerCase() ===
                  data.lang.toLowerCase() ? (
                  data.externalRedirectUrl !== undefined &&
                  data.externalRedirectUrl !== "" ? (
                    <link
                      rel="alternate"
                      hrefLang={data.externalUrlLocale.toLowerCase()}
                      href={data.externalRedirectUrl}
                    />
                  ) : (
                    <link
                      rel="alternate"
                      hrefLang={data.externalUrlLocale.toLowerCase()}
                      href={toggleOptionsData.link}
                    />
                  )
                ) : null
              )
            )
          : null}
        {staticLinksArray.length > 0
          ? staticLinksArray.map((data: any) => (
              <link rel="alternate" hrefLang={data.locales} href={data.url} />
            ))
          : null}
      </Helmet>

      <a href="#maincontent" className="skip" tabIndex={0}>
        Skip to main content
      </a>
      <header className={"main-header header-thrift v1 " + dynamicClassName}>
        {minimizedText || expandedText ? (
          <section className="notify-bar" tabIndex={-1}>
            <div className="inner">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={(e) => handleChange(e)}
                      className={
                        nondisclosureMsgCookie === "minimize" ? "" : "open"
                      }
                    >
                      <span>notify-bar</span>
                      <span aria-hidden="true">notify-bar</span>
                    </button>
                    {minimizedText ? (
                      <small
                        style={{ ...minimizeOpts }}
                        className="primary"
                        dangerouslySetInnerHTML={{
                          __html: minimizedText + embededHtml,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                    {expandedText ? (
                      <small
                        style={{ ...expandOpts }}
                        className="secondary"
                        dangerouslySetInnerHTML={{
                          __html: expandedText + embededHtmlExpanded,
                        }}
                      ></small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <div className="header-inner">
          {topHeaderBar ? (
            <div
              className="top-bar"
              aria-hidden="true"
              style={{ background: topPromoBarColor }}
            >
              <div className="container">
                <span dangerouslySetInnerHTML={{ __html: topHeaderBar }}></span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="main-menu-listing">
            <div className="container-fluid">
              <div
                className={
                  countryLangToggle
                    ? "langauage-block lang-dropdown-with-flag"
                    : "langauage-block"
                }
              >
                {countryLangToggle && options ? (
                  options.map((data: any, i: any) => {
                    if (
                      (data.link === undefined || data.link === "") &&
                      getLanguage() === data.langKey
                    ) {
                      return (
                        <Fragment>
                          {data.icon ? (
                            <img
                              className={"flag-icon"}
                              src={data.icon}
                              alt="flag"
                            />
                          ) : (
                            <img
                              className={"globe-icon"}
                              width="13"
                              height="13"
                              src={require("../../scss/images/globe.png")}
                              alt="globe"
                              aria-hidden="true"
                            />
                          )}
                        </Fragment>
                      );
                    }
                    return <Fragment></Fragment>;
                  })
                ) : (
                  <img
                    className={"globe-icon"}
                    width="13"
                    height="13"
                    src={require("../../scss/images/globe.png")}
                    alt="globe"
                    aria-hidden="true"
                  />
                )}

                <DropdownMenu trigger={getLanguage().toUpperCase()}>
                  {countryLangToggle && options ? (
                    <DropdownItemGroup>
                      {options.map((data: any, i: any) => {
                        let _lang = data.langLabel.split("/");
                        let langName = _lang[1].trim();
                        return (
                          <DropdownItem
                            onClick={() => {
                              changeLanguage(langName.toLowerCase());
                              changeSite(data.link);
                            }}
                          >
                            <img
                              className={"flag-icon"}
                              src={data.icon}
                              alt="flag"
                              aria-hidden="true"
                            />
                            {data.langLabel}
                          </DropdownItem>
                        );
                      })}
                    </DropdownItemGroup>
                  ) : (
                    // COUNTRY IS ASSUMED BASED ON DOMAIN
                    <DropdownItemGroup>
                      {options.map((data: any) =>
                        Object.keys(data).map((item: any, i) => {
                          return (
                            <DropdownItem
                              onClick={() =>
                                changeLanguage(data[item].toLowerCase())
                              }
                            >
                              {data[item]}
                            </DropdownItem>
                          );
                        })
                      )}
                    </DropdownItemGroup>
                  )}
                </DropdownMenu>
              </div>
              <div
                className="listing-block"
                role="navigation"
                onFocus={handleTabBlur}
              >
                {topMenuLength > 0 ? (
                  <ul>
                    {topMenu.map((topMenuItem: any, i) => (
                      <li>
                        <a
                          href={
                            topMenuItem.alias
                              ? addLangKeyPath(topMenuItem.alias)
                              : "#"
                          }
                          className={topMenuItem.alias === path ? "active" : ""}
                          aria-label={topMenuItem.title}
                          css={css`
                            &:hover {
                              border-color: ${thriftNavUnderlineColor};
                            }
                          `}
                        >
                          {topMenuItem.menuItemIcon ? (
                            <img
                              className="globe-icon"
                              src={topMenuItem.menuItemIcon}
                              alt="globe"
                              aria-hidden="true"
                            />
                          ) : (
                            ""
                          )}
                          {topMenuItem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="header-inner-main header-inner-main-2">
            <div className="container-fluid">
              <div className="logo-row">
                {siteLogo ? (
                  <a
                    href={addLangKeyPath("/")}
                    aria-label="Homepage Link"
                    aria-hidden="true"
                  >
                    {" "}
                    <img
                      src={siteLogo}
                      style={is2ndAve ? { maxHeight: "unset" } : {}}
                      alt={siteLogoAltText ? siteLogoAltText : ""}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="middle-block">
                <div className="hero-listing-row" role="navigation">
                  <ul
                    css={css`
                      li:after {
                        background: ${thriftNavUnderlineColor} !important;
                      }
                    `}
                  >
                    {siteMainMenu.map((siteMainMenuItem: any, i) => (
                      <li>
                        <a
                          href={
                            siteMainMenuItem.alias
                              ? addLangKeyPath(siteMainMenuItem.alias)
                              : "#"
                          }
                          aria-label={siteMainMenuItem.title}
                          style={{ color: thriftNavLinkColor }}
                        >
                          {siteMainMenuItem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="find-store">
                <div className="dropdown-search">
                  <SearchInput
                    hide={hideSearchPills}
                    searchMagnifyingGlassColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={searchPlaceholderText}
                    searchText={searchText}
                    responsive={true}
                  />
                </div>
                <div className="dropdown-store">
                  <FindStore
                    hide={hideSearchPills}
                    searchBorderPillColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={placeholder}
                    storeUrl={storeUrl}
                    storeSite={storeSite}
                    countryCode={countryCode}
                    langCookieKey={langCookieKey}
                    storeLocation={storeLocation}
                    storeText={storeText}
                    handleFocus={handleFocus}
                    selectRedirect={selectRedirect}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                    responsive={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="thrift-nav">
            <div className="container">
              <div className="thrift-inner" role="navigation">
                {thriftTextLogo ? (
                  <h6 dangerouslySetInnerHTML={{ __html: thriftTextLogo }}></h6>
                ) : (
                  ""
                )}
                <ul>
                  {thriftProudNavigation.map(
                    (thriftProudNavigationItem: any, i) => {
                      if (
                        (thriftProudNavigationItem.alias.includes(
                          "thrift-proud/community"
                        ) &&
                          activeCommunityClass === true) ||
                        (thriftProudNavigationItem.alias.includes(
                          "thrift-proud/thriftorials"
                        ) &&
                          activeThriftorialClass === true)
                      ) {
                        return (
                          <li>
                            <a
                              href={
                                thriftProudNavigationItem.alias
                                  ? addLangKeyPath(
                                      thriftProudNavigationItem.alias
                                    )
                                  : "#"
                              }
                              // className="active"
                              aria-label={thriftProudNavigationItem.title}
                              style={{ borderColor: thriftNavUnderlineColor }}
                              css={css`
                                &:hover {
                                  border-color: ${thriftNavUnderlineColor} !important;
                                }
                              `}
                            >
                              {thriftProudNavigationItem.menuItemIcon ? (
                                <img
                                  className="globe-icon"
                                  src={thriftProudNavigationItem.menuItemIcon}
                                  alt="globe"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}
                              <span
                                style={{ color: thriftNavLinkColor }}
                                dangerouslySetInnerHTML={{
                                  __html: thriftProudNavigationItem.title,
                                }}
                                aria-hidden="true"
                              ></span>
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li>
                            <a
                              href={
                                thriftProudNavigationItem.alias
                                  ? addLangKeyPath(
                                      thriftProudNavigationItem.alias
                                    )
                                  : "#"
                              }
                              // className={thriftProudNavigationItem.alias===path?"active":""}
                              aria-label={thriftProudNavigationItem.title}
                              style={
                                path.includes(thriftProudNavigationItem.alias)
                                  ? { borderColor: thriftNavUnderlineColor }
                                  : {}
                              }
                              css={css`
                                &:hover {
                                  border-color: ${thriftNavUnderlineColor} !important;
                                }
                              `}
                            >
                              {thriftProudNavigationItem.menuItemIcon ? (
                                <img
                                  className="globe-icon"
                                  src={thriftProudNavigationItem.menuItemIcon}
                                  alt="globe"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}
                              <span
                                style={{ color: thriftNavLinkColor }}
                                dangerouslySetInnerHTML={{
                                  __html: thriftProudNavigationItem.title,
                                }}
                              ></span>
                            </a>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <header
        className={"main-header header-thrift v2 " + dynamicClassName}
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="header-inner" aria-hidden="true" tabIndex={-1}>
          {topHeaderBar ? (
            <div
              className="top-bar"
              aria-hidden="true"
              tabIndex={-1}
              style={{ background: topPromoBarColor }}
            >
              <div className="container">
                <span dangerouslySetInnerHTML={{ __html: topHeaderBar }}></span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            className="header-inner-main header-inner-main-2"
            aria-hidden="true"
            tabIndex={-1}
          >
            <div className="container-fluid" aria-hidden="true" tabIndex={-1}>
              <div className="logo-row">
                {siteLogo ? (
                  <a href={addLangKeyPath("/")} aria-label="homepage-link">
                    {" "}
                    <img
                      src={siteLogo}
                      style={is2ndAve ? { maxHeight: "unset" } : {}}
                      alt={siteLogoAltText ? siteLogoAltText : ""}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="middle-block">
                <div className="hero-listing-row">
                  <ul
                    css={css`
                      li:after {
                        background: ${thriftNavUnderlineColor} !important;
                      }
                    `}
                  >
                    {siteMainMenu.map((siteMainMenuItem: any, i) => (
                      <li>
                        <a
                          href={
                            siteMainMenuItem.alias
                              ? addLangKeyPath(siteMainMenuItem.alias)
                              : "#"
                          }
                          className={
                            siteMainMenuItem.alias === path ? "active" : ""
                          }
                          aria-label="menu"
                          style={{ color: thriftNavLinkColor }}
                        >
                          {siteMainMenuItem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="find-store">
                <div className="dropdown-search">
                  <SearchInput
                    hide={hideSearchPills}
                    searchMagnifyingGlassColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={searchPlaceholderText}
                    searchText={searchText}
                    responsive={true}
                  />
                </div>
                <div className="dropdown-store">
                  <FindStore
                    hide={hideSearchPills}
                    searchBorderPillColor={searchMagnifyingGlassColor}
                    searchInlineTextColor={searchInlineTextColor}
                    searchPlaceholder={placeholder}
                    storeUrl={storeUrl}
                    storeSite={storeSite}
                    countryCode={countryCode}
                    langCookieKey={langCookieKey}
                    storeLocation={storeLocation}
                    storeText={storeText}
                    handleFocus={handleFocus}
                    selectRedirect={selectRedirect}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                    responsive={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="thrift-nav" tabIndex={-1} aria-hidden="true">
            <div className="container" tabIndex={-1} aria-hidden="true">
              <div className="thrift-inner" aria-hidden="true">
                {thriftTextLogo ? (
                  <h6 dangerouslySetInnerHTML={{ __html: thriftTextLogo }}></h6>
                ) : (
                  ""
                )}
                <ul>
                  {thriftProudNavigation.map(
                    (thriftProudNavigationItem: any, i) => {
                      if (
                        (thriftProudNavigationItem.alias.includes(
                          "thrift-proud/community"
                        ) &&
                          activeCommunityClass === true) ||
                        (thriftProudNavigationItem.alias.includes(
                          "thrift-proud/thriftorials"
                        ) &&
                          activeThriftorialClass === true)
                      ) {
                        return (
                          <li>
                            <a
                              href={
                                thriftProudNavigationItem.alias
                                  ? addLangKeyPath(
                                      thriftProudNavigationItem.alias
                                    )
                                  : "#"
                              }
                              // className="active"
                              aria-label="thrift-proud-menu"
                              style={
                                path.includes(thriftProudNavigationItem.alias)
                                  ? { borderColor: thriftNavUnderlineColor }
                                  : {}
                              }
                            >
                              {thriftProudNavigationItem.menuItemIcon ? (
                                <img
                                  className="globe-icon"
                                  src={thriftProudNavigationItem.menuItemIcon}
                                  alt="globe"
                                />
                              ) : (
                                ""
                              )}
                              <span
                                style={{ color: thriftNavLinkColor }}
                                dangerouslySetInnerHTML={{
                                  __html: thriftProudNavigationItem.title,
                                }}
                              ></span>
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li>
                            <a
                              href={
                                thriftProudNavigationItem.alias
                                  ? addLangKeyPath(
                                      thriftProudNavigationItem.alias
                                    )
                                  : "#"
                              }
                              // className={thriftProudNavigationItem.alias===path?"active":""}
                              aria-label="thrift-proud-menu"
                              style={
                                path.includes(thriftProudNavigationItem.alias)
                                  ? { borderColor: thriftNavUnderlineColor }
                                  : {}
                              }
                              css={css`
                                &:hover {
                                  border-color: ${thriftNavUnderlineColor} !important;
                                }
                              `}
                            >
                              {thriftProudNavigationItem.menuItemIcon ? (
                                <img
                                  className="globe-icon"
                                  src={thriftProudNavigationItem.menuItemIcon}
                                  alt="globe"
                                />
                              ) : (
                                ""
                              )}
                              <span
                                style={{ color: thriftNavLinkColor }}
                                dangerouslySetInnerHTML={{
                                  __html: thriftProudNavigationItem.title,
                                }}
                              ></span>
                            </a>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <header
        className={"mobile-header thrift-mobile-header v1 " + dynamicClassName}
        aria-hidden="true"
      >
        {minimizedText || expandedText ? (
          <section className="notify-bar" aria-hidden="true">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <button
                    onClick={(e) => handleChange(e)}
                    className={
                      nondisclosureMsgCookie === "minimize" ? "" : "open"
                    }
                  >
                    <span>notify-bar</span>
                    <span>notify-bar</span>
                  </button>
                  {minimizedText ? (
                    <small
                      style={{ ...minimizeOpts }}
                      className="primary"
                      dangerouslySetInnerHTML={{ __html: minimizedText }}
                    ></small>
                  ) : (
                    ""
                  )}
                  {expandedText ? (
                    <small
                      style={{ ...expandOpts }}
                      className="secondary"
                      dangerouslySetInnerHTML={{ __html: expandedText }}
                    ></small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <div className="container" aria-hidden="true">
          <div className="row">
            <div className="col-12">
              <div className="main-top-bar">
                <div className="mobile-toggle">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="center-logo">
                  {siteLogo ? (
                    <a href={addLangKeyPath("/")} aria-label="homepage-link">
                      {" "}
                      <img
                        src={siteLogo}
                        style={is2ndAve ? { maxHeight: "unset" } : {}}
                        alt={siteLogoAltText ? siteLogoAltText : ""}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="right-store-btn">
                  {hideSearchPills ? (
                    <div style={{ width: "86px" }} />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
                    <a href="javascript:void(0);" aria-label="store-link">
                      {mobileStoreText}
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ color: searchMagnifyingGlassColor }}
                      />
                    </a>
                  )}
                </div>
              </div>
              {siteMainMenu?.length > 0 ? (
                <div
                  className="mobile-navbar"
                  style={{ background: siteMainMenuColor }}
                >
                  <ul>
                    {siteMainMenu.map((siteMainMenuItem: any, i) => (
                      <li>
                        <a
                          href={
                            siteMainMenuItem.alias
                              ? addLangKeyPath(siteMainMenuItem.alias)
                              : "#"
                          }
                          dangerouslySetInnerHTML={{
                            __html: siteMainMenuItem.title,
                          }}
                          aria-label="menu"
                        ></a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {topHeaderBar ? (
                <div
                  className="mobile-permotion-bar"
                  style={{ background: topPromoBarColor }}
                >
                  <p dangerouslySetInnerHTML={{ __html: topHeaderBar }}></p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="thrift-nav" aria-hidden="true">
          <div className="container">
            <div className="thrift-inner">
              <div className="thrift-top-head">
                {thriftTextLogo ? (
                  <h6 dangerouslySetInnerHTML={{ __html: thriftTextLogo }}></h6>
                ) : (
                  ""
                )}

                {/* New Drop Down */}
                <DropdownMenu
                  isOpen={categoryIsOpen}
                  onOpenChange={(attrs) => {
                    setCategoryIsOpen(!categoryIsOpen);
                  }}
                  trigger={
                    mobileDropdownLabel ? mobileDropdownLabel : "Category"
                  }
                >
                  <DropdownItemGroup>
                    {thriftProudNavigation.map((data: any, i: any) => {
                      if (data.title.includes("&reg;")) {
                        data.title = data.title.slice(0, -5);
                        data.title = data.title + "®";
                      } else if (data.title.includes("<sup>TM</sup>")) {
                        data.title = data.title.slice(0, -13);
                        data.title = data.title + "™";
                      }
                      return (
                        <DropdownItem
                          onClick={() => {
                            history.push(
                              data.alias ? addLangKeyPath(data.alias) : "#"
                            );
                            window.location.reload();
                          }}
                        >
                          {data.title}
                        </DropdownItem>
                      );
                    })}
                  </DropdownItemGroup>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-mask"></div>
        <div
          className="find-store-collapse-block"
          aria-hidden="true"
          role="dialog"
          aria-labelledby="heading"
        >
          <h6 className="text-center" id="heading">
            {storeText}
          </h6>
          <img
            className="close-icon"
            src="../../images/close.png"
            alt="close"
          />
          <div className="textfield-wrap">
            <form
              action={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              method="post"
              className="storeAutoCompleteId"
            >
              <Autocomplete
                items={storeLocation}
                inputProps={{ placeholder: storeText }}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item}
                renderItem={(item, highlighted) => (
                  <div
                    className="items"
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item}
                  </div>
                )}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  handleFocus(e);
                }}
                onSelect={(value) => {
                  setValue(value);
                  selectRedirect(value);
                }}
                onMenuVisibilityChange={(isOpen) => {
                  value.length > 0
                    ? setDropdownVisible(isOpen)
                    : setDropdownVisible(false);
                }}
                open={dropdownVisible}
              />
              <span
                className="arrow-right"
                aria-hidden="true"
                style={{ background: searchMagnifyingGlassColor }}
              >
                <a
                  href={
                    storeUrl +
                    "?q=" +
                    value +
                    "&site=" +
                    storeSite +
                    "&lang=" +
                    langCookieKey +
                    "&country=" +
                    countryCode
                  }
                  aria-label="Location Search"
                >
                  <i className="fa fa-arrow-right clr-white" />
                </a>
              </span>
            </form>
          </div>
        </div>
        <div
          role="dialog"
          aria-label="Main Navigation"
          className="mobile-menu"
          aria-hidden="true"
        >
          <div className="menu-close-btn">
            <div className="inner">
              <span></span>
              <span></span>
            </div>
          </div>
          {!hideSearchPills && (
            <div className="mobile-find-store">
              <button className="btn find-store-btn" tabIndex={0}>
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ color: searchMagnifyingGlassColor }}
                ></i>
                <span style={{ color: searchInlineTextColor }}>
                  {storeText ? storeText : ""}
                </span>
              </button>
            </div>
          )}
          <div className="mobile-main-menu">
            <ul>
              {siteMainMenu.map((siteMainMenuItem: any, i) => (
                <li>
                  <a
                    href={
                      siteMainMenuItem.alias
                        ? addLangKeyPath(siteMainMenuItem.alias)
                        : "#"
                    }
                    dangerouslySetInnerHTML={{ __html: siteMainMenuItem.title }}
                    aria-label="menu"
                  ></a>
                </li>
              ))}
            </ul>
          </div>

          <div className="mobile-secondary-menu primary">
            {topMenuLength > 0 ? (
              <ul>
                {topMenu.map((topMenuItem: any, i) => (
                  <li>
                    <a
                      href={
                        topMenuItem.alias
                          ? addLangKeyPath(topMenuItem.alias)
                          : "#"
                      }
                      className={topMenuItem.alias === path ? "active" : ""}
                      aria-label="menu"
                    >
                      {topMenuItem.menuItemIcon ? (
                        <img
                          className="globe-icon"
                          src={topMenuItem.menuItemIcon}
                          alt="globe"
                        />
                      ) : (
                        ""
                      )}
                      {topMenuItem.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </div>

          <SearchInput
            hide={hideSearchPills}
            searchMagnifyingGlassColor={searchMagnifyingGlassColor}
            searchInlineTextColor={searchInlineTextColor}
            searchPlaceholder={searchPlaceholderText}
            searchText={searchText}
            responsive={false}
          />
          <div
            className={
              countryLangToggle
                ? "langauage-block lang-dropdown-with-flag"
                : "langauage-block"
            }
          >
            {countryLangToggle && options ? (
              options.map((data: any, i: any) => {
                if (
                  (data.link === undefined || data.link === "") &&
                  getLanguage() === data.langKey
                ) {
                  return (
                    <Fragment>
                      {data.icon ? (
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                        />
                      ) : (
                        <img
                          className={"globe-icon"}
                          width="13"
                          height="13"
                          src={require("../../scss/images/globe.png")}
                          alt="globe"
                        />
                      )}
                    </Fragment>
                  );
                }
                return <Fragment></Fragment>;
              })
            ) : (
              <img
                className={"globe-icon"}
                width="13"
                height="13"
                src={require("../../scss/images/globe.png")}
                alt="globe"
              />
            )}

            <DropdownMenu trigger={getLanguage().toUpperCase()}>
              {countryLangToggle && options ? (
                <DropdownItemGroup>
                  {options.map((data: any, i: any) => {
                    let _lang = data.langLabel.split("/");
                    let langName = _lang[1].trim();
                    return (
                      <DropdownItem
                        onClick={() => {
                          changeLanguage(langName.toLowerCase());
                          changeSite(data.link);
                        }}
                      >
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                          aria-hidden="true"
                        />
                        {data.langLabel}
                      </DropdownItem>
                    );
                  })}
                </DropdownItemGroup>
              ) : (
                // COUNTRY IS ASSUMED BASED ON DOMAIN
                <DropdownItemGroup>
                  {options.map((data: any) =>
                    Object.keys(data).map((item: any, i) => {
                      return (
                        <DropdownItem
                          onClick={() =>
                            changeLanguage(data[item].toLowerCase())
                          }
                        >
                          {data[item]}
                        </DropdownItem>
                      );
                    })
                  )}
                </DropdownItemGroup>
              )}
            </DropdownMenu>
          </div>
        </div>
        <div className="mobile-store-search" aria-hidden="true">
          <div className="close-btn" tabIndex={0}>
            <span></span>
            <span></span>
          </div>
          <p>{storeText ? storeText : ""}</p>
          <form
            action={
              storeUrl +
              "?q=" +
              value +
              "&site=" +
              storeSite +
              "&lang=" +
              langCookieKey +
              "&country=" +
              countryCode
            }
            method="post"
            className="storeAutoCompleteId"
          >
            <Autocomplete
              items={storeLocation}
              inputProps={{ placeholder: storeText }}
              shouldItemRender={(item, value) =>
                item.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item}
              renderItem={(item, highlighted) => (
                <div
                  className="items"
                  style={{
                    backgroundColor: highlighted ? "#eee" : "transparent",
                  }}
                >
                  {item}
                </div>
              )}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                handleFocus(e);
              }}
              onSelect={(value) => {
                setValue(value);
                selectRedirect(value);
              }}
              onMenuVisibilityChange={(isOpen) => {
                value.length > 0
                  ? setDropdownVisible(isOpen)
                  : setDropdownVisible(false);
              }}
              open={dropdownVisible}
            />
            <a
              className="arrow-right"
              href={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              aria-label="Location Search"
            >
              <img
                className="arrow-icon"
                width="21"
                height="21"
                src={require("../../images/arrow-right.png")}
                alt="arrow-right"
              ></img>
            </a>
          </form>
        </div>
      </header>

      <header
        className={
          topHeaderBar
            ? "mobile-header thrift-mobile-header v2 " + dynamicClassName
            : "mobile-header thrift-mobile-header v2 no-top-bar " +
              dynamicClassName
        }
        aria-hidden="true"
      >
        <div className="container" aria-hidden="true">
          <div className="row">
            <div className="col-12">
              <div className="main-top-bar">
                <div className="mobile-toggle">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="center-logo">
                  {siteLogo ? (
                    <a href={addLangKeyPath("/")} aria-label="homepage link">
                      {" "}
                      <img
                        src={siteLogo}
                        style={is2ndAve ? { maxHeight: "unset" } : {}}
                        alt={siteLogoAltText ? siteLogoAltText : ""}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="right-store-btn">
                  {hideSearchPills ? (
                    <div style={{ width: "86px" }} />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
                    <a href="javascript:void(0);" aria-label="store-link">
                      {mobileStoreText}
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ color: searchMagnifyingGlassColor }}
                      />
                    </a>
                  )}
                </div>
              </div>
              <div
                className="mobile-navbar"
                style={{ background: siteMainMenuColor }}
              >
                <ul>
                  {siteMainMenu.map((siteMainMenuItem: any, i) => (
                    <li>
                      <a
                        href={
                          siteMainMenuItem.alias
                            ? addLangKeyPath(siteMainMenuItem.alias)
                            : "#"
                        }
                        dangerouslySetInnerHTML={{
                          __html: siteMainMenuItem.title,
                        }}
                        aria-label="menu"
                      ></a>
                    </li>
                  ))}
                </ul>
              </div>
              {topHeaderBar ? (
                <div
                  className="mobile-permotion-bar"
                  style={{ background: topPromoBarColor }}
                >
                  <p dangerouslySetInnerHTML={{ __html: topHeaderBar }}></p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="thrift-nav" aria-hidden="true">
          <div className="container">
            <div className="thrift-inner">
              <div className="thrift-top-head">
                {thriftTextLogo ? (
                  <h6 dangerouslySetInnerHTML={{ __html: thriftTextLogo }}></h6>
                ) : (
                  ""
                )}
                {/* New Drop Down */}
                <DropdownMenu
                  isOpen={mobileCategoryIsOpen}
                  onOpenChange={(attrs) => {
                    setMobileCategoryIsOpen(!mobileCategoryIsOpen);
                  }}
                  trigger={
                    mobileDropdownLabel ? mobileDropdownLabel : "Category"
                  }
                >
                  <DropdownItemGroup>
                    {thriftProudNavigation.map((data: any, i: any) => {
                      if (data.title.includes("&reg;")) {
                        data.title = data.title.slice(0, -5);
                        data.title = data.title + "®";
                      } else if (data.title.includes("<sup>TM</sup>")) {
                        data.title = data.title.slice(0, -13);
                        data.title = data.title + "®";
                      } // Subscript TM
                      return (
                        <DropdownItem
                          onClick={() => {
                            history.push(
                              data.alias ? addLangKeyPath(data.alias) : "#"
                            );
                            window.scrollTo(0, 0);
                            window.location.reload();
                          }}
                        >
                          {data.title}
                        </DropdownItem>
                      );
                    })}
                  </DropdownItemGroup>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-mask"></div>
        <div
          className="find-store-collapse-block"
          aria-hidden="true"
          role="dialog"
          aria-labelledby="heading"
        >
          <h6 className="text-center" id="heading">
            {storeText}
          </h6>
          <img
            className="close-icon"
            src="../../images/close.png"
            alt="close"
          />
          <div className="textfield-wrap">
            <form
              action={
                storeUrl +
                "?q=" +
                value +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              method="post"
              className="storeAutoCompleteId"
            >
              <Autocomplete
                items={storeLocation}
                inputProps={{ placeholder: storeText }}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item}
                renderItem={(item, highlighted) => (
                  <div
                    className="items"
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item}
                  </div>
                )}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  handleFocus(e);
                }}
                onSelect={(value) => {
                  setValue(value);
                  selectRedirect(value);
                }}
                onMenuVisibilityChange={(isOpen) => {
                  value.length > 0
                    ? setDropdownVisible(isOpen)
                    : setDropdownVisible(false);
                }}
                open={dropdownVisible}
              />
              <a
                className="arrow-right"
                href={
                  storeUrl +
                  "?q=" +
                  value +
                  "&site=" +
                  storeSite +
                  "&lang=" +
                  langCookieKey +
                  "&country=" +
                  countryCode
                }
                aria-label="Search Location"
              >
                <img
                  className="arrow-icon"
                  width="21"
                  height="21"
                  src={require("../../images/arrow-right.png")}
                  alt="arrow-right"
                ></img>
              </a>
            </form>
          </div>
        </div>
        <div
          role="dialog"
          aria-label="Main Navigation"
          className="mobile-menu"
          aria-hidden="true"
        >
          <div className="menu-close-btn">
            <div className="inner">
              <span></span>
              <span></span>
            </div>
          </div>
          {!hideSearchPills && (
            <div className="mobile-find-store">
              <button className="btn find-store-btn" tabIndex={0}>
                <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                {storeText}
              </button>
            </div>
          )}
          <div className="mobile-main-menu">
            <ul>
              {siteMainMenu.map((siteMainMenuItem: any, i) => (
                <li>
                  <a
                    href={
                      siteMainMenuItem.alias
                        ? addLangKeyPath(siteMainMenuItem.alias)
                        : "#"
                    }
                    dangerouslySetInnerHTML={{ __html: siteMainMenuItem.title }}
                    aria-label="menu"
                  ></a>
                </li>
              ))}
            </ul>
          </div>
          <div className="mobile-secondary-menu primary">
            {topMenuLength > 0 ? (
              <ul>
                {topMenu.map((topMenuItem: any, i) => (
                  <li>
                    <a
                      href={
                        topMenuItem.alias
                          ? addLangKeyPath(topMenuItem.alias)
                          : "#"
                      }
                      className={topMenuItem.alias === path ? "active" : ""}
                      aria-label="menu"
                    >
                      {topMenuItem.menuItemIcon ? (
                        <img
                          className="globe-icon"
                          src={topMenuItem.menuItemIcon}
                          alt="globe"
                        />
                      ) : (
                        ""
                      )}
                      {topMenuItem.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </div>

          <div className="search-box">
            <form action={"/search/" + searchItem}>
              <input
                onFocus={() => setPlaceholder(searchPlaceholderText)}
                onBlur={() => setPlaceholder(searchText)}
                type="text"
                placeholder={placeholder}
                aria-label="Search"
                aria-required="true"
                onChange={(e) => {
                  setSearchItem(e.target.value);
                }}
              />
              <i className="fa fa-search" aria-hidden="true"></i>
              <span className="arrow-right">
                <a
                  href={addLangKeyPath("/search/" + searchItem)}
                  aria-label="Search"
                >
                  <i
                    className="fa fa-arrow-right clr-white"
                    aria-hidden="true"
                  ></i>
                </a>
              </span>
            </form>
          </div>
          <div
            className={
              countryLangToggle
                ? "langauage-block lang-dropdown-with-flag"
                : "langauage-block"
            }
          >
            {countryLangToggle && options ? (
              options.map((data: any, i: any) => {
                if (
                  (data.link === undefined || data.link === "") &&
                  getLanguage() === data.langKey
                ) {
                  return (
                    <Fragment>
                      {data.icon ? (
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                        />
                      ) : (
                        <img
                          className={"globe-icon"}
                          width="13"
                          height="13"
                          src={require("../../scss/images/globe.png")}
                          alt="globe"
                        />
                      )}
                    </Fragment>
                  );
                }
                return <Fragment></Fragment>;
              })
            ) : (
              <img
                className={"globe-icon"}
                width="13"
                height="13"
                src={require("../../scss/images/globe.png")}
                alt="globe"
              />
            )}

            <DropdownMenu trigger={getLanguage().toUpperCase()}>
              {countryLangToggle && options ? (
                <DropdownItemGroup>
                  {options.map((data: any, i: any) => {
                    let _lang = data.langLabel.split("/");
                    let langName = _lang[1].trim();
                    return (
                      <DropdownItem
                        onClick={() => {
                          changeLanguage(langName.toLowerCase());
                          changeSite(data.link);
                        }}
                      >
                        <img
                          className={"flag-icon"}
                          src={data.icon}
                          alt="flag"
                          aria-hidden="true"
                        />
                        {data.langLabel}
                      </DropdownItem>
                    );
                  })}
                </DropdownItemGroup>
              ) : (
                // COUNTRY IS ASSUMED BASED ON DOMAIN
                <DropdownItemGroup>
                  {options.map((data: any) =>
                    Object.keys(data).map((item: any, i) => {
                      return (
                        <DropdownItem
                          onClick={() =>
                            changeLanguage(data[item].toLowerCase())
                          }
                        >
                          {data[item]}
                        </DropdownItem>
                      );
                    })
                  )}
                </DropdownItemGroup>
              )}
            </DropdownMenu>
          </div>
        </div>
        <div className="mobile-store-search" aria-hidden="true">
          <div className="close-btn" tabIndex={0}>
            <span></span>
            <span></span>
          </div>
          <p>{storeText ? storeText : ""}</p>
          <form
            action={
              storeUrl +
              "?q=" +
              value +
              "&site=" +
              storeSite +
              "&lang=" +
              langCookieKey +
              "&country=" +
              countryCode
            }
            method="post"
            className="storeAutoCompleteId"
          >
            <Autocomplete
              items={storeLocation}
              inputProps={{ placeholder: storeText }}
              shouldItemRender={(item, value) =>
                item.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item}
              renderItem={(item, highlighted) => (
                <div
                  className="items"
                  style={{
                    backgroundColor: highlighted ? "#eee" : "transparent",
                  }}
                >
                  {item}
                </div>
              )}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                handleFocus(e);
              }}
              onSelect={(value) => {
                setValue(value);
                selectRedirect(value);
              }}
              onMenuVisibilityChange={(isOpen) => {
                value.length > 0
                  ? setDropdownVisible(isOpen)
                  : setDropdownVisible(false);
              }}
              open={dropdownVisible}
            />
            <span
              className="arrow-right"
              aria-hidden="true"
              style={{ background: searchMagnifyingGlassColor }}
            >
              <a
                href={
                  storeUrl +
                  "?q=" +
                  value +
                  "&site=" +
                  storeSite +
                  "&lang=" +
                  langCookieKey +
                  "&country=" +
                  countryCode
                }
                aria-label="Location Search"
              >
                <i className="fa fa-arrow-right clr-white" />
              </a>
            </span>
          </form>
        </div>
      </header>
      <Modal
        aria-hidden="true"
        dialogClassName="country-modal-dialog"
        show={showCountryPopup}
        aria-labelledby="VVUS Canadian users"
        centered
      >
        <Modal.Body className="country-popup-modal">
          <div
            className="modal-body"
            style={{ backgroundColor: temporaryModalBg }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(event) => countryModalClose(event)}
            ></button>
            {temporaryModalIcon ? (
              <img src={temporaryModalIcon} alt="Temporary Modal Icon" />
            ) : (
              ""
            )}
            <div className="clearfix"></div>
            {temporaryModalDescription ? (
              <div
                className="info"
                dangerouslySetInnerHTML={{ __html: temporaryModalDescription }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
  allAliases: state.site.allAliases,
});

HeaderThrift.defaultProps = {
  fetchSiteData: () => {},
  header: {},
  allAliases: [],
};

export { HeaderThrift };

export default connect(mapStateToProps, mapDispatchToProps)(HeaderThrift);
