import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from "../../Client";
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const ContactUsCategories: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [contactUsCategories, setContactUsCategories] = useState([]);
  const [contactUsCategoriesLength, setContactUsCategoriesLength] = useState(0);
  const [contactUsInformationLength, setContactUsInformationLength] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [embeddedEntry, setEmbeddedEntry] = useState([]);
  const [embeddedEntryLength, setEmbeddedEntryLength] = useState(0);
  const [singleInforEntry, setSingleInforEntry] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState("");
  const [contactUsCategoriesInformation, setContactUsCategoriesInformation] = useState([]);
  const [contactUsCategoriesInformationLength, setContactUsCategoriesInformationLength] = useState(0);
  const [id, setId] = useState<any>("");
  const [idLength, setIdLength] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() => {

    if(componentData.contactUsCategories[0].sys.id !== undefined && componentData.contactUsCategories[0].sys.id !== ""){
      let clickFirstItem = setInterval(function(){
        if(jQuery(".tab-entry-"+componentData.contactUsCategories[0].sys.id+" #vertical-tabs-content li").first().length > 0){
          clearInterval(clickFirstItem);
          jQuery(".tab-entry-"+componentData.contactUsCategories[0].sys.id+" #vertical-tabs-content li").first().click();
        }
      }, 300);
      setTimeout(() => {
        clearInterval(clickFirstItem);
      }, 5000);
    }

    if (componentData.title != undefined && componentData.title != "") {
      setTitle(componentData.title);
    }

    if (componentData.bottomText != undefined && componentData.bottomText != "") {
      let bottomrawRichTextField = documentToHtmlString(
        componentData.bottomText
      );
      setBottomText(bottomrawRichTextField);
    }

    if (componentData.backgroundColor != undefined && componentData.backgroundColor != "") {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (
      componentData.contactUsCategories != undefined &&
      componentData.contactUsCategories != ""
    ) {
      let allContactUsCategories: any = [];

      componentData.contactUsCategories.map(async (item: any,i:any) => {

        if (item.sys.id !== undefined && item.sys.id !== "") {
          let singleCategory = {
            index: i,
            title: "",
            icon: "",
            iconAltText: "",
            entryId: item.sys.id,
            contactUsInformation : [],
          };

          await client
            .getEntry(item.sys.id, {locale: currLocale})
            .then(async (entry: any) => {

              if (entry != "" && entry != undefined) {

                if (
                  entry.fields.title != "" &&
                  entry.fields.title != undefined
                ) {
                  singleCategory["title"] = entry.fields.title;
                }

                if (
                  entry.fields.icon !== "" &&
                  entry.fields.icon !== undefined
                ) {
                  let fileUrl = entry.fields.icon.fields.file.url;
                  let altText= entry.fields.icon.fields.title;
                  singleCategory["icon"] = fileUrl;
                  singleCategory["iconAltText"]=altText;
                }

                if (
                  entry.fields.contactUsInformation !== "" &&
                  entry.fields.contactUsInformation !== undefined
                ) {

                  let allContactUsInformation: any = [];
                  entry.fields.contactUsInformation.map(async (infoItem: any, infoIndex: any) => {

                    let singleInfoEntry = {
                      index: infoIndex,
                      entryId: infoItem.sys.id,
                      title: "",
                      subTitle: "",
                      description: ""
                    }
                    if (
                      infoItem.fields.title !== "" &&
                      infoItem.fields.title !== undefined
                    ) {
                      singleInfoEntry["title"] = infoItem.fields.title;
                    }

                    if (
                      infoItem.fields.subTitle !== "" &&
                      infoItem.fields.subTitle !== undefined
                    ) {
                      singleInfoEntry["subTitle"] = infoItem.fields.subTitle;
                    }

                    if (
                      infoItem.fields.description !== "" &&
                      infoItem.fields.description !== undefined
                    ) {
                    // const options = {
                    //   renderNode: {
                    //     [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    //       const id = node.data.target.sys.id;
                    //        client.getEntry(id, {locale: currLocale}).then(async(entry : any) => { 
                    //          // setEmbeddedEntry(entry.fields.html);
                    //       });
                    //       // if(!embeddedEntry){
                    //       //   return 'loading..';
                    //       // }
                    //       return embeddedEntry[i];
                    //     },
                    //     [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}"/>`
                    //   }              

                    // }
                    //   let rawRichTextField:any = documentToHtmlString(infoItem.fields.description, options);
                    //   singleInfoEntry["description"] = rawRichTextField;
                    }

                    allContactUsInformation.push(singleInfoEntry);
                    allContactUsInformation.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                    
                    await setContactUsInformationLength(allContactUsInformation.length);
                  });

                  singleCategory["contactUsInformation"] = allContactUsInformation;
                }

                allContactUsCategories.push(singleCategory);
                allContactUsCategories.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

                await setContactUsCategories(allContactUsCategories);
                await setContactUsCategoriesLength(allContactUsCategories.length);
              }
            })
            .catch(console.error);
          }
      });
    }

  }, []);

  const selectCategoriesItems=async(entry_id:any)=>
  {
    let allInfoEntry:any=[];
    await client
      .getEntry(entry_id, {locale: currLocale})
        .then(async (entry: any) => {
           let singleInfoEntry = {
           title:"",
           subTitle: "",
           description: ""
         }
          if (entry != "" && entry != undefined) {
            if (entry.fields.title !== "" && entry.fields.title !== undefined) {
                singleInfoEntry["title"] = entry.fields.title;
            }

            if (entry.fields.subTitle !== "" && entry.fields.subTitle !== undefined) {
                singleInfoEntry["subTitle"] = entry.fields.subTitle;
            }
            if (entry.fields.description !== "" && entry.fields.description !== undefined) {
             const options = {
               renderNode: {
                 [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {  
                  return node.data.target.fields.html;
                 },
                 [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                 [INLINES.ASSET_HYPERLINK]:(node: any) => `<a href="${node.data.target.fields.file.url}" target="_blank">${node.data.target.fields.title}</a> `
               }     

              }
              
               let rawRichTextField:any = documentToHtmlString(entry.fields.description, options);
               singleInfoEntry["description"] = rawRichTextField;
               allInfoEntry.push(singleInfoEntry)
               await setContactUsCategoriesInformation(allInfoEntry);
              
            }
          }

        })
  }
  
 const handleChange=async(clickedEntryId : any)=>
  {     
    let entryId = jQuery(".tab-entry-"+clickedEntryId+" #vertical-tabs-content li").first().attr("entry-id");
    jQuery(".tab-entry-"+clickedEntryId+" #vertical-tabs-content li a.active").removeClass("active");
    jQuery(".tab-entry-"+clickedEntryId+" #vertical-tabs-content li").first().find("a").addClass("active");
    await selectCategoriesItems(entryId);
  }

  return (
    <>
      <section className="responsive-tabs" style={{backgroundColor: backgroundColor}} id="responsive-tabs-content">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {title ? <h5 className="mobile-hide">{title}</h5> : ""}
            </div>      
            <div className="col-12">

              <ul id="tabs" className="nav nav-tabs main-tabs" role="tablist">    
                {contactUsCategories.map(
                  ({title, icon, entryId,iconAltText}, i) =>{ 
                    if (title === "" && icon === "") return null;

                    return  (
                      <li key={i} className="nav-item" entry-id={entryId} onClick={() => handleChange(entryId)}>
                        <a entry-id={entryId} id={"tab-"+i} href={"#pane-"+i} className="nav-link" data-toggle="tab" role="tab">
                          <samp  >
                          {icon ? <img width = '30' height = '32' src={icon} alt={iconAltText?iconAltText:""} aria-hidden='true' /> : ""}
                          {title ? <span dangerouslySetInnerHTML={{ __html:title}} ></span> : ""}
                          </samp>
                        </a>
                      </li>
                    )
                }
                )}
              </ul>

              <div id="content" className="tab-content" role="tablist">
                {contactUsCategories.map(
                  (item: any, i) => (
                    <div id={"pane-"+i} className={"card tab-pane fade tab-entry-"+item.entryId} role="tabpanel" aria-labelledby="tab-1">
                      <div className="card-header" role="tab" id="heading-1" onClick={() => handleChange(item.entryId)}>
                        <h5 className="mb-0">                
                          <a data-toggle="collapse" href={"#collapse-"+i} aria-expanded={i==0?"true":"false"} aria-controls={"collapse-"+i} className={i==0?"":"collapsed"}>
                            {item.icon ?<samp> <img width = '30' height = '32' src={item.icon} alt={item.iconAltText?item.iconAltText:""}/> </samp>: ""}
                            {item.title ? <span dangerouslySetInnerHTML={{__html:item.title}}></span> : ""}
                          </a>
                        </h5>
                      </div>
                      <div id={"collapse-"+i} className={i==0?"collapse show":"collapse"} data-parent="#content" role="tabpanel" aria-label={"heading-"+i}>
                        <div className="card-body">
                          <div className="vertical-tabs" id="vertical-tabs-content">
                            <ul id={"tabs"+i} className="nav nav-tabs sub-tabs" role="tablist">
                              {item.contactUsInformation ? item.contactUsInformation.map(
                                (innerItem : any, tabi: any) => (
                                  <li entry-id={innerItem.entryId} className="nav-item" onClick={()=>selectCategoriesItems(innerItem.entryId)}>
                                    {innerItem.title ? <a id={"tab-"+i+tabi} href={"#pane-"+i+tabi} className={tabi === 0 ? "nav-link active": "nav-link"} data-toggle="tab" role="tab">{innerItem.title}</a> : ""}
                                  </li>
                                )
                              ) : ""}
                            </ul>
                            <div id={"content"+i} className="tab-content sub-content-block" role="tablist">
                              {item.contactUsInformation ? item.contactUsInformation.map(
                                (belowInnerItem : any, newtabi: any) => (

                                  <div id={"pane-"+i+newtabi} className={newtabi === 0 ? "card tab-pane fade active show" : "card tab-pane fade"} role="tabpanel" aria-label={"tab-"+i+newtabi}>
                                    <div className="card-header" role="tab" id={"heading-"+i+newtabi}>
                                      <h5 className="mb-0">
                                        {belowInnerItem.title ? <a data-toggle="collapse" href={"#collapse-"+i+newtabi} aria-expanded="true" aria-controls={"collapse-"+i+newtabi}>
                                        {belowInnerItem.title}
                                        </a> : ""}
                                      </h5>
                                    </div>

                                    <div id={"collapse-"+i+newtabi} className="collapse active show" data-parent="#content" role="tabpanel" aria-label={"heading-"+i+newtabi}>
                                      {item.title ? <h4 dangerouslySetInnerHTML={{ __html:item.title}}></h4> : ""}
                                    {contactUsCategoriesInformation? contactUsCategoriesInformation.map((items:any)=> <div className="card-body">
                                        {items.subTitle ? <h3><ReactMarkdown allowDangerousHtml source={items.subTitle} disallowedTypes={['paragraph']} unwrapDisallowed /></h3> : ""}
                                        {items.description?<div dangerouslySetInnerHTML={{ __html: items.description }}></div>:""}
                                      </div>
                                       ):null}
                                    </div>
                                  </div>
                                )
                              ) : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {bottomText ?
              <div className="col-12 bottom-hidden-info">
              <small dangerouslySetInnerHTML={{ __html: bottomText }}></small>
              </div>
              : ""
            }
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ContactUsCategories.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ContactUsCategories };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsCategories);
