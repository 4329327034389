import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const CommonMedia: React.FC<any> = (props) => {
    return (
        <>
        <div className="bg-grey pt-5 pb-5 common-media-with-text-block">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="info">
                            <h2 className="mb-4">{props.Common_Media.title}</h2>
                            <h6 className="mb-3">{props.Common_Media.heading}</h6>
                            <p className="mb-4">{props.Common_Media.paragraph}</p>
                            <a href="#" className="large-text-cta cta-black">{props.Common_Media.anchor_text} <i className="fa fa-caret-right" aria-hidden="true"> </i></a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="media-wrap">
                            <img src={props.Common_Media.chart_image}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="banner-solid-bg pt-5 pb-5 text-center card-with-only-text-block mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">                    
                        <h3 className="clr-white">{props.Banner_Solid.heading}</h3>
                        <p className="clr-white">{props.Banner_Solid.paragraph}</p>
                        <a href="#" className="large-text-cta cta-white">{props.Banner_Solid.anchor_text} <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

CommonMedia.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { CommonMedia };

export default connect(mapStateToProps, mapDispatchToProps)(CommonMedia);
