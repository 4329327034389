import React, { useEffect, useState } from "react";
import ComponentComposer from "../../components/ComponentComposer";
import {fetchDynamicPage,} from "../../api/cms/api";
import { useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import client from "../../Client";
import FindStore from "../../components/FindStore";
import ReactMarkdown from 'react-markdown';
import { RootState } from "../../reducers";
import { connect } from "react-redux";
import {setAllAliases} from "../../actions/cms";
import Slider from "react-slick";
import { getLocale } from "../../api/cms/api";
import { getGlobalData} from "../../api/cms/api";


const DynamicPage: React.FC<any> = (props) => {

  let originPath= window.location.href

  const currLocale = getLocale();
  const globalData = getGlobalData();
  const [data, setData] = useState<any[]>([]);
  const { alias1, alias2 } = useParams();
  const location = useLocation();
  const [heading, setHeading] = useState("");
  const [title, setTitle] = useState("");
  const [tagLabel, setTagLabel] = useState("");
  const [tagNames, setTagNames] = useState([]);
  const [tagNamesLength, setTagNamesLength] = useState(0);
  const [storyBannerImage, setStoryBannerImage] = useState("");
  const [storyBannerImageAltText, setStoryBannerImageAltText] = useState("");
  const [description, setDescription] = useState("");
  const [allRelatedStories, setAllRelatedStories] = useState<any[]>([]);
  const [allRelatedStoriesLength, setAllRelatedStoriesLength] = useState(0);
  const [highlightedText, setHighlightedText] = useState("");
  const [seoInformation,setSeoInformation] = useState([]);
  const [miscTagsConfiguration,setMiscTagsConfiguration] = useState([]);
  const [miscTagsConfigurationLength,setMiscTagsConfigurationLength] = useState(0);
  const [contentType,setContentType] = useState('');
  const [nextStory,setNextStory] = useState([]);
  const [prevStory,setPrevStory] = useState([]);
  const [nextStoryLength,setNextStoryLength] = useState(0);
  const [prevStoryLength,setPrevStoryLength] = useState(0);
  const [prevStartIndex,setPrevStartIndex] = useState(0);
  const [prevEndIndex,setPrevEndIndex] = useState(1);
  const [nextStartIndex,setNextStartIndex] = useState(0);
  const [nextEndIndex,setNextEndIndex] = useState(1);
  const [previousStoryLabel,setPreviousStoryLabel] = useState("");
  const [nextStoryLabel,setNextStoryLabel] = useState("");
  const [screenSize,setScreenSize] = useState(1920);
  const [shareButtonLabel, setShareButtonLabel] = useState("SHARE")
  const [storyShareButtons,setStoryShareButtons] = useState({});
  const [storyBannerImageWidth, setStoryBannerImageWidth] = useState('');
  const [storyBannerImageHeight, setStoryBannerImageHeight] = useState('')

  useEffect(() => {

    if(window.screen.width!==undefined)
    {
      setScreenSize(window.screen.width);
    }

    const fetchData = async () => {
      let data:any;

      if (alias1 !== undefined && alias1 != "" && alias2 !== undefined && alias2 != "") {
        data = await fetchDynamicPage(alias1+"/"+alias2);
      }
      else if(alias1 !== undefined && alias1 != "") {
        data = await fetchDynamicPage(alias1);
      }
      else {
        let fullAlias = '';
        let pathArray = location.pathname.split('/');
        const pattern = /[a-z]/;
        if (pathArray[1] !== undefined && pathArray[1].match(pattern) && pathArray[1].length < 3) {

          pathArray = pathArray.filter(function (el) {
            return el != "";
          });
          pathArray.shift();
          fullAlias = pathArray.join('/');
        }
        else {
          pathArray = pathArray.filter(function (el) {
            return el != "";
          });
          fullAlias = pathArray.join('/');
        }
        if(fullAlias !== "") {
          data = await fetchDynamicPage(fullAlias);
        }
      }

      if (!data || !data.components) {
          return;
      }
      if(data.content_type!==undefined && data.content_type!=="")
      {
        setContentType(data.content_type);
      }

      if(data.content_type=="designer")
      {
        if (data.page.title !== undefined && data.page.title !== "") {
          setTitle(data.page.title);
        }
        if (data.page.heading !== undefined && data.page.heading !== "") {
          setHeading(data.page.heading);
        }
        
      }

      if(data.content_type=="story")
      { 
        let allNextStories:any=[];

        await client.getEntries({ 
          content_type: 'story',
          'fields.domains': globalData.domain,
          'locale': currLocale,
          'order': 'sys.createdAt',
          'sys.createdAt[gt]': data.page.createdAt,
          })
          .then( async (response) => {
            if(response.items.length>0)
            {
              response.items.map(async(storyData:any,i:any)=>
              {
                 let singleStoryItem = {
                  title: '',
                  urlAlias:'',
                  storyBannerImage:"",
                   storyBannerImageAltText:''
                  }
                if(storyData.fields !== undefined)
                {
                  if(storyData.fields.parentPage !== undefined)
                  {
                    if(storyData.fields.parentPage.fields.title == data.parentPage)
                    {
                      if(storyData.fields.title!==undefined && storyData.fields.title!=="")
                      {
                        singleStoryItem['title'] = storyData.fields.title;
                      }
                      if(storyData.fields.urlAlias!==undefined && storyData.fields.urlAlias!=="")
                      {
                        singleStoryItem['urlAlias'] = storyData.fields.urlAlias;
                      }
                      if(storyData.fields.storyBannerImage!==undefined && storyData.fields.storyBannerImage!=="")
                      {
                        singleStoryItem['storyBannerImage'] = storyData.fields.storyBannerImage.fields.file.url;
                        singleStoryItem['storyBannerImageAltText'] = storyData.fields.storyBannerImage.fields.title;
                      }
                       allNextStories.push(singleStoryItem);
                       allNextStories.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                       
                       await setNextStory(allNextStories);
                       await setNextStoryLength(allNextStories.length)
                    }
                  }
                }
              })
              
            }
          })

        let allPreviousStories:any=[];

        await client.getEntries({ 
          content_type: 'story',
          'fields.domains': globalData.domain,
          'locale': currLocale,
          'order': 'sys.createdAt',
          'sys.createdAt[lt]': data.page.createdAt,
          })
          .then( async (response) => {
            if(response.items.length>0)
            {
              response.items.map(async(storyData:any,i:any)=>
              {
                 let singleStoryItem = {
                  title: '',
                  urlAlias:'',
                  storyBannerImage:"",
                   storyBannerImageAltText:'',
                  }

                if(storyData.fields !== undefined)
                {
                  if(storyData.fields.parentPage !== undefined)
                  {
                    if(storyData.fields.parentPage.fields.title == data.parentPage)
                    {
                      
                      if(storyData.fields.title!==undefined && storyData.fields.title!=="")
                      {
                        singleStoryItem['title'] = storyData.fields.title;
                      }
                      if(storyData.fields.urlAlias!==undefined && storyData.fields.urlAlias!=="")
                      {
                        singleStoryItem['urlAlias'] = storyData.fields.urlAlias;
                      }
                      if(storyData.fields.storyBannerImage!==undefined && storyData.fields.storyBannerImage!=="")
                      {
                        singleStoryItem['storyBannerImage'] = storyData.fields.storyBannerImage.fields.file.url;
                        singleStoryItem['storyBannerImageAltText'] = storyData.fields.storyBannerImage.fields.title;
                      }
                        allPreviousStories.push(singleStoryItem);
                       allPreviousStories.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                       
                       await setPrevStory(allPreviousStories);
                       await setPrevStoryLength(allPreviousStories.length)
                    }
                  }
                }
              })
              
            }
          })

         if(data.sliderNextStoryLabel!=undefined && data.sliderNextStoryLabel!="")
         {
           setNextStoryLabel(data.sliderNextStoryLabel);
         }  

         if(data.sliderPreviousStoryLabel!=undefined && data.sliderPreviousStoryLabel!="")
         {
           setPreviousStoryLabel(data.sliderPreviousStoryLabel);
         } 

        if(data.seoInformation!=undefined && data.seoInformation!="")
        {
          let allMescTags:any=[];
          setSeoInformation((seoInformation)=>seoInformation.concat(data.seoInformation)); 
          const script = document.createElement("script");
          script.type = 'application/json';
          script.innerHTML = JSON.stringify(data.seoInformation.structuredData);   
          document.body.appendChild(script);

          if(data.seoInformation.metaDescription!=undefined && data.seoInformation.metaDescription!="")
          {
            document.getElementsByTagName("meta")[3].content=data.seoInformation.metaDescription;              
          }

          if(data.seoInformation.miscTagsConfiguration!=undefined && data.seoInformation.miscTagsConfiguration!="")
              {
                data.seoInformation.miscTagsConfiguration.map((item:any,i:any)=>
                 client.getEntry(item.sys.id).then(async(entry : any) => {
                     let singleMescTag={
                         name:"",
                         content:""
                      }
                  if(entry!=undefined && entry!="")
                  {
                    if(entry.fields.name!=undefined && entry.fields.name!="")
                    {
                        singleMescTag["name"]=entry.fields.name;
                    }   

                    if(entry.fields.value!=undefined && entry.fields.value!="")
                    {
                        singleMescTag["content"]=entry.fields.value;
                    }   
                    allMescTags.splice(i,0,singleMescTag);
                    await setMiscTagsConfiguration(allMescTags);
                    await setMiscTagsConfigurationLength(allMescTags.length);
                  }
                 }) 
                )
          }
        }

        if(data.page.shareButtons !== undefined && data.page.shareButtons !== "")
        {
          if(data.page.shareButtons.fields !== undefined && data.page.shareButtons.fields !== "")
          {
            let obj={}
            if (!Object.keys(obj).length) {
              if(data.page.shareButtons.fields.shareLabel !== undefined && data.page.shareButtons.fields.shareLabel !== "")
              {
                setShareButtonLabel(data.page.shareButtons.fields.shareLabel )
              }
              
              if(data.page.shareButtons.fields.facebookButton !== undefined && data.page.shareButtons.fields.facebookButton !== "")
              {
                Object.assign(obj, { "facebookButton": data.page.shareButtons.fields.facebookButton});
              }
              if(data.page.shareButtons.fields.twitterButton !== undefined && data.page.shareButtons.fields.twitterButton !== "")
              {
                Object.assign(obj, { "twitterButton": data.page.shareButtons.fields.twitterButton});
              }
              if(data.page.shareButtons.fields.linkedInButton !== undefined && data.page.shareButtons.fields.linkedInButton !== "")
              {
                Object.assign(obj, { "linkedInButton": data.page.shareButtons.fields.linkedInButton});
              }
              if(data.page.shareButtons.fields.pinterestButton !== undefined && data.page.shareButtons.fields.pinterestButton !== "")
              {
                Object.assign(obj, { "pinterestButton": data.page.shareButtons.fields.pinterestButton});
              }
              if(data.page.shareButtons.fields.emailButton !== undefined && data.page.shareButtons.fields.emailButton !== "")
              {
                Object.assign(obj, { "emailButton": data.page.shareButtons.fields.emailButton});
              }
            }
            setStoryShareButtons(obj);
          }
        }
        
        if (data.page.title !== undefined && data.page.title !== "") {
          setTitle(data.page.title);
        }

        if (data.page.tags !== undefined && data.page.tags !== "") {
          if (data.page.tags.tagLabel !== undefined && data.page.tags.tagLabel !== "") {
            setTagLabel(data.page.tags.tagLabel ? data.page.tags.tagLabel : "");
          }
          
          if (data.page.tags!== undefined && data.page.tags !== "") {
            let allTagNames: any = [];
            if(Object.keys(data.page.tags).length !== 0){
              data.page.tags.tagNames.map(async (item: any, i: any) => {
                if (item.sys.id !== undefined && item.sys.id !== "") {  

                  await client
                    .getEntry(item.sys.id)
                    .then(async (entry: any) => { 

                      if (entry !== undefined && entry !== "") {
                        if (entry.fields.tagName !== undefined && entry.fields.tagName !== "") {
                          allTagNames.splice(i, 0, entry.fields.tagName);
                          setTagNames(allTagNames);
                          setTagNamesLength(allTagNames.length);
                        }
                      }
                    })
                    .catch(console.error);
                }
              });
            }

          }
        }
        
        if (data.relatedStories !== undefined && data.relatedStories !== "") {
          let allStories:any=[];
            data.relatedStories.map((item:any,i:any)=>
            {
              let singleStory={
              title:"",
              description:"",
              image:"",
              imageAltText:"",
              id:"",
              urlAlias:""
              }
              if (item.fields != "" && item.fields != undefined) {
                if (item.fields.title != "" && item.fields.title != undefined) {
                      singleStory["title"] = item.fields.title;
                } 

                if (item.fields.description != "" && item.fields.description != undefined) {
                      singleStory["description"] = item.fields.description;
                } 

                if (item.fields.storyBannerImage != "" && item.fields.storyBannerImage != undefined) {
                      singleStory["image"] = item.fields.storyBannerImage.fields.file.url;
                      singleStory['imageAltText']= item.fields.storyBannerImage.fields.title;
                }

                if (item.fields.urlAlias != "" && item.fields.urlAlias != undefined) {
                  singleStory["urlAlias"]=item.fields.urlAlias;
                }
              }

              if (item.sys.id != "" && item.sys.id != undefined) {
                    singleStory["id"] = item.sys.id;
              }

              allStories.splice(i,0,singleStory);
              setAllRelatedStories(allStories);
              setAllRelatedStoriesLength(allStories.length);
            })
        }

        if(screenSize<767)
        {
          if(data.page.mobileStoryBannerImage !== undefined && data.page.mobileStoryBannerImage !== "") {
           setStoryBannerImage(data.page.mobileStoryBannerImage);
           setStoryBannerImageAltText(data.page.mobileStoryBannerImageAltText);
          }
          else if(data.page.storyBannerImage !== undefined && data.page.storyBannerImage !== "")
          {
            setStoryBannerImage(data.page.storyBannerImage);
            setStoryBannerImageAltText(data.page.storyBannerImageAltText); 
          }
          setStoryBannerImageWidth('360')
          setStoryBannerImageHeight('480')
        }
        else if(screenSize>767)
        {
          if(data.page.storyBannerImage !== undefined && data.page.storyBannerImage !== "") {
            setStoryBannerImage(data.page.storyBannerImage);   
            setStoryBannerImageAltText(data.page.storyBannerImageAltText);            
          } 
          else if (data.page.mobileStoryBannerImage !== undefined && data.page.mobileStoryBannerImage !== "") {
            setStoryBannerImage(data.page.mobileStoryBannerImage);
            setStoryBannerImageAltText(data.page.mobileStoryBannerImageAltText);    
          }
          setStoryBannerImageWidth('2236')
          setStoryBannerImageHeight('890')
        }

        if (data.page.description !== undefined && data.page.description !== "") {
          await setDescription(data.page.description);  
        }
        
        if (data.page.highlightedText !== undefined && data.page.highlightedText !== "") {
          await setHighlightedText(data.page.highlightedText);
        }

      }


      if (data.allAliases !== undefined && data.allAliases !== "") {
        props.changeAliase(data.allAliases);
      }

      if(data.seoInformation!=undefined && data.seoInformation!="")
      {
         let allMescTags:any=[];
        setSeoInformation((seoInformation)=>seoInformation.concat(data.seoInformation)); 
        const script = document.createElement("script");
        script.type = 'application/json';
        script.innerHTML = JSON.stringify(data.seoInformation.structuredData);   
        document.body.appendChild(script);

        if(data.seoInformation.metaDescription!=undefined && data.seoInformation.metaDescription!="")
        {
          document.getElementsByTagName("meta")[3].content=data.seoInformation.metaDescription;              
        }

       if(data.seoInformation.miscTagsConfiguration!=undefined && data.seoInformation.miscTagsConfiguration!="")
           {
             data.seoInformation.miscTagsConfiguration.map((item:any,i:any)=>
              client.getEntry(item.sys.id).then(async(entry : any) => {
                  let singleMescTag={
                      name:"",
                      content:""
                   }
               if(entry!=undefined && entry!="")
               {
                 if(entry.fields.name!=undefined && entry.fields.name!="")
                 {
                     singleMescTag["name"]=entry.fields.name;
                 }   

               if(entry.fields.value!=undefined && entry.fields.value!="")
                 {
                     singleMescTag["content"]=entry.fields.value;
                 }   
                 allMescTags.splice(i,0,singleMescTag);
                 await setMiscTagsConfiguration(allMescTags);
                 await setMiscTagsConfigurationLength(allMescTags.length);
               }
              }) 
             )
         }
      }

      setData(data.components ? data.components : null);

    };

    fetchData();
  }, [screenSize]);
  

const selectPrevStory=()=>
{
  if(prevEndIndex<prevStoryLength)
  {
    setPrevStartIndex(prevStartIndex+1)
    setPrevEndIndex(prevEndIndex+1)
  }
  else
  {
    setPrevStartIndex(0)
    setPrevEndIndex(1)
  }
  
}

const selectNextStory=()=>
{
  if(nextEndIndex<nextStoryLength)
  {
   setNextStartIndex(nextStartIndex+1)
   setNextEndIndex(nextEndIndex+1) 
  }
  else
  {
    setNextStartIndex(0)
    setNextEndIndex(1)
  }
}

 
const slickSettings = {     
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      slidesToShow: 2
    };  

  const { favicon } = globalData;

  return (
    <div className="savers-page" id="maincontent">
      {seoInformation.length>0?seoInformation.map((item:any)=>
                <Helmet>
                {item.pageTitle?<title>{item.pageTitle}</title>:null}
                {item.keywords?<meta name="keywords" content={item.keywords} />:null}
                {item.shortDescription?<meta name="short-description" content={item.shortDescription} />:null}
                {item.longDescription?<meta name="long-description" content={item.longDescription} />:null}
                {item.pageTitle?<meta property="og:title" content={item.pageTitle} />:null}
                {item.contentType?<meta property="og:type" content={item.contentType} />:null}
                {item.image?<meta property="og:image" content={item.image} />:null}
                {item.longDescription?<meta name="og:description" content={item.longDescription} />:null}
                <meta property="og:url" content={originPath} />
                {item.canonical?<link rel="canonical" href={item.canonical} />:null}
                <link rel="icon" href={'/' + favicon} />
                {miscTagsConfiguration.length>=1?miscTagsConfiguration.map((item:any)=>
                        <meta name={item.name} content={item.content}/>
                     ):null
                    }
                {item.noIndex==true||item.noIndex==false?<meta name="robots" content={item.noIndex}/>:null}

                {globalData.domain == "2ndavestorescom-react-test.metaltoad-sites.com" || globalData.domain == "2ndavestores.com" ?
                  <script async src="https://www.googletagmanager.com/gtag/js?id=G-EZRP4HG33V"></script>
                : null}
                {globalData.domain == "2ndavestorescom-react-test.metaltoad-sites.com" || globalData.domain == "2ndavestores.com" ?
                  <script type="text/javascript">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-EZRP4HG33V');`}</script>
                : null}
                {globalData.domain == "gogreendropca-react-test.metaltoad-sites.com" || globalData.domain == "gogreendrop.ca" ?
                  <script async src="https://www.googletagmanager.com/gtag/js?id=G-R0HSL6DE0D"></script>
                : null}
                {globalData.domain == "gogreendropca-react-test.metaltoad-sites.com" || globalData.domain == "gogreendrop.ca" ?
                  <script type="text/javascript">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-R0HSL6DE0D');`}</script>
                : null}
                </Helmet>):null}
      {
        contentType=="story"?
        <>
          <div className="small-container text-center mt-2">
            {title ?
              <h5 className="pt-3 mb-3" dangerouslySetInnerHTML={{ __html:title}}></h5>
              : ""
            }
            {tagLabel || tagNames ?
              <p className="small-text">{tagLabel? "("+tagLabel+") ": ""}
              {tagNames.map(
                (tagItem, i) => (
                  <>{i === 0 ? tagItem: ", "+tagItem}</>
                )
              )}
              </p>
              : ""
            }
          </div>
           {highlightedText?
            <div className="small-container">
              <p className="small-text gray-color">
                <ReactMarkdown allowDangerousHtml source={highlightedText} disallowedTypes={['paragraph']} unwrapDisallowed />
              </p>
            </div>
            :""
          }
          {
            Object.entries(storyShareButtons).length !== 0
            ?
            <ul className="stroy-share-icons">
            {shareButtonLabel}
            {
              Object.entries(storyShareButtons).map((data, index)=>{
                let shareCurrentPage= window.location.href;
                let shareTitle= title;
                let shareImage= "https:"+storyBannerImage;
                let shareDescription= description;
                if(data[0]=="facebookButton" && data[1]==true)
                {
                  return(                 
                  <li><a href={`https://facebook.com/sharer.php?u=${shareCurrentPage}`} aria-label="facebook link" target="_blank">
                   <i className="fa fa-facebook" aria-hidden="true"/> 
                  </a></li>)
                }

                if(data[0]=="twitterButton" && data[1]==true)
                {
                  return(                 
                  <li><a href={`https://twitter.com/share?text=${shareTitle}&url=${shareCurrentPage}`} aria-label="twitter link" target="_blank">
                   <i className="fa fa-twitter" aria-hidden="true"/> 
                  </a></li>)
                }

                if(data[0]=="linkedInButton" && data[1]==true)
                {
                  return(                 
                  <li><a href={`http://www.linkedin.com/shareArticle?mini=true&url=${shareCurrentPage}&title=${shareTitle}`} aria-label="linkedin link" target="_blank">
                   <i className="fa fa-linkedin" aria-hidden="true"/> 
                  </a></li>)
                }

                if(data[0]=="pinterestButton" && data[1]==true)
                {
                  return(                 
                  <li><a href={`http://pinterest.com/pin/create/button/?url=${shareCurrentPage}&media=${shareImage}&description=${shareTitle}`} aria-label="pinterest link" target="_blank">
                   <i className="fa fa-pinterest" aria-hidden="true"/> 
                  </a></li>)
                }

                if(data[0]=="emailButton" && data[1]==true)
                {
                  return(                 
                  <li><a href={`mailto:?SUBJECT=${shareTitle}&BODY=${shareCurrentPage}`} aria-label="envelope link" target="_blank">
                   <i className="fa fa-envelope" aria-hidden="true"/> 
                  </a></li>)
                }
                
              })
            }
            </ul>
            :
            null
          }
          {storyBannerImage ?
            <div className="full-bleed-feature-media mb-5 mt-5">
              <img src={storyBannerImage} width={storyBannerImageWidth} height={storyBannerImageHeight} alt={storyBannerImageAltText ? storyBannerImageAltText : ""}/>
            </div>
            : ""
          }
          {description?
            <div className="small-container ptb-80">
              <div className="longform-text"> 
                <ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed />
              </div>
            </div>
            :""
          }
          {data && data.length>0 && data.map((d, i) => <ComponentComposer key={i} {...d} />)}
          {allRelatedStories.length>=1?
            <div className="related-story-block">
              <div className="container">
                <h4 className="mb-4">Related stories</h4>
                <div className="row">
                {allRelatedStories?allRelatedStories.map((item:any)=>
                  <div className="col-md-4">
                    <div className="grid-box">
                      <div className="media-row mb-4">
                        {item.image?<img src={item.image} alt={item.imageAltText?item.imageAltText:""}/>:null}
                      </div>
                      <div className="media-info">
                        {item.title?<h5>{item.title}</h5>:null}
                        {item.description?item.description.length<135?<ReactMarkdown allowDangerousHtml source={item.description}></ReactMarkdown>:<ReactMarkdown allowDangerousHtml source={item.description.substring(0,135)+"........"}></ReactMarkdown>:null}
                        <a className="small-text" href={"/"+item.urlAlias}>Read more</a>
                      </div>
                    </div>
                  </div>):null
                }
                </div>
              </div>
            </div>:null
          }
       {prevStoryLength>0 || nextStoryLength>0?
            <div className="next-prevs-slider">
              <div id="next-prvs" >
                <div className="container">
                <div className="row">                             
                  <div className="col-6 left">
                  {prevStory?prevStory.slice(prevStartIndex,prevEndIndex).map((data:any,i:any)=>
                    <div className="inner">
                      <div className="mobile-button-wrap desktop-hide">
                        <button className="slick-arrow slick-prev" aria-label="previous-story-button" onClick={(e)=>selectPrevStory()}></button>
                        {data?<h6>{previousStoryLabel!=="" ? previousStoryLabel : "PREVIOUS STORY"}</h6>:null}                  
                      </div> 
                      <div className="slick-arrow slick-prev mobile-hide" onClick={(e)=>selectPrevStory()}></div>
                      <div className="story-media"><a href={"/"+data.urlAlias}> {data.storyBannerImage?<span><img src={data.storyBannerImage} alt={data.storyBannerImageAltText}/></span>:null}</a></div>
                      <div className="story-info">
                        <h6 className="mobile-hide">{previousStoryLabel!=="" ? previousStoryLabel : "PREVIOUS STORY"}</h6>
                        {data.title?<p dangerouslySetInnerHTML={{__html:data.title}}></p>:null}
                      </div>
                    </div>):null}  
                  </div>  
                  <div className="col-6 right">
                  {nextStory?nextStory.slice(nextStartIndex,nextEndIndex).map((data:any)=> 
                    <div className="inner">        
                      <div className="mobile-button-wrap desktop-hide">
                        <h6><a href={data.urlAlias}>{nextStoryLabel!=="" ? nextStoryLabel : "NEXT STORY"}</a></h6>
                        <div className="slick-arrow slick-next" onClick={(e)=>selectNextStory()}></div>
                      </div>                               
                      <div className="story-info">
                        <h6 className="mobile-hide mobile-hide">{nextStoryLabel!=="" ? nextStoryLabel : "NEXT STORY"}</h6>
                        {data.title?<p className="mobile-hide" dangerouslySetInnerHTML={{__html:data.title}}></p>:null}
                      </div>
                      <div className="story-media"><a href={"/"+data.urlAlias}>{data.storyBannerImage?<span><img src={data.storyBannerImage} alt={data.storyBannerImageAltText}/></span>:null}</a></div> 
                      <div className="story-info desktop-hide">     
                        {data.title?<p className="" dangerouslySetInnerHTML={{__html:data.title}}></p>:null}
                      </div>
                      <div className="slick-arrow slick-next mobile-hide" onClick={(e)=>selectNextStory()}></div>
                    </div>):null}
                  </div>  
                </div>
                </div>
              </div>
            </div> 
            :null
          }

          <section className="horizontal-rule" >
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <hr style={{borderTop:'2px solid #FFFFFF',marginTop:"20px"}}></hr>
                  </div>
              </div>
            </div>
          </section>

          <section className="horizontal-rule" >
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <hr style={{borderTop:'2px solid #FFFFFF',marginTop:"40px"}}></hr>
                  </div>
              </div>
            </div>
          </section>

        </>
        :
        <>
          {contentType=="designer"?
            <section className="designer-info">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="heading-inner text-center">
                    <p className="body1">{heading ? heading : 'Designer'}</p> 
                    {title?<h2>{title}</h2>:null}                          
                   </div>
                 </div>
               </div>
            </div>
          </section>:null}
          {data && data.length>0 && data.map((d, i) => <ComponentComposer key={i} {...d} />)}
        </>
        
      }
    </div>
  );
}


const mapDispatchToProps = (dispatch: any) => ({
   changeAliase:(newAlias:any)=>{dispatch(setAllAliases(newAlias))},
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
  allAliases:state.site.allAliases,
});

DynamicPage.defaultProps = {
  fetchSiteData: () => {},
  header: {},
  allAliases:[]
};

export { DynamicPage };

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPage);