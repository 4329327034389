import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const HeroCardSmallTwo: React.FC<any> = (props) => {
    useEffect(() => {
        fetchdata();
        async  function fetchdata() {
        }


    });

    const {

        site_logo,
        storeText,
        storeTextMobile,
        searchText,
        HeaderOptions,
        menuItems
    } = props.header;

    return (
        <div className="hero-card hero-card-small mt-5 mb-5">
            <div className="container">
                <div className="card-inner bg-dblue2">
                    <div className="row">
                        <div className="col-md-6 hero-media-info" style={{paddingLeft:"75px"}}>
                            <h3 className="clr-white">{props.title}</h3>
                            <p>{props.desc}</p>
                            <a href="#" className="small-text-cta cta-white">{props.linkText}<i className="fa fa-caret-right"
                                                                                        aria-hidden="true"></i></a>
                        </div>
                        <div className="col-md-6 hero-media">
                            <img src={props.images[0].card_media} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

HeroCardSmallTwo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { HeroCardSmallTwo };

export default connect(mapStateToProps, mapDispatchToProps)(HeroCardSmallTwo);
