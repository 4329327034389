import React, { useEffect ,useState} from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageCarousel: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [ctaTitle, setCtaTitle] = useState('');
  const [ctaLink, setCtaLink] = useState('');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [ctaClass, setCtaClass] = useState('read-more-link');
  const [activeItemHoverLayer, setActiveItemHoverLayer] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(1);
  const [images, setImages] = useState([]);
  const [imagesLength, setImagesLength] = useState(0);
  const [rightSideSubHead, setRightSideSubHead] = useState('');
  const [itemClass,setItemClass]=useState("");
  const [topPadding, setTopPadding] = useState('');
  const [bottomPadding, setBottomPadding] = useState('');
  const [mobileTopPadding, setMobileTopPadding] = useState('');
  const [mobileBottomPadding, setMobileBottomPadding] = useState('');
  const [entryId, setEntryId] = useState('');
  const [autoplay, setAutoplay] = useState(false);
  const [titleFontColor, setTitleFontColor] = useState("#000000");
  const [subtitleFontColor, setSubtitleFontColor] = useState("#000000");
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [activeItemIndex, setActiveItemIndex] = useState(1);

  useEffect(() => {

    if (
      componentData.title != "" &&
      componentData.title != undefined
    ) {
      setTitle(componentData.title);
    }

     if (
      componentData.titleFontColor != "" &&
      componentData.titleFontColor != undefined
    ) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (
      componentData.subtitle != "" &&
      componentData.subtitle != undefined
    ) {
      setSubtitle(componentData.subtitle);
    }

    if (
      componentData.subtitleFontColor != "" &&
      componentData.subtitleFontColor != undefined
    ) {
      setSubtitleFontColor(componentData.subtitleFontColor);
    }

    if (
      componentData.activeItemHoverLayer != "" &&
      componentData.activeItemHoverLayer != undefined
    ) {
      setActiveItemHoverLayer(componentData.activeItemHoverLayer);
    }

    if (
      componentData.backgroundColor != "" &&
      componentData.backgroundColor != undefined
    ) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (
      componentData.backgroundImage != "" &&
      componentData.backgroundImage != undefined
    ) {
      setBackgroundImage(componentData.backgroundImage.fields.file.url);
    }

    if (
      componentData.topPadding != "" &&
      componentData.topPadding != undefined
    ) {
      setTopPadding(componentData.topPadding);
    }

     if (
      componentData.mobileTopPadding != "" &&
      componentData.mobileTopPadding != undefined
    ) {
      setMobileTopPadding(componentData.mobileTopPadding);
    }


    if (
      componentData.bottomPadding != "" &&
      componentData.bottomPadding != undefined
    ) {
      setBottomPadding(componentData.bottomPadding);
    }

     if (
      componentData.mobileBottomPadding != "" &&
      componentData.mobileBottomPadding != undefined
    ) {
      setMobileBottomPadding(componentData.mobileBottomPadding);
    }

    if (
      componentData.entryId != "" &&
      componentData.entryId != undefined
    ) {
      setEntryId("content-"+componentData.entryId);
    }

    if (
      componentData.mobileBackgroundImage != "" &&
      componentData.mobileBackgroundImage != undefined
    ) {
      setMobileBackgroundImage(componentData.mobileBackgroundImage.fields.file.url);
    }

     if (
      componentData.autoplay != "" &&
      componentData.autoplay != undefined
    ) {
      setAutoplay(componentData.autoplay);
    }

    if (
      componentData.numberOfItemsToShow != "" &&
      componentData.numberOfItemsToShow != undefined
    ) {
        setNumberOfItemsToShow(componentData.numberOfItemsToShow);
        if(componentData.numberOfItemsToShow==1)
        {
          setItemClass("item-one")
        }
        else if(componentData.numberOfItemsToShow=='MixedItems')
        {
          setItemClass("mixed-items")
        }
        else if(componentData.numberOfItemsToShow==3)
        {
          setItemClass("item-three")
        }
        else if(componentData.numberOfItemsToShow==5)
        {
          setItemClass("item-five")
        }
        else if(componentData.numberOfItemsToShow==10)
        {
          setItemClass("item-ten")
        }
    }

     if (
      componentData.images != "" &&
      componentData.images != undefined
    ) {
      let allImages:any=[];
      componentData.images.map(async(item:any,i:any)=>
      {
        let singleImage={
          index: i,
          imagepath:"",
          imageAltText:""
        }
        singleImage['imagepath']=item.fields.file.url;
        singleImage['imageAltText']=item.fields.title;
        allImages.push(singleImage);
        allImages.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
        
        await setImages(allImages);
        await setImagesLength(allImages.length);
      })
    }

    if (
      componentData.rightSideSubHead != "" &&
      componentData.rightSideSubHead != undefined
    ) {
      setRightSideSubHead(componentData.rightSideSubHead);
    }

    if (componentData.ctaLink != '' && componentData.ctaLink != undefined) {
      client.getEntry(componentData.ctaLink.sys.id, {locale: currLocale})
      .then((entry : any) => {
        if (entry != '' && entry != undefined) {
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
            let ctaClassName = 'read-more-link';

            if (entry.fields.ctaType === 'Primary Button') {
              setCtaClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setCtaClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setCtaClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setCtaClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setCtaClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setCtaClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setCtaClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setCtaClass('read-more-link');
            }
          }
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setCtaTitle(entry.fields.title);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setCtaLink(entry.fields.alias);
          }
          if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setHoverColor(entry.fields.hoverColor);
          }

          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setOpenLinkInNewTab("_blank");
          }
        }
      })
      .catch(console.error)
    }

  },[]);

  const slickSettings1 = {     
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: (currentSlide: number)=>{setActiveItemIndex(currentSlide + 1)}
  };


  const slickSettings3 = {     
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: (currentSlide: number)=>{setActiveItemIndex(currentSlide + 1)}
  };

  const slickSettingsmixeditems = {     
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {         
          slidesToScroll: 1
        }
      },
      
      {
        breakpoint: 575,
        settings: {        
          dots: true,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: (currentSlide: number)=>{setActiveItemIndex(currentSlide + 1)}
  };

  return (

    <div className={"carousel-media-block "+ itemClass+" "+ entryId} style={{backgroundColor: backgroundColor}}>
      {activeItemHoverLayer ? <style dangerouslySetInnerHTML=
        {{__html: `
          .carousel-media-block.${entryId}.item-one .owl-slider .slick-slide.slick-active span::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: ${activeItemHoverLayer};
              z-index: 1;
          }
          

          `,
        }}
        />
        : ""
      }

        <style dangerouslySetInnerHTML=
          {{__html: `
            .hidden-aria-section {
              display: none;
            }

            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            } 
            `,
          }}
        />
      {backgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div> : ""}
      {mobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+mobileBackgroundImage+")"}}></div> : ""}
      <div className="container">
        {title?<div className="text-center" style={{color:titleFontColor}}><h2>{title}</h2></div>: ""}
        <div className="mb-4 carousel-heading-row">
          {subtitle?<h4 style={{color:subtitleFontColor}}>{subtitle}</h4>:null}
          {rightSideSubHead?<p className="right-side-sub-head"><ReactMarkdown allowDangerousHtml source={rightSideSubHead} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:""}
           {ctaTitle ? 
             <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)}>{ctaTitle}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                  <span className="svg-outer">
                    <svg width="387" height="12" viewBox="0 0 387 12">
                      <defs>
                        <clipPath>
                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                        </clipPath>
                      </defs>
                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                      </g>
                    </svg>          
                  </span>
                  <span>           
                  </span>
              </a>
              : ""
            }

        </div>
      </div>
    
      <div className="container-fluid">
        <div aria-live="polite" aria-atomic="true" className="hidden-aria-section" aria-labelledby="image-carousel">Item {activeItemIndex} of {images.length}</div>
        <div className="owl-slider" id="image-carousel">
        {numberOfItemsToShow == 3 ?
          <Slider {...slickSettings3}>
            {images
                ? images.map((item:any) =>
              <div className="item">
                <span>
                {item.imagepath?<LazyLoadImage  src={item.imagepath} alt={item.imageAltText?item.imageAltText:""} />:null}
                </span>
              </div>):null
            }
          </Slider>
          :
          numberOfItemsToShow == 1 ?
          <Slider {...slickSettings1}>
            {images
                ? images.map((item:any) =>
              <div className="item">
                <span>
                {item.imagepath?<img src={item.imagepath} alt={item.imageAltText?item.imageAltText:""}/>:null}
                </span>
              </div>):null
            }
          </Slider>
          :
          <Slider {...slickSettingsmixeditems}>
            {images
                ? images.map((item:any) =>
              <div className="item">
                <span>
                {item.imagepath?<LazyLoadImage  src={item.imagepath} alt={item.imageAltText?item.imageAltText:""}/>:null}
                </span>
              </div>):null
            }
          </Slider>
        }
        </div>
      </div>
   </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

ImageCarousel.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { ImageCarousel };

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel);

