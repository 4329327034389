import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { getGlobalData, getLocale } from "../../api/cms/api";

const ContactSearchBanner: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [title, setTitle] = useState("");
  const [titleFontColor, setTitleFontColor] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [subTitleFontColor, setSubTitleFontColor] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundMobileImage, setBackgroundMobileImage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [categoryFieldLabel, setCategoryFieldLabel] = useState("");
  const [keywordFieldLabel, setKeywordFieldLabel] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [contactUsCategories, setContactUsCategories] = useState([]);
  const [contactUsCategoriesLength, setContactUsCategoriesLength] = useState(0);
  const [formCategory, setFormCategory] = useState("");
  const [formKeyword, setFormKeyword] = useState("");

  const { domain } = getGlobalData();

  useEffect(() => {

    $(".select-outer label").css("display", "none");
    $(".select-outer label").css("display", "none");


    let search:any= "";
    const urlParams = new URLSearchParams(window.location.search);

    let params:any= urlParams.get('contact_category');

    if(params !== null) {
      search = urlParams.get('contact_category');
      search = search.replace(/_/g, ' ');
    }
    else {
      search = window.location.search;
      search = search.replace(/[?]/g, "");
    }

    if(search == "scroll-to-contact-form") {
      let element = (document.getElementById("contact-form") as HTMLInputElement);
      let scrollInterval = setInterval(()=>{
        element.scrollIntoView({behavior:"smooth",block: "nearest"});
      },200)

      setTimeout(()=>{
        clearInterval(scrollInterval)
      },1000)
      
    }

    if (componentData.title !== undefined && componentData.title !== "") {
      setTitle(componentData.title);
    }

    if (componentData.titleFontColor !== undefined && componentData.titleFontColor !== "") {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.subTitle !== undefined && componentData.subTitle !== "") {
      setSubTitle(componentData.subTitle);
    }

    if (componentData.subTitleFontColor !== undefined && componentData.subTitleFontColor !== "") {
      setSubTitleFontColor(componentData.subTitleFontColor);
    }

    if (componentData.backgroundImage !== undefined && componentData.backgroundImage !== "") {
      setBackgroundImage(componentData.backgroundImage.fields.file.url);
    }

    if (componentData.backgroundMobileImage !== undefined && componentData.backgroundMobileImage !== "") {
      setBackgroundMobileImage(componentData.backgroundMobileImage.fields.file.url);
    }

    if (componentData.backgroundColor !== undefined && componentData.backgroundColor !== "") {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.categoryFieldLabel !== undefined && componentData.categoryFieldLabel !== "") {
      setCategoryFieldLabel(componentData.categoryFieldLabel);
    }

    if (componentData.keywordFieldLabel !== undefined && componentData.keywordFieldLabel !== "") {
      setKeywordFieldLabel(componentData.keywordFieldLabel);
    }

    if (componentData.buttonColor !== undefined && componentData.buttonColor !== "") {
      setButtonColor(componentData.buttonColor);
    }

    if (componentData.buttonTextColor !== undefined && componentData.buttonTextColor !== "") {
      setButtonTextColor(componentData.buttonTextColor);
    }

    client.getEntries({
      'content_type': 'contactUsCategory',
      'locale': currLocale
    })
    .then( async (response: any) => {
      if (response.items !== undefined && response.items !== '' && response.items.length > 0) {
        let allContactUsCategories: any = [];

        response.items.map(async (item: any, i:any) => {
          const singleContactUsCategory = {
            index: i,
            id: item.sys.id,
            title: item.fields?.title,
            domains: item.fields?.domains
          }

          /**
           * show categories based in the current domain
           */

          const showContent = singleContactUsCategory.domains?.includes(domain);

          if (!showContent) {
            return;
          }

          if(item.fields.title === search)
          {
            if(item.sys.id !== undefined && item.sys.id !== "")
            {
              setTimeout(
              () => {
                jQuery('#category').val(item.sys.id).trigger('change');
                jQuery('[entry-id="'+item.sys.id+'"]').click();
                jQuery('.tab-entry-'+item.sys.id+' .card-header a').click();
                let element = (document.getElementById("responsive-tabs-content") as HTMLInputElement);
                element.scrollIntoView({behavior:"smooth", block: 'center'});
              },500);
            }
          }
          allContactUsCategories.push(singleContactUsCategory);
          allContactUsCategories.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
        });

        setContactUsCategories(allContactUsCategories);
        setContactUsCategoriesLength(allContactUsCategories.length);
      }
    })
    .catch(console.error);

  }, []);


  const handleFormSubmit= async (event: React.FormEvent<HTMLFormElement>) => {
     event.preventDefault();
     event.stopPropagation();
     jQuery('[entry-id="'+formCategory+'"]').click();
  };

  const handleChange= async (event: any) => {
     event.preventDefault();
     event.stopPropagation();
     if (event.target.value !== undefined && event.target.value !== "") {
      jQuery('[entry-id="'+event.target.value+'"]').click();
      jQuery('.tab-entry-'+event.target.value+' .card-header a').click();
      let element = (document.getElementById("responsive-tabs-content") as HTMLInputElement);
      element.scrollIntoView({behavior:"smooth", block: 'center'});
    }
    
  };


  return (
    <div className="banner-with-solid-bg banner-with-bg-media contact-banner bg6" style={{backgroundColor: backgroundColor}}>
      {backgroundImage ? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div>: ""}
      {backgroundMobileImage ? <div className="mobile-bg" style={{backgroundImage: "url("+backgroundMobileImage+")"}}></div>: ""}
      <div className="container">
        <div className="banner-info">
          {title ? <h2 className="clr-white" dangerouslySetInnerHTML={{ __html:title}} style={{color: titleFontColor}}></h2> : ""}
          {subTitle ? <h4 className="clr-white" dangerouslySetInnerHTML={{ __html:subTitle}} style={{color: subTitleFontColor}}></h4> : ""}
        </div>
        <form className="banner-form" onSubmit={handleFormSubmit}>
          <div className="select-outer">
            <label htmlFor="category">category list</label>
            <select name="category" id="category" onChange={handleChange} tabIndex={0}>
              <option value="" disabled selected>{categoryFieldLabel? categoryFieldLabel: "Select your option"}</option>
              {contactUsCategoriesLength > 0 ?
                contactUsCategories.map((item: any, i: any) => 
                  <option key={item.id} value={item.id} aria-label={item.title} dangerouslySetInnerHTML={{ __html:item.title}}></option>
                ) : ""
              }
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

ContactSearchBanner.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { ContactSearchBanner };

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearchBanner);
