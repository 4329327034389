import React, { useEffect ,useState} from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getGlobalData, getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactMarkdown from 'react-markdown';

const CarouselMediaBlock: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [heading, setHeading] = useState("");
  const [isCarousel, setIsCarousel] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [ctaLinks, setCtaLinks] = useState([]);
  const [carouselItems, setCarouselItems] = useState([]);
  const [carouselItemsLength, setCarouselItemsLength] = useState(0);
  const [sectionBackgroundColor, setSectionBackgroundColor] = useState('');
  const [sectionBackgroundImage, setSectionBackgroundImage] = useState('');
  const [sectionMobileBackgroundImage, setSectionMobileBackgroundImage] = useState('');
  const [autoplay, setAutoplay] = useState(false);
  const [numberOfSlides, setNumberOfSlides] = useState(1);
  const [itemClass,setItemClass]=useState("");
  const [bottomLayer, setBottomLayer] = useState(false);
  const [topLayer, setTopLayer] = useState(false);
  const [topPadding, setTopPadding] = useState('');
  const [bottomPadding, setBottomPadding] = useState('');
  const [mobileTopPadding, setMobileTopPadding] = useState('');
  const [mobileBottomPadding, setMobileBottomPadding] = useState('');

  const globalData = getGlobalData();
  const storeSite = globalData.storeSite;
  const is2ndAve = storeSite === "2a";

  const rightArrow = require("../../images/2A_scroll_btn_right_45x45.png");
  const leftArrow = require("../../images/2A_scroll_btn_left_45x45.png");

  const [entryId, setEntryId] = useState('');


  useEffect(() => {
    if (
      componentData.heading != "" &&
      componentData.heading != undefined
    ) {
      setHeading(componentData.heading);
    }

    if (
      componentData.carousel != "" &&
      componentData.carousel != undefined
    ) {
      setIsCarousel(componentData.carousel);
    }

     if (
      componentData.autoplay != "" &&
      componentData.autoplay != undefined
    ) {
      setAutoplay(componentData.autoplay);
    }

     if (
      componentData.sectionBackgroundColor != "" &&
      componentData.sectionBackgroundColor != undefined
    ) {
      setSectionBackgroundColor(componentData.sectionBackgroundColor);
    }

     if (
      componentData.sectionBackgroundImage != "" &&
      componentData.sectionBackgroundImage != undefined
    ) {
      setSectionBackgroundImage(componentData.sectionBackgroundImage.fields.file.url);
    }

    if (
      componentData.sectionBackgroundMobileImage != "" &&
      componentData.sectionBackgroundMobileImage != undefined
    ) {
      setSectionMobileBackgroundImage(componentData.sectionBackgroundMobileImage.fields.file.url);
    }

    if (
      componentData.numberOfSlides != "" &&
      componentData.numberOfSlides != undefined
    ) {
        setNumberOfSlides(componentData.numberOfSlides);
        if(componentData.numberOfSlides==2)
        {
          setItemClass("item-two")
        }
        else if(componentData.numberOfSlides==3)
        {
          setItemClass("item-three")
        }         
    }

    if (componentData.bottomLayer != undefined && componentData.bottomLayer != "") {
      setBottomLayer(componentData.bottomLayer);
    }

    if (componentData.topLayer != undefined && componentData.topLayer != "") {
      setTopLayer(componentData.topLayer);
    }

    if (
      componentData.entryId != "" &&
      componentData.entryId != undefined
    ) {
      setEntryId("content-"+componentData.entryId);
    }

    if (
      componentData.topPadding != "" &&
      componentData.topPadding != undefined
    ) {
      setTopPadding(componentData.topPadding);
    }

     if (
      componentData.mobileTopPadding != "" &&
      componentData.mobileTopPadding != undefined
    ) {
      setMobileTopPadding(componentData.mobileTopPadding);
    }


    if (
      componentData.bottomPadding != "" &&
      componentData.bottomPadding != undefined
    ) {
      setBottomPadding(componentData.bottomPadding);
    }

     if (
      componentData.mobileBottomPadding != "" &&
      componentData.mobileBottomPadding != undefined
    ) {
      setMobileBottomPadding(componentData.mobileBottomPadding);
    }



    if (
      componentData.carouselItems != "" &&
      componentData.carouselItems != undefined
    ) {
      let allCarouselItems: any = [];
      componentData.carouselItems.map((item: any,i:any) => {
      let singleCarouselItem = {
            index: i,
            title: "",
            description: "",
            descriptionColor: "",
            backgroundColor: "",
            ctaTitle:"",
            ctaAriaLabel:"",
            ctaLink:"",
            ctaClass:"",
            ctaButtonColor:"",
            ctaButtonTextColor:"",
            ctaTextColor:"",
            ctaUnderlineColor:"",
            ctaZigzaglineColor:"",
            hoverColor:"",
            openLinkInNewTab:"",
            backgroundImage: "",
            textFontColor: "",
            tag: "",
            tagBackgroundColor: "",
            tagTextColor: "",
            icon: "",
            iconAltText:''
          };         
        client
          .getEntry(item.sys.id, {locale: currLocale})
          .then( async (entry: any) => {
            if (entry.fields.backgroundImage != undefined && entry.fields.backgroundImage != "") 
            {
              singleCarouselItem["backgroundImage"] = entry.fields.backgroundImage.fields.file.url;
            }

            if (entry.fields.icon != undefined && entry.fields.icon != "") 
            {
              singleCarouselItem["icon"] = entry.fields.icon.fields.file.url;
              singleCarouselItem["iconAltText"] = entry.fields.icon.fields.title;
            }

            if (entry.fields.title != undefined && entry.fields.title != "") 
            {
              singleCarouselItem["title"] = entry.fields.title;
            }

            if (entry.fields.description != undefined && entry.fields.description != "") 
            {
              singleCarouselItem["description"] = entry.fields.description;
            }

            if (entry.fields.descriptionColor != undefined && entry.fields.descriptionColor != "") 
            {
              singleCarouselItem["descriptionColor"] = entry.fields.descriptionColor;
            }

            if (entry.fields.backgroundColor != undefined && entry.fields.backgroundColor != "") 
            {
              singleCarouselItem["backgroundColor"] = entry.fields.backgroundColor;
            }

            if (entry.fields.textFontColor != undefined && entry.fields.textFontColor != "") 
            {
              singleCarouselItem["textFontColor"] = entry.fields.textFontColor;
            }

            if (entry.fields.tag != undefined && entry.fields.tag != "") 
            {
              singleCarouselItem["tag"] = entry.fields.tag;
            }

            if (entry.fields.tagBackgroundColor != undefined && entry.fields.tagBackgroundColor != "") 
            {
              singleCarouselItem["tagBackgroundColor"] = entry.fields.tagBackgroundColor;
            }

            if (entry.fields.tagTextColor != undefined && entry.fields.tagTextColor != "") 
            {
              singleCarouselItem["tagTextColor"] = entry.fields.tagTextColor;
            }

            if (entry.fields.icon != undefined && entry.fields.icon != "") 
            {
              singleCarouselItem["icon"] = entry.fields.icon.fields.file.url;
            }

            if (entry.fields.cta != undefined && entry.fields.cta != "") 
            {
              if (entry.fields.cta.fields.title !== undefined && entry.fields.cta.fields.title !== "") {
                singleCarouselItem["ctaTitle"] = entry.fields.cta.fields.title;
              }

              if (entry.fields.cta.fields.ariaLabel !== undefined && entry.fields.cta.fields.ariaLabel !== "") {
                singleCarouselItem["ctaAriaLabel"] = entry.fields.cta.fields.ariaLabel;
              }

              if (entry.fields.cta.fields.alias !== undefined && entry.fields.cta.fields.alias !== "") {
                singleCarouselItem["ctaLink"] = entry.fields.cta.fields.alias;
              }

              singleCarouselItem["ctaClass"] = 'read-more-link';
              if (entry.fields.cta.fields.ctaType != '' && entry.fields.cta.fields.ctaType != undefined) {
                let ctaClassName = 'read-more-link';

                if (entry.fields.cta.fields.ctaType === 'Primary Button') {
                  ctaClassName = 'btn btn-primary';
                }
                else if (entry.fields.cta.fields.ctaType === 'Secondary Button') {
                  ctaClassName = 'btn btn-secondary';
                }
                else if (entry.fields.cta.fields.ctaType === 'Small Text CTA Black') {
                  ctaClassName = 'small-text-cta cta-black';
                }
                else if (entry.fields.cta.fields.ctaType === 'Small Text CTA White') {
                  ctaClassName = 'small-text-cta cta-white';
                }
                else if (entry.fields.cta.fields.ctaType === 'Large Text CTA Black') {
                  ctaClassName = 'large-text-cta cta-black';
                }
                else if (entry.fields.cta.fields.ctaType === 'Large Text CTA White') {
                  ctaClassName = 'large-text-cta cta-white';
                }
                else if (entry.fields.cta.fields.ctaType === 'Icon Text CTA') {
                  ctaClassName = 'icon-text-cta';
                }
                else if (entry.fields.cta.fields.ctaType === 'Read More CTA') {
                  ctaClassName = 'read-more-link';
                }

                singleCarouselItem["ctaClass"] = ctaClassName;
              }

               if (entry.fields.cta.fields.ctaTextColor !== undefined && entry.fields.cta.fields.ctaTextColor !== "") {
                singleCarouselItem["ctaTextColor"] = entry.fields.cta.fields.ctaTextColor;
              }

               if (entry.fields.cta.fields.ctaZigzaglineColor !== undefined && entry.fields.cta.fields.ctaZigzaglineColor !== "") {
                singleCarouselItem["ctaZigzaglineColor"] = entry.fields.cta.fields.ctaZigzaglineColor;
              }

               if (entry.fields.cta.fields.ctaUnderlineColor !== undefined && entry.fields.cta.fields.ctaUnderlineColor !== "") {
                singleCarouselItem["ctaUnderlineColor"] = entry.fields.cta.fields.ctaUnderlineColor;
              }

               if (entry.fields.cta.fields.ctaButtonColor !== undefined && entry.fields.cta.fields.ctaButtonColor !== "") {
                singleCarouselItem["ctaButtonColor"] = entry.fields.cta.fields.ctaButtonColor;
              }

               if (entry.fields.cta.fields.ctaButtonTextColor !== undefined && entry.fields.cta.fields.ctaButtonTextColor !== "") {
                singleCarouselItem["ctaButtonTextColor"] = entry.fields.cta.fields.ctaButtonTextColor;
              }
               if (entry.fields.cta.fields.hoverColor !== undefined && entry.fields.cta.fields.hoverColor !== "") {
                singleCarouselItem["hoverColor"] = entry.fields.cta.fields.hoverColor;
              }

              if (entry.fields.cta.fields.openLinkInNewTab !== undefined && entry.fields.cta.fields.openLinkInNewTab !== "" && entry.fields.cta.fields.openLinkInNewTab === true) {
                singleCarouselItem["openLinkInNewTab"] = "_blank";
              }
            }

            allCarouselItems.push(singleCarouselItem);
            allCarouselItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
            
            setCarouselItems(allCarouselItems);
            setCarouselItemsLength(allCarouselItems.length);


          })
          .catch(console.error);
      });
    }

    },[]);
 
 const settings3 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: autoplay,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        dots: true,
        slidesToScroll: 1
      }
    }
  ]
};

const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: autoplay,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        dots: true,
        autoplay: false,
        slidesToScroll: 1,
        // draggable: false,
        // swipe: false,
        // swipeToSlide: false,
        // touchMove: false
          
      }
    }
  ]
};

    
  return (
  <>
      <style dangerouslySetInnerHTML=
        {{__html: `   
          .card-with-bg-same-fill.banner-solid-bg.${entryId} {
            padding-top: ${topPadding};
            padding-bottom: ${bottomPadding};
            
          }

          @media(max-width: 767px) {
            .card-with-bg-same-fill.banner-solid-bg.${entryId} {
              padding-top: ${mobileTopPadding};
              padding-bottom: ${mobileBottomPadding};
            }
          }

          

          `,
        }}
        />
      <div className={`${topLayer ? "card-with-bg-same-fill banner-solid-bg" : "card-with-bg-same-fill banner-solid-bg no-top-layer"} ${bottomLayer ? "": "no-bottom-layer"} ${itemClass} ${entryId}`} style={{backgroundColor: sectionBackgroundColor}}>
         {heading && <h2 style={{ color: "white", margin: "0 auto 48px auto", width: "fit-content" }}>{heading}</h2>} 
         {sectionBackgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+sectionBackgroundImage+")"}}></div> : ""}
          {sectionMobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+sectionMobileBackgroundImage+")"}}></div> : ""}
            <div className="container">
                <div className="pad-lr-48">
                    <div className="row mgn-rev-lr-22 store-inner">    
                    {
                      isCarousel==true?
                       carouselItems.length>0
                            ? <div className="container xs-no-padding">
                            {numberOfSlides == 3 ?
                            <Slider {...settings3}>
                              {carouselItems.map(({title,description,backgroundColor,ctaTitle,ctaAriaLabel,ctaLink,ctaClass,openLinkInNewTab,backgroundImage,textFontColor,ctaTextColor,ctaButtonColor,ctaZigzaglineColor,ctaUnderlineColor,ctaButtonTextColor,hoverColor,tag,tagBackgroundColor,tagTextColor,icon,iconAltText,descriptionColor},i) =>
                              <div className="pad-lr-22">
                                <style dangerouslySetInnerHTML=
                                   {{__html: `
                                      ${is2ndAve ? `.slick-arrow.slick-next {
                                        background: url(${rightArrow}) no-repeat;
                                      }

                                      .slick-arrow.slick-prev {
                                        background: url(${leftArrow}) no-repeat;
                                      }` : ''}

                                     .large-text-cta.new.cta-white.${entryId} i,
                                     .large-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};            
                                     }                         

                                    .large-text-cta.new.cta-white.${entryId}:hover  i,
                                    .large-text-cta.new.cta-white.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} i,
                                     .large-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover i,
                                    .large-text-cta.new.cta-black.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId} i,
                                     .small-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover i,
                                     .small-text-cta.new.cta-black.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId} i,
                                     .small-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};
                                     }
                                     .small-text-cta.new.cta-white.${entryId}:hover i,
                                     .small-text-cta.new.cta-white.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .btn.btn-primary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-primary.${entryId}:focus,
                                     .btn.btn-primary.${entryId}:focus:active,
                                     .btn.btn-primary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${hoverColor};   
                                     }                         

                                     .btn.btn-primary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${ctaButtonColor};
                                     }                        

                                     .btn.btn-primary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color:${hoverColor};
                                     }                         

                                     .btn.btn-secondary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-secondary.${entryId}:focus,
                                     .btn.btn-secondary.${entryId}:focus:active,
                                     .btn.btn-secondary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${hoverColor};   
                                     }                         

                                     .btn.btn-secondary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${ctaButtonColor};
                                     }

                                      .btn.btn-secondary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color:${hoverColor};
                                     }                         

                                     .read-more-link.${entryId} {
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     .read-more-link.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     }                         

                                     .icon-text-cta.${entryId},a{
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     
                                     .icon-text-cta.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     } 
                                     `,
                                   }}
                                 />
                                
                                <div className="common-card" style={{backgroundColor: backgroundColor,backgroundImage: "url("+backgroundImage+")", color: textFontColor}}>                                  
                                  <div className="inner">
                                  {tag ?  <span className="tag-style style2" style={{backgroundColor: tagBackgroundColor,color:tagTextColor}}>{tag}</span> : null}
                                  {title?<h5 className="clr-white clr-dgreen2" dangerouslySetInnerHTML={{ __html: title }}></h5>:null}
                                  {description?<p className="clr-white clr-dgreen2" style={{color:descriptionColor}}><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}                                  
                                  <div className="bottom-links-row">
                                      {ctaTitle ? 
                                      <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)} aria-label={ctaAriaLabel}>{ctaTitle}
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                        <span className="svg-outer">
                                          <svg width="387" height="12" viewBox="0 0 387 12">
                                            <defs>
                                              <clipPath>
                                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                              </clipPath>
                                            </defs>
                                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                            </g>
                                          </svg>          
                                        </span>
                                        <span>           
                                        </span>
                                      </a>
                                       
                                       : ""}
                                      
                                      {icon ?<img src={icon} width = '50' height = '44' alt={iconAltText?iconAltText:""}></img>: null }
                                  </div>
                                  </div>
                                </div>
                              </div>)} 
                            </Slider>
                            :                          
                            <Slider {...settings2}>
                              {carouselItems.map(({title,description,backgroundColor,ctaTitle,ctaAriaLabel,ctaLink,ctaClass,openLinkInNewTab,backgroundImage,textFontColor,icon,descriptionColor,ctaTextColor,ctaButtonColor,ctaZigzaglineColor,ctaUnderlineColor,ctaButtonTextColor,hoverColor,iconAltText},i) =>
                              <div className="pad-lr-22">
                                <style dangerouslySetInnerHTML=
                                   {{__html: `
                                    ${is2ndAve ? `.slick-arrow.slick-next {
                                        background: url(${rightArrow}) no-repeat;
                                      }

                                      .slick-arrow.slick-prev {
                                        background: url(${leftArrow}) no-repeat;
                                      }` : ''}
                                      
                                     .large-text-cta.new.cta-white.${entryId} i,
                                     .large-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};            
                                     }                         

                                    .large-text-cta.new.cta-white.${entryId}:hover  i,
                                    .large-text-cta.new.cta-white.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} i,
                                     .large-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover i,
                                    .large-text-cta.new.cta-black.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId} i,
                                     .small-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover i,
                                     .small-text-cta.new.cta-black.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId} i,
                                     .small-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};
                                     }
                                     .small-text-cta.new.cta-white.${entryId}:hover i,
                                     .small-text-cta.new.cta-white.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .btn.btn-primary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-primary.${entryId}:focus,
                                     .btn.btn-primary.${entryId}:focus:active,
                                     .btn.btn-primary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${hoverColor};   
                                     }                         

                                     .btn.btn-primary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${ctaButtonColor};
                                     }                         

                                     .btn.btn-primary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color: ${hoverColor};
                                     }                         

                                     .btn.btn-secondary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-secondary.${entryId}:focus,
                                     .btn.btn-secondary.${entryId}:focus:active,
                                     .btn.btn-secondary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${hoverColor};   
                                     }                         

                                     .btn.btn-secondary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${ctaButtonColor};
                                     }

                                      .btn.btn-secondary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color: ${hoverColor};
                                     }                         

                                     .read-more-link.${entryId} {
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     .read-more-link.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     }                         

                                     .icon-text-cta.${entryId},a{
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     
                                     .icon-text-cta.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     } 
                                     `,
                                   }}
                                 />
                                <div className="common-card" style={{backgroundColor: backgroundColor,backgroundImage: "url("+backgroundImage+")", color: textFontColor}}>
                                  {title?<h3 className="clr-white mb-3 clr-dgreen2" dangerouslySetInnerHTML={{ __html: title }}></h3>:null}
                                  {description?<p className="clr-white clr-dgreen2 body1" style={{color:descriptionColor}}><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                                  
                                  <div className="bottom-links-row">
                                    {ctaTitle ? 
                                      <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)} aria-label={ctaAriaLabel}>{ctaTitle}
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                        <span className="svg-outer">
                                          <svg width="387" height="12" viewBox="0 0 387 12">
                                            <defs>
                                              <clipPath>
                                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                              </clipPath>
                                            </defs>
                                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                            </g>
                                          </svg>          
                                        </span>
                                        <span>           
                                        </span>
                                      </a>
                                      : ""}
                                    {icon ?<img src={icon} width = '50' height = '44' alt={iconAltText?iconAltText:""}></img>: null }
                                  </div>
                                </div>
                              </div>)} 
                            </Slider>
                          }
                          </div>
                          :null
                        :
                        carouselItems
                            ? carouselItems.map(({title,description,backgroundColor,ctaTitle,ctaAriaLabel,ctaLink,ctaClass,openLinkInNewTab,backgroundImage,textFontColor,ctaTextColor,ctaButtonColor,ctaZigzaglineColor,ctaUnderlineColor,ctaButtonTextColor,hoverColor,tagBackgroundColor,tagTextColor,tag,icon,iconAltText,descriptionColor},i) =>
                        <div className="col-md-4 pad-lr-22">
                            <style dangerouslySetInnerHTML=
                                   {{__html: `
                                     .large-text-cta.new.cta-white.${entryId} i,
                                     .large-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};            
                                     }                         

                                    .large-text-cta.new.cta-white.${entryId}:hover  i,
                                    .large-text-cta.new.cta-white.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} i,
                                     .large-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover i,
                                    .large-text-cta.new.cta-black.${entryId}:hover {
                                         text-decoration: none;
                                         color:${hoverColor};
                                     }                         

                                      .large-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .large-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId} i,
                                     .small-text-cta.new.cta-black.${entryId} {
                                        color:${ctaTextColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover i,
                                     .small-text-cta.new.cta-black.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-black.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-black.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId} i,
                                     .small-text-cta.new.cta-white.${entryId} {
                                        color:${ctaTextColor};
                                     }
                                     .small-text-cta.new.cta-white.${entryId}:hover i,
                                     .small-text-cta.new.cta-white.${entryId}:hover {
                                       text-decoration: none;
                                        color:${hoverColor};
                                     }                         

                                      .small-text-cta.new.cta-white.${entryId} span {
                                       background: ${ctaUnderlineColor};
                                     }                         

                                     .small-text-cta.new.cta-white.${entryId}:hover svg path {
                                       stroke: ${ctaZigzaglineColor};
                                     }                         

                                     .btn.btn-primary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-primary.${entryId}:focus,
                                     .btn.btn-primary.${entryId}:focus:active,
                                     .btn.btn-primary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${hoverColor};   
                                     }                         

                                     .btn.btn-primary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${ctaButtonColor};
                                     }                         

                                     .btn.btn-primary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color: ${hoverColor};
                                     }                         

                                     .btn.btn-secondary.${entryId}:active {
                                       background:${ctaButtonColor} !important;
                                       border-color: ${ctaButtonColor} !important;
                                     }                         

                                     .btn.btn-secondary.${entryId}:focus,
                                     .btn.btn-secondary.${entryId}:focus:active,
                                     .btn.btn-secondary.${entryId}:visited{
                                         background:${hoverColor};
                                        color:${ctaButtonTextColor};
                                        border-color:${hoverColor};   
                                     }                         

                                     .btn.btn-secondary.${entryId} {
                                         background:${ctaButtonColor};
                                        color:${ctaButtonTextColor};
                                        border-color: ${ctaButtonColor};
                                     }
                                     
                                      .btn.btn-secondary.${entryId}:hover{
                                          background:${hoverColor};
                                          border-color:${hoverColor};
                                     }                         

                                     .read-more-link.${entryId} {
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     .read-more-link.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     }                         

                                     .icon-text-cta.${entryId},a{
                                       color: ${ctaTextColor};
                                       text-decoration-color: ${ctaUnderlineColor};
                                     }
                                     
                                     .icon-text-cta.${entryId}:hover {
                                       color: ${hoverColor};
                                       text-decoration-color: ${hoverColor};
                                     } 
                                     `,
                                   }}
                                 />
                            <div className="common-card" style={{backgroundColor: backgroundColor,backgroundImage: "url("+backgroundImage+")", color: textFontColor}}>
                              <div className="common-card-inner">
                                  {title?<h4 className="clr-white mb-3 clr-dgreen2" dangerouslySetInnerHTML={{ __html: title }}></h4>:null}
                                {description?<p className="clr-white clr-dgreen2" style={{color:descriptionColor}}><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                              </div>
                                <div className="bottom-links-row">
                                    {ctaTitle ? 
                                      <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)} aria-label={ctaAriaLabel}>{ctaTitle}
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                        <span className="svg-outer">
                                          <svg width="387" height="12" viewBox="0 0 387 12">
                                            <defs>
                                              <clipPath>
                                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                              </clipPath>
                                            </defs>
                                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                            </g>
                                          </svg>          
                                        </span>
                                        <span>           
                                        </span>
                                      </a>
                                      : ""}
                                    {icon ?<img src={icon} width = '50' height = '44' alt={iconAltText?iconAltText:""}></img>: null }
                                </div>
                            </div>
                        </div>)
                        :null
                        }
                    </div>
                    
                </div>  
            </div>  
        </div>
  </> 
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

CarouselMediaBlock.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { CarouselMediaBlock };

export default connect(mapStateToProps, mapDispatchToProps)(CarouselMediaBlock);

