import React, { useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import ReactMarkdown from 'react-markdown';

const PercentageBox: React.FC<any> = (componentData) => {

    const [title, setTitle] = useState('');
    const [landscapeTop, setLandscapeTop] = useState('');
    const [landscapeBottom, setLandscapeBottom] = useState('');
    const [portraitTop, setPortraitTop] = useState('');
    const [portraitBottom, setPortraitBottom] = useState('');
    const [landscapeImage, setLandscapeImage] = useState('');
    const [landscapeImageAltText, setLandscapeImageAltText] = useState('');
    const [portraitImage, setPortraitImage] = useState('');
    const [portraitImageAltText, setPortraitImageAltText] = useState('');

     useEffect(() => {
      if (componentData.title != '' && componentData.title != undefined) {
        setTitle(componentData.title);
      }
      if (componentData.landscapeTop != '' && componentData.landscapeTop != undefined) {
        setLandscapeTop(componentData.landscapeTop);
      }
      if (componentData.landscapeBottom != '' && componentData.landscapeBottom != undefined) {
        setLandscapeBottom(componentData.landscapeBottom);
      }
      if (componentData.portraitTop != '' && componentData.portraitTop != undefined) {
        setPortraitTop(componentData.portraitTop);
      }
     
      if (componentData.landscapeImage != '' && componentData.landscapeImage != undefined) {
        let fileUrl = componentData.landscapeImage.fields.file.url;
        setLandscapeImageAltText(componentData.landscapeImage.fields.title);
        setLandscapeImage(fileUrl);
      }
      if (componentData.portraitImage != '' && componentData.portraitImage != undefined) {
        let fileUrl = componentData.portraitImage.fields.file.url;
        setPortraitImageAltText(componentData.portraitImage.fields.title);
        setPortraitImage(fileUrl);
      }
 

     });
    return (
      <div className="medium-container">              
        {title?<div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s"> 
          <h2 className="box-heading">{title}</h2>
        </div>:null}
        {landscapeTop?<p className="desktop-hide mb-60 "><ReactMarkdown allowDangerousHtml source={landscapeTop}></ReactMarkdown> </p>:null}
        <div className="percetage-box">
          <div className="landscape">            
            {landscapeTop?<div className="top"><p><ReactMarkdown allowDangerousHtml source={landscapeTop}></ReactMarkdown> </p></div>:null}
            {landscapeBottom? <div className="bottom"><h1 dangerouslySetInnerHTML={{__html:landscapeBottom}}></h1>
              <img className="wow slideInLeft" data-wow-duration="1s" data-wow-delay="0.5s" src={landscapeImage} alt={landscapeImageAltText?landscapeImageAltText:""}></img>
            </div>:null}
            
          </div>        
          <div className="portrait">
            <div className="top"><h1 dangerouslySetInnerHTML={{__html:portraitTop}}></h1></div>
            <div className="bottom"><ReactMarkdown allowDangerousHtml source={portraitBottom} disallowedTypes={['paragraph']} unwrapDisallowed />
            <img className="wow slideInUp50graph" data-wow-duration="1s" data-wow-delay="0.5s" src={portraitImage} alt={portraitImageAltText?portraitImageAltText:""}></img>
            </div>
          </div>
        </div>
      </div>
       
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

PercentageBox.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { PercentageBox };

export default connect(mapStateToProps, mapDispatchToProps)(PercentageBox);
