import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from '../../Client';
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";

const TwoColumnCard: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [cardItems, setCardItems] = useState([]);
  const [cardItemsLength, setCardItemsLength] = useState(0);
  const [bottomLayer, setBottomLayer] = useState(false);
  const [topLayer, setTopLayer] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [entryId, setEntryId] = useState('');

  
  useEffect(() => {
    if (componentData.cardItem != undefined && componentData.cardItem != '') {
      let allCardItems: any = [];

      if (componentData.bottomLayer != undefined && componentData.bottomLayer != "") {
        setBottomLayer(componentData.bottomLayer);
      }

      if (componentData.entryId != "" && componentData.entryId != undefined) {
        setEntryId("content-"+componentData.entryId);
      }

      if (componentData.topLayer != undefined && componentData.topLayer != "") {
        setTopLayer(componentData.topLayer);
      }

      if (componentData.backgroundColor != undefined && componentData.backgroundColor != "") {
        setBackgroundColor(componentData.backgroundColor);
      }

      componentData.cardItem.map(async (item: any, i: any) => {

        if (item.sys.id !== undefined && item.sys.id !== '') {
          let singleCardItem = {
            index: i,
            title: '',
            description: '',
            ctaButtonTitle: '',
            ctaButtonAriaLabel: '',
            ctaButtonLink: '',
            ctaButtonClass: '',
            ctaButtonColor:"",
            ctaButtonTextColor:"",
            ctaTextColor:"",
            ctaUnderlineColor:"",
            ctaZigzaglineColor:"",
            hoverColor:"",
            openLinkInNewTab: '',
            image: '',
            imageAltText: ''
          };

          await client.getEntry(item.sys.id, {locale: currLocale})
          .then( async (entry : any) => {
            if (entry != '' && entry != undefined) {

              if (entry.fields.title != '' && entry.fields.title != undefined) {
                singleCardItem['title'] = entry.fields.title;
              }

              if (entry.fields.description != '' && entry.fields.description != undefined) {
                singleCardItem['description'] = entry.fields.description;
              }

              if (entry.fields.ctaButton != '' && entry.fields.ctaButton != undefined) {
                if (entry.fields.ctaButton.fields.title !== undefined && entry.fields.ctaButton.fields.title !== "") {
                  singleCardItem['ctaButtonTitle'] = entry.fields.ctaButton.fields.title;
                }

                if (entry.fields.ctaButton.fields.ariaLabel !== undefined && entry.fields.ctaButton.fields.ariaLabel !== "") {
                  singleCardItem['ctaButtonAriaLabel'] = entry.fields.ctaButton.fields.ariaLabel;
                }

                if (entry.fields.ctaButton.fields.alias !== undefined && entry.fields.ctaButton.fields.alias !== "") {
                  singleCardItem['ctaButtonLink'] = entry.fields.ctaButton.fields.alias;
                }

                if (entry.fields.ctaButton.fields.ctaType != '' && entry.fields.ctaButton.fields.ctaType != undefined) {
                  let ctaClassName = 'read-more-link';

                  if (entry.fields.ctaButton.fields.ctaType === 'Primary Button') {
                    ctaClassName = 'btn btn-primary';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Secondary Button') {
                    ctaClassName = 'btn btn-secondary';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Small Text CTA Black') {
                    ctaClassName = 'small-text-cta cta-black';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Small Text CTA White') {
                    ctaClassName = 'small-text-cta cta-white';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Large Text CTA Black') {
                    ctaClassName = 'large-text-cta cta-black';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Large Text CTA White') {
                    ctaClassName = 'large-text-cta cta-white';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Icon Text CTA') {
                    ctaClassName = 'icon-text-cta';
                  }
                  else if (entry.fields.ctaButton.fields.ctaType === 'Read More CTA') {
                    ctaClassName = 'read-more-link';
                  }

                  singleCardItem['ctaButtonClass'] = ctaClassName;
                }

                 if (entry.fields.ctaButton.fields.ctaTextColor !== undefined && entry.fields.ctaButton.fields.ctaTextColor !== "") {
                     singleCardItem["ctaTextColor"] = entry.fields.ctaButton.fields.ctaTextColor;
                  }
                  if (entry.fields.ctaButton.fields.ctaZigzaglineColor !== undefined && entry.fields.ctaButton.fields.ctaZigzaglineColor !== "") {
                   singleCardItem["ctaZigzaglineColor"] = entry.fields.ctaButton.fields.ctaZigzaglineColor;
                  }
                  if (entry.fields.ctaButton.fields.ctaUnderlineColor !== undefined && entry.fields.ctaButton.fields.ctaUnderlineColor !== "") {
                   singleCardItem["ctaUnderlineColor"] = entry.fields.ctaButton.fields.ctaUnderlineColor;
                  }
                  if (entry.fields.ctaButton.fields.ctaButtonColor !== undefined && entry.fields.ctaButton.fields.ctaButtonColor !== "") {
                   singleCardItem["ctaButtonColor"] = entry.fields.ctaButton.fields.ctaButtonColor;
                 }
                  if (entry.fields.ctaButton.fields.ctaButtonTextColor !== undefined && entry.fields.ctaButton.fields.ctaButtonTextColor !== "") {
                   singleCardItem["ctaButtonTextColor"] = entry.fields.ctaButton.fields.ctaButtonTextColor;
                 }
                 if (entry.fields.ctaButton.fields.hoverColor !== undefined && entry.fields.ctaButton.fields.hoverColor !== "") {
                  singleCardItem["hoverColor"] = entry.fields.ctaButton.fields.hoverColor;
                  }

                if (entry.fields.ctaButton.fields.openLinkInNewTab !== undefined && entry.fields.ctaButton.fields.openLinkInNewTab !== "" && entry.fields.ctaButton.fields.openLinkInNewTab === true) {
                  singleCardItem['openLinkInNewTab'] = "_blank";
                }
              }

              if (entry.fields.image !== '' && entry.fields.image !== undefined) {
                let fileUrl = entry.fields.image;
                let altText = entry.fields.title;
                singleCardItem['image'] = fileUrl;
                singleCardItem['imageAltText']=altText;
              }
              allCardItems.push(singleCardItem);
              allCardItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              
              await setCardItems(allCardItems);
              await setCardItemsLength(allCardItems.length);

            }
          })
          .catch(console.error)
        }
      });
    }
  }, []);

  return (  
    <>

      <div className={`${topLayer ? "two-column-card" : "two-column-card no-top-layer"} ${bottomLayer ? "two-column-card": "two-column-card no-bottom-layer"}` } style={{backgroundColor: backgroundColor}}>
        <div className="container">
          <div className="card-inner">
            <div className="row">
            {
              cardItems.map(({title,description,ctaButtonTitle,ctaButtonAriaLabel,ctaButtonLink,ctaButtonClass,openLinkInNewTab,
                image,imageAltText,ctaTextColor,ctaButtonColor,ctaZigzaglineColor,ctaUnderlineColor,ctaButtonTextColor,hoverColor
              }, i) => 
                <div className="col-md-6">
                  <style dangerouslySetInnerHTML=
                    {{__html: `
                      .large-text-cta.new.cta-white.${entryId} i,
                      .large-text-cta.new.cta-white.${entryId} {
                         color:${ctaTextColor};            
                      }          

                     .large-text-cta.new.cta-white.${entryId}:hover  i,
                     .large-text-cta.new.cta-white.${entryId}:hover {
                          text-decoration: none;
                          color:${hoverColor};
                      }          

                      .large-text-cta.new.cta-white.${entryId} span {
                        background: ${ctaUnderlineColor};
                      }          

                      .large-text-cta.new.cta-white.${entryId}:hover svg path {
                        stroke: ${ctaZigzaglineColor};
                      }          

                       .large-text-cta.new.cta-black.${entryId} i,
                      .large-text-cta.new.cta-black.${entryId} {
                         color:${ctaTextColor};
                      }          

                      .large-text-cta.new.cta-black.${entryId}:hover i,
                     .large-text-cta.new.cta-black.${entryId}:hover {
                          text-decoration: none;
                          color:${hoverColor};
                      }          

                       .large-text-cta.new.cta-black.${entryId} span {
                        background: ${ctaUnderlineColor};
                      }          

                      .large-text-cta.new.cta-black.${entryId}:hover svg path {
                        stroke: ${ctaZigzaglineColor};
                      }          

                      .small-text-cta.new.cta-black.${entryId} i,
                      .small-text-cta.new.cta-black.${entryId} {
                         color:${ctaTextColor};
                      }          

                      .small-text-cta.new.cta-black.${entryId}:hover i,
                      .small-text-cta.new.cta-black.${entryId}:hover {
                        text-decoration: none;
                         color:${hoverColor};
                      }          

                       .small-text-cta.new.cta-black.${entryId} span {
                        background: ${ctaUnderlineColor};
                      }          

                      .small-text-cta.new.cta-black.${entryId}:hover svg path {
                        stroke: ${ctaZigzaglineColor};
                      }          

                      .small-text-cta.new.cta-white.${entryId} i,
                      .small-text-cta.new.cta-white.${entryId} {
                         color:${ctaTextColor};
                      }
                      .small-text-cta.new.cta-white.${entryId}:hover i,
                      .small-text-cta.new.cta-white.${entryId}:hover {
                        text-decoration: none;
                         color:${hoverColor};
                      }          

                       .small-text-cta.new.cta-white.${entryId} span {
                        background: ${ctaUnderlineColor};
                      }          

                      .small-text-cta.new.cta-white.${entryId}:hover svg path {
                        stroke: ${ctaZigzaglineColor};
                      }          

                      .btn.btn-primary.${entryId}:active {
                        background:${ctaButtonColor} !important;
                        border-color: ${ctaButtonColor} !important;
                      }          

                      .btn.btn-primary.${entryId}:focus,
                      .btn.btn-primary.${entryId}:focus:active,
                      .btn.btn-primary.${entryId}:visited{
                          background:${hoverColor};
                         color:${ctaButtonTextColor};
                         border-color: ${hoverColor};   
                      }          

                      .btn.btn-primary.${entryId} {
                          background:${ctaButtonColor};
                         color:${ctaButtonTextColor};
                         border-color: ${ctaButtonColor};
                      }          

                      .btn.btn-primary.${entryId}:hover{
                           background:${hoverColor};
                           border-color: ${hoverColor};
                      }          

                      .btn.btn-secondary.${entryId}:active {
                        background:${ctaButtonColor} !important;
                        border-color: ${ctaButtonColor} !important;
                      }          

                      .btn.btn-secondary.${entryId}:focus,
                      .btn.btn-secondary.${entryId}:focus:active,
                      .btn.btn-secondary.${entryId}:visited{
                          background:${hoverColor};
                         color:${ctaButtonTextColor};
                         border-color: ${hoverColor};   
                      }          

                      .btn.btn-secondary.${entryId} {
                          background:${ctaButtonColor};
                         color:${ctaButtonTextColor};
                         border-color: ${ctaButtonColor};
                      }

                       .btn.btn-secondary.${entryId}:hover{
                           background:${hoverColor};
                           border-color: ${hoverColor};
                      }          

                      .read-more-link.${entryId} {
                        color: ${ctaTextColor};
                        text-decoration-color: ${ctaUnderlineColor};
                      }
                      .read-more-link.${entryId}:hover {
                        color: ${hoverColor};
                        text-decoration-color: ${hoverColor};
                      }          

                      .icon-text-cta.${entryId},a{
                        color: ${ctaTextColor};
                        text-decoration-color: ${ctaUnderlineColor};
                      }
                      
                      .icon-text-cta.${entryId}:hover {
                        color: ${hoverColor};
                        text-decoration-color: ${hoverColor};
                      }               

                      `,
                    }}
                  />
                  <div className="card-wrap">
                    {title ? (<h3 dangerouslySetInnerHTML={{ __html:title}}></h3>)
                      : ""
                    }
                    {description ? (<p dangerouslySetInnerHTML={{ __html:description}}></p>)
                      : ""
                    }
                    {ctaButtonTitle ? 
                    <a className={ctaButtonClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaButtonLink)} aria-label={ctaButtonAriaLabel}>{ctaButtonTitle}
                      <i className="fa fa-caret-right" aria-hidden="true"></i>          
                      <span className="svg-outer">
                        <svg width="387" height="12" viewBox="0 0 387 12">
                          <defs>
                            <clipPath>
                              <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                            </clipPath>
                          </defs>
                          <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                            <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                          </g>
                        </svg>          
                      </span>
                      <span>           
                      </span>
                    </a>
                      : ""
                    }
                    {image ? (<img className="hover-thumb" src={image} alt={imageAltText?imageAltText:""}/>)
                      : ""
                    }
                  </div>
                </div>
              )
            }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColumnCard.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColumnCard };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnCard);
