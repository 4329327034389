import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchStoreLocatorView } from "../../api/cms/mock";
import i18next from "i18next";
import Cookies from "universal-cookie";
import ComponentComposer from "../../components/ComponentComposer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cookies = new Cookies();
let date = new Date(); // Now

const ThriftState: React.FC<any> = () => {
  const [data, setData] = useState<any[]>([]);
  const [storeLocatorData, setStoreLocatorData] = useState(null);
  const { lang } = useParams();
  useEffect(() => {
    if (lang) {
      cookies.set("Language", lang, { path: "/", expires: date });
    } else if (i18next.language && !cookies.get("Language")) {
      cookies.set("Language", i18next.language, { path: "/", expires: date });
    }
    console.log("test");
    fetchdata();
    async function fetchdata() {
      const data = await fetchStoreLocatorView();
      setData(data.components ? data.components : null);
    }
  }, []);


  const slickSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <>



      <div className="savers-page" id="maincontent">
        <div className="impact-main-wrapper">


          <div className="full-bleed-banner4 text-center banner-solid-bg">
            <img className="desktop-bg" src={require("../../images/thrift-banner.jpg")} />
            <img className="mobile-bg" src={require("../../images/thrift-banner-mobile.jpg")} />
            <div className="container">
              <div className="banner-info">



                <div className="small-banner-logo">
                  <img className="" src={require("../../images/small-saver-logo.png")} />
                </div>
                <div className="clearfix"></div>
                <h1 className="big clr-white">
                  <samp><span>The State</span><span>of Thrift</span></samp>
                </h1>
                <h1><span>2020 Impact Report</span></h1>
              </div>
            </div>
          </div>





          <section className="horizontal-rule one">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <style dangerouslySetInnerHTML=
                  {{__html: `
                            
                    .horizontal-rule.one hr {
                      margin-top: 150px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.one hr {
                        margin-top: 65px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="-impact-2-col-para overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="col-12 side-space-85">
                  <div id="sticky-title" className="sticky-title">
                    <h1 className="solid-heading left wow slideInLeft" data-wow-duration="2s" data-wow-delay="0s">
                      <span>To our thrift&nbsp;</span><span>community,</span>
                    </h1>
                  </div>
                  <div className="col-2-para">
                    <div className="leftSideContent wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                      <p>In our more than 65 years as a secondhand retailer, we’ve never been more <strong>Thrift Proud<sup>®</sup></strong>. While this past year has been challenging, we remain inspired by our mission to champion reuse, engage with our communities, and provide a one-of-a-kind experience for our shoppers. You deserve it, and our environment demands it. </p>
                      <p>We also realize that 2020 has caused many families to tighten their budgets and reconsider their shopping habits. Since we opened our first store, we’ve offered options that are affordable, sustainable, and stylish, and these commitments are more important than ever. </p>
                      <p>The following report looks at how thrift has swiftly changed and where we envision the secondhand space might go next. We anticipate that in both our </p>
                    </div>
                    <div className="rightSideContent wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                    <p> current and post-COVID world, shopping behaviors and trends will continue to evolve. </p>
                    <p>As we look ahead, the safety and well-being of our team members, customers, and communities remains our focus. Whether browsing our racks or dropping off your stuff after a decluttering session, we want you to feel good coming to Savers®.
Your pre-loved clothing and household items have the power to change the world. </p>
                    <p>So, thank you for redefining what it means to shop secondhand. We look forward to seeing you soon.</p>
                      <div className="hiddeninfo-btn desktop-hide">Read More<span><i></i><i></i></span></div>
                      <div className="signature wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                        <img className="" src={require("../../images/signature.jpg")} />
                        <h3 className="body1">Mark Walsh <span>CEO</span></h3>
                      </div>
                    </div>
                   
                    
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="horizontal-rule" className="horizontal-rule two overflow-hidden">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `
                            
                    .horizontal-rule.two hr {
                      margin-top:140px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.two hr {
                        margin-top: 65px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>

          <section className="overflow-hidden -impact-left-img-with-text">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner style1">
                    <h1 className="solid-heading left"> <span>A fresh take on</span></h1>
                    <div className="clearfix"></div>
                    <img src={require("../../images/brand-logo.png")} />
                    <p>Since our humble thrift shop beginnings in 1954, we’ve come a long way in the secondhand space, growing from a single store to a global reuse leader-and it’s all because of you. We couldn’t be more <strong>Thrift Proud<sup>TM</sup></strong> -and hope you are, too.  </p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="left-img-with-text">
                    <div className="bg-layer"></div>
                    <div className="box-layer-inner">
                      <div className="left-img">
                        <img className="" src={require("../../images/imgt-1.jpg")} />
                      </div>
                      <div className="right-info">
                        <div className="overflow-hidden">
                          <div className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0s">
                            <p className="body1">So, what does it mean to be <br></br><strong>Thrift Proud<sup>®</sup>?</strong></p>
                            <h2>It’s the thrill of finding “this <br></br>is exactly what I need,” <br></br>
    the “why not?” and <br></br> “where’d you get that?!”<br></br>
    It’s reusing, repurposing,<br></br> and upcycling,<br></br>
    helping save the planet <br></br> while saving a few bucks.</h2>
                            <h1 style={{ color: "#7EDDD3" }}>This is thrift.</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-rule three">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                        <style dangerouslySetInnerHTML=
                          {{__html: `
                                    
                            .horizontal-rule.three hr {
                              margin-top: 55px;
                              border-top: 0;
                            }
                            @media(max-width: 767px) {
                              .horizontal-rule.three hr {
                                margin-top: 0px;
                              }
                            }
                              `
                            }}
                          /> 
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-impact-testimonials">

                    <div className="impact-head text-center">
                      <p>But enough about us. Thrift is what you make it. We <br></br>polled shoppers on why they’re <strong>#ThriftProud.</strong><br></br>
Here’s what they had to share:</p>
                    </div>

                    <div className="testimonilas-outer">
                      <img className="desktop-bg one" src={require("../../images/testimonial-bg.jpg")} />
                      <div className="item bg1 wow zoomIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration=".5s" data-wow-delay="0.5s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                            <div className="name">@lady.king.seay</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>I’m thrift proud knowing I can dress my kids for a fraction of the cost.<span className="quote-right">”</span></p>
                      </div>
                      <div className="item bg2 wow zoomIn" data-wow-duration="1s" data-wow-delay="1s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration=".5s" data-wow-delay="1s">
                            <div className="icon bottom"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                            <div className="name">@ptowngirls</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>#thriftproud is finding something unique that you know has character and has been well loved. 🙌🏽 <span className="quote-right">”</span></p>
                      </div>
                      <div className="item bg3 wow zoomIn" data-wow-duration="1s" data-wow-delay="1.5s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration=".5s" data-wow-delay="1.5s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                            <div className="name">@r_vanram</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>Being able to buy knowing that it has a more positive impact on the environment, that makes me #thriftproud <span className="quote-right">”</span></p>
                      </div>
                    </div>


                  </div>

                  <div className="right-impact-testimonials with-slider">

                    <div className="impact-head text-center">
                      <p>But enough about us. Thrift is what you make it. We polled<br></br> shoppers on why they’re <strong>#ThriftProud.</strong><br></br>
  Here’s what they had to share:</p>
                    </div>

                    <div className="testimonilas-outer">
                      <img className="desktop-bg" src={require("../../images/testimonial-bg.jpg")} />
                      <Slider {...slickSettings1}>
                        <div className="item bg1 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.5s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                              <div className="name">@lady.king.seay</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>I’m thrift proud knowing I can dress my kids for a fraction of the cost.<span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg2 wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1s">
                              <div className="icon bottom"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                              <div className="name">@ptowngirls</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>#thriftproud is finding something unique that you know has character and has been well loved. 🙌🏽 <span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg3 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.5s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                              <div className="name">@r_vanram</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>Being able to buy knowing that it has a more positive impact on the environment, that makes me #thriftproud <span className="quote-right">”</span></p>
                        </div>
                      </Slider>
                    </div>
                  </div>



                </div>

              </div>
            </div>
          </section>


          <section className="horizontal-rule four">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `
                            
                    .horizontal-rule.four hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.four hr {
                        margin-top: 0px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>




          <section className="-impact-right-trends">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"> <span>Today's thrift</span><span>scene</span></h1>
                    <p>Financially squeezed consumers are going to be making more sustainable shopping choices following the pandemic-this means choosing brands that are grounded in conscious consumerism and social responsibility.<sup>1</sup> At home, they’re also more open about which items to keep and which to pass on to someone new.</p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="medium-container text-center">
                    <div className="impact-head">
                      <h2 className="box-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">ON TREND: FASHION AT A FRACTION OF THE COST.</h2>
                    </div>
                  </div>
                  <div className="small-container">
                    <div className="imapct-right-trends">
                      <div className="graph small orange">
                        <div className="graph-icon">
                          <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
                          </div>
                          <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">
                            <h1>33% <span>financially squeezed</span></h1>
                          </div>
                        </div>
                        <div className="graph-info">
                          <span className="line"></span>
                          <p>33% of shoppers are ‘financially-squeezed’ with less disposable income than before the crisis.<sup>2</sup></p>
                        </div>
                      </div>

                      <div className="graph medium blue" style={{ marginTop: "-190px" }}>
                        <div className="graph-icon">
                          <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="2s">
                          </div>
                          <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                            <h1>60% <span>spending less</span></h1>
                          </div>

                        </div>
                        <div className="graph-info">
                          <span className="line"></span>
                          <p>60% of shoppers are spending less on fashion and about half expect to continue to do so post-crisis.<sup>3</sup></p>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="medium-container wow fadeInUp text-center" data-wow-duration="2s" data-wow-delay=".5s">
                    <div className="impact-head">
                      <h2 className="box-heading">one-of-a-kind style outweighs labels and brands. </h2>
                    </div>
                  </div>

                  <div className="small-container">
                    <div className="impact-2-col-with-top-img">
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                          <img className="" src={require("../../images/bag.png")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1s">
                          <p>50% of Gen Z and millennial shoppers expect to purchase more items secondhand.<sup>4</sup></p>
                        </div>
                      </div>
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                          <div className="multiple">
                            <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.5s" src={require("../../images/vector1.png")} />
                            <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="2s" src={require("../../images/vector2.png")} />
                            <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="2.5s" src={require("../../images/vector4.png")} />
                            <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="3s" src={require("../../images/vector3.png")} />
                          </div>
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1s">
                          <p>One-in-four shoppers say they would be delighted to receive a secondhand item as a gift.<sup>5</sup></p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="small-container text-center">
                    <div className="impact-head wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                      <h2 className="box-heading">more time at home = more decluttering</h2>
                    </div>



                    <div className="right-impact-testimonials square mobile-negtive-space">
                      <img className="orange-layer3" src={require("../../images/orange-layer3.png")}></img>
                      <div className="testimonilas-outer square1">
                        <p><span className="quote-left">“</span>It is a challenging and uncertain time, but it may also be an opportunity to express gratitude for your space and to tidy up if you’ve been wanting to.<span className="quote-right">”</span></p>
                        <p className="name">– Marie Kondo, <span>author and home organizing expert<sup>6</sup></span></p>
                        <img className="" src={require("../../images/testimonial-img.jpg")} />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </section>
          <section className="horizontal-rule five">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                  <style dangerouslySetInnerHTML=
                    {{__html: `
                              
                      .horizontal-rule.five hr {
                        margin-top: 140px;
                        border-top: 0;
                      }
                      @media(max-width: 767px) {
                        .horizontal-rule.five hr {
                          margin-top: 85px;
                        }
                      }
                        `
                      }}
                    /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"><span>New clothes </span><span> are old news.</span></h1>
                    <p>Thrift is trending–and experts agree. From the red carpet and runways to the racks of our stores, shoppers are choosing sustainable, secondhand options over fast fashion. In the Taking Stock With Teens 2020 Fall Report, thrift/consignment stores jumped more than 30 points to be the #13 favorite brand or retailer for teens.<sup>7</sup> What’s more, new clothing sales are forecasted to decline, while resale is projected to quintuple by 2024.<sup>8</sup></p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <img className="orange-layer1" src={require("../../images/orange-layer1.png")}></img>
                  <div className="small-container">
                    <div className="right-impact-testimonials square mobile-negtive-space" data-wow-duration="1s" data-wow-delay=".5s">
                      <div className="testimonilas-outer square2 wow bounceIn">
                        <p><span className="quote-left">“</span>I think it’s an opportunity for all of us to look at our industry and to look at our lives, and to rethink our values, and to really think about the waste, and the amount of money, and consumption, and excess that we have all indulged in and how we really need to rethink what this industry stands for.<span className="quote-right">”</span></p>
                        <p className="name">– Anna Wintour, <span>editor-in-chief of VOGUE<sup>9</sup></span></p>
                        <img className="d-none" src={require("../../images/testimonial-img2.jpg")} />
                      </div>
                    </div>
                  </div>

                  <div className="right-impact-testimonials square">
                    <img className="orange-layer2" src={require("../../images/orange-layer2.png")}></img>
                    <div className="static-container">
                      <div className="left-block">
                        <div className="right-impact-testimonials square wow bounceIn" data-wow-duration="1s" data-wow-delay="1s">
                          <div className="testimonilas-outer square3 ">
                            <p><span className="quote-left">“</span>We have so much power to change the world by just being careful in what we buy.<span className="quote-right">”</span></p>
                            <p className="name">– Emma Watson, <span> actress and activist<sup>10</sup></span></p>
                          </div>
                        </div>
                      </div>
                      <div className="right-block">
                        <div className="right-impact-testimonials square  wow bounceIn" data-wow-duration="1s" data-wow-delay="1.5s">
                          <div className="testimonilas-outer square3">
                            <p><span className="quote-left">“</span>Consumers are shopping with their values. They care about climate change and the impact of apparel on the environment and that’s why they’re more attracted to pre-owned and pre-loved clothing.<span className="quote-right">”</span></p>
                            <p className="name">- Jill Standish, <span> head of Accenture’s Global Retail Practice<sup>11</sup></span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="horizontal-rule six">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                    {{__html: `
                              
                      .horizontal-rule.six hr {
                        margin-top: 125px;
                        border-top: 0;
                      }
                      @media(max-width: 767px) {
                        .horizontal-rule.six hr {
                          margin-top: 45px;
                        }
                      }
                        `
                      }}
                    /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>

          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"><span>We are what</span><span>we wear.</span></h1>
                    <p>New clothing is the leading contributor to pollution and second-largest consumer of water worldwide. As shoppers consider the impact of their own clothing footprint, they’re more likely than ever to
shop thrift. </p>
                  </div>
                </div>

                <div className="impact-main-right overflow-hidden">
                  <div className="medium-container">
                    <div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                      <h2 className="box-heading">TEXTILE WASTE: THE ULTIMATE FASHION FAUX PAS. </h2>
                    </div>

                    <p className="desktop-hide mb-60">The avrage person buys 60% more clothing items and keep them for about half as long as 15 years ago.<sup>12</sup></p>
                    <div className="percetage-box">
                      <div className="landscape">
                        <div className="top"><p>The average person buys <strong>60%</strong> more clothing items and keeps them for about <strong>half as long</strong> as 15 years ago.<sup>12</sup></p></div>
                        <div className="bottom">
                          <h1><span className="desktop-hide">Keep</span> 1/2 as long</h1>
                          <img className="wow slideInLeft" data-wow-duration="1s" data-wow-delay="0.5s" src={require("../../images/blue-bottom.png")}></img>
                        </div>
                      </div>


                      <div className="portrait">
                        <div className="top"><h1><span className="desktop-hide">Buy </span>60% <br></br>more</h1></div>
                        <div className="bottom"></div>
                        <img className="wow slideInUp50graph" data-wow-duration="1s" data-wow-delay="0.5s" src={require("../../images/orange-right.png")}></img>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-rule seven">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                        <style dangerouslySetInnerHTML=
                          {{__html: `
                                    
                            .horizontal-rule.seven hr {
                              margin-top: 55px;
                              border-top: 0;
                            }
                            @media(max-width: 767px) {
                              .horizontal-rule.seven hr {
                                margin-top: 125px;
                              }
                            }
                              `
                            }}
                          /> 
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="-impact-right-stats">
                    <div className="medium-container">
                      <div className="right fixed-img">                        
                        <div className="impact-header">
                          <p className="hidden-para wow fadeInOut" data-wow-duration="2s" data-wow-delay="2s"> One garbage truck-worth of <br></br> textiles is landfilled or burned<br></br> every second. </p>
                          <img className="wow slideInRight2" data-wow-duration="6s" data-wow-delay="0.5s" src={require("../../images/car.png")} />
                          <div className="inner wow fadeIn" data-wow-duration="2s" data-wow-delay="4s">
                            <p>More than</p>
                            <div className="clearfix"></div>
                            <h1 className="solid-heading"><span> 26 billion pounds</span></h1>
                            <div className="clearfix"></div>
                            <p>of reusable textiles are <br></br>thrown away each year</p>
                          </div>
                          </div>
                      </div>
                    </div>


                    <div className="horizontal-rule eight">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                          <style dangerouslySetInnerHTML=
                          {{__html: `
                                    
                            .horizontal-rule.eight hr {
                              margin-top: 55px;
                              border-top: 0;
                            }
                            @media(max-width: 767px) {
                              .horizontal-rule.eight hr {
                                margin-top: 64px;
                              }
                            }
                              `
                            }}
                          /> 
                            <hr></hr>
                          </div>
                        </div>
                      </div>
                    </div>

                    

                    <div className="medium-container">
                      <div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                        <h2 className="box-heading">Go clean with your clothes. </h2>
                      </div>
                      <div className="impact-right-stats">
                        <div className="two-col-with-img">
                          <div className="img-col">
                            <div className="inner circel">
                            <img className="animatted-img wow slideInUp50" data-wow-duration="2s" data-wow-delay=".5s" src={require("../../images/globe-fill.png")} />
                              <img src={require("../../images/globe-empty.png")}></img>
                              
                            </div>
                          </div>
                          <div className="info-col">
                            <div className="info-col-inner">
                              <p>Every years, the <br></br>fashion industry uses.</p>
                              <div className="inline">
                                <span className="large">93</span>
                                <span>billion m<sup>3</sup> <br></br> of water</span>
                                <span><img src={require("../../images/drop.png")} /></span>
                              </div>
                            </div>
                            <div className="info-col-inner">
                              <p>-enough  to meet the <br></br>consumption needs of </p>
                              <div className="inline">
                                <span className="large">5</span>
                                <span>million<br></br> people.<sup className="regular-font">13</sup> </span>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="two-col-with-img">
                          <div className="info-col">
                            <div className="info-col-inner">
                              <p>The annual environment impact of a household's clothing equals the water needed to fill 1,000 bathtubs.<sup>14</sup></p>
                            </div>
                          </div>
                          <div className="img-col">
                            <div className="inner">
                              <h2>x1000</h2>
                              <img className="animatted-img wow slideInUp100" data-wow-duration="2s" data-wow-delay=".5s" src={require("../../images/bath-fill.png")} />
                              <img src={require("../../images/empty-tub.png")} />
                            
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                    <div className="small-container">
                      <div className="three-col-with-img">
                        <div className="info-col-inner">
                          <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">Just one thrifted <br></br>t-shirt can</p>
                          <img className="wow fadeInDown" data-wow-duration="2s" data-wow-delay=".5s" src={require("../../images/stats3.png")} />
                        </div>
                        <div className="info-col-inner wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
                          <h2>save <span>700</span> gallons <br></br>of water</h2>
                        </div>
                        <div className="info-col-inner">
                          <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">-more than <br></br>you'll drink in </p>
                          <img className="wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.5s" src={require("../../images/stats4.png")} />
                          <h3 className="wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.5s">years<sup>15</sup></h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>                           
          </section>

           
         
          <section className="horizontal-rule nine">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                  <style dangerouslySetInnerHTML=
                    {{__html: `
                              
                      .horizontal-rule.nine hr {
                        margin-top: 120px;
                        border-top: 0;
                      }
                      @media(max-width: 767px) {
                        .horizontal-rule.nine hr {
                          margin-top: 35px;
                        }
                      }
                        `
                      }}
                    /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>

          <section className="overflow-hidden -impact-right-cycle">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner style2">
                    <h1 className="solid-heading left "><span>What goes <br></br> around, comes</span> <span>around:<br></br> the circular <br></br>economy.</span></h1>
                    <p>Just how old trends come back into style, we want to keep your stuff in use for years to come. <br></br><strong>Currently, less than 1% of
used clothing is actually “recycled” into new garments,</strong> which makes reuse even
more important.<sup>16</sup></p>
                  </div>
                </div>


                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                      <h2 className="box-heading">The Circular Economy. </h2>
                    </div>

                    <p className="text-center body1 mb-40">At Savers®, we're serious about championing reuse and have been for decades.<br></br>That means keeping items out of landfills and in your homes and closets. </p>
                    <div className="impact-right-cycle">
                      <div className="inner">
                        <div className="round-blocks main wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
                          <h1>Our savers<sup>®</sup> cycle</h1>
                        </div>
                        <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="1.5s">
                          <img src={require("../../images/cycle-icon2.svg")} />
                          <p>Savers stores accept community donations (thank you) on behalf of a local nonprofit.</p>
                        </div>
                        <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2s">
                          <img src={require("../../images/cycle-icon3.svg")} />
                          <p>We pay our nonprofit partners for your stuff, helping them fund programs in the community.</p>
                        </div>
                        <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2.5s">
                          <img src={require("../../images/cycle-icon4.svg")} />
                          <p>Your one-of-a-kind finds are given a second chance to be loved again by thrifters like you.</p>
                        </div>
                        <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="3s">
                          <img src={require("../../images/cycle-icon5.svg")} />
                          <p>Together, we champion reuse and keep millions of reusable items out of landfills every year.</p>
                        </div>
                        <span className="circel"><i></i><i></i><i></i><i></i></span>
                      </div>
                    </div>

                    <div className="impact-right-cycle with-slider desktop-hide">
                      <div className="inner">
                        <div className="heading">
                          <h1>Our savers<sup>®</sup> cycle</h1>
                        </div>
                        <Slider {...slickSettings1}>
                          <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="1.5s">
                            <img src={require("../../images/cycle-icon2.svg")} />
                            <div><p>Savers stores accept community donations (thank you) on behalf of a local nonprofit.</p></div>
                          </div>
                          <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2s">
                            <img src={require("../../images/cycle-icon3.svg")} />
                            <div><p>We pay our nonprofit partners for your stuff, helping them fund programs in the community.</p></div>
                          </div>
                          <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="2.5s">
                            <img src={require("../../images/cycle-icon4.svg")} />
                            <div><p>TYour one-of-a-kind finds are given a second chance to be loved again by thrifters like you.</p></div>
                          </div>
                          <div className="round-blocks wow fadeIn" data-wow-duration="2s" data-wow-delay="3s">
                            <img src={require("../../images/cycle-icon5.svg")} />
                            <div><p>Together, we champion reuse and keep millions of reusable items out of landfills every year.</p></div>
                          </div>
                        </Slider>

                      </div>
                    </div>

                    <p className="text-center body2 italic"><i>Shopping at Savers® does not support any nonprofit.</i><br></br>
Savers® is a professional fundraiser where required. <a href="">Learn more</a></p>

                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="horizontal-rule ten">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `
                              
                    .horizontal-rule.ten hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.ten hr {
                        margin-top: 105px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"> <span>The Savers<sup>®</sup></span><span> Solution.</span></h1>
                    <p>On average, Savers® diverts <strong>700 million pounds </strong> of clothing and textiles from landfills each year.</p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="small-container">
                    <div className="impact-head text-center">
                      <h2 className="box-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">Last year alone, we saved:</h2>
                    </div>
                    <div className="imact-right-solutions">
                      <ul className="small-icon-blocks">
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-1.png")} /></div>
                          <h2>244</h2>
                          <p>million tops</p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1.2s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-2.png")} /></div>
                          <h2>84</h2>
                          <p>million pants</p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1.4s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-3.png")} /></div>
                          <h2>27</h2>
                          <p>million dresses</p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1.6s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-4.png")} /></div>
                          <h2>20</h2>
                          <p>million coats</p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1.8s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-5.png")} /></div>
                          <h2>28</h2>
                          <p>million pairs of shoes</p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="2s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-6.png")} /></div>
                          <h2>31</h2>
                          <p>million accessories </p>
                        </li>
                        <li className="wow fadeIn" data-wow-duration="2s" data-wow-delay="2.2s">
                          <div className="img-wrap"><img src={require("../../images/small-icon-7.png")} /></div>
                          <h2>13</h2>
                          <p>million pieces of kitchenwares</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="small-container">
                    <div className="row">
                      <div className="col-12 col-md-5 col-lg-4 xs-no-space">
                        <div className="portrait-graph">
                          <div className="head">
                            <div className="top-left">
                              <span className="wow slideInUp" data-wow-duration="2s" data-wow-delay=".5s"></span>
                              <div className="inner">
                                <p>95% reuse</p>
                              </div>

                            </div>
                            <div className="top-right">
                              <span className="wow slideInUp" data-wow-duration="2s" data-wow-delay=".5s"></span>
                              <div className="inner wow fadeIn" data-wow-duration="2s" data-wow-delay="1s">
                                <p>only <br></br> 5% waste</p>
                              </div>

                            </div>
                          </div>

                          <div className="bottom">
                            <span className="line"></span>
                            <p>That means 95% of clothing and textiles dropped off at our stores are reused or repurposed in some shape or form–resulting in only 5% waste.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-7 col-lg-8 xs-no-space">
                        <div className="imapct-right-trends portrait">
                          <div className="graph small blue" style={{ marginTop: "0px" }}>
                            <div className="graph-icon">
                              <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                              </div>
                              <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">
                                <h3>60%</h3>
                              </div>

                            </div>
                            <div className="graph-info">
                              <span className="line"></span>
                              <p>60% of the clothing items we touch are re-worn.</p>
                            </div>
                          </div>
                          <div className="graph small orange">
                            <div className="graph-icon">
                              <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="2s">
                              </div>
                              <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
                                <h3>35%</h3>
                              </div>
                            </div>
                            <div className="graph-info">
                              <span className="line"></span>
                              <p>35% are upcycled or downcycled into reusable products or turned into post-consumer textile fibers.</p>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="horizontal-rule elewen">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `
                              
                    .horizontal-rule.elewen hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.ten hr {
                        margin-top: 65px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>


          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner style2">
                    <h1 className="solid-heading left"> <span>Until every <br></br>item has a</span><span> home.</span></h1>
                    <p>In our perfect world, every single item produced would find its way to its forever home. That’s why we work to keep as many reusable goods as possible out of landfills and in the hands of those who’ll love them.</p>
                  </div>
                </div>
                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center">
                      <h2 className="box-heading wow fadeIn" data-wow-duration="2s" data-wow-delay="0" >OUR LOCAL NONPROFIT AND GLOBAL REUSE PARTNERS. </h2>
                    </div>

                    <div className="row">
                      <div className="col-12 col-lg-6 xs-no-space desktop-pr-24 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                        <p>By choosing to purchase the goods we sell in our stores from local nonprofit organizations, we provide steady revenue that helps support each of their missions. Our suppliers provide a wide range of impactful services, including support for people with disabilities, medical research, and veteran and youth programs.</p>
                        <p className="body2"><i>Shopping at Savers<sup>®</sup> does not support any nonprofit. Savers<sup>®</sup> is a professional fundraiser where required. <a href="">Learn more</a></i></p>

                      </div>
                      <div className="col-12 col-lg-6 xs-no-space desktop-pl-24 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                        <p>Finding a second or third use for pre-loved items is what we do, and we recognize the global demand for secondhand goods. So, we partner with buyers both near and far to extend the life of items that don’t sell on our sales floors. What's passed over in our stores is sold to small business owners-allowing them to supply their local communities with gently used, affordable items like clothing, housewares, toys, and shoes. In fact, these goods are sold within reuse markets across six continents and 34 countries.</p>
                        

                      </div>
                    </div>

                    <div className="map-outer">
                      <img className="mt-15 xs-mt-45 wow fadeIn" data-wow-duration="2s" data-wow-delay="0" src={require("../../images/map.png")} />
                      <div className="map-label">
                        <span></span>
                        <p className="body2">areas we sell <br></br>secondhand goods to</p>
                      </div>
                    </div>
                  </div>

                  <div className="horizontal-rule tweleve">
                    <div className="impact-container">
                      <div className="row">
                        <div className="col-12">
                        <style dangerouslySetInnerHTML=
                          {{__html: `
                                      
                            .horizontal-rule.tweleve hr {
                              margin-top: 55px;
                              border-top: 0;
                            }
                            @media(max-width: 767px) {
                              .horizontal-rule.tweleve hr {
                                margin-top: 50px;
                              }
                            }
                              `
                            }}
                          /> 
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="left-img-with-text">
                    <div className="bg-layer"></div>
                    <div className="box-layer-inner">
                      <div className="left-img">
                        <img className="" src={require("../../images/img-t2.png")} />
                      </div>
                      <div className="right-info">
                        <h6>Partner Spotlight.</h6>
                        <div className="overflow-hidden">
                        <div className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0s">
                        
                          <div className="wow slideInLeft" data-wow-duration="2s" data-wow-delay="1s">
                            
                            <p className="body1">Ever wonder what happens to single shoes?</p>
                            <h2 className="">Separated shoes are a drop-off item difficult to resell, for obvious reasons. But in the spirit of trying to divert as much as we possibly can from landfills, we partner with specialized buyers around the world who accept all our lonely laces and make pairs to resell. And while finding an exact match may seem like a moon shot, they have a 50% perfect match rate of identical style and size shoes. In short? That’s fewer sneakers in landfills and more kicks where they belong on feet.</h2>

                          </div>
                        </div>
                        </div>

                      </div>
                    </div>
                    
                  </div>

                </div>

              </div>
            </div>
          </section>





         
          <section className="horizontal-rule thirteen">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <style dangerouslySetInnerHTML=
                    {{__html: `
                                
                      .horizontal-rule.thirteen hr {
                        margin-top: 125px;
                        border-top: 0;
                      }
                      @media(max-width: 767px) {
                        .horizontal-rule.thirteen hr {
                          margin-top: 85px;
                        }
                      }
                        `
                      }}
                    /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>

          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"><span>#savers is our </span><span>happy place.</span></h1>
                    <p>We think being different is a good thing. That’s why our stores are packed full of one-of-a-kind finds, with <strong> up to 10,000 new treasures added to each store’s racks and shelves daily.</strong> we are proud to offer style at savings so our shoppers can love more for less. </p>
                  </div>
                </div>


                <div className="impact-main-right">
                  <div className="impact-head text-center impact-xs-padding lg-mt-20">
                    <p>We asked thrifters like you why they love the thrill of the find. Here’s <br></br> what they had to say: </p>
                    <h2 className="box-heading mt-25 desktop-hide">Why i thrift:</h2>
                  </div>

                  <div className="right-impact-testimonials v2">
                    <div className="testimonilas-outer">
                      <img className="layer4" src={require("../../images/svg/layer4.svg")} />
                      <img className="layer5" src={require("../../images/layer7.svg")} />
                      <img className="layer6" src={require("../../images/svg/layer8.svg")} />
                      <div className="item bg4 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay=".4s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Junebugluvr</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>It’s a treasure hunt, there’s a story in every item.<span className="quote-right">”</span></p>
                      </div>
                      <div className="item bg5 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.8s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay=".8s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Picturemarcella</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>An opportunity to be extra creative with my wardrobe while not contributing to fast fashion. 🙌<span className="quote-right">”</span></p>
                      </div>
                      <div className="item bg6 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.2s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Ashtynsthriftflips</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>Sustainability is the biggest factor for me! The prices, the originality, and the fun factor also really play a big role! ❤️<span className="quote-right">”</span></p>
                      </div>

                    </div>
                    <div className="testimonilas-outer below-slides">
                      <div className="item bg7 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@hiambernicole</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>As someone who’s focused on improving my personal finances and also a shopaholic, it’s a perfect guilt free past time. No better feeling than finding that perfect piece and knowing you only spent a few bucks on it 😄!<span className="quote-right">”</span></p>
                      </div>
                    </div>
                  </div>


                  <div className="right-impact-testimonials v2 with-slider">
                      <img className="layer4" src={require("../../images/svg/layer4.svg")} />
                      <img className="layer5" src={require("../../images/svg/layer7.svg")} />
                      <img className="layer6" src={require("../../images/svg/layer8.svg")} />
                    <div className="testimonilas-outer">
                      <img className="desktop-bg" src={require("../../images/testimonial-bg.jpg")} />
                      <Slider {...slickSettings1}>
                        <div className="item bg4 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay=".4s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Junebugluvr</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>It’s a treasure hunt, there’s a story in every item.<span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg5 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.8s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay=".8s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Picturemarcella</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>An opportunity to be extra creative with my wardrobe while not contributing to fast fashion. 🙌<span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg6 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.2s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@Ashtynsthriftflips</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>Sustainability is the biggest factor for me! The prices, the originality, and the fun factor also really play a big role! ❤️<span className="quote-right">”</span></p>
                        </div>

                      </Slider>
                    </div>
                    <div className="testimonilas-outer below-slides">
                      <img className="layer4" src={require("../../images/svg/layer4.svg")} />
                      <div className="item bg7 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s">
                        <div className="user-name">
                          <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
                            <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@hiambernicole</div>
                          </div>
                        </div>
                        <p><span className="quote-left">“</span>As someone who’s focused on improving my personal finances and also a shopaholic, it’s a perfect guilt free past time. No better feeling than finding that perfect piece and knowing you only spent a few bucks on it 😄!<span className="quote-right">”</span></p>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </section>



          <section className="horizontal-rule forteen">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                    {{__html: `
                                
                      .horizontal-rule.forteen hr {
                        margin-top: 125px;
                        border-top: 0;
                      }
                      @media(max-width: 767px) {
                        .horizontal-rule.forteen hr {
                          margin-top: 0px;
                        }
                      }
                        `
                      }}
                    /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>

          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner style3">
                    <h1 className="solid-heading left"><span>The only</span> <span> style that<br></br> matters is</span><span>yours.</span></h1>
                    <p>It’s thrifters like you who define what’s next in terms of style and trends. What are shoppers on the look-out for today… and tomorrow?</p>
                  </div>
                </div>


                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center">
                      <p className="mobile-hide">We asked our secondhand fans for their fashion <br></br> forecasts for  the year ahead.</p>
                      <h2 className="box-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0">Thrift predictions:</h2>
                    </div>

                    <div className="right-impact-testimonials v3">
                      <div className="testimonilas-outer">
                        <img className="layer7" src={require("../../images/svg/layer4.svg")} />
                        <img className="layer8" src={require("../../images/svg/layer5.svg")} />
                        <img className="layer9" src={require("../../images/svg/layer6.svg")} />
                        <div className="item bg8 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s">
                          <div className="user-name" >
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.4s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                              <div className="name">@thriftymaymay</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>Thrifted monochromatic style everywhere!<span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg9 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.8s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.8s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@chelseabretal</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>I have a vision of kitten heels becoming the next statement shoe.<span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg10 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.2s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@map1988</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>I predict that quality furniture from the 80s will be more sought after. <span className="quote-right">”</span></p>
                        </div>
                        <div className="item bg11 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s">
                          <div className="user-name">
                            <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
                              <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@janellealeff60s</div>
                            </div>
                          </div>
                          <p><span className="quote-left">“</span>More comfort minded fabrics and textures with elegant touches of jewelry and accessories.<span className="quote-right">”</span></p>
                        </div>
                      </div>
                    </div>

                    <div className="right-impact-testimonials v3 with-slider">
                        <img className="layer7" src={require("../../images/svg/layer4.svg")} />
                        <img className="layer8" src={require("../../images/svg/layer5.svg")} />
                        <img className="layer9" src={require("../../images/svg/layer6.svg")} />
                      <div className="testimonilas-outer">
                        <img className="desktop-bg" src={require("../../images/testimonial-bg.jpg")} />
                        <Slider {...slickSettings1}>
                          <div className="item bg8 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div className="user-name" >
                              <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.4s">
                                <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div>
                                <div className="name">@thriftymaymay</div>
                              </div>
                            </div>
                            <p><span className="quote-left">“</span>Thrifted monochromatic style everywhere!<span className="quote-right">”</span></p>
                          </div>
                          <div className="item bg9 wow zoomIn" data-wow-duration="2s" data-wow-delay="0.8s">
                            <div className="user-name">
                              <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0.8s">
                                <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@chelseabretal</div>
                              </div>
                            </div>
                            <p><span className="quote-left">“</span>I have a vision of kitten heels becoming the next statement shoe.<span className="quote-right">”</span></p>
                          </div>
                          <div className="item bg10 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                            <div className="user-name">
                              <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.2s">
                                <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@map1988</div>
                              </div>
                            </div>
                            <p><span className="quote-left">“</span>I predict that quality furniture from the 80s will be more sought after. <span className="quote-right">”</span></p>
                          </div>
                          <div className="item bg11 wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s">
                            <div className="user-name">
                              <div className="inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="1.6s">
                                <div className="icon top"><i className="fa fa-instagram" aria-hidden="true"></i></div><div className="name">@janellealeff60s</div>
                              </div>
                            </div>
                            <p><span className="quote-left">“</span>More comfort minded fabrics and textures with elegant touches of jewelry and accessories.<span className="quote-right">”</span></p>
                          </div>

                        </Slider>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="horizontal-rule fifteen">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `                                
                    .horizontal-rule.fifteen hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.forteen hr {
                        margin-top: 20px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"><span>We stand for &nbsp;</span><span> secondhand.</span></h1>
                    <p>Our purpose: <strong>Champion Reuse</strong> We serve the environment by inspiring a future where secondhand is second nature. </p>
                  </div>
                </div>


                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0">
                      <h2 className="box-heading">Our Values:</h2>
                    </div>
                  </div>
                  <div className="medium-container">
                    <div className="impact-2-col-with-top-img with-title">
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4">
                          <img src={require("../../images/value1.svg")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay=".4">
                          <h6>Do the right thing</h6>
                          <p>Stand up for what’s right and do what we say we are going to do. </p>
                        </div>
                      </div>
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                          <img src={require("../../images/value2.svg")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay=".8s">
                          <h6>Celebrate uniqueness</h6>
                          <p>Respect and celebrate what makes us one-of-a-kind.</p>
                        </div>
                      </div>
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
                          <img src={require("../../images/value3.svg")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.2s">
                          <h6>Make service count</h6>
                          <p>Be friendly and helpful in every interaction. </p>
                        </div>
                      </div>
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.6s">
                          <img src={require("../../images/value4.svg")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.6s">
                          <h6>Find a better way </h6>
                          <p>Learn every day, adapt and improve. </p>
                        </div>
                      </div>
                      <div className="inner">
                        <div className="top-img wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.8s">
                          <img src={require("../../images/value5.svg")} />
                        </div>
                        <div className="bottom-info wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.8s">
                          <h6>Make an impact</h6>
                          <p>Support our team members and engage with our local communities. </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="horizontal-rule sixteen">
            <div className="impact-container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `                                
                    .horizontal-rule.sixteen hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.sixteen hr {
                        margin-top: 40px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>


          <section className="overflow-hidden -impact-values">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner style2">
                    <h1 className="solid-heading left"><span>And we love</span> <span>our thrift <br></br>community.  </span></h1>
                    <p>At Savers®, there’s no such thing as a typical shopper or team member—and we like it that way. Just like the one-of-a-kind finds on our racks, we embrace and celebrate the individuality of every person who comes into our stores. </p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center wow fadeInUp" data-wow-duration="2s" data-wow-delay="0">
                      <h2 className="box-heading">That’s why we value and prioritize:</h2>
                    </div>

                    <div className="row">
                      <div className="col-12 xs-no-space">
                        <div className="impact-prioritize">
                          <img className="wow fadeIn" data-wow-duration="2s" data-wow-delay=".4s" src={require("../../images/prio1.jpg")} />
                          <h6>Reuse:</h6>
                          <p>You're making a difference while shopping for everyday household necessities and one-of-a-kind finds, and while dropping off reusable goods after when responsibly decluttering your home. This helps us keep items out of landfills by reducing waste in our local communities, and we couldn’t do it without you.</p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 xs-no-space">
                        <div className="impact-prioritize">
                          <img className="wow fadeIn" data-wow-duration="2s" data-wow-delay=".8s" src={require("../../images/prio2.jpg")} />
                          <h6>Our Community: </h6>
                          <p>We are deeply connected to the local communities we serve. That’s why we choose to buy the goods we sell in out stores from local nonprofits, which helps support their missions and the vital work they do. </p>
                          <p className="body2"><i>Shopping at Savers<sup>®</sup> does not support any nonprofit. Savers is a professional fundraiser where required. <a href="">Learn more</a></i></p>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6 xs-no-space">
                        <div className="impact-prioritize">
                          <img className="wow fadeIn" data-wow-duration="2s" data-wow-delay="1.2s" src={require("../../images/prio3.jpg")} />
                          <h6>An Inclusive Environment:</h6>
                          <p>We know that creating an inclusive and respectful culture is essential. No matter your gender or gender identity, race, ethnicity, age, religion, interests, or sexual orientation, we know that when diverse and unique people come together in an inclusive environment-we are able to achieve amazing things. <a href="">Learn more</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="horizontal-rule seventeen">
            <div className="container">
              <div className="row">
                <div className="col-12">
                <style dangerouslySetInnerHTML=
                  {{__html: `                                
                    .horizontal-rule.seventeen hr {
                      margin-top: 125px;
                      border-top: 0;
                    }
                    @media(max-width: 767px) {
                      .horizontal-rule.seventeen hr {
                        margin-top: 35px;
                      }
                    }
                      `
                    }}
                  /> 
                  <hr></hr>
                </div>
              </div>
            </div>
          </section>
          <section className="impact-timeline-bg overflow-hidden">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-left">
                  <div className="impact-left-inner">
                    <h1 className="solid-heading left"><span>What’s next &nbsp;</span><span>for thrift.&nbsp;</span></h1>
                    <p>The future may still feel unpredictable, but no matter what, we are thrifters for life. As we look ahead, we are thankful for our customers, team members and communities for helping us forge the future
of thrift.</p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="medium-container">
                    <div className="impact-head text-center">
                      <p>Here’s where we anticipate the thrift industry is headed:  </p>
                    </div>

                    <div className="impact-timeline">
                      <div className="impact-timeline-outer">
                        <div className="timeline-img">
                          <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0" src={require("../../images/timeline1.jpg")} />
                        </div>
                        <div className="timeline-info wow fadeInRight" data-wow-duration="2s" data-wow-delay="0">
                          <h6>style as a stance.</h6>
                          <p>Shoppers will continue to prioritize purpose and values as they make purchases-with a focus on supporting their community, protecting the environment and preferring socially responsible brands.</p>
                        </div>
                      </div>
                      <div className="impact-timeline-outer">
                        <div className="timeline-img">
                          <img className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".5s" src={require("../../images/timeline2.jpg")} />
                        </div>
                        <div className="timeline-info wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s">
                          <h6>we're all in this together.</h6>
                          <p>We can’t solve clothing waste alone. We expect to see increased collaboration across industry leaders, and we are excited to team up to take on this issue. </p>
                        </div>
                      </div>
                      <div className="impact-timeline-outer">
                        <div className="timeline-img">
                          <img className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1s" src={require("../../images/timeline3.jpg")} />
                        </div>
                        <div className="timeline-info wow fadeInRight" data-wow-duration="2s" data-wow-delay="1s">
                          <h6>secondhand is here to stay.</h6>
                          <p>Trends may come and go, but thrift is here for the long-haul. The resale movement will continue to grow as more people discover the fun and sustainable treasure hunt that is thrift. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="impact-source">
            <div className="impact-container">
              <div className="row">
                <div className="impact-main-full">
                  <h1 id="big-heading" className="solid-heading left big-heading">
                    <span>Thanks for&nbsp;</span><span> thrifting!</span></h1>
                </div>
                <div className="impact-main-full source-logo">
                  <img src={require("../../images/white-logo.svg")} />
                </div>
              </div>
              <div className="row">
                <div className="impact-main-left">
                  <h6>Thanks for continually inspiring us with your one-of-a-kind style, passion for the planet, and commitment to our communities. We hope to see you in store soon!</h6>
                  <div className="love-us">
                    <h6>Share our story</h6>
                    <ul>
                      <li><a href="https://www.instagram.com/savers_thrift/"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                      <li><a href="https://www.facebook.com/savers"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a href="https://www.pinterest.com/saversvvillage/"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                      <li><a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                    </ul>
                    <img src={require("../../images/heart.svg")} />

                    <p>To learn more about how we are supporting our team members and customers during the COVID-19 pandemic, please read our <a href="">company statement</a> here.</p>
                  </div>
                </div>

                <div className="impact-main-right">
                  <div className="medium-container">
                    <h6 className="text-uppercase">Sources</h6>
                    <ul>
                      <li><span>1</span>“How will COVID-19 change the retail consumer?”. Accenture. Web. <a href="">https://www.accenture.com/_acnmedia/PDF-126/Accenture-COVID-19-Retail-Consumer-Resarch-Wave-Four-POV.pdf</a></li>
                      <li><span>2</span>“How will COVID-19 change the retail consumer?”. Accenture. Web. <a href="">https://www.accenture.com/_acnmedia/PDF-126/Accenture-COVID-19-Retail-Consumer-Resarch-Wave-Four-POV.pdf</a></li>
                      <li><span>3</span>“Survey: Consumer sentiment on sustainability in fashion.” McKinsey & Company. Web. <a href="">https://www.mckinsey.com/industries/retail/our-insights/survey-consumer-sentiment-on-sustainability-in-fashion#</a></li>
                      <li><span>4</span>“Survey: Consumer sentiment on sustainability in fashion.” McKinsey & Company. Web. <a href="">https://www.mckinsey.com/industries/retail/our-insights/survey-consumer-sentiment-on-sustainability-in-fashion#</a> </li>
                      <li><span>5</span>“Let the holiday (re)sales begin!” Deloitte. Web. <a href="">https://www2.deloitte.com/us/en/pages/consumer-business/articles/let-the-holiday-resale-begin.html</a> </li>
                      <li><span>6</span>“How Marie Kondo Declutters During a Pandemic.” The New York Times. Web. <a href="">https://www.nytimes.com/2020/03/20/business/marie-kondo-coronavirus-diary.html?te=1&nl=morning briefing&emc=edit_NN_p_20200323&section=topNews&campaign_id=9&instance_id=16988&segment_id=22598&user_id=de3e540da4e2c05ad5dacbb13ff4b3ee&regi_id=77234649tion=topNews</a> </li>
                      <li><span>7</span>“Taking Stock With Teens 2020.” Piper Sandler. Web. <a href="">http://www.pipersandler.com/private/pdf/TSWTs_Fall_2020_Full_Report.pdf</a>  </li>
                      <li><span>8</span>Global Data and thredUP. GlobalData Market Sizing and Growth Estimates and thredUP</li>
                      <li><span>9</span>“Coronavirus: Why the fashion industry faces an ‘existential crisis’” BBC. Web. <a href="">https://www.bbc.com/news/entertainment-arts-52394504</a></li>
                      <li><span>10</span>“Wear the Change you want to see” Good On You. Web. <a href="">https://goodonyou.eco/about/</a></li>
                      <li><span>11</span>“Coronavirus and luxury retail: Shopping for used Hermes, Cartier in Covid era.” CNBC. Web. <a href="">https://www.cnbc.com/2020/09/19/coronavirus-how-the-pandemic-has-impacted-luxury-retail.html</a> </li>
                      <li><span>12</span>“Fashion at the crossroads.” Web. Greenpeace. <a href="">https://www.greenpeace.org/static/planet4-international-stateless/2017/09/76e05528-fashion-at-the-crossroads.pdf</a> </li>
                      <li><span>13</span>“How Much Do Our Wardrobes Cost to the Environment?” The World Bank. Web. <a href="">https://www.worldbank.org/en/news/feature/2019/09/23/costo-moda-medio-ambiente#:~:text=Every%20year%20the%20fashion%20industry,from%20fabric%20dyeing%20and%20treatment.&text=At%20this%20pace%2C%20the%20fashion,more%20than%2050%20%25%20by%202030</a></li>
                      <li><span>14</span>“All of Us Need to Keep Our Garb Out of the Garbage”. Simple Recycling. Web. <a href="">https://simplerecycling.com/all-of-us-need-to-keep-our-garb-out-of-the-garbage/</a></li>
                      <li><span>15</span>"The Apparel Industry’s Environmental Impact in 6 Graphics" Web. World Resources Institute. <a href="https://www.wri.org/blog/2017/07/apparel-industrys-environmental-impact-6-graphics">https://www.wri.org/blog/2017/07/apparel-industrys-environmental-impact-6-graphics</a></li>
                      <li><span>16</span>“A new textiles economy” Web. <a href="">https://www.ellenmacarthurfoundation.org/assets/down loads/A-New-Textiles-Economy.pdf</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div></div>
    </>


  );
};

export default ThriftState;
