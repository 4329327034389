import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const CommonCard: React.FC<any> = (props) => {
    return (
        <>
        <div className="card-with-bg-same-fill banner-solid-bg pt-5 pb-5">
            <div className="container">
                <div className="pad-lr-48">
                    <div className="row mgn-rev-lr-22 store-inner">
                        <div className="col-md-4 pad-lr-22">
                            <div className="common-card">
                                <div className="common-card-inner">
                                    <h5 className="clr-white mb-3 clr-dgreen2">{props.Common_Card[0].title}</h5>
                                    <p className="clr-white clr-dgreen2">{props.Common_Card[0].heading}</p>
                                </div>
                                <div className="bottom-links-row">
                                    <a href="#" className="large-text-cta cta-white">{props.Common_Card[0].anchor_text} <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 pad-lr-22">
                            <div className="common-card">
                                <h5 className="clr-white mb-3 clr-dgreen2">{props.Common_Card[1].title}</h5>
                                <div className="bottom-links-row">
                                    <a href="#" className="large-text-cta cta-white">{props.Common_Card[1].anchor_text} <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 pad-lr-22">
                            <div className="common-card bg-dgreen1">
                                <h5 className="clr-white mb-3 clr-dgreen2">{props.Common_Card[2].title}</h5>
                                <div className="bottom-links-row">
                                    <a href="#" className="large-text-cta cta-white">{props.Common_Card[2].anchor_text} <i className="fa fa-caret-right" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>  
            </div>  
        </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

CommonCard.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { CommonCard };

export default connect(mapStateToProps, mapDispatchToProps)(CommonCard);
