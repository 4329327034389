import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getLocale } from "../../api/cms/api";

const RichTextEditor: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [type, setType] = useState('Container');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {

     function fetchComponentData()
    {
      client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
         if(entry.fields.content!=undefined &&entry.fields.content!="")
         {
            const options = {
          renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
              return node.data.target.fields.html;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width = '240' height = '115' alt="${node.data.target.fields.title}"/>`
          }
          }
            let rawRichTextField = documentToHtmlString(entry.fields.content, options);
            setContent(rawRichTextField);
         }
      })
    }

    fetchComponentData();

    if (componentData.type !== '' && componentData.type !== undefined) {
      setType(componentData.type);
    }

    if (componentData.backgroundColor !== '' && componentData.backgroundColor !== undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }


  }, []);


  return (
    <>
      {type === "Half Container"?
        <div className="small-container ptb-20" style={{backgroundColor: backgroundColor}}>
          <div className="longform-text" dangerouslySetInnerHTML={{ __html:content}}></div>
        </div>
        :
        <section className="about-info" style={{backgroundColor: backgroundColor}}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 extra-space" dangerouslySetInnerHTML={{ __html:content}}></div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

RichTextEditor.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { RichTextEditor };

export default connect(mapStateToProps, mapDispatchToProps)(RichTextEditor);
