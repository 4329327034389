import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getGlobalData, getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";

const Story2ColumnsCard: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const { domain } = getGlobalData();
  const [title, setTitle] = useState('');
  const [entryId, setEntryId] = useState('');
  const [leftSideContent, setLeftSideContent] = useState('');
  const [rightSideContent, setRightSideContent] = useState('');
  const [divider, setDivider] = useState('divider');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [leftCtaTitle, setLeftCtaTitle] = useState('');
  const [leftCtaAriaLabel, setLeftCtaAriaLabel] = useState('');
  const [leftCtaLink, setLeftCtaLink] = useState('');
  const [leftCtaClass, setLeftCtaClass] = useState('read-more-link');
  const [leftOpenLinkInNewTab, setLeftOpenLinkInNewTab] = useState('');


  const [leftCtaButtonColor, setLeftCtaButtonColor] = useState('');
  const [leftCtaButtonTextColor,setLeftCtaButtonTextColor] = useState('');
  const [leftCtaTextColor, setLeftCtaTextColor] = useState('');
  const [leftCtaUnderlineColor, setLeftCtaUnderlineColor] = useState('');
  const [leftCtaZigzaglineColor, setLeftCtaZigzaglineColor] = useState('');
  const [leftHoverColor, setLeftHoverColor] = useState('');

  const [rightCtaTitle, setRightCtaTitle] = useState('');
  const [rightCtaAriaLabel, setRightCtaAriaLabel] = useState('');
  const [rightCtaLink, setRightCtaLink] = useState('');
  const [rightCtaClass, setRightCtaClass] = useState('read-more-link');
  const [rightOpenLinkInNewTab, setRightOpenLinkInNewTab] = useState('');

  const [rightCtaButtonColor, setRightCtaButtonColor] = useState('');
  const [rightCtaButtonTextColor, setRightCtaButtonTextColor] = useState('');
  const [rightCtaTextColor, setRightCtaTextColor] = useState('');
  const [rightCtaUnderlineColor, setRightCtaUnderlineColor] = useState('');
  const [rightCtaZigzaglineColor, setRightCtaZigzaglineColor] = useState('');
  const [rightHoverColor, setRightHoverColor] = useState('');

  const [titleFontColor, setTitleFontColor] = useState('');
  const [leftSideFontColor, setLeftSideFontColor] = useState('');
  const [rightSideFontColor, setRightSideFontColor] = useState('');
  const [dividerColor, setDividerColor] = useState('#fff');
  const [embeddedEntry, setEmbeddedEntry] = useState('');
  const [leftEmbeddedEntry, setLeftEmbeddedEntry] = useState('');
  const [leftSideData,setLeftSideData]=useState(null);
  const [rightSideData,setRightSideData]=useState(null);
  const [textAlignment, setTextAlignment] = useState('');


  useEffect(() => {

    function fetchComponentData()
    {
      client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
        const options = {
          renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
              return node.data.target.fields.html;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width="479" height="359" alt="${node.data.target.fields.title}"/>`
          }
        }
        const rightSideContent = entry.fields.rightSideContent;
        const leftSideContent = entry.fields.leftSideContent;

        /**
         * 
         * Removes content from HTML document, based on domain
         * 
         * @param content - the HTML content to analyze
         * @param key - key of the parent object
         * @param type - "left" or "right" - two columns of the two column card
         */
        const deleteDomain = (content: any, key: number, type: string) => {
          if (!content.data.target) {
            return;
          }
          const fields = content.data.target.fields;
          const hasDomainsArray = fields.domains;
          const domainCheck = !hasDomainsArray || fields.domains.includes(domain);
          if (!domainCheck) {
            if (type === "right") {
              delete entry.fields.rightSideContent.content[key];
            } else if (type === "left") { 
              delete entry.fields.leftSideContent.content[key];
            }
          }
        }
        rightSideContent.content.forEach((item: any, key: number) => deleteDomain(item, key, "right"));
        leftSideContent.content.forEach((item: any, key: number) => deleteDomain(item, key, "left"));

       if(entry.fields.rightSideContent!=undefined && entry.fields.rightSideContent!="")
       { 
         let rawRichTextFieldRight = documentToHtmlString(rightSideContent, options);
         setRightSideContent(rawRichTextFieldRight);
       }

       if(entry.fields.leftSideContent!=undefined && entry.fields.leftSideContent!="")
       { 
         let rawRichTextFieldLeft = documentToHtmlString(leftSideContent, options);
         setLeftSideContent(rawRichTextFieldLeft);
       }

      }); 
    }
    
    fetchComponentData();
  
    if (componentData.title !== undefined && componentData.title !== "") {
      setTitle(componentData.title);
    }

    if (componentData.entryId != "" && componentData.entryId != undefined) {
      setEntryId("content-"+componentData.entryId);
    }

    if (componentData.divider !== undefined && componentData.divider !== "") {
      if (componentData.divider === true) {
        setDivider('divider');
      }
      else if (componentData.divider === false) {
        setDivider('no-divider');
      }
    }

    if (componentData.textAlignment !== undefined && componentData.textAlignment !== "") {
      if (componentData.textAlignment === true) {
        setTextAlignment('text-center');
      }     
    }

   

    if (componentData.backgroundColor !== undefined && componentData.backgroundColor !== "") {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.leftSideCta !== undefined && componentData.leftSideCta !== "") {
      client.getEntry(componentData.leftSideCta.sys.id, {locale: currLocale})
      .then((entry : any) => {
      if (entry != '' && entry != undefined) {
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
            
            if (entry.fields.ctaType === 'Primary Button') {
              setLeftCtaClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setLeftCtaClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Primary Green Button') {
              setLeftCtaClass('btn btn-success');
            }             
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setLeftCtaClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setLeftCtaClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setLeftCtaClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setLeftCtaClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setLeftCtaClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setLeftCtaClass('read-more-link');
            }
          }

          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setLeftCtaTitle(entry.fields.title);
          }
          if (entry.fields.ariaLabel != '' && entry.fields.ariaLabel != undefined) {
            setLeftCtaAriaLabel(entry.fields.ariaLabel);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setLeftCtaLink(entry.fields.alias);
          }
           if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setLeftCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setLeftCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setLeftCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setLeftCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setLeftCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setLeftHoverColor(entry.fields.hoverColor);
          }
          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setLeftOpenLinkInNewTab("_blank");
          }
        }
      })
    }

    if (componentData.rightSideCta !== undefined && componentData.rightSideCta !== "") {
      client.getEntry(componentData.rightSideCta.sys.id, {locale: currLocale})
      .then((entry : any) => {
      if (entry != '' && entry != undefined) {
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
            
            if (entry.fields.ctaType === 'Primary Button') {
              setRightCtaClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setRightCtaClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Primary Green Button') {
              setRightCtaClass('btn btn-success');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setRightCtaClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setRightCtaClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setRightCtaClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setRightCtaClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setRightCtaClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setRightCtaClass('read-more-link');
            }
          }
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setRightCtaTitle(entry.fields.title);
          }
          if (entry.fields.ariaLabel != '' && entry.fields.ariaLabel != undefined) {
            setRightCtaAriaLabel(entry.fields.ariaLabel);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setRightCtaLink(entry.fields.alias);
          }
           if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setRightCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setRightCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setRightCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setRightCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setRightCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setRightHoverColor(entry.fields.hoverColor);
          }
          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setRightOpenLinkInNewTab("_blank");
          }
        }
      })
    }

    if (componentData.titleFontColor !== undefined && componentData.titleFontColor !== "") {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.leftSideFontColor !== undefined && componentData.leftSideFontColor !== "") {
      setLeftSideFontColor(componentData.leftSideFontColor);
    }

    if (componentData.rightSideFontColor !== undefined && componentData.rightSideFontColor !== "") {
      setRightSideFontColor(componentData.rightSideFontColor);
    }

    if (componentData.dividerColor !== undefined && componentData.dividerColor !== "") {
      setDividerColor(componentData.dividerColor);
    }

    
  });

  return (
  <>
        <style dangerouslySetInnerHTML=
          {{__html: `
            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${leftCtaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${leftHoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${leftCtaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${leftCtaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${leftCtaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${leftHoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${leftCtaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${leftCtaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${leftCtaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${leftHoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${leftCtaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${leftCtaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${leftCtaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${leftHoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${leftCtaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${leftCtaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${leftCtaButtonColor} !important;
              border-color: ${leftCtaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${leftHoverColor};
               color:${leftCtaButtonTextColor};
               border-color: ${leftHoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${leftCtaButtonColor};
               color:${leftCtaButtonTextColor};
               border-color: ${leftCtaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${leftHoverColor};
                 border-color: ${leftHoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${leftCtaButtonColor} !important;
              border-color: ${leftCtaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${leftHoverColor};
               color:${leftCtaButtonTextColor};
               border-color:${leftHoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${leftCtaButtonColor};
               color:${leftCtaButtonTextColor};
               border-color:${leftCtaButtonColor};
            }

             .btn.btn-secondary.${entryId}:hover{
                 background:${leftHoverColor};
                 border-color:${leftHoverColor};
            }

            .read-more-link.${entryId} {
              color: ${leftCtaTextColor};
              text-decoration-color: ${leftCtaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${leftHoverColor};
              text-decoration-color: ${leftHoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${leftCtaTextColor};
              text-decoration-color: ${leftCtaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${leftHoverColor};
              text-decoration-color: ${leftHoverColor};
            } 
            
            `,
          }}
        />
         <style dangerouslySetInnerHTML=
          {{__html: `
            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${rightCtaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${rightHoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${rightCtaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${rightCtaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${rightCtaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${rightHoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${rightCtaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${rightCtaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${rightCtaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${rightHoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${rightCtaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${rightCtaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${rightCtaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${rightHoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${rightCtaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${rightCtaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${rightCtaButtonColor} !important;
              border-color: ${rightCtaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${rightHoverColor};
               color:${rightCtaButtonTextColor};
               border-color:${rightHoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${rightCtaButtonColor};
               color:${rightCtaButtonTextColor};
               border-color:${rightCtaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${rightHoverColor};
                 border-color:${rightHoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${rightCtaButtonColor} !important;
              border-color: ${rightCtaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${rightHoverColor};
               color:${rightCtaButtonTextColor};
               border-color:${rightHoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${rightCtaButtonColor};
               color:${rightCtaButtonTextColor};
               border-color:${rightCtaButtonColor};
            }

             .btn.btn-secondary.${entryId}:hover{
                 background:${rightHoverColor};
                 border-color:${rightHoverColor};
            }

            .read-more-link.${entryId} {
              color: ${rightCtaTextColor};
              text-decoration-color: ${rightCtaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${rightHoverColor};
              text-decoration-color: ${rightHoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${rightCtaTextColor};
              text-decoration-color: ${rightCtaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${rightHoverColor};
              text-decoration-color: ${rightHoverColor};
            } 
            `,
          }}
        />
    <div className={divider ? "text-block "+divider+" " + textAlignment: "text-block "+ textAlignment} style={{backgroundColor: backgroundColor}}>
      <div className="container">
        <div className="pad-lr-48">
          {title ? <div className="text-center main-title" style={{color: titleFontColor}}><h2>{title}</h2></div> : ""}
          <div className="row">
            <div className="col-md-6">
              <div className="text-info-left">
                {leftSideContent ?
                  <div className="inner-content" dangerouslySetInnerHTML={{ __html:leftSideContent}} style={{color: leftSideFontColor}}></div>
                  : ""
                }
                {leftCtaTitle ? 
                   <a className={leftCtaClass+" new"+" "+entryId} target={leftOpenLinkInNewTab} href={addLangKeyPath(leftCtaLink)} aria-label={leftCtaAriaLabel}>{leftCtaTitle}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                  <span className="svg-outer">
                    <svg width="387" height="12" viewBox="0 0 387 12">
                      <defs>
                        <clipPath>
                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                        </clipPath>
                      </defs>
                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                      </g>
                    </svg>          
                  </span>
                  <span>           
                  </span>
              </a>
                : ""
                }
              </div>
            </div>
            <span className="divider-line" style={{backgroundColor: dividerColor}}></span>
            <div className="col-md-6">
              <div className="text-info-right">
                {rightSideContent ?
                  <div className="inner-content" dangerouslySetInnerHTML={{ __html:rightSideContent}} style={{color: rightSideFontColor}}></div>
                  : ""
                }
                {rightCtaTitle ? 
                  <a className={rightCtaClass+" new"+" "+entryId} target={rightOpenLinkInNewTab} href={addLangKeyPath(rightCtaLink)} aria-label={rightCtaAriaLabel}>{rightCtaTitle}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                  <span className="svg-outer">
                    <svg width="387" height="12" viewBox="0 0 387 12">
                      <defs>
                        <clipPath>
                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                        </clipPath>
                      </defs>
                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                      </g>
                    </svg>          
                  </span>
                  <span>           
                  </span>
              </a>
                : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

Story2ColumnsCard.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};


export { Story2ColumnsCard };

export default connect(mapStateToProps, mapDispatchToProps)(Story2ColumnsCard);
