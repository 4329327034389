import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import { getLocale, addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from "react-markdown";
const TwoColumnHorizontalList: React.FC<any> = (componentData) => {
  const currLocale = getLocale();
  const [listItems, setListItems] = useState([]);
  const [title, setTitle] = useState('');
  const [backgroundColor, setBackgroundColor] = useState("");
  const [columnType, setColumnType] = useState("2");
  const [subtitleContent, setSubtitleContent] = useState("");
  const [entryId, setEntryId] = useState('');
  const ctaButtonColor = '';
  const ctaButtonTextColor = '';
  const hoverColor = '';

  const isFourColumn = columnType === "4";
  const isTwoColumn = columnType === "2";

  const fourColumnClasses = isFourColumn ? "col-6 col-md-4 col-lg-3" : "col-md-4";
  const bootstrapColumnClasses = isTwoColumn ? "col-md-6" : fourColumnClasses;

  const attempt = async () => {
    const result: any = await Promise.all(componentData.listItems.map(
      (item: any, i: any) => {
        return client.getEntry(item.sys.id, { locale: currLocale })
      }
    ))
  
    const array = result.map((entry: any, i: any)=>{
      let singleListItem = {
        index: i,
        title: "",
        image: "",
        imageAltText: "",
        description: "",
        ctaButtonTitle: "",
        ctaButtonAriaLabel: "",
        ctaButtonLink: "",
        ctaButtonClass: "",
        openLinkInNewTab: "",
        tag: "",
        ctaButtonColor: "",
        ctaButtonTextColor: "",
        ctaTextColor: "",
        ctaUnderlineColor: "",
        ctaZigzaglineColor: "",
        hoverColor: "",
        tagBackgroundColor: "",
      };
      if (entry != "" && entry != undefined) {
        if (
          entry.fields.title != "" &&
          entry.fields.title != undefined
        ) {
          singleListItem["title"] = entry.fields.title;
        }

        if (
          entry.fields.image !== "" &&
          entry.fields.image !== undefined
        ) {
          let fileUrl = entry.fields.image.fields.file.url;
          let altText = entry.fields.image.fields.title;
          singleListItem["image"] = fileUrl;
          singleListItem["imageAltText"] = altText;
        }

        if (
          entry.fields.description != "" &&
          entry.fields.description != undefined
        ) {
          singleListItem["description"] =
            entry.fields.description;
        }

        if (
          entry.fields.tag != "" &&
          entry.fields.tag != undefined
        ) {
          singleListItem["tag"] = entry.fields.tag;
        }

        if (
          entry.fields.tagBackgroundColor != "" &&
          entry.fields.tagBackgroundColor != undefined
        ) {
          singleListItem["tagBackgroundColor"] =
            entry.fields.tagBackgroundColor;
        }

        if (
          entry.fields.cta != "" &&
          entry.fields.cta != undefined
        ) {
          if (
            entry.fields.cta.fields.title !== undefined &&
            entry.fields.cta.fields.title !== ""
          ) {
            singleListItem["ctaButtonTitle"] =
              entry.fields.cta.fields.title;
          }

          if (
            entry.fields.cta.fields.ariaLabel !== undefined &&
            entry.fields.cta.fields.ariaLabel !== ""
          ) {
            singleListItem["ctaButtonAriaLabel"] =
              entry.fields.cta.fields.ariaLabel;
          }

          if (
            entry.fields.cta.fields.alias !== undefined &&
            entry.fields.cta.fields.alias !== ""
          ) {
            singleListItem["ctaButtonLink"] =
              entry.fields.cta.fields.alias;
          }

          singleListItem["ctaButtonClass"] = "read-more-link";
          if (
            entry.fields.cta.fields.ctaType != "" &&
            entry.fields.cta.fields.ctaType != undefined
          ) {
            if (
              entry.fields.cta.fields.ctaType === "Primary Button"
            ) {
              singleListItem["ctaButtonClass"] =
                "btn btn-primary";
            } else if (
              entry.fields.cta.fields.ctaType ===
              "Secondary Button"
            ) {
              singleListItem["ctaButtonClass"] =
                "btn btn-secondary";
            } else if (
              entry.fields.cta.fields.ctaType ===
              "Small Text CTA Black"
            ) {
              singleListItem["ctaButtonClass"] =
                "small-text-cta cta-black";
            } else if (
              entry.fields.cta.fields.ctaType ===
              "Small Text CTA White"
            ) {
              singleListItem["ctaButtonClass"] =
                "small-text-cta cta-white";
            } else if (
              entry.fields.cta.fields.ctaType ===
              "Large Text CTA Black"
            ) {
              singleListItem["ctaButtonClass"] =
                "large-text-cta cta-black";
            } else if (
              entry.fields.cta.fields.ctaType ===
              "Large Text CTA White"
            ) {
              singleListItem["ctaButtonClass"] =
                "large-text-cta cta-white";
            } else if (
              entry.fields.cta.fields.ctaType === "Icon Text CTA"
            ) {
              singleListItem["ctaButtonClass"] = "icon-text-cta";
            } else if (
              entry.fields.cta.fields.ctaType === "Read More CTA"
            ) {
              singleListItem["ctaButtonClass"] = "read-more-link";
            }
          }

          if (
            entry.fields.cta.fields.ctaTextColor !== undefined &&
            entry.fields.cta.fields.ctaTextColor !== ""
          ) {
            singleListItem["ctaTextColor"] =
              entry.fields.cta.fields.ctaTextColor;
          }
          if (
            entry.fields.cta.fields.ctaZigzaglineColor !==
              undefined &&
            entry.fields.cta.fields.ctaZigzaglineColor !== ""
          ) {
            singleListItem["ctaZigzaglineColor"] =
              entry.fields.cta.fields.ctaZigzaglineColor;
          }
          if (
            entry.fields.ctaUnderlineColor !== undefined &&
            entry.fields.cta.fields.ctaUnderlineColor !== ""
          ) {
            singleListItem["ctaUnderlineColor"] =
              entry.fields.cta.fields.ctaUnderlineColor;
          }
          if (
            entry.fields.cta.fields.ctaButtonColor !==
              undefined &&
            entry.fields.cta.fields.ctaButtonColor !== ""
          ) {
            singleListItem["ctaButtonColor"] =
              entry.fields.cta.fields.ctaButtonColor;
          }
          if (
            entry.fields.cta.fields.ctaButtonTextColor !==
              undefined &&
            entry.fields.cta.fields.ctaButtonTextColor !== ""
          ) {
            singleListItem["ctaButtonTextColor"] =
              entry.fields.cta.fields.ctaButtonTextColor;
          }
          if (
            entry.fields.cta.fields.hoverColor !== undefined &&
            entry.fields.cta.fields.hoverColor !== ""
          ) {
            singleListItem["hoverColor"] =
              entry.fields.cta.fields.hoverColor;
          }

          if (
            entry.fields.cta.fields.openLinkInNewTab !==
              undefined &&
            entry.fields.cta.fields.openLinkInNewTab !== "" &&
            entry.fields.cta.fields.openLinkInNewTab === true
          ) {
            singleListItem["openLinkInNewTab"] = "_blank";
          }
        }
    }
    return singleListItem;
    })
    
    setListItems(array);
  }

  useEffect(() => {
      (async () => {
        if (componentData.title !== undefined && componentData.title !== "") {
          setTitle(componentData.title);
        }

        if (
          componentData.entryId !== "" &&
          componentData.entryId !== undefined
        ) {
          setEntryId("content-" + componentData.entryId);
        }

        if (
          componentData.subtitleContent !== undefined &&
          componentData.subtitleContent !== ""
        ) {
          setSubtitleContent(componentData.subtitleContent);
        }

        if (
          componentData.backgroundColor !== undefined &&
          componentData.backgroundColor !== ""
        ) {
          setBackgroundColor(componentData.backgroundColor);
        }

        if (
          componentData.columnType !== undefined &&
          componentData.columnType !== ""
        ) {
          setColumnType(componentData.columnType);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    (async () => {
      if (
        componentData.listItems !== undefined &&
        componentData.listItems !== ""
      ) {
        await attempt();
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[componentData.listItems]);

  return (
    <>
      <div className={isFourColumn?"two-column-card-layout four-cols":"two-column-card-layout two-cols"} style={{backgroundColor: backgroundColor}}>
        <div className="container">
          <div className="row">
            {title?<div className="col-12 col-md-12">
                <div className="gallery-head">

                    {title?<h4 dangerouslySetInnerHTML={{ __html: title }}></h4>:null}
                    {subtitleContent?<p dangerouslySetInnerHTML={{ __html: subtitleContent }}></p>:null}
                </div>
            </div>:null}
            {
              listItems.map((item:any, i) => 
                <div className={bootstrapColumnClasses} key={item.index}>
                  <style dangerouslySetInnerHTML=
                      {{__html: `
                        .large-text-cta.new.cta-white.${entryId} i,
                        .large-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};            
                        }            

                       .large-text-cta.new.cta-white.${entryId}:hover  i,
                       .large-text-cta.new.cta-white.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                        .large-text-cta.new.cta-white.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-white.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                         .large-text-cta.new.cta-black.${entryId} i,
                        .large-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .large-text-cta.new.cta-black.${entryId}:hover i,
                       .large-text-cta.new.cta-black.${entryId}:hover {
                            text-decoration: none;
                            color:${item.hoverColor};
                        }            

                         .large-text-cta.new.cta-black.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .large-text-cta.new.cta-black.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId} i,
                        .small-text-cta.new.cta-black.${entryId} {
                           color:${item.ctaTextColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId}:hover i,
                        .small-text-cta.new.cta-black.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-black.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-black.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .small-text-cta.new.cta-white.${entryId} i,
                        .small-text-cta.new.cta-white.${entryId} {
                           color:${item.ctaTextColor};
                        }
                        .small-text-cta.new.cta-white.${entryId}:hover i,
                        .small-text-cta.new.cta-white.${entryId}:hover {
                          text-decoration: none;
                           color:${item.hoverColor};
                        }            

                         .small-text-cta.new.cta-white.${entryId} span {
                          background: ${item.ctaUnderlineColor};
                        }            

                        .small-text-cta.new.cta-white.${entryId}:hover svg path {
                          stroke: ${item.ctaZigzaglineColor};
                        }            

                        .btn.btn-primary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-primary.${entryId}:focus,
                         .btn.btn-primary.${entryId}:focus:active,
                         .btn.btn-primary.${entryId}:visited{
                             background:${hoverColor};
                            color:${ctaButtonTextColor};
                            border-color:${hoverColor};   
                         }             

                         .btn.btn-primary.${entryId} {
                             background:${ctaButtonColor};
                            color:${ctaButtonTextColor};
                            border-color:${ctaButtonColor};
                         }            

                        .btn.btn-primary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color:${item.hoverColor};
                        }            

                        .btn.btn-secondary.${entryId}:active {
                          background:${item.ctaButtonColor} !important;
                          border-color: ${item.ctaButtonColor} !important;
                        }            

                        .btn.btn-secondary.${entryId}:focus,
                        .btn.btn-secondary.${entryId}:focus:active,
                        .btn.btn-secondary.${entryId}:visited{
                            background:${hoverColor};
                           color:${ctaButtonTextColor};
                           border-color:${hoverColor};   
                        }            

                        .btn.btn-secondary.${entryId} {
                            background:${ctaButtonColor};
                           color:${ctaButtonTextColor};
                           border-color: ${ctaButtonColor};
                        }

                         .btn.btn-secondary.${entryId}:hover{
                             background:${item.hoverColor};
                             border-color: ${item.hoverColor};
                        }            

                        .read-more-link.${entryId} {
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        .read-more-link.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }            

                        .icon-text-cta.${entryId},a{
                          color: ${item.ctaTextColor};
                          text-decoration-color: ${item.ctaUnderlineColor};
                        }
                        
                        .icon-text-cta.${entryId}:hover {
                          color: ${item.hoverColor};
                          text-decoration-color: ${item.hoverColor};
                        }       
                        `,
                      }}
                    /> 
                  <div className="verical-list-wrap">
                    {item.image ? (
                      <div className="media-row">
                        <a href={addLangKeyPath(item.ctaButtonLink)}>
                        {item.image ? (<img src={item.image} width = '576' height = '385' alt={item.imageAltText?item.imageAltText:""}/>): ""}
                        </a>
                      </div>
                    ): ""}
                    {item.tag ? (<span className="tag-style tag-bg1" style={{background: item.tagBackgroundColor}}>{item.tag}</span>): ""}
                    {item.title ? (<p className="horizontal-list-item-title" dangerouslySetInnerHTML={{ __html: item.title }}></p>): ""}
                    {item.description ? (<p>
                      <ReactMarkdown allowDangerousHtml source={item.description} disallowedTypes={['paragraph']} unwrapDisallowed />
                    </p>): ""}
                    {item.ctaButtonTitle ?
                      <a className={item.ctaButtonClass+" new "+entryId} target={item.openLinkInNewTab} href={addLangKeyPath(item.ctaButtonLink)} aria-label={item.ctaButtonAriaLabel}>{item.ctaButtonTitle}
                        <i className="fa fa-caret-right" aria-hidden="true"></i>          
                        <span className="svg-outer">
                          <svg width="387" height="12" viewBox="0 0 387 12">
                            <defs>
                              <clipPath>
                                <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                              </clipPath>
                            </defs>
                            <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                              <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                            </g>
                          </svg>          
                        </span>
                        <span>           
                        </span>
                      </a>
                      : ""}

                  </div>
                </div>
              )
            }
          </div>
        </div>  
      </div>

    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

TwoColumnHorizontalList.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { TwoColumnHorizontalList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoColumnHorizontalList);
