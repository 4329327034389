import React from 'react';
import './styles.css';

const PageNotFound: React.FC<any> = () => {
  return (
    <React.Fragment>
    	<div className="jumbotron bg-dark">
     
      <hr className="my-4" />
      <div className="fourOhFour"></div>
      

    </div>
    <section className="paragraph paragraph--type--simple-text paragraph--view-mode--default paragraph--type--simple-text-318 col-12 featured-game-title dark">
                <div className="dynamic-description">

      <p><span>The requested page was not found. The page may have been moved, or the link could be outdated. Please return to <a href="http://WheelofFortune.com" title="Wheel of Fortune">WheelofFortune.com</a>.</span></p>


<p><a href="/">Home</a> | <a href="/ways-to-play">Ways to Play</a> | <a href="/node/2">Giveaways</a> | <a href="/">FAQs</a></p>


      </div>
      </section>
    </React.Fragment>
  );
};

export default PageNotFound;