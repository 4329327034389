import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const HeroCardMediumTwo: React.FC<any> = (props) => {
    useEffect(() => {
        fetchdata();
        async  function fetchdata() {
            console.log("medium two",props);
        }


    });

    const {

        site_logo,
        storeText,
        storeTextMobile,
        searchText,
        HeaderOptions,
        menuItems
    } = props.header;

    return (
    <div className={props.page=="locatorView"?"hero-card hero-card-medium mb-5 mt-5":"hero-card hero-card-medium mb-6 "}>
        <div className="container">
            <div className="card-inner">
                <div className="row">
                    <div className="col-md-6 hero-media">
                        <img src={props.media_image}/>

                    </div>
                    <div className="col-md-6 hero-media-info">
                        <h2>{props.title}<span>{props.sub_title}</span></h2>
                        <p className="body1">{props.paragraph}</p>
                        <a href="javascript:void(0);" className="btn btn-primary">{props.anchor_text}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

HeroCardMediumTwo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { HeroCardMediumTwo };

export default connect(mapStateToProps, mapDispatchToProps)(HeroCardMediumTwo);
