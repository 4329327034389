import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const Pagination: React.FC<any> = ({
                                            fetchSiteData,
                                            header,
                                        }) => {
    useEffect(() => {
        fetchdata();
        async  function fetchdata() {
            const data = await  fetchSiteData();
            console.log("datasss",data);
        }


    }, [fetchSiteData]);

    const {

        site_logo,
        storeText,
        storeTextMobile,
        searchText,
        HeaderOptions,
        menuItems
    } = header;

    return (
        <>
            <div className="pagination">
                <div className="container">
                    <ul className="pagination-listing">
                        <li className="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li className="next"><a href="#"><i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

Pagination.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { Pagination };

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
