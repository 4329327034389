import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FullBleedFeature: React.FC<any> = (props) => {


    return (
        <>
            <div className="small-container text-center mt-5">
                <h4 className="pt-3 mb-3">{props.headerText}</h4>
                <p className="small-text">{props.desc}</p>
            </div>


            <div className="full-bleed-feature-media mt-5" >
                <LazyLoadImage src={props.bg_image} width="100%"></LazyLoadImage>
            </div>

            <div className="small-container ptb-80">
                <div className="longform-text">

                    <p className="mb-0">{props.small_container}</p>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FullBleedFeature.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FullBleedFeature };

export default connect(mapStateToProps, mapDispatchToProps)(FullBleedFeature);
