import React, { useEffect, useState } from "react";
import ComponentComposer from "../../components/ComponentComposer";
import {fetchAllUrl} from "../../api/cms/api";
import Helmet from "react-helmet";
import client from "../../Client";
import { RootState } from "../../reducers";
import { connect } from "react-redux"
import {setAllAliases} from "../../actions/cms";

const UrlGeneratorPage: React.FC<any> = (props) => {

    useEffect(() => {
      async function getData()
      {
         let fetchData:any= await fetchAllUrl();        
      }
        getData();
    }, [fetchAllUrl]);

    return (
        <div className="savers-page" id="maincontent">
        </div>
    );
}

export default UrlGeneratorPage ;
