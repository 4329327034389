import React, { useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";

const TwoColumnParagraph: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const[title,setTitle]=useState('');
    const[columnOneParagraph,setColumnOneParagraph]=useState('');
    const[columnTwoParagraph,setColumnTwoParagraph]=useState('');
    const[mainImage,setMainImage]=useState('');
     const[mainImageAltText,setMainImageAltText]=useState('');
    const[mapDescription,setMapDescription]=useState('');

    useEffect(()=>
    {
     function fetchComponentData()
     {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry.fields.columnOneParagraph!="" && entry.fields.columnOneParagraph!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.columnOneParagraph, options);
              setColumnOneParagraph(rawRichText);
          }   
          if(entry.fields.columnTwoParagraph!="" && entry.fields.columnTwoParagraph!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.columnTwoParagraph, options);
              setColumnTwoParagraph(rawRichText);
          } 
        }); 
      }

    }
    
    fetchComponentData();

        if(componentData.title!="" && componentData.title!=undefined)
        {
            setTitle(componentData.title);
        }

        if(componentData.mainImage!="" && componentData.mainImage!=undefined)
        {
            let fileUrl= componentData.mainImage.fields.file.url;
            setMainImage(fileUrl);
            setMainImageAltText(componentData.mainImage.fields.title);
        }
         if(componentData.mapDescription!="" && componentData.mapDescription!=undefined)
        {
            setMapDescription(componentData.mapDescription);
        }

    },[]);

    return (
    <>
        <div className="medium-container">
           {title?<div className="impact-head text-center">
             <h2 className="box-heading wow fadeIn" data-wow-duration="2s" data-wow-delay="0" >{title}</h2>
           </div>:null}          
           <div className="row">
             {columnOneParagraph?<div className="col-12 col-lg-6 xs-no-space desktop-pr-24 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
               <p dangerouslySetInnerHTML={{__html:columnOneParagraph}}></p>
             </div>:null}
             {columnTwoParagraph?<div className="col-12 col-lg-6 xs-no-space desktop-pl-24 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
               <p dangerouslySetInnerHTML={{__html:columnTwoParagraph}}></p>
             </div>:null}
           </div>    
           <div className="map-outer">
              {mainImage?<img className="mt-15 xs-mt-45 wow fadeIn" data-wow-duration="2s" width = '850' height = '470' data-wow-delay="0" src={mainImage} alt={mainImageAltText?mainImageAltText:""}/>:null}
              {mapDescription?<div className="map-label">
                <span></span>
                <p className="body2" dangerouslySetInnerHTML={{__html:mapDescription}}></p>
              </div>:null}
           </div>     

        </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

TwoColumnParagraph.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { TwoColumnParagraph };

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnParagraph);
