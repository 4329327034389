import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { BLOCKS } from '@contentful/rich-text-types';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getRichTextEntityLinks } from '@contentful/rich-text-links';
import { getGlobalData, getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';
import $ from 'jquery';
import { isEqual } from "lodash";

const Page: React.FC<any> = (componentData) => {
  
  const currLocale = getLocale();
  const [type, setType] = useState('Container');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [description, setDescription] = useState('');
  const [fullHtmlDescription, setFullHtmlDescription] = useState('');
  const [title, setTitle] = useState('');
  const [contentAlignment, setContentAlignment] = useState('');

  const globalData = getGlobalData();
  const { domain } = globalData;

  useEffect(() => {

  function fetchComponentData()
    {
      client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
         if(entry.fields.description!=undefined &&entry.fields.description!="")
         {
            const options = {
          renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
              return node.data.target.fields.html;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" width = '335' height = '54' alt="${node.data.target.fields.title}"/>`,
            [INLINES.ASSET_HYPERLINK]:(node: any) => `<a href="${node.data.target.fields.file.url}" target="_blank">${node.data.target.fields.title}</a> `
          }
          }            
            const description = entry.fields.description;
            /**
             * 
             * Removes content from HTML document, based on domain
             * 
             * @param item - the HTML content to analyze
             * @param key - key of the parent object
             */
            const deleteDomain = (item: any, key: number) => {
              if (!item.data.target) {
                return;
              }
              const fields = item.data.target.fields;
              const hasDomainsArray = fields.domains;
              const domainCheck = !hasDomainsArray || fields.domains.includes(domain);
              if (!domainCheck) {
                delete description.content[key]
              }
            }
            entry.fields.description.content.forEach((item: any, key: number) => deleteDomain(item, key));
            // let rawRichTextLinks=getRichTextEntityLinks(entry.fields.description);
            let rawRichTextDescription = documentToHtmlString(description, options);
            setDescription(rawRichTextDescription);
         }
      })
    }

    fetchComponentData();
    if (componentData.type !== '' && componentData.type !== undefined) {
      setType(componentData.type);
    }

    if (componentData.backgroundColor !== '' && componentData.backgroundColor !== undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.title !== '' && componentData.title !== undefined) {
      setTitle(componentData.title);
    }

    if (componentData.contentAlignment !== '' && componentData.contentAlignment !== undefined) {
      setContentAlignment(componentData.contentAlignment);
    }

    if (componentData.fullHtmlDescription !== '' && componentData.fullHtmlDescription !== undefined) {
      setFullHtmlDescription(componentData.fullHtmlDescription);
    }


    setTimeout(()=>
      {  
        if( $('table').length>0 )  
        {
          var SearchFieldsTable = $("table tbody");

           var trows = SearchFieldsTable.children("tr");          

           $.each(trows, function (index, row) {

             if(index==0)
             {
               var headings:any= $(row).children("th");
                $(headings).attr("scope","col");
             }
             else
             {
                var headings:any= $(row).children("th");
                $(headings).attr("scope","row");
             }
               
           });         
        }
        $('table').wrap("<div class=\"table-wrapper\"></div>");
      },1000)
    

  }, []);

  return (
    <>
      {type === "Half Container"?
        <div className="small-container ptb-80">
          {title?<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>:null}
          {description?<div className="col-12 col-lg-10 offset-lg-1" dangerouslySetInnerHTML={{ __html:description}}></div>:null}
          {fullHtmlDescription?<div className="col-12 col-lg-10 offset-lg-1"><ReactMarkdown allowDangerousHtml source={fullHtmlDescription} disallowedTypes={['paragraph']} unwrapDisallowed /></div>:null}
        </div>
        :
        <section className="about-info center">
          <div className="container">
              <div className={"heading-inner row "+(contentAlignment=="Center"?"text-center":"")} >
              <div className="col-12 col-lg-10 offset-lg-1 extra-space">{title?<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>:null}</div>
              {description?<div className="col-12 col-lg-10 offset-lg-1 extra-space" dangerouslySetInnerHTML={{ __html:description}}></div>:null}
               {fullHtmlDescription?<div className="col-12 col-lg-10 offset-lg-1 extra-space"><ReactMarkdown allowDangerousHtml source={fullHtmlDescription} disallowedTypes={['paragraph']} unwrapDisallowed /></div>:null}
            </div>
          </div>
        </section>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

Page.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { Page };

export default connect(mapStateToProps, mapDispatchToProps)(Page);
