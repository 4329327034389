import React, { useEffect ,useState} from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const ProfileInfo: React.FC<any> = (componentData) => {
    
    const currLocale = getLocale();
    const [description, setDescription] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [textFontColor, setTextFontColor] = useState("");
    const [socialLinks, setSocialLinks] = useState([]);
    const [socialLinksLength, setSocialLinksLength] = useState(0);
    const [image, setImage] = useState('');
    const [imageAltText, setImageAltText] = useState('');
    const [ctaTitle, setCtaTitle] = useState('');
    const [ctaAriaLabel, setctaAriaLabel] = useState('');
    const [ctaLink, setCtaLink] = useState('');
    const [ctaClass, setCtaClass] = useState('read-more-link');
    const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
    const [entryId, setEntryId] = useState('');
    const [ctaButtonColor, setCtaButtonColor] = useState('');
    const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
    const [ctaTextColor, setCtaTextColor] = useState('');
    const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
    const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
    const [hoverColor, setHoverColor] = useState('');

     useEffect(() => {
        if (componentData.description != '' && componentData.description != undefined) {
         setDescription(componentData.description);
        }
        if (componentData.entryId != "" && componentData.entryId != undefined) {
          setEntryId("content-"+componentData.entryId);
        }
        if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
         setBackgroundColor(componentData.backgroundColor);
        }
        if (componentData.textFontColor != '' && componentData.textFontColor != undefined) {
         setTextFontColor(componentData.textFontColor);
        }
        if (componentData.image != '' && componentData.image != undefined) {
         setImage(componentData.image.fields.file.url);
         setImageAltText(componentData.image.fields.title);
        }

        if (componentData.socialLinks != '' && componentData.socialLinks != undefined) {
         componentData.socialLinks.map(async(item:any,i:any)=>
         {
            let allSocialLinks:any=[];

          await client.getEntry(item.sys.id, {locale: currLocale})
          .then(async(entry : any) => {
            let singleSocialLink={
                index: i,
                socialIconClass:"",
                link:""
            }
            if (entry != '' && entry != undefined) {
              if(entry.fields.socialiconClass != undefined && entry.fields.socialiconClass != ""){
                 singleSocialLink["socialIconClass"]=entry.fields.socialiconClass.replace("fa-fa", "fa fa");
              }

              if(entry.fields.link != undefined && entry.fields.link != ""){
                 singleSocialLink["link"]=entry.fields.link;
              }
              await allSocialLinks.push(singleSocialLink);
              allSocialLinks.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              
              await setSocialLinks(allSocialLinks);
              await setSocialLinksLength(allSocialLinks.length);
            }
             
           }) 
         })
        }

        if (componentData.cta != '' && componentData.cta != undefined) {
          client.getEntry(componentData.cta.sys.id, {locale: currLocale})
          .then((entry : any) => {
            if (entry != '' && entry != undefined) {
              if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
                let ctaClassName = 'read-more-link';    

                if (entry.fields.ctaType === 'Primary Button') {
                  setCtaClass('btn btn-primary');
                }
                else if (entry.fields.ctaType === 'Secondary Button') {
                  setCtaClass('btn btn-secondary');
                }
                else if (entry.fields.ctaType === 'Small Text CTA Black') {
                  setCtaClass('small-text-cta cta-black');
                }
                else if (entry.fields.ctaType === 'Small Text CTA White') {
                  setCtaClass('small-text-cta cta-white');
                }
                else if (entry.fields.ctaType === 'Large Text CTA Black') {
                  setCtaClass('large-text-cta cta-black');
                }
                else if (entry.fields.ctaType === 'Large Text CTA White') {
                  setCtaClass('large-text-cta cta-white');
                }
                else if (entry.fields.ctaType === 'Icon Text CTA') {
                  setCtaClass('icon-text-cta');
                }
                else if (entry.fields.ctaType === 'Read More CTA') {
                  setCtaClass('read-more-link');
                }
              }
              if (entry.fields.ariaLabel != '' && entry.fields.ariaLabel != undefined) {
                setctaAriaLabel(entry.fields.ariaLabel);
              }

              if (entry.fields.title != '' && entry.fields.title != undefined) {
                setCtaTitle(entry.fields.title);
              }

              if (entry.fields.alias != '' && entry.fields.alias != undefined) {
                setCtaLink(entry.fields.alias);
              }
              if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
                setCtaTextColor(entry.fields.ctaTextColor);
              }
              if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
                setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
              }
              if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
                setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
              }
              if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
                setCtaButtonColor(entry.fields.ctaButtonColor);
              }
              if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
                setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
              }
              if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
                setHoverColor(entry.fields.hoverColor);
              }
              if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
                setOpenLinkInNewTab("_blank");
              }
            }
      })
      .catch(console.error)
    }
     },[]);
    return (
    <>
       <style dangerouslySetInnerHTML=
          {{__html: `
            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            } 
            `,
          }}
        />
    <section className="profile-info">
        <div className="container" style={{backgroundColor: backgroundColor,color:textFontColor}}>
            <div className="row">
                <div className="col-12">
                    <div className="inner">
                        <div className="left">
                            {image?<img src={image} width = '226' height = '226' alt={imageAltText?imageAltText:""}></img>:null}
                        </div>
                        <div className="right">
                            {description?<ReactMarkdown allowDangerousHtml source={description}></ReactMarkdown> :null}
                            <div className="info-bottom">
                                {ctaTitle ? 
                                  <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)} aria-label={ctaAriaLabel}>{ctaTitle}
                                    <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                    <span className="svg-outer">
                                      <svg width="387" height="12" viewBox="0 0 387 12">
                                        <defs>
                                          <clipPath>
                                            <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                          </clipPath>
                                        </defs>
                                        <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                          <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                        </g>
                                      </svg>          
                                    </span>
                                    <span>           
                                    </span>
                                </a>
                                  : ""
                                }
                                <div className="social-block">
                                    <ul>        
                                        {socialLinks?socialLinks.map((item:any)=>
                                          <li><a href={addLangKeyPath(item.link)} aria-label={item.socialIconClass+" link"}><i className={item.socialIconClass} aria-hidden="true"></i></a></li>
                                        ):null}                 
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ProfileInfo.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ProfileInfo };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
