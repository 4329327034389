import React,{ useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTarget } from '../../utils';
import { footerIds } from '../../analyticsTags';
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import defaultSonyLogo from '../../images/footer-logo.png';
import Cookies from "universal-cookie";
import { useLocation, useHistory } from "react-router-dom";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath,getDefaultLanguage } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';
import { getGlobalData } from "../../api/cms/api";

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Entry } from 'contentful';

const cookies = new Cookies();

const Footer: React.FC<any> = ({ fetchSiteData,footer,allAliases }) => {
  const defaultLanguage= getDefaultLanguage();
  const location = useLocation();
  let history = useHistory();
  const currLocale = getLocale();

  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  
  const globalData = getGlobalData();
  const langToggleOpts = globalData.langToggleOpts;

  const [currLang, setCurrLang] = useState("");
  const [ctaTitle, setCtaTitle] = useState('');
  const [ctaAriaLabel, setCtaAriaLabel] = useState('');
  const [ctaLink, setCtaLink] = useState('');
  const [ctaClass, setCtaClass] = useState('read-more-link');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [entryId, setEntryId] = useState('');
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [options,setOptions]= useState<any>([]);
  const [countryLangToggle, setCountryLangToggle] = useState(false);
  const [dynamicToggleClass, setDynamicToggleClass] = useState("");
  const [levelAccessCta, setLevelAccessCta] = useState("");

  let date = new Date(); // Now
  date.setDate(date.getDate() + 30);

  useEffect(() => {    
      
    client.getEntries({
      content_type: 'languageToggle',     
      'fields.domains': globalData.localeDomain,
       'locale': currLocale
      })
      .then(async (response: any) => {
        if(response.items.length>0)
        {
         let optionsArray:any=[]; 
         response.items.map(async(data:any)=>
         {
          if(data.fields!==undefined || data.fields!=="")
          {
            if(data.fields.alias!==undefined && data.fields.alias!=="")
            {
              if(data.fields.flagImage!==undefined)
              {
                let toggleLangKey = data.fields.mappingLanguage ? data.fields.mappingLanguage : '';
                let toggleLangLabel = data.fields.alias ? data.fields.alias : '' ;
                let flagIcon = data.fields.flagImage ? data.fields.flagImage.fields.file.url:'';
                let redirectLink= data.fields.redirectUrl ? data.fields.redirectUrl : '';
                let itemOrder = data.fields.toggleItemOrder ? data.fields.toggleItemOrder: 0;
                optionsArray.push({langKey: toggleLangKey, langLabel: toggleLangLabel, icon: flagIcon, link: redirectLink,index:itemOrder});   
                optionsArray.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                await setOptions(optionsArray); 
                setCountryLangToggle(true);
              }
              else
              {
                optionsArray.push({[data.fields.alias]: data.fields.alias});
                optionsArray.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
                await setOptions(optionsArray); 
              }
            }

          }         
         }
        )
        }
      });

    fetchdata();
    async function fetchdata() {
      const data = await fetchSiteData();

    }

  }, [fetchSiteData]);

    const {
        LinkOptions,
        menuItems,
        join_ss_text,
        join_ss_desc,
        join_cta_id,
        bottomFooterBottomMenuLinks,
        bottomFooterColumn1MenuLinks,
        bottomFooterColumn2CopyrightContent,
        social,
        termlyButton,
        levelAccessImage
    } = footer;



    client.getEntry(join_cta_id, {locale: currLocale})
    .then((entry : any) => {
      if (entry != '' && entry != undefined) {

          setEntryId("content-"+join_cta_id);

        if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
          let ctaClassName = 'read-more-link';

          if (entry.fields.ctaType === 'Primary Button') {
            setCtaClass('btn btn-primary');
          }
          else if (entry.fields.ctaType === 'Secondary Button') {
            setCtaClass('btn btn-secondary');
          }
          else if (entry.fields.ctaType === 'Small Text CTA Black') {
            setCtaClass('small-text-cta cta-black');
          }
          else if (entry.fields.ctaType === 'Small Text CTA White') {
            setCtaClass('small-text-cta cta-white');
          }
          else if (entry.fields.ctaType === 'Large Text CTA Black') {
            setCtaClass('large-text-cta cta-black');
          }
          else if (entry.fields.ctaType === 'Large Text CTA White') {
            setCtaClass('large-text-cta cta-white');
          }
          else if (entry.fields.ctaType === 'Icon Text CTA') {
            setCtaClass('icon-text-cta');
          }
          else if (entry.fields.ctaType === 'Read More CTA') {
            setCtaClass('read-more-link');
          }
        }
        if (entry.fields.title != '' && entry.fields.title != undefined) {
          setCtaTitle(entry.fields.title);
        }
        if (entry.fields.ariaLabel != '' && entry.fields.ariaLabel != undefined) {
          setCtaAriaLabel(entry.fields.ariaLabel);
        }
        if (entry.fields.alias != '' && entry.fields.alias != undefined) {
          setCtaLink(entry.fields.alias);
        }
         if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setHoverColor(entry.fields.hoverColor);
          }
        if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
          setOpenLinkInNewTab("_blank");
        }
      }
    })
    .catch(() => {})

  const getLanguage = () => {

    let pathArray = window.location.pathname.split('/');
    if (pathArray[1] !== undefined && pathArray[1] == "en") {
      return "en";
    }
    else if (pathArray[1] !== undefined && pathArray[1] == "fr") {
      return "fr";
    }
    else if (pathArray[1] !== undefined && pathArray[1] == "es") {
      return "es";
    }
    else if (cookies.get("Language") !== undefined && cookies.get("Language") !== "") {
      return cookies.get("Language");
    }
    return "en";
  };

  const changeLanguage=(value:any)=>{

      setCurrLang(value);
      cookies.set("Language", value, { path: "/", expires: date });

      let cookiePriority = true;
      let selectedLocale = getLocale(cookiePriority);

      let pathArray = location.pathname.split('/');
      const pattern = /[a-z]/;

      if(Object.keys(allAliases).length > 0)
      {
         Object.keys(allAliases).map(
          (item: any, i) => {

            if(item == selectedLocale){
              if(allAliases[item] !== "") {
                 if(defaultLanguage.toLowerCase()==value)
                  {
                    let fullAlias = '/'+allAliases[item];
                    history.push(fullAlias);
                    window.location.reload(true);
                  }
                  else{
                    let fullAlias = '/'+value+'/'+allAliases[item];;
                    history.push(fullAlias);
                    window.location.reload(true);
                  }
              }
              else {
                if (pathArray[1] !== undefined && pathArray[1].match(pattern) && pathArray[1].length < 3) {
                  pathArray = pathArray.filter(function (el) {
                    return el != "";
                  });
                  pathArray.shift();
                  if(defaultLanguage.toLowerCase()==value)
                  {
                    let fullAlias = '/'+pathArray.join('/');
                    history.push(fullAlias);
                    window.location.reload(true);
                  }
                  else{
                    let fullAlias = '/'+value+'/'+pathArray.join('/');
                    history.push(fullAlias);
                    window.location.reload(true);
                  }
                }
                else {
                  pathArray = pathArray.filter(function (el) {
                    return el != "";
                  });

                  if(defaultLanguage.toLowerCase()==value)
                  {
                    let fullAlias = '/'+pathArray.join('/');
                    history.push(fullAlias);
                    window.location.reload(true);
                  }
                  else{
                    let fullAlias = '/'+value+'/'+pathArray.join('/');
                    history.push(fullAlias);
                    window.location.reload(true);
                  }

                   
                }
              }
            }
          });
      }
      else {
        if (pathArray[1] !== undefined && pathArray[1].match(pattern) && pathArray[1].length < 3) {
          pathArray = pathArray.filter(function (el) {
            return el != "";
          });
          pathArray.shift();
          if(defaultLanguage.toLowerCase()==value)
          {
            let fullAlias = '/'+pathArray.join('/');
            history.push(fullAlias);
            window.location.reload(true);
          }
          else{
            let fullAlias = '/'+value+'/'+pathArray.join('/');
            history.push(fullAlias);
            window.location.reload(true);
          }
        }
        else {
          pathArray = pathArray.filter(function (el) {
            return el != "";
          });

          if(defaultLanguage.toLowerCase()==value)
          {
            let fullAlias = '/'+pathArray.join('/');
            history.push(fullAlias);
            window.location.reload(true);
          }
          else{
            let fullAlias = '/'+value+'/'+pathArray.join('/');
            history.push(fullAlias);
            window.location.reload(true);
          }
        }
      }
  }

  const changeSite = (value: any) => {
    window.location.href = value;
  }

  const fetchLevelAccessCta = async (item: any) => {
    try {
      const entry: Entry<{text: string,domain: string[]}> = await client.getEntry(item.sys.id, {locale: currLocale});
      if (!entry.fields.domain || entry.fields.domain && entry.fields.domain.includes(globalData.domain)) {
        setLevelAccessCta(entry.fields.text);
      }
    } catch (error) {
      console.log(error || "Error fetching footer icon");
    }
  }

  useEffect(() => {
    (async () => {
      if(levelAccessImage?.ctaList) {
        try {
          await levelAccessImage.ctaList.forEach((item: any) => {
            fetchLevelAccessCta(item);
          })
        } catch (e) {/** */} 
      }
    })();
  },[levelAccessImage])

  return (
    <>
       <style dangerouslySetInnerHTML=
          {{__html: `
            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color:${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color:${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color:${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color:${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color:${ctaButtonColor};
            }

             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color:${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            } 
            `,
          }}
        />
      <footer id="footer">
          <div className="container">
              <div className="footer-inner">
                  <div className="row">
                      <div className="col-md-3 col-xs-12">
                          <div className="footer-col footer-col-1">
                              <ul>
                                  {LinkOptions  ? LinkOptions.map((item:any)=>{
                                      return(<li><a href={addLangKeyPath(item.link)} dangerouslySetInnerHTML={{__html:item.title}}></a></li>)
                                  }) : null}
                              </ul>
                          </div>
                      </div>

                      <div className="col-md-3 col-xs-12">
                          <div className="footer-col footer-common-listing">
                              <ul>
                                  {menuItems  ? menuItems.map((item:any)=>{
                                      return(<li><a href={addLangKeyPath(item.link)} dangerouslySetInnerHTML={{__html:item.title}}></a></li>)
                                  }) : null}

                              </ul>
                          </div>
                      </div>

                      <div className="col-md-3 col-xs-12">
                          <div className="footer-col footer-descp-block">
                              {join_ss_text?<p className="title-20" dangerouslySetInnerHTML={{ __html:join_ss_text}}></p>:null}
                              {join_ss_desc?<p><ReactMarkdown allowDangerousHtml source={join_ss_desc} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                              {ctaTitle ?  
                                <a className={ctaClass+" new"+" content-"+join_cta_id} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)} aria-label={ctaAriaLabel}>{ctaTitle}
                                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                  <span className="svg-outer">
                                    <svg width="387" height="12" viewBox="0 0 387 12">
                                      <defs>
                                        <clipPath>
                                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                        </clipPath>
                                      </defs>
                                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                      </g>
                                    </svg>          
                                  </span>
                                  <span>           
                                  </span>
                                </a>
                               : ""}
                          </div>
                      </div>

                      <div className="col-md-3 col-xs-12">
                          <div className="footer-col social-block">
                              <ul>
                                {social && Array.isArray(social) ? social.map(
                                  (item : any, i: any) => (
                                    <li><a href={addLangKeyPath(item.url)} aria-label={item.icon_class+" link"}><i className={item.icon_class} aria-hidden="true"/></a></li>
                                  )
                                ) : ""}
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="footer-bottom">
              <div className="container">
                  <div className="footer-bt-row" >
                      <div className="row">
                          <div className="col-md-6 col-xs-12">
                              <div className="listing-block">
                                  <ul>
                                  {bottomFooterColumn1MenuLinks  ? bottomFooterColumn1MenuLinks.map((item:any)=>{
                                      return(<li><a href={addLangKeyPath(item.link)} dangerouslySetInnerHTML={{__html:item.title}}></a></li>)
                                  }) : null}
                                  </ul>
                              </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            {bottomFooterColumn2CopyrightContent?<p dangerouslySetInnerHTML={{__html:bottomFooterColumn2CopyrightContent.replace(/CURRENT_DATE/g, new Date().getUTCFullYear())}}></p>:null}
                              
                          </div>
                      </div>
                  </div>

                  <div className="footer-bt-row2">
                      <div className="row">
                          <div className="col-md-10 col-xs-12">
                              <div className="listing-block">
                                  <ul>
                                  {bottomFooterBottomMenuLinks  ? bottomFooterBottomMenuLinks.map((item:any)=>{
                                      return(<li><a href={addLangKeyPath(item.link)} dangerouslySetInnerHTML={{__html:item.title}}></a></li>)
                                  }) : null}
                                  </ul>
                              </div>
                          </div>
                          <div className="col-md-2 col-xs-12">
                            <div className={countryLangToggle ? "langauage-block lang-dropdown-with-flag": "langauage-block"}>
                              { countryLangToggle && options ?
                                options.map((data:any, i:any) => {
                                  if ((data.link == undefined || data.link == '') && getLanguage() == data.langKey) {
                                    return <>
                                      {data.icon ? <img className={"flag-icon"} src={data.icon} alt="flag" /> 
                                        : <img className={"globe-icon"} width = '13' height = '13' src={require("../../scss/images/globe.png")} alt="globe"  aria-hidden='true' />
                                      }
                                    </>
                                  }
                                })
                                : <img className={"globe-icon"} width = '13' height = '13' src={require("../../scss/images/globe.png")} alt="globe"  aria-hidden='true'/>
                              }


                              <DropdownMenu trigger={getLanguage().toUpperCase()}>
                                { countryLangToggle && options ?
                                  <DropdownItemGroup>
                                    {options.map((data:any, i:any) => {
                                      let _lang = data.langLabel.split('/')
                                      let countryName = _lang[0].trim()
                                      let langName = _lang[1].trim()
                                      let countryAriaLabel = countryName === "US"  ? "Country is United States and " : "Country is Canada and "
                                      let langAriaLabel = langName === "ES" ? "language is Spanish" : langName === "EN" ? "language is English" :  "language is French"
                                      return(
                                      <DropdownItem onClick={() => {changeLanguage(langName.toLowerCase()); changeSite(data.link)} }>
                                        <img className={"flag-icon"} src={data.icon} alt="flag" aria-hidden='true' /> 
                                        {data.langLabel}
                                      </DropdownItem>
                                      ) 
                                    })}
                                  </DropdownItemGroup>
                                  :
                                  // COUNTRY IS ASSUMED BASED ON DOMAIN
                                  <DropdownItemGroup>
                                    {options.map((data:any) =>
                                    Object.keys(data).map(
                                      (item: any, i) => {
                                      return <DropdownItem onClick={() => changeLanguage(data[item].toLowerCase())}>{data[item]}</DropdownItem>
                                    }))}
                                  </DropdownItemGroup>}
                              </DropdownMenu>

                            </div>
                          </div>
                      </div>
                      <div className="row">
                        {levelAccessImage &&
                          <div className="col-md-3">
                            <a href={levelAccessCta} style={{ marginTop: 10 }}>
                              <img src={levelAccessImage.imageUrl} alt={levelAccessImage.altText} style={{ height: 30, marginTop: 10 }} />
                            </a>
                          </div>
                        }
                        <div className="right-cookie-btn col-md-9">
                        { termlyButton!==""&& termlyButton!==undefined?
                            termlyButton.map((item:any)=>
                            {
                              return (<div dangerouslySetInnerHTML={{__html:item.fields.termlyButtonHtml}}></div>)
                            }):null
                          }</div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
   </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: any) => ({
    footer: state.site.footer,
    allAliases:state.site.allAliases,
});

Footer.defaultProps = {
    fetchSiteData: () => {},
    footer: {
        LinkOptions:[],
        menuItems:[],
        join_ss_text:"",
        join_ss_desc:"",
        join_cta_text:"",
        join_cta_link:"",
    },
};



export { Footer };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
