import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const ThriftProudNav: React.FC<any> = ({
                                  props
                               }) => {
    useEffect(() => {
       console.log(props,"props");

    }, );



    return (
           <>
            <div className="thrift-nav">
            <div className="container">
               <div className="thrift-inner">
                   <h6>Thrift Proud</h6>
                   <ul>
                       <li><a href="#"><img className="globe-icon" src={require("../../images/shirt-female.png")} /> Style</a></li>
                       <li><a href="#"><img className="globe-icon"
                                            src={require("../../images/clothes-design-thread.png")}/> thriftorials</a></li>
                       <li><a href="#"><img className="globe-icon" src={require("../../images/recycling-refresh.png")}/> Rethink
                           Reuse</a></li>
                       <li><a href="#"><img className="globe-icon"  src={require("../../images/ecology-globe-leaf.png")} /> impact</a>
                       </li>
                       <li><a href="#"><img className="globe-icon"   src={require("../../images/multiple-users-1.png")}/> Community</a>
                       </li>
                       <li><a href="#"><img className="globe-icon" src={require("../../images/newspaper-fold.png")}/> News & Updates</a>
                       </li>
                   </ul>
               </div>
            </div>
            </div>

           </>
    );
};
export default  ThriftProudNav;





