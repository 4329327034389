import React, { useEffect, useState } from "react";
import ComponentComposer from "../../components/ComponentComposer";
import {fetchDonationPage} from "../../api/cms/mock";
import {fetchCms} from "../../api/cms/api";
import Helmet from "react-helmet";
import client from "../../Client";
import { RootState } from "../../reducers";
import { connect } from "react-redux"
import {setAllAliases} from "../../actions/cms";

const DonationPage: React.FC<any> = (props) => {

    const [data, setData] = useState<any[]>([]);
    const [seoInformation,setSeoInformation]=useState([]);
    const [miscTagsConfiguration,setMiscTagsConfiguration]=useState([]);
    const [miscTagsConfigurationLength,setMiscTagsConfigurationLength]=useState(0);
    let originPath= window.location.href

    useEffect(() => {
        const fetchData = async () => {
            const data:any = await fetchCms("Donate");

            if (!data || !data.components) {
                return;
            }

            if (data.allAliases !== undefined && data.allAliases !== "") {
              props.changeAliase(data.allAliases)
            }

            if(data.seoInformation!=undefined && data.seoInformation!="")
            {
                let allMescTags:any=[];
              setSeoInformation((seoInformation)=>seoInformation.concat(data.seoInformation)); 
              const script = document.createElement("script");
              script.type = 'application/json';
              script.innerHTML = JSON.stringify(data.seoInformation.structuredData);   
              document.body.appendChild(script);

              if(data.seoInformation.metaDescription!=undefined && data.seoInformation.metaDescription!="")
              {
                document.getElementsByTagName("meta")[3].content=data.seoInformation.metaDescription;              
              }

              if(data.seoInformation.miscTagsConfiguration!=undefined && data.seoInformation.miscTagsConfiguration!="")
              {
                data.seoInformation.miscTagsConfiguration.map((item:any,i:any)=>
                 client.getEntry(item.sys.id).then(async(entry : any) => {
                     let singleMescTag={
                         name:"",
                         content:""
                      }
                  if(entry!=undefined && entry!="")
                  {
                    if(entry.fields.name!=undefined && entry.fields.name!="")
                    {
                        singleMescTag["name"]=entry.fields.name;
                    }   

                    if(entry.fields.value!=undefined && entry.fields.value!="")
                    {
                        singleMescTag["content"]=entry.fields.value;
                    }   
                    allMescTags.splice(i,0,singleMescTag);
                    await setMiscTagsConfiguration(allMescTags);
                    await setMiscTagsConfigurationLength(allMescTags.length);
                  }
                 }) 
                )
            }
            }

             setData(data.components ? data.components : null);

        };

        fetchData();
    }, []);
    return (
        <div className="savers-page" id="maincontent">
            {seoInformation.length>0?seoInformation.map((item:any)=>
                <Helmet>
                {item.pageTitle?<title>{item.pageTitle}</title>:null}
                {item.keywords?<meta name="keywords" content={item.keywords} />:null}
                {item.shortDescription?<meta name="short-description" content={item.shortDescription} />:null}
                {item.longDescription?<meta name="long-description" content={item.longDescription} />:null}
                {item.pageTitle?<meta property="og:title" content={item.pageTitle} />:null}
                {item.contentType?<meta property="og:type" content={item.contentType} />:null}
                {item.image?<meta property="og:image" content={item.image} />:null}
                {item.longDescription?<meta name="og:description" content={item.longDescription} />:null}
                <meta property="og:url" content={originPath} />
                {item.canonical?<link rel="canonical" href={item.canonical} />:null}
                {miscTagsConfiguration.length>=1?miscTagsConfiguration.map((item:any)=>
                    <meta name={item.name} content={item.content}/>
                 )
                 :null}
                {item.noIndex==true||item.noIndex==false?<meta name="robots" content={item.noIndex}/>:null}
                </Helmet>):null}
            {data && data.map((d, i) => <ComponentComposer  key={i} {...d} />)}
        </div>
    );
}
const mapDispatchToProps = (dispatch: any) => ({
   changeAliase:(newAlias:any)=>{dispatch(setAllAliases(newAlias))},
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
  allAliases:state.site.allAliases,
});

DonationPage.defaultProps = {
  fetchSiteData: () => {},
  header: {},
  allAliases:[]
};

export { DonationPage };

export default connect(mapStateToProps, mapDispatchToProps)(DonationPage);
