import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ComponentComposer from "../../components/ComponentComposer";
import client from '../../Client';

const SearchPage: React.FC<any> = () => {
  const { keyword } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsLength, setSearchResultsLength] = useState(0);

  useEffect(() => {
    if (keyword !== undefined && keyword !== "") {

      client.getEntries({
        'query': keyword,
        'content_type': 'pageLayout',
        'limit': 10
      })
      .then((response) => {
        if (response.items.length > 0) {
          let allSearchResults: any = [];

          response.items.map(async(entry:any,i:any) => {
            allSearchResults.splice(i, 0, entry);
            await setSearchResults(allSearchResults);
            await setSearchResultsLength(allSearchResults.length);
          });
        }
      })
      .catch(console.error)
    }
  },[]);

  return (
    <>
     <div className="savers-page" id="maincontent">
      <section className="banner-with-solid-bg banner-solid-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="clr-white">Search</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-5">
          <div className="container">
              <div className="row">
                  <div className="col-12 col-lg-10 offset-lg-1">
                    {searchResultsLength > 0 ? searchResults.map(
                        (item: any, i) => (
                          <div className="search-item">
                            {item.fields.title? <h5 dangerouslySetInnerHTML={{ __html:item.fields.title}}></h5>: ""}
                            {item.fields.description? <p dangerouslySetInnerHTML={{ __html:item.fields.description}}></p> : ""}
                            {item.fields.urlAlias?<a href={"/"+item.fields.urlAlias} className="btn btn-primary">Read More</a>: ""}
                          </div>
                        )
                      )
                    : <h4>There is no result for your search</h4>
                    }
                  </div>
              </div>
          </div>
      </section>
    </div>
    </>
  );
}

export default SearchPage;
