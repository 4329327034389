import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const FacilitiesBlock: React.FC<any> = (props) => {
    return (
    <>
      <div className="facilities-block mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="non-profit-block">
                                <h4 className="mb-4">{props.title}</h4>
                                <h4 className="mb-4 mob-title">{props.title}</h4>
                                <div className="non-proft-inner">
                                    <div className="logo-row">
                                        <img src={props.logo}/>
                                    </div>
                                    <p className="mt-3"><b>{props.heading}</b></p>
                                    <p className="small-text">{props.paragraph}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="facility-wrap">
                                <h4 className="mb-4">{props.facility_title}</h4>
                                <h4 className="mb-4 mob-title">{props.facility_title}</h4>
                                <ul>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[0].image}/></span>
                                        <p className="small-text">{props.facility[0].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[1].image}/></span>
                                        <p className="small-text">{props.facility[1].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[2].image}/></span>
                                        <p className="small-text">{props.facility[2].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[3].image}/></span>
                                        <p className="small-text">{props.facility[3].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[4].image}/></span>
                                        <p className="small-text">{props.facility[4].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[5].image}/></span>
                                        <p className="small-text">{props.facility[5].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[6].image}/></span>
                                        <p className="small-text">{props.facility[6].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[7].image}/></span>
                                        <p className="small-text">{props.facility[7].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[8].image}/></span>
                                        <p className="small-text">{props.facility[8].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[9].image}/></span>
                                        <p className="small-text">{props.facility[9].title}</p>
                                    </li>
                                    <li>
                                        <span className="icon-wrap"><img src={props.facility[10].image}/></span>
                                        <p className="small-text">{props.facility[10].title}</p>
                                    </li>
                                    <li>
                        <span className="learn-more">
                            {props.facility[11].title}
                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FacilitiesBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FacilitiesBlock };

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesBlock);
