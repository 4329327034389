import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
// import Autocomplete from "react-autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getGlobalData } from "../../api/cms/api";
import Cookies from "universal-cookie";
import $ from "jquery";
import { TextField } from "@material-ui/core";
import { getLocale } from "../../api/cms";

const cookies = new Cookies();

const FindStore: React.FC<any> = (componentData) => {
  const langCookieKey = cookies.get("Language").toUpperCase();

  const globalData = getGlobalData();
  const storeUrl = globalData.storeUrl;
  const storeSite = globalData.storeSite;
  const countryCode = globalData.countryCode;
  const currLocale = getLocale();

  const [title, setTitle] = useState(
    "Find a Community Donation Center<sup>TM</sup>"
  );
  const [titleColor, setTitleColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [location, setLocation] = useState([]);
  const [value, setValue] = useState<string | null>("");
  const [inputValue, setInputValue] = useState("");
  const [inputValueCopy, setInputValueCopy] = useState("");
  const [uplift, setUplift] = useState(false);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [placeholderText, setPlaceholderText] = useState("Enter Location");
  const [buttonText, setButtonText] = useState("Search");

  useEffect(() => {
    $(".storeAutoCompleteId div input").attr("aria-label", "Enter location");
    // $(".storeAutoCompleteId div input").attr("aria-required","true");

    if (componentData.title !== undefined && componentData.title !== "") {
      setTitle(componentData.title);
    }

    if (
      componentData.titleColor !== undefined &&
      componentData.titleColor !== ""
    ) {
      setTitleColor(componentData.titleColor);
    }

    if (componentData.uplift !== undefined && componentData.uplift !== "") {
      setUplift(componentData.uplift);
    }

    if (
      componentData.buttonBackgroundColor !== undefined &&
      componentData.buttonBackgroundColor !== ""
    ) {
      setButtonBackgroundColor(componentData.buttonBackgroundColor);
    }

    if (
      componentData.buttonTextColor !== undefined &&
      componentData.buttonTextColor !== ""
    ) {
      setButtonTextColor(componentData.buttonTextColor);
    }

    if (
      componentData.backgroundColor !== undefined &&
      componentData.backgroundColor !== ""
    ) {
      setBackgroundColor(componentData.backgroundColor);
    } else {
      setBackgroundColor("#E1251B");
    }

    if (
      componentData.buttonText !== undefined &&
      componentData.buttonText !== ""
    ) {
      setButtonText(componentData.buttonText);
    }

    if (
      componentData.placeholderText !== undefined &&
      componentData.placeholderText !== ""
    ) {
      setPlaceholderText(componentData.placeholderText);
    }
  }, [componentData]);

  const fetchLocations = async () => {
    try {
      if (location.length <= 0) {
        const response = await fetch(
          storeUrl + "api/getAutocompleteData?date=" + Date().toLocaleString()
        );
        const data = await response.json();
        if (data.data.length > 0) {
          setLocation(data.data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      await fetchLocations();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRedirect = (updatedValue: any) => {
    window.location.href =
      storeUrl +
      "?q=" +
      updatedValue +
      "&site=" +
      storeSite +
      "&lang=" +
      langCookieKey +
      "&country=" +
      countryCode;
  };

  const searchQuery = value?.length ? value : inputValue;
  const noOptionsText: Record<string, string> = {
    "fr-CA": "Aucune option",
    "fr-TG": "Aucune option",
    "es-VE": "Sin opciones",
    es: "Sin opciones",
  };

  const handleClick = () => {
    document.getElementById("enter-location-link")?.click();
  };

  return (
    <div
      className={
        "find-store-newsletter " + (uplift === true ? "half-top-reverse" : "")
      }
    >
      <div className="container">
        <div className="newsletter-wrap">
          <div
            className="newsletter-inner"
            style={{ backgroundColor: backgroundColor, color: titleColor }}
          >
            {title ? (
              <p
                className="find-store-label"
                dangerouslySetInnerHTML={{ __html: title }}
                aria-label={title}
              ></p>
            ) : (
              ""
            )}
            <form
              action={
                storeUrl +
                "?q=" +
                searchQuery +
                "&site=" +
                storeSite +
                "&lang=" +
                langCookieKey +
                "&country=" +
                countryCode
              }
              method="post"
              className="storeAutoCompleteId"
            >
              <Autocomplete
                options={location}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ height: "36px", marginTop: 0 }}
                    label={placeholderText}
                    className="items"
                  />
                )}
                style={{
                  width: "252px",
                  height: "36px",
                  backgroundColor: "white",
                  borderRadius: "4px 0px 0px 4px",
                  fontSize: "15px",
                  border: "none",
                  transform: "none",
                }}
                value={value}
                inputValue={inputValue}
                getOptionLabel={(option) => (option ? option : "")}
                onInputChange={(_, value) => {
                  setInputValue(value);
                }}
                onBlur={() => {
                  setInputValueCopy(inputValue);
                }}
                onChange={(_, value) => {
                  setValue(value);
                  if (value) {
                    selectRedirect(value);
                  }
                }}
                noOptionsText={noOptionsText[currLocale] ?? undefined}
              />
              <div
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClick();
                  }
                }}
                onClick={handleClick}
                style={{
                  backgroundColor: buttonBackgroundColor,
                  color: buttonTextColor,
                  borderLeft: "2px solid " + backgroundColor,
                  display: "inline-block",
                  padding: 0,
                }}
                className="btn"
              >
                <a
                  id="enter-location-link"
                  href={
                    storeUrl +
                    "?q=" +
                    inputValueCopy +
                    "&site=" +
                    storeSite +
                    "&lang=" +
                    langCookieKey +
                    "&country=" +
                    countryCode
                  }
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    padding: "0 24px",
                  }}
                >
                  {buttonText}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => {
  return {
    header: state.site.header,
  };
};

FindStore.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { FindStore };

export default connect(mapStateToProps, mapDispatchToProps)(FindStore);

// <form>
//     <input className="textfield-style" type="text" placeholder="Enter location" onChange={(e)=>setSearchedItem(e.target.value)} />
//         <button className="btn" style={{color: backgroundColor, borderLeft: "2px solid "+backgroundColor}}>Search</button>
// </form>
