export default () => ({
  id: "thrift_proud",
  page: {
    title: "Thrift Proud",
    redirect_path: "",
    metatag_image:
      "https://dev-wheel-of-fortune.pantheonsite.io/sites/default/files/2020-09/wof-share-image.jpeg",
    metatag_description: "Meta content goes here",
    status: "ongoing",
  },
  components: [
    {
      componentName: "header",
    },
    {
      componentName: "storeLocatorView",
      data: {
        paragraph:
          "Savers/VV is a for profit company proudly accepting donations on behalf of local nonprofits. Shopping in our stores does not support any nonprofit.",
        anchor_text: "Learn more here",
        donation_text: "Donations at this location benefit",
        donation_anchor_text: "[NPP name with link]",
        logo: "http://45.40.138.118/savers/dev/images/logo.svg",
        search_button_text: "search",
        load_button_text: "load more",
        direction_link_title: "Get Directions",
        view_link_title: "View Details",
        tabs: [
          {
            tab: "List",
            icon: "http://45.40.138.118/savers/dev/images/list.png",
          },
          {
            tab: "Map",
            icon: "http://45.40.138.118/savers/dev/images/map-icon.png",
          },
        ],
        places: [
          {
            id: 1,
            Location: "Savers newZealand",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
          {
            id: 2,
            Location: "Savers Canada",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
          {
            id: 3,
            Location: "Savers America",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
          {
            id: 4,
            Location: "Savers Europe",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode",
            Contact: "(123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
          {
            id: 5,
            Location: "Savers Londone",
            Distance: "13.2mi",
            Address: "123 Address line, City State, zipcode (123)-456-7890",
            Open: "OPEN TODAY UNTIL 7PM",
            Detail:
              "Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam nonumy eirmod.",
          },
        ],
      },
    },
    {
      componentName: "twoColsCardWithoutLayer",
      data: {
        common_image:
          "http://45.40.138.118/savers/dev/images/hover-element.png",
        Two_Colum_Card: [
          {
            title: "Declutter",
            sub_title: "responsibly",
            paragraph: "Our planet and local nonprofit partners thank you.",
            anchor_text: "Find a store",
          },
          {
            title: "Lifestyle",
            paragraph:
              "This is your go-to place for style inspiration, what’s going on in our world, and in our communities.",
            anchor_text: "Read on",
          },
        ],
        Common_Boxes: [
          {
            title:
              "Style your dream space with secondhand finds secondhand finds",
            paragraph:
              "This is your go-to place for style inspiration, what’s going on in our world, and in our communities.",
            anchor_text: "Get Started",
            image: "http://45.40.138.118/savers/dev/images/fun-logo.png",
          },
          {
            title:
              "Style your dream space with secondhand finds secondhand finds",
            paragraph:
              "This is your go-to place for style inspiration, what’s going on in our world, and in our communities.",
            anchor_text: "Read more",
            image: "",
          },
          {
            title:
              "Style your dream space with secondhand finds secondhand finds",
            paragraph:
              "This is your go-to place for style inspiration, what’s going on in our world, and in our communities.",
            anchor_text: "Sign up",
            image: "http://45.40.138.118/savers/dev/images/super.png",
          },
        ],
      },
    },
    {
      componentName: "heroCardMediumTwo",
      data: {
        page: "locatorView",
        title: "Work that matters.",
        sub_title: "Join our team!",
        paragraph: "Learn, grow and be rewarded.",
        anchor_text: "explore opportunities now",
        media_image: "http://45.40.138.118/savers/dev/images/media10.png",
      },
    },
  ],
});
