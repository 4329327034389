import React, { useState, useEffect } from "react";
import ComponentComposer from "../../components/ComponentComposer";
import {fetchStoreLocatorDetail} from "../../api/cms/mock";
import i18next from "i18next";

const StoreLocatorDetail: React.FC<any> = () => {
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {


        fetchdata();
        async  function fetchdata() {
            const data = await  fetchStoreLocatorDetail();
            setData(data.components ? data.components : null);

        }
    },);

    return (
        <div className="savers-page" id="maincontent">
            {data && data.map((d, i) => <ComponentComposer key={i} {...d} />)}
        </div>
    );

}

export default StoreLocatorDetail;
