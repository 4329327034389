import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { addLangKeyPath } from "../../api/cms/api";
import { RootState } from '../../reducers';
import { css } from '@emotion/css'

interface ISearchInputProps {
    searchMagnifyingGlassColor: string, 
    searchInlineTextColor: string,
    searchPlaceholder: string,
    searchText: string
    responsive: boolean
    hide: boolean
}

/**
 * SV-282: componetized search input. 
 * Responsiveness triggers at 1800px screen width, managed by custom css at _header.css, showing only the magnifying glass icon.
 */

const SearchInput: React.FC<ISearchInputProps> = (props: ISearchInputProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    
    const searchPlaceholderText = isFocused ? props.searchPlaceholder : props.searchText;

    if (props.hide) {
        return <div className="search-box" style={{ height: "48px" }} />;
    }

    return (
        <>
        {props.responsive ?
            <a href="javascript:void(0)" className="search-toggle-btn" tabIndex={0} aria-label="search-toggle-btn-link" >
                <i className="fa fa-search" aria-hidden="true" style={{color: props.searchMagnifyingGlassColor}} />
            </a> : null
        }
        <div className="search-box">                    
        <form action={"/search/"+searchItem} tabIndex={-1}>
            <input 
                onFocus={()=>setIsFocused(true)}
                onBlur={()=>setIsFocused(false)}
                type="text"
                placeholder={searchPlaceholderText}
                aria-label="Search"
                onChange={(e)=>{setSearchItem(e.target.value)}}
                style={isFocused ? {boxShadow: `inset 0 0 2px 1px ${props.searchMagnifyingGlassColor}`} : {}}
                className={css`${!isFocused && 
                    `&::placeholder {
                        color: ${props.searchInlineTextColor} !important;
                    }`}
                `}
            />
            <i className="fa fa-search" style={{ color: props.searchMagnifyingGlassColor }} aria-hidden="true"></i>
            <span className="arrow-right" aria-hidden="true" style={{ background: props.searchMagnifyingGlassColor }}>
                <a href={addLangKeyPath("/search/"+searchItem)}><i aria-hidden="true" className="fa fa-arrow-right clr-white" ></i></a>
            </span>
        </form>
        </div>
        </>
    )
}


const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

export { SearchInput };

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);