import {

  AppThunk,
} from './actionTypes';
import authApi from '../api/auth';
import {  SiteActionType } from '../types';



export function gigyaLogin(): AppThunk {
  return async function(dispatch) {

  };
}

export function gigyaLoginGiveaway():AppThunk{
  return async function (dispatch){

    
  }
}

export function gigyaLogOut(): AppThunk{
  return async function(dispatch: any) {

  };
}

export function gigyaJoin(): AppThunk{
  return async function(dispatch) {

  };
}

export function gigyaProfile(): AppThunk{
  return async function(dispatch) {

  };
}



