import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const SmallContainer: React.FC<any> = (componentData) => {
    
  const [descriptionText, setDescriptionText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [smallDescription, setSmallDescription] = useState('');

  useEffect(() => {

   if (componentData.titleText != '' && componentData.titleText != undefined) {
      setTitleText(componentData.titleText);
    }

    if (componentData.descriptionText != '' && componentData.descriptionText != undefined) {
      setDescriptionText(componentData.descriptionText);
    }

    if (componentData.smallDescription != '' && componentData.smallDescription != undefined) {
      setSmallDescription(componentData.smallDescription);
    }


   },[]);

    return (
        <>
         <div className="small-container text-center mt-5">
            <h4 className="pt-3 mb-3">{titleText?titleText:""}</h4>
            <p className="small-text">{smallDescription?smallDescription:""}</p>
            <div className="longform-text">
                <p className="mb-0">{descriptionText?descriptionText:""}</p>
            </div>
          </div> 
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

SmallContainer.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { SmallContainer };

export default connect(mapStateToProps, mapDispatchToProps)(SmallContainer);
