import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';


const SliderBlock: React.FC<any> = (props) => {


    return (
        <>
          <div className="next-prvs-slider-block">
            <div className="container">
                            <hr />
                            <div className="owl-slider">
                                <div id="next-prvs" className="owl-carousel">
                                    {props.slides ? props.slides.map((_item:any)=>{
                                      return(  <div className="item">
                                          <div className="story-inner">
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="story-media">
                                                          <span><img src={_item.url} /></span>
                                                      </div>
                                                  </div>
                                                  <div className="col-md-8">
                                                      <div className="story-info">
                                                          <h6>{_item.title}</h6>
                                                          <p>{_item.desc}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>)
                                    }):null}
                                </div>
                            </div>
            </div>
        </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

SliderBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { SliderBlock };

export default connect(mapStateToProps, mapDispatchToProps)(SliderBlock);
