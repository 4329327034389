import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const ImpactGraph: React.FC<any> = (componentData) => {

    const currLocale = getLocale();
    const[title,setTitle]= useState('');
    const[financiallySqueezedTitle,setFinanciallySqueezedTitle]= useState('');
    const[financiallySqueezedValue,setFinanciallySqueezedValue]= useState('');
    const[financiallySqueezedDescription,setFinanciallySqueezedDescription]= useState('');//
    const[spendingLessTitle,setSpendingLessTitle]= useState('');
    const[spendingLessValue,setSpendingLessValue]= useState('');
    const[spendingLessDescription,setSpendingLessDescription]= useState('');//

    useEffect(()=>
    {

     function fetchComponentData()
    {
      if(componentData.entryId!="" && componentData.entryId!=undefined)
      {
        client.getEntry(componentData.entryId, {locale: currLocale}).then(async(entry : any) => {
          if(entry.fields.financiallySqueezedDescription!="" && entry.fields.financiallySqueezedDescription!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.financiallySqueezedDescription, options);
              setFinanciallySqueezedDescription(rawRichText);
          }   
          if(entry.fields.spendingLessDescription!="" && entry.fields.spendingLessDescription!=undefined)
          {
             const options = {
                renderNode: {
                  [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    return node.data.target.fields.html;
                  },
                  [BLOCKS.EMBEDDED_ASSET]: (node: any) => `<img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.title}"/>`,
                }
              }
              let rawRichText = documentToHtmlString(entry.fields.spendingLessDescription, options);
              setSpendingLessDescription(rawRichText);
          }   
        }); 
      }

    }
    
    fetchComponentData();
  

      if(componentData.title!="" && componentData.title!=undefined)
      {
        setTitle(componentData.title);
      }
      if(componentData.financiallySqueezedTitle!="" && componentData.financiallySqueezedTitle!=undefined)
      {
        setFinanciallySqueezedTitle(componentData.financiallySqueezedTitle);
      }
      if(componentData.financiallySqueezedValue!="" && componentData.financiallySqueezedValue!=undefined)
      {
        setFinanciallySqueezedValue(componentData.financiallySqueezedValue);
      }
      if(componentData.spendingLessTitle!="" && componentData.spendingLessTitle!=undefined)
      {
        setSpendingLessTitle(componentData.spendingLessTitle);
      }
       if(componentData.spendingLessValue!="" && componentData.spendingLessValue!=undefined)
      {
        setSpendingLessValue(componentData.spendingLessValue);
      }

    },[])

    return (
    <>
     <div className="medium-container text-center">
      {title?<div className="impact-head">
         <h2 className="box-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s"><ReactMarkdown allowDangerousHtml source={title} disallowedTypes={['paragraph']} unwrapDisallowed /></h2>
       </div>:null}
     </div>
     <div className="small-container">
       <div className="imapct-right-trends">
         <div className="graph small orange">
           <div className="graph-icon">
             <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
             </div>
             <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">
               <h1>{financiallySqueezedValue} <span>{financiallySqueezedTitle}</span></h1>
             </div>
           </div>
           <div className="graph-info">
             <span className="line"></span>
             {financiallySqueezedDescription?<p dangerouslySetInnerHTML={{__html:financiallySqueezedDescription}}></p>:null}
           </div>
         </div>           
         <div className="graph medium blue" style={{ marginTop: "-190px" }}>
           <div className="graph-icon">
             <div className="ring wow zoomIn" data-wow-duration="2s" data-wow-delay="2s">
             </div>
             <div className="circel wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
               <h1>{spendingLessValue} <span>{spendingLessTitle}</span></h1>
             </div>             </div>
           <div className="graph-info">
             <span className="line"></span>
             {spendingLessDescription?<p dangerouslySetInnerHTML={{__html:spendingLessDescription}}></p>:null}
           </div>
         </div>        
       </div>
     </div>
    </>

    );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ImpactGraph.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ImpactGraph };

export default connect(mapStateToProps, mapDispatchToProps)(ImpactGraph);
