import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import { Button, useMediaQuery } from "@material-ui/core";
import ReactHTMLParser from "react-html-parser";

const FullBleedBannerFive: React.FC<any> = (componentData) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isTablet = useMediaQuery("(max-width:992px)");
  const isMobile = useMediaQuery("(max-width:767px)");

  const [videoURL, setVideoURL] = useState("");
  const [videoURLMobile, setVideoURLMobile] = useState("");
  const [imageURLMobile, setImageURLMobile] = useState("");
  const [isPlaying, setIsPlaying] = useState(true);
  const [title, setTitle] = useState("");
  const [titleFontColor, setTitleFontColor] = useState("");
  const [titleIcon, setTitleIcon] = useState("");
  const [iconAltText, setIconAltText] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionFontColor, setDescriptionFontColor] = useState("");
  const [desktopHeight, setDesktopHeight] = useState<number | null>(null);
  const [tabHeight, setTabHeight] = useState<number | null>(null);
  const [mobileHeight, setMobileHeight] = useState<number | null>(null);
  const [contentAlignmentClass, setContentAlignmentClass] = useState({
    desktop: "justify-content-center",
    mobile: "justify-content-center",
  });
  const [verticalAlignmentClass, setVerticalAlignmentClass] = useState({
    desktop: "justify-content-center",
    mobile: "justify-content-center",
  });
  const [textAlignmentClass, setTextAlignmentClass] = useState({
    desktop: "text-center",
    mobile: "text-center",
  });
  const [lineHeight, setLineHeight] = useState(1.2);
  const [showTextOnMobile, setShowTextOnMobile] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const videoHeight = useMemo(() => {
    if (isMobile) return mobileHeight || "auto";
    if (isTablet) return tabHeight || "auto";
    return desktopHeight || "auto";
  }, [isMobile, isTablet, mobileHeight, tabHeight, desktopHeight]);

  const videoURLCurrent = useMemo(() => {
    if (isMobile && videoURLMobile) {
      return videoURLMobile;
    }
    return videoURL;
  }, [isMobile, videoURL, videoURLMobile]);

  useEffect(() => {
    if (componentData.title) {
      setTitle(componentData.title);
    }

    if (componentData.titleFontColor) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.titleIcon) {
      setIconAltText(componentData.titleIcon.fields.title);
      setTitleIcon(componentData.titleIcon.fields.file.url);
    }

    if (componentData.description) {
      setDescription(componentData.description);
    }

    if (componentData.descriptionFontColor) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.backgroundColor) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.fullBleedBanner5Video) {
      setVideoURL(componentData.fullBleedBanner5Video.fields.file.url);
    }

    if (componentData.fullBleedBanner5VideoMobile) {
      setVideoURLMobile(
        componentData.fullBleedBanner5VideoMobile.fields.file.url
      );
    }

    if (componentData.mobileBackgroundImage) {
      setImageURLMobile(componentData.mobileBackgroundImage.fields.file.url);
    }

    if (componentData.fullBleedBanner3DesktopHeight) {
      setDesktopHeight(componentData.fullBleedBanner3DesktopHeight);
    }

    if (componentData.fullBleedBanner3MobileHeight) {
      setMobileHeight(componentData.fullBleedBanner3MobileHeight);
    }

    if (componentData.fullBleedBanner3TabHeight) {
      setTabHeight(componentData.fullBleedBanner3TabHeight);
    }

    if (componentData.contentAlignmentBanner5) {
      const [desktopAlign, mobileAlign] = componentData.contentAlignmentBanner5
        .split(" / ")
        .map((a: string) => a.split(" ")[0].toLowerCase());

      setContentAlignmentClass({
        desktop: `justify-content-${desktopAlign}`,
        mobile: `justify-content-${mobileAlign || desktopAlign}`,
      });
    }

    if (componentData.verticalAlignment) {
      const [desktopAlign, mobileAlign] = componentData.verticalAlignment
        .split(" / ")
        .map((a: string) => a.split(" ")[0].toLowerCase());

      setVerticalAlignmentClass({
        desktop: `justify-content-${desktopAlign}`,
        mobile: `justify-content-${mobileAlign || desktopAlign}`,
      });
    }

    if (componentData.textAlignment) {
      const [desktopAlign, mobileAlign] = componentData.textAlignment
        .split(" / ")
        .map((a: string) => a.split(" ")[0].toLowerCase());

      setTextAlignmentClass({
        desktop: `text-${desktopAlign}`,
        mobile: `text-${mobileAlign || desktopAlign}`,
      });
    }

    if (componentData.lineHeight) {
      setLineHeight(componentData.lineHeight);
    }

    if (componentData.showTextOnMobile != null) {
      setShowTextOnMobile(componentData.showTextOnMobile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="impact-main-wrapper">
      <div
        className="full-bleed-banner5 text-center banner-solid-bg"
        style={backgroundColor ? { backgroundColor } : {}}
      >
        {videoURLCurrent ? (
          <div className="video-container">
            {isMobile && imageURLMobile ? (
              <img
                className="image-mobile"
                alt={`Banner Mobile: ${title}`}
                src={imageURLMobile}
              />
            ) : (
              <video
                tabIndex={1}
                ref={videoRef}
                className="video-element"
                aria-label={title}
                autoPlay
                loop
                muted
                playsInline
                src={videoURLCurrent}
                height={videoHeight}
              />
            )}

            <div
              className={`container banner-container ${
                isMobile
                  ? contentAlignmentClass.mobile
                  : contentAlignmentClass.desktop
              }`}
            >
              <div
                className={`banner-info ${
                  isMobile
                    ? textAlignmentClass.mobile
                    : textAlignmentClass.desktop
                } ${
                  isMobile
                    ? verticalAlignmentClass.mobile
                    : verticalAlignmentClass.desktop
                } ${isMobile && !showTextOnMobile ? "no-display-mobile" : ""}`}
                style={{ lineHeight }}
              >
                <div>
                  {titleIcon ? (
                    <div className="small-banner-logo">
                      <img
                        className=""
                        width="100"
                        height="82"
                        src={titleIcon}
                        alt={iconAltText ?? ""}
                      />
                    </div>
                  ) : null}
                  {title ? (
                    <h1
                      className="big clr-white"
                      style={{ color: titleFontColor }}
                    >
                      <samp dangerouslySetInnerHTML={{ __html: title }}></samp>
                    </h1>
                  ) : null}
                </div>
                {description ? (
                  <h1
                    className="clr-white"
                    style={{ color: descriptionFontColor }}
                  >
                    {ReactHTMLParser(description)}
                  </h1>
                ) : null}
              </div>
            </div>
            {(!isMobile || !imageURLMobile) && (
              <div className="play-pause-button">
                <Button
                  variant="contained"
                  size="small"
                  className="mui-play-pause-button"
                  onClick={togglePlayPause}
                  aria-label={isPlaying ? "Pause video" : "Play video"}
                >
                  {isPlaying ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      className="svg-icon"
                    >
                      <path fill="#fff" d="M0 0h3v9H0zM6 0h3v9H6z"></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      className="svg-icon"
                    >
                      <path fill="#fff" d="M0 0v9l10-4.5z"></path>
                    </svg>
                  )}
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

FullBleedBannerFive.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { FullBleedBannerFive };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullBleedBannerFive);
