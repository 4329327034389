import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';

const StoreNearBy: React.FC<any> = (props) => {
    useEffect(() => {
        fetchdata();
        async  function fetchdata() {
        }


    });

    const {

        site_logo,
        storeText,
        storeTextMobile,
        searchText,
        HeaderOptions,
        menuItems
    } = props.header;

    return (
        <div className="store-near-by bg-grey">
            <div className="container">
                <h4 className="mb-4">Stores nearby</h4>
                <div className="row">
                    <div className="col-md-4">
                        <div className="address-box">
                            <div className="logo-row">
                                <img src={props.logo} alt="logo" />
                            </div>
                            <div className="location-name">
                                <h6>{props.places[0].Location}</h6>
                                <span className="distance">{props.places[0].Distance}</span>
                            </div>
                            <p>{props.places[0].Address} <span
                                className="phone-number">{props.places[0].Contact}</span></p>
                            <h6 className="opening-time small-text clr-green">{props.places[0].Open}</h6>
                            <p className="small-text">{props.places[0].Detail}</p>
                            <div className="directions-links">
                                <a href="#">{props.direction_link_title}</a>
                                <a href="#">{props.view_link_title}</a>
                            </div>
                            <p className="donation-text small-text mb-0">{props.donation_text} <a href="#">{props.donation_anchor_text}</a></p>
                        </div>
                    </div>

                    <div className="col-md-4">
                       <div className="address-box">
                            <div className="logo-row">
                                <img src={props.logo} alt="logo"/>
                            </div>
                            <div className="location-name">
                                <h6>{props.places[1].Location}</h6>
                                <span className="distance">{props.places[1].Distance}</span>
                            </div>
                            <p>{props.places[1].Address} <span
                                className="phone-number">{props.places[1].Contact}</span></p>
                            <h6 className="opening-time small-text clr-green">{props.places[1].Open}</h6>
                            <p className="small-text">{props.places[1].Detail}</p>
                            <div className="directions-links">
                                <a href="#">{props.direction_link_title}</a>
                                <a href="#">{props.view_link_title}</a>
                            </div>
                            <p className="donation-text small-text mb-0">{props.donation_text} <a href="#">{props.donation_anchor_text}</a></p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="address-box">
                            <div className="logo-row">
                                <img src={props.logo} alt="logo"/>
                            </div>
                            <div className="location-name">
                                <h6>{props.places[0].Location}</h6>
                                <span className="distance">{props.places[0].Distance}</span>
                            </div>
                            <p>{props.places[0].Address} <span
                                className="phone-number">{props.places[0].Contact}</span></p>
                            <h6 className="opening-time small-text clr-green">{props.places[0].Open}</h6>
                            <p className="small-text">{props.places[0].Detail}</p>
                            <div className="directions-links">
                                <a href="#">{props.direction_link_title}</a>
                                <a href="#">{props.view_link_title}</a>
                            </div>
                            <p className="donation-text small-text mb-0">{props.donation_text} <a href="#">{props.donation_anchor_text}</a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData()),
    onLogIn: () => dispatch(gigyaLogin()),
    onLogOut: () => dispatch(gigyaLogOut()),
    onJoin: () => dispatch(gigyaJoin()),
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

StoreNearBy.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { StoreNearBy };

export default connect(mapStateToProps, mapDispatchToProps)(StoreNearBy);
