import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { connect } from 'react-redux';
import {
    gigyaLogin,
    gigyaJoin,
    gigyaLogOut,
} from '../../actions/auth';
import { loadPageData } from "../../actions/cms";
import { addLangKeyPath } from "../../api/cms/api";
import { RootState } from '../../reducers';
import { css } from "@emotion/react";
/** @jsx jsx */
import { jsx } from '@emotion/react';

interface IFindStoreInputProps {
    searchBorderPillColor: string,
    searchInlineTextColor: string,
    searchPlaceholder: string, 
    storeUrl: any, 
    storeSite: any, 
    countryCode: any, 
    langCookieKey: any, 
    storeLocation: any,
    storeText: any,
    dropdownVisible: boolean,
    setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>,
    handleFocus: (e: any) => Promise<void>, 
    selectRedirect: (updatedValue: any) => void,
    responsive: boolean
    hide: boolean
}

/**
 * SV-282: componetized search input. 
 * Responsiveness triggers at 1800px screen width, managed by custom css at _header.css, showing only the map pin icon.
 */

const FindStoreInput: React.FC<IFindStoreInputProps> = (props: IFindStoreInputProps) => {
    const [value, setValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    if (props.hide) {
        return <div className="btn find-store-btn" />;
    }

    return (
        <React.Fragment> 
        {props.responsive && <a href="javascript:void(0)" className="store-toggle-btn" tabIndex={0} aria-label="store-toggle-btn-link" ><i className="fa fa-map-marker" aria-hidden="true" style={{color: props.searchBorderPillColor}} /></a>}
        <div className="btn find-store-btn">
            <form 
                action={props.storeUrl+"?q="+value+"&site="+props.storeSite+"&lang="+props.langCookieKey+"&country="+props.countryCode} 
                method="post" 
                tabIndex={-1}
                css={css`
                    div input::placeholder {
                        color: ${props.searchInlineTextColor} !important;
                    }
                `}
                className="storeAutocompleteId"
            >
                <Autocomplete
                    wrapperProps={{ role: 'listbox', id: 'store-finder' }}
                    aria-hidden='true'
                    items={props.storeLocation}
                    inputProps={{ style: isFocused ? {boxShadow: `inset 0 0 2px 1px ${props.searchBorderPillColor}`} : {}, placeholder: props.storeText, "aria-controls":"store-finder"}}
                    shouldItemRender={(item, value) => item.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item}
                    renderItem={(item, highlighted) =>
                        <div className="items" role="option" aria-selected={highlighted ? 'true' : 'false'} style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}>
                            {item}
                        </div>
                    }
                    value={value}
                    onChange={e =>{
                        setValue(e.target.value);
                        props.handleFocus(e);
                    }}
                    onSelect={value => {
                        setValue(value);
                        props.selectRedirect(value);
                    }}
                    onMenuVisibilityChange={isOpen => {
                        setIsFocused(isOpen);
                        value.length > 0 ? props.setDropdownVisible(isOpen) : props.setDropdownVisible(false);
                    }}
                    open={props.dropdownVisible}
                />
                <i className="fa fa-map-marker" aria-hidden="true" style={{color: props.searchBorderPillColor}}></i>
                <span className="arrow-right" aria-hidden="true" style={{ background: props.searchBorderPillColor }}>
                    <a href={props.storeUrl+"?q="+value+"&site="+props.storeSite+"&lang="+props.langCookieKey+"&country="+props.countryCode}>
                        <i className="fa fa-arrow-right clr-white" aria-hidden="true" />
                    </a>
                </span>
            </form> 
        </div>
        </React.Fragment>
    )
}


const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

export { FindStoreInput };

export default connect(mapStateToProps, mapDispatchToProps)(FindStoreInput);