import {createClient} from 'contentful';
import './config';

const siteEnv = global.config.siteEnv;
const accessToken = global.config.accessToken;
const client = createClient({
  space: '312853a4ptgv',
  environment: siteEnv,
  accessToken: accessToken
})

export default client